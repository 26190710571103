import { SVGIconProps } from "./types";

export default function Folder({
  width = 16,
  height = 16,
  onClick,
  className
}: SVGIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width={width}
      height={height}
      onClick={onClick}
      className={className}
    >
      <path d="M464 128H272l-64-64H48C21.5 64 0 85.5 0 112v288c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V176c0-26.5-21.5-48-48-48z" />
    </svg>
  );
}

/* External Imports */

/* Local Imports */
import DashboardCard from "@/components/cards/dashboardCard";
import Button from "@/components/button";
import Icon from "@/components/icons";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

/* Constants */
import { STYLES, METRICS, METRIC_LABELS } from "@/globals/constants";

/* Store */
import { EncounterInfo } from "@/store/services/encounter";

/* Styles */
import styles from "./styles.module.scss";
import { useCustomPracticeLocations } from "@/globals/helpers/customHooks";

/* VisitSumary Typescript Interface */
interface VisitSummaryProps {
  encounter: EncounterInfo;
}

// Component to display the visit summary if encounter is viewed as a patient
export default function VisitSummary({
  encounter,
}: VisitSummaryProps) {

  // Display the name and value of the summary items
  function NameValueDisplay({ name, value }: { name: string; value: string | undefined }) {
    if (!value) {
      value = "N/A";
    }
    return (
      <div className={styles.summaryItem}>
        <div className={styles.name}>{name}</div>
        <div className={styles.value}>{value}</div>
      </div>
    );
  }

  // Determine what to display in the summary items
  const customPracticeLocations = useCustomPracticeLocations();
  const matchedLocation = customPracticeLocations.find(location => 
    (typeof location === "object" ? location.location_id : location) === encounter.location
  );
  const locString = typeof matchedLocation === "object" ? matchedLocation.name : matchedLocation ?? "N/A";
  const displayDate = encounter.appointment?.starts ?? encounter.start ?? encounter.created ?? null;
  const displayType = encounter.appointment?.appointment_type.appointment_type ?? encounter.appointment_type?.appointment_type ?? encounter.encounter_type;

  // Items to display in the summary card
  const summaryItems = [
    { name: "Date", value: displayDate ? convertUtcIntToLocalDatetime(displayDate).format("MM/DD/YYYY hh:mm A") : "N/A" },
    { name: "Clinical Provider", value: encounter.provider ? encounter.provider.first_name + " " + encounter.provider.last_name : "N/A" },
    { name: "Type", value: displayType && METRICS[displayType] ? METRIC_LABELS[METRICS[displayType]] : "N/A"},
    { name: "Location", value: locString },
  ];

  // Function to convert the summary to PDF and print
  // TODO: Improve print page functionality
  const handlePrint = () => {
    window.print();
  };

  const printButton = (
    <Button style={STYLES.SECONDARY} onClick={handlePrint}>
      <Icon svg="printer" />
      Print
    </Button>
  )

  return (
    <DashboardCard title="Visit Summary" customRightElement={printButton} className={styles.VisitSummary}>
      <div className={styles.VisitSummaryItems}>
        {summaryItems.map((item, index) => (
          <NameValueDisplay key={index} name={item.name} value={item.value} />
        ))}
      </div>
    </DashboardCard>
  );
}

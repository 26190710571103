/* Note form component */
/* External Imports */
import clsx from "clsx";
import { useForm, Controller } from "react-hook-form";
import { useEffect } from "react";
import dayjs from "dayjs";
/* Local Imports */

// components
import TextArea from "@/components/textArea";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import Button from "@/components/button";
import Input from "@/components/input";
import Clock from "../../../../public/svgs/clock.svg";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// constants
import { STYLES } from "@/globals/constants";

// store

// styles
import styles from "./styles.module.scss";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* Noteform Typescript Interface */
interface NoteformProps {
  onSubmit: (data: any, reset: (values: any) => void) => void;
  defaultValues?: any;
  disabled?: boolean;
}

export default function Noteform({
  onSubmit,
  defaultValues,
  disabled = false
}: NoteformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    reset
  } = form;
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */
  useEffect(() => {
    if (defaultValues) {
      reset(
        {
          ...defaultValues
        },
        { keepValues: true }
      );
    }
  }, [defaultValues]);

  return (
    <form
      className={clsx(styles.LaborForm, styles.Noteform)}
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data, reset);
      })}
    >
      <Controller
        name="start_time"
        control={control}
        render={({ field: { onChange, name, value } }) => {
          return (
            <DateTimeStamp
              name={name}
              onChange={onChange}
              initialValue={defaultValues && defaultValues.start_time}
              value={value}
            />
          );
        }}
      />
      <div className={styles.fullWidth}>
        <ControlledTextArea
          label={"SOAP Note"}
          name={"soap_note"}
          id={"soap_note"}
          hiddenLabel={true}
          placeholder={"Add notes here."}
          rows={12}
          form={form}
          disabled={disabled}
        />
      </div>
      <div className={styles.fullWidth}>
        <Button
          style={STYLES.FULL_WIDTH}
          type="submit"
          nativeButtonProps={{ disabled }}
        >
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}

/* MaternalProgress form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
/* Local Imports */

// components
import Input from "@/components/input";
import Button from "@/components/button";
import ControlledCombobox from "@/components/input/controlledCombobox";
import Clock from "../../../../public/svgs/clock.svg";

// constants
import { STYLES } from "@/globals/constants";
import { FORM_METRICS, FORM_VALUES } from "./constants";
import { EVENTS } from "@/components/flows/labor/constants";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";
// store

// styles
import styles from "./styles.module.scss";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* MaternalProgressform Typescript Interface */
interface MaternalProgressformProps {
  onSubmit: (data: any, reset: (values: any) => void) => void;
  defaultValues: any;
  disabled?: boolean;
}

export default function MaternalProgressform({
  onSubmit,
  defaultValues,
  disabled = false
}: MaternalProgressformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    setValue,
    reset
  } = form;

  /* Redux */

  /* Local State */
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);

  return (
    <form
      data-cy="progress-update-form"
      className={clsx(styles.LaborForm, styles.MaternalProgressForm)}
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data, reset);
      })}
    >
      <fieldset
        className={clsx(styles.time, styles.progressTime, styles.spanTwo)}
      >
        <legend className={clsx("t4 dark", styles.sectionTitle)}>
          Start time
        </legend>
        <Controller
          name="start_time"
          control={control}
          render={({ field: { onChange, name, value } }) => {
            return (
              <DateTimeStamp
                name={name}
                onChange={onChange}
                value={value}
                hiddenLabel
              />
            );
          }}
        />
      </fieldset>
      <fieldset className={clsx(styles.progressSection, styles.spanSix)}>
        <legend className={clsx("t4 dark", styles.sectionTitle)}>Vitals</legend>
        <div className={styles.inputs}>
          <Input
            type="text"
            label="Respirations"
            name="respirations"
            id="respirations"
            register={register}
            disabled={disabled}
          />
          <Input
            type="text"
            label="Temp"
            name="temp"
            id="temp"
            register={register}
            disabled={disabled}
          />
          <Input
            type="text"
            label="Heart Rate"
            name="heart_rate"
            id="heart_rate"
            register={register}
            disabled={disabled}
          />
          <Input
            type="text"
            label="Blood Pressure"
            name="BP"
            id="BP"
            register={register}
            disabled={disabled}
          />
          <Input
            type="text"
            label="Fundus"
            name="fundus"
            id="fundus"
            register={register}
            disabled={disabled}
          />
          <Input
            type="text"
            label="Inputs"
            name="inputs"
            id="inputs"
            register={register}
            disabled={disabled}
          />
          <Input
            type="text"
            label="Outputs"
            name="outputs"
            id="outputs"
            register={register}
            disabled={disabled}
          />
          <Input
            type="number"
            label="Estimated Bleeding"
            name={"est_bleeding"}
            id={"est_bleeding"}
            register={register}
            disabled={disabled}
          />
          <ControlledCombobox
            options={FORM_VALUES[EVENTS.BIRTH][FORM_METRICS.MATERNAL_POS]}
            label="Maternal Position"
            name={"maternal_position"}
            control={control}
            isHorizontalLayout={false}
            errors={errors}
            fullWidth
            disabled={disabled}
          />
        </div>
      </fieldset>
      <fieldset className={clsx(styles.fullWidth, styles.progressTime)}>
        <legend>Comments</legend>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          hiddenLabel
          rows={textAreaFocused ? 4 : 1}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
        />
      </fieldset>
      <div className={styles.fullWidth}>
        <Button
          style={STYLES.FULL_WIDTH}
          type="submit"
          nativeButtonProps={{ disabled }}
        >
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}

import * as generated from "./generated/cases";

const cases = generated.cases.enhanceEndpoints({
  endpoints: {
    caseCreate: {
      invalidatesTags: ["CareHistory"]
    },
    caseRead: {
      providesTags: ["Case"]
    },
    caseUpdate: {
      invalidatesTags: ["Case", "CareHistory"]
    },
    caseDelete: {
      invalidatesTags: ["CareHistory"]
    },
    caseCommentCreate: {
      invalidatesTags: ["Case"]
    },
    caseCommentDelete: {
      invalidatesTags: ["Case"]
    },
    caseCommentUpdate: {
      invalidatesTags: ["Case"]
    }
  }
});

export * from "./generated/cases";
export default cases;
export const {
  useCaseCreateMutation,
  useCaseReadQuery,
  useLazyCaseReadQuery,
  useCaseUpdateMutation,
  useCaseDeleteMutation
} = cases;

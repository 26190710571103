import clsx from "clsx";
import { sanitize } from "dompurify";
import { useFormContext } from "react-hook-form";

import ContentRenderer from "@/components/textArea/contentRenderer";
import ControlledTextArea from "@/components/textArea/controlledTextArea";

import { EncounterInfo } from "@/store/services/encounter";

import styles from "./styles.module.scss";

interface NoteTabItemProps {
  isAutosaveError: boolean;
  autosaveNotification: string;
  encounter: EncounterInfo;
  isEditMode: boolean;
}

export default function NoteEditor({
  autosaveNotification,
  encounter,
  isAutosaveError,
  isEditMode
}: NoteTabItemProps) {
  const form = useFormContext();

  return (
    <div className={styles.formType}>
      {isEditMode ? (
        <>
          <ControlledTextArea
            label="Note"
            hiddenLabel
            placeholder="Start typing or enter dot phrases to generate templates"
            id="note"
            name="note"
            required={false}
            setValue={form.setValue}
            content={encounter.note || ""}
            submitOnEnter
            form={form}
            rows={14}
          />
          <p
            className={clsx(
              { [styles.autosaveError]: isAutosaveError },
              styles.autosaveNotification
            )}
          >
            {autosaveNotification}
          </p>
        </>
      ) : (
        <ContentRenderer content={sanitize(encounter.note || "")} />
      )}
    </div>
  );
}

import { useState, useEffect, useRef } from "react";
import clsx from "clsx";
import { useCombobox } from "downshift";
import Icon from "@/components/icons";
import styles from "./styles.module.scss";

interface ComboboxProps<T> {
  options: T[];
  label: string;
  name?: string;
  placeholder?: string;
  required?: boolean;
  onChange?: (item: T | null) => void;
  onSearch?: (item: string) => void;
  hiddenLabel?: boolean;
  labelAcc?: (item: T) => string;
  initialValue?: T | null;
  error?: string;
  noFilter?: boolean;
  selectOnInput?: boolean;
  fullWidth?: boolean;
  noMenu?: boolean;
  isHorizontalLayout?: boolean;
  clearOnSelect?: boolean;
  isDisabled?: boolean;
  onEnter?: (d: string) => void;
  value?: T | null;
  valueKey?: keyof T;
  setNullValues?: boolean;
  hasClearButton?: boolean;
  debounce?: boolean;
}

/**
 * ComboboxSelect component provides a customizable combobox input field with various features such as filtering, debouncing, and custom rendering.
 *
 * @template T - The type of the options.
 *
 * @param {ComboboxProps<T>} props - The properties for the ComboboxSelect component.
 * @param {string} [props.placeholder="Select"] - The placeholder text for the input field.
 * @param {string} props.label - The label for the combobox.
 * @param {string} [props.name] - The name attribute for the input field.
 * @param {T[]} [props.options=[]] - The list of options to display in the combobox.
 * @param {(item: T | null) => void} [props.onChange] - Callback function when the selected item changes.
 * @param {(item: string) => void} [props.onSearch] - Callback function when the input value changes.
 * @param {boolean} [props.hiddenLabel=false] - Whether to hide the label visually.
 * @param {(item: T) => string} [props.labelAcc=item => item] - Function to access the label of an option.
 * @param {T | null} [props.initialValue=null] - The initial selected value.
 * @param {string} [props.error] - Error message to display.
 * @param {boolean} [props.noFilter=false] - Whether to disable filtering of options.
 * @param {boolean} [props.selectOnInput=false] - Whether to select the first option on input.
 * @param {boolean} [props.fullWidth=false] - Whether the combobox should take the full width of its container.
 * @param {boolean} [props.noMenu=false] - Whether to hide the dropdown menu.
 * @param {boolean} [props.isHorizontalLayout=false] - Whether to use a horizontal layout.
 * @param {boolean} [props.clearOnSelect] - Whether to clear the input field on selection.
 * @param {boolean} [props.isDisabled=false] - Whether the input field is disabled.
 * @param {(d: string) => void} [props.onEnter] - Callback function when the Enter key is pressed.
 * @param {T | null} [props.value] - The controlled value of the combobox.
 * @param {keyof T} [props.valueKey] - The key to access the value of an option.
 * @param {boolean} [props.setNullValues=false] - Whether to allow null values.
 * @param {boolean} [props.hasClearButton=false] - Whether to show a clear button.
 * @param {boolean} [props.debounce=false] - Whether to debounce the search input.
 *
 * @returns {JSX.Element} The rendered ComboboxSelect component.
 */
export default function ComboboxSelect({
  placeholder = "Select",
  label,
  name,
  options = [],
  onChange,
  onSearch,
  hiddenLabel = false,
  labelAcc = item => item,
  initialValue = null,
  error,
  noFilter = false,
  selectOnInput = false,
  fullWidth = false,
  noMenu = false,
  isHorizontalLayout = false,
  clearOnSelect,
  isDisabled = false,
  onEnter,
  value,
  valueKey,
  setNullValues = false,
  hasClearButton = false,
  debounce = false
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
}: ComboboxProps<any>) {
  const [selectedItem, setSelectedItem] = useState<typeof initialValue>(initialValue);
  const [doNotFilter, setDoNotFilter] = useState(noFilter);
  const [items, setItems] = useState(options);
  const [debounceInputValue, setDebounceInputValue] = useState("");
  const [dropdownPosition, setDropdownPosition] = useState("bottom");
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setItems(options);
  }, [options]);

  useEffect(() => {
    if (selectedItem && onChange) onChange(selectedItem);
  }, [selectedItem, onChange]);

  useEffect(() => {
    if (valueKey) return;
    if (value || value === "" || (setNullValues && value === null)) {
      setSelectedItem(value);
      if (onChange) {
        onChange(value);
      }
    }
  }, [value]);

  useEffect(() => {
    if (value || value === "") {
      setSelectedItem(value);
      if (onChange) {
        onChange(value);
      }
    }
  }, [valueKey]);

  useEffect(() => {
    if (!debounce || !onSearch) return;
    const debouncedSearch = setTimeout(() => {
      onSearch(debounceInputValue);
    }, 500);

    return () => clearTimeout(debouncedSearch);
  }, [debounceInputValue]);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    highlightedIndex,
    getItemProps,
    reset
  } = useCombobox({
    items,
    selectedItem,
    itemToString(item) {
      return item ? labelAcc(item) : "";
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (onChange) {
        onChange(selectedItem);
      }
      if (clearOnSelect) {
        reset();
        if (inputRef.current) inputRef?.current?.blur();
      }
    },
    onStateChange: changes => {
      if (changes.type === "__input_keydown_enter__") {
        if (inputRef && inputRef.current && inputRef.current.value && onEnter) {
          onEnter(inputRef.current.value);
        }
      }
    },
    onInputValueChange: ({ inputValue = "" }) => {
      if (onSearch) {
        if (debounce) {
          setDebounceInputValue(inputValue);
        } else {
          onSearch(inputValue);
        }
      }

      const filteredOptions = doNotFilter
        ? options
        : options.filter(item => {
            const inputValueLowerCase = inputValue?.toLowerCase().trim();
            const itemLowerCase = labelAcc && labelAcc(item).toLowerCase();

            if (inputValueLowerCase === "") {
              return true;
            }

            return (
              itemLowerCase.includes(inputValueLowerCase) ||
              itemLowerCase.includes(inputValueLowerCase.replace(/\s/g, ""))
            );
          });
      setDoNotFilter(false);
      setItems(filteredOptions);
      if (selectOnInput && !(filteredOptions.length > 0)) {
        if (onChange) {
          onChange(null);
        }
      }

      if (inputValue === "") {
        setSelectedItem(null);
        if (onChange) {
          onChange(null);
        }
      }
    },
    onIsOpenChange: ({ isOpen }) => {
      if (isOpen) {
        const inputRect = inputRef.current?.getBoundingClientRect();
        const viewportHeight = window.innerHeight;

        if (inputRect) {
          const spaceAbove = inputRect.top;
          const spaceBelow = viewportHeight - inputRect.bottom;

          let position = "bottom";

          if (spaceBelow < 400 && spaceAbove > spaceBelow) {
            position = "top";
          }
          setDropdownPosition(position);
        }
      }
    }
  });

  const handleInputClick = () => {
    setDoNotFilter(true);
    if (inputRef && inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
    <div
      className={clsx(styles.Input, styles.Select, {
        [styles.fullWidth]: fullWidth,
        [styles.horizontal]: isHorizontalLayout
      })}
      data-cy="input-combobox"
      data-cy-name={name}
    >
      <label
        {...getLabelProps()}
        className={clsx({ [styles.hidden]: hiddenLabel })}
      >
        {label}
      </label>
      <div className={styles.inputWrapper}>
        <input
          {...getInputProps({
            ref: inputRef && inputRef,
            placeholder,
            onClick: handleInputClick,
            disabled: isDisabled
          })}
          data-cy={`${name}-input`}
          name={name}
          className={clsx({
            [styles.hasError]: error
          })}
        />
        {hasClearButton && (
          <button
            type="button"
            className={styles.clear}
            onClick={() => {
              reset();
            }}
            aria-label="Clear input field"
          >
            <Icon svg="close_grey" width={7} />
          </button>
        )}

        {!noMenu && (
          <button
            type="button"
            className={styles.close}
            {...getToggleButtonProps()}
          >
            <Icon svg="caret_down" width={10} />
          </button>
        )}
        <ul
          {...getMenuProps()}
          className={clsx({
            [styles.open]: items.length > 0 && isOpen && !noMenu,
            [styles[dropdownPosition]]: true
          })}
        >
          {isOpen &&
            !noMenu &&
            items.map((item, index) => (
              <li
                style={{
                  backgroundColor: highlightedIndex === index ? "#F9FAFB" : null
                }}
                key={`${item}${index}`}
                {...getItemProps({
                  item,
                  index
                })}
                data-cy="combobox-option"
              >
                {item ? labelAcc(item) : ""}
              </li>
            ))}
        </ul>
      </div>
      {error && <div className={styles.validationError}>{error}</div>}
    </div>
  );
}

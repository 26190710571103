// third-party
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";

// components
import Button from "@/components/button";
import DashboardCard from "@/components/cards/dashboardCard";
import Datagrid from "@/components/datagrid";
import { ModalComponentProps, ModalController, useModal } from "@/components/modalV2/ModalController";

// icons
import Plus from "@/../public/svgs/plus.svg";
import Files from "@/../public/svgs/files.svg";
import Edit from "@/../public/svgs/edit.svg";

// store
import { PatientInfo, Pregnancy } from "@/store/services/patient";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";

// styles
import styles from "./styles.module.scss";

// constants
import { METRICS, STYLES, APP_KEYS } from "@/globals/constants";
import { MODAL_TYPES } from "@/components/modal/dispatcher";

// utils
import { getWeightObjectFromGrams } from "@/components/input/weightInput";

export interface PastPregnanciesProps {
  patient: PatientInfo;
  isOpen?: boolean;
}

export default function PastPregnancies({
  patient,
}: PastPregnanciesProps) {
  const isMobile = useMediaQuery({ query: "(max-width: 1200px)" });
  const dispatch = useDispatch();

  const [noteModalActions, noteModalState] = useModal<NoteModalProps>();

  const pregnancyData = useMemo(() => {
    const pastPregnancyData = (pregnancies: Pregnancy[]) => {
      return pregnancies.map(pregnancy => {
        return {
          id: pregnancy.pregnancy_id,
          metrics: {
            [METRICS.DELIVERY_DATE]: pregnancy.delivery_date || "-",
            [METRICS.GESTATIONAL_AGE]: pregnancy.gestational_age_at_delivery
              ? `${Math.floor(pregnancy.gestational_age_at_delivery / 7)} w ${
                  pregnancy.gestational_age_at_delivery % 7
                } days`
              : "-",
            [METRICS.LABOR_LENGTH]: pregnancy.length_of_labor_minutes
              ? `${Math.floor(pregnancy.length_of_labor_minutes / 60)} hrs`
              : "-",
            [METRICS.PREGNANCY_OUTCOME]: pregnancy.outcome,
            [METRICS.LIVING]: pregnancy.living ? "Yes" : "-",
            [METRICS.WEIGHT]: pregnancy.weight
              ? `${getWeightObjectFromGrams(pregnancy.weight).pounds} lbs ${
                  getWeightObjectFromGrams(pregnancy.weight).ounces
                } oz`
              : "-",
            [METRICS.BIRTH_SEX]: pregnancy.sex,
            [METRICS.NAME]: pregnancy.name,
            [METRICS.BIRTHPLACE]: pregnancy.birthplace,
            "":
            <div className={styles.pregnancyActions}>
              {pregnancy.comment && (
                 <Button type="button" style={STYLES.NO_OUTLINE} onClick={() => handleOpenNote(pregnancy)}>
                    <Files />
                </Button>
              )}
              <Button type="button" style={STYLES.NO_OUTLINE} onClick={() => handleEditPregnancy(pregnancy)}>
                <Edit stroke={styles.gray800} height={16} width={16} />
              </Button>
            </div>
          }
        };
      });
    };

    return pastPregnancyData(patient.medical_history.pregnancies || []);
  }, [patient.medical_history.pregnancies]);

  // Open the note modal with the pregnancy comment as text
  const handleOpenNote = (pregnancy: Pregnancy) => {
    noteModalActions.open({ text: pregnancy.comment?.replace(/<\/?p>/g, "") || "No note available" });
  }

  const handleEditPregnancy = (preg: Pregnancy) => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.PAST_PREGNANCY,
        props: { patient, preg, title: "Edit past pregnancy" }
      })
    );
  };

  const handleAddPregnancy = () => {
    dispatch(setModalIsOpen(true));
    dispatch(
      setModalContent({
        type: MODAL_TYPES.PAST_PREGNANCY,
        props: { patient, title: "Add past pregnancy" }
      })
    );
  };

  if (isMobile) {
    return null;
  }

  return (
    <DashboardCard
      title="Past Pregnancies"
      customRightElement={
        <Button style={STYLES.SECONDARY} onClick={handleAddPregnancy}>
          <Plus stroke={styles.gray400} width={12} height={12} />
          Add Pregnancy
        </Button>
      }
    >
      <Datagrid
        gridType={APP_KEYS.PREGNANCIES}
        data={pregnancyData}
        defaultSortDirection="descending"
        defaultSortMetric="delivery_date"
      />

      <ModalController
        title="Notes"
        width={500}
        close={noteModalActions.close}
        modalState={noteModalState}
        modalComponent={NoteModal}
      />
    </DashboardCard>
  );
}

type NoteModalProps = {
  text : string;
};

// Modal to display pregnancy history notes/comments
function NoteModal({
  text
}: ModalComponentProps<NoteModalProps>) {
  return (
    <div className={styles.noteModal}>
      <div className={styles.textAreaContainer}>
        <div className={styles.textAreaContent} >
          {text}
        </div>
      </div>
    </div>
  );
}
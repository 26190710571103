import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    problemUpsert: build.mutation<
      ProblemUpsertApiResponse,
      ProblemUpsertApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/problem/upsert`,
        method: "POST",
        body: queryArg.problemUpsertRequest
      })
    }),
    problemInfo: build.query<ProblemInfoApiResponse, ProblemInfoApiArg>({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/problem/${queryArg.problemId}/info`
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as problem };
export type ProblemUpsertApiResponse =
  /** status 200 Sent in response to successfully upserting a problem.
   */ ProblemId;
export type ProblemUpsertApiArg = {
  patientId: UserId;
  /** Sent as the payload to upsert a problem.
   */
  problemUpsertRequest: ProblemUpsertRequest;
};
export type ProblemInfoApiResponse =
  /** status 200 Sent in response to successfully requesting the information about a problem.
   */ ProblemInfo;
export type ProblemInfoApiArg = {
  problemId: ProblemId;
  patientId: UserId;
};
export type ProblemId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type UserId = number;
export type ProblemType = "NOTE" | "DIAGNOSIS";
export type ProblemStatus = "ACTIVE" | "ARCHIVED" | "DELETED";
export type ProblemUpsertRequest = {
  /** System-assigned identifier for a problem.
   */
  id?: number;
  type: ProblemType;
  status?: ProblemStatus;
  date_of_onset?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** Optional note limited to 4K in length.
   */
  note?: string;
  title?: string;
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id?: number;
};
export type ProblemInfo = {
  /** System-assigned identifier for a problem.
   */
  id: number;
  type: ProblemType;
  status: ProblemStatus;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  date_of_onset?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  created_by: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  archived?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  archived_by?: number;
};
export const {
  useProblemUpsertMutation,
  useProblemInfoQuery,
  useLazyProblemInfoQuery
} = injectedRtkApi;

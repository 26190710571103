// Select for updating Patient Data
import React from "react";
import { useDispatch } from "react-redux";
import Select, { SingleValue, StylesConfig } from "react-select";

import {
  SelectBlueStyles,
  SelectBlueTheme
} from "@/components/facePage/patientMedicalState/helpers";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { STATUS_KEYS } from "@/globals/constants";

import {
  PatientInfo,
  usePatientUpsertMutation
} from "@/store/services/patient";

import { useCustomPatientTypes } from "@/globals/helpers/customHooks";

import {
  FieldValueTypes,
  OptionType,
  createUpdatePayload,
  getCurrentValue,
  getOptions,
  getSelectProps,
  hasValueChanged
} from "./helpers";

interface PatientSelectProps<F extends keyof FieldValueTypes> {
  patient: PatientInfo;
  field: F;
  label: string;
}

export default function PatientUpsertSelect<F extends keyof FieldValueTypes>({
  patient,
  field,
  label
}: PatientSelectProps<F>) {
  const dispatch = useDispatch();
  const customPatientTypes = useCustomPatientTypes();
  const [updatePatient, { isLoading }] = usePatientUpsertMutation();

  // Get options for this select field
  const options = getOptions(field, customPatientTypes);

  // Get current value of the select
  const currentValue = getCurrentValue(patient, field, options);

  // Handle select changes
  const handleChange = async (selectedOption: SingleValue<OptionType>) => {
    // Return early for null/undefined selection on required fields
    if (!selectedOption && (field === "type" || field === "blood_type")) {
      return;
    }

    const value = selectedOption?.value || "";

    const newValue = value as FieldValueTypes[F];

    // Check if the value has changed
    if (!hasValueChanged(patient, field, newValue)) {
      return;
    }

    // Create the update payload
    const updatePayload = createUpdatePayload(patient, field, newValue);

    // Send the update
    await updatePatient({
      patientUpsertRequest: updatePayload
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: `Updated patient ${label.toLowerCase()}`,
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: `Failed to update ${label.toLowerCase()}`,
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <>
      <p>{label}</p>
      <Select<OptionType>
        theme={SelectBlueTheme}
        styles={SelectBlueStyles as StylesConfig<OptionType>}
        options={options}
        value={currentValue}
        onChange={handleChange}
        isLoading={isLoading}
        {...getSelectProps(field, label)}
      />
    </>
  );
}

/* Vitals Section */
/* External Imports */
import {
  useRef,
  useEffect,
  useState,
  Fragment,
  useMemo
} from "react";
import { useRouter } from "next/router";
import { useDispatch } from "react-redux";
import clsx from "clsx";

/* Local Imports */

// components
import Input from "@/components/input";

// constants
import {
  METRICS,
  METRIC_LABELS,
  STATUS_KEYS,
} from "@/globals/constants";

// store
import {
  EncounterId,
  EncounterType,
  useEncounterUpdateMutation
} from "@/store/services/encounter";
import { UserId } from "@/store/services/fax";
import {
  usePatientGetInfoQuery,
  usePatientUpsertMutation
} from "@/store/services/patient";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";
import { useForm } from "react-hook-form";

/* Component Typescript Interface */
interface VitalsProps {
  encounterId: EncounterId;
  providerId: UserId;
  patientId: UserId;
  encounterType: EncounterType;
  defaultValues?: {
    height?: number;
    weight?: number;
    temperature?: number;
    blood_pressure?: string;
    heart_rate?: string;
  };
  isEditable?: boolean;
}

export default function Vitals({
  encounterId,
  providerId,
  patientId,
  encounterType,
  defaultValues,
  isEditable = true
}: VitalsProps) {
  const dispatch = useDispatch();
  const router = useRouter();
  const formRef = useRef<HTMLFormElement>(null);

  /* UseForm */
  const { register, getValues, setValue } = useForm();

  /* Redux */
  const [updateEncounter, { error: updateEncounterError }] =
    useEncounterUpdateMutation();
  const [updatePatient, { error: updatePatientErr }] =
    usePatientUpsertMutation();
  const { data: patient } = usePatientGetInfoQuery({ patientId: patientId });

  /* Local State */
  const [editMode, setEditMode] = useState(isEditable);

  /* Effects */
  useEffect(() => {
    setEditMode(isEditable);
  }, [isEditable]);

  // intercept navigation to save form
  useEffect(() => {
    const handleRouteChange = () => {
      if (editMode) {
        handleSubmit();
      }
    };

    // When the component is mounted, subscribe to router changes
    // and log those route changes
    router.events.on("routeChangeStart", handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  }, [formRef, router.events, editMode, encounterType]);

  if (updateEncounterError) {
    dispatch(
      addAlertToToastTrough({
        message: "Vitals could not be saved to encounter",
        type: STATUS_KEYS.ERROR
      })
    );
  }

  if (updatePatientErr) {
    dispatch(
      addAlertToToastTrough({
        message: "Vitals could not be saved to patient",
        type: STATUS_KEYS.ERROR
      })
    );
  }

  /* Event Handlers */
  const handleSubmit = () => {
    // get the form data
    const formData = getValues();
    // get the values from the form data
    const { feet, inches, weight, temperature, blood_pressure, heart_rate } = formData;

    // convert the values to numbers
    let heightNum, weightNum, temperatureNum: number | undefined;

    const feetNum = parseFloat((feet || "0") as string);
    const inchesNum = parseFloat((inches || "0") as string);
    heightNum = feetNum * 12 + inchesNum;

    if (weight) {
      weightNum = parseFloat(weight as string);
    }
    if (temperature) {
      temperatureNum = parseFloat(temperature as string);
    }

    // Convert weight to kg
    weightNum = weightNum ? weightNum * 0.453592 : undefined;

    // Convert height to cm
    heightNum = heightNum ? Math.round(heightNum * 2.54) : undefined;

    updateEncounter({
      encounterId,
      encounterCreateUpdatePayload: {
        patient_id: patientId,
        provider_id: providerId,
        encounter_type: encounterType,
        vitals: {
          height: heightNum,
          weight: weightNum,
          temperature: temperatureNum,
          blood_pressure: blood_pressure as string,
          heart_rate: heart_rate as string
        }
      }
    });

    // Update patient height and weight
    updatePatient({
      patientUpsertRequest: {
        user_id: patientId,
        medical_history: {
          ...patient?.medical_history,
          height: heightNum,
          weight: weightNum
        }
      }
    });
  };

  const initialValues = useMemo(() => {
    if (defaultValues) {
      const heightInches = Math.round((defaultValues.height ?? 0) / 2.54);
      const heightFeet = Math.floor(heightInches / 12);
      const heightRemainder = heightInches % 12;

      // convert weight from kg to lbs and round to nearest tenth of a pound
      const weight = defaultValues.weight ? (defaultValues.weight / 0.453592).toFixed(2) : "";

      // if there are default values, set the initial values to the default values
      return {
        [METRICS.FEET]: defaultValues.height ? heightFeet : "",
        [METRICS.INCHES]: defaultValues.height ? heightRemainder % 12 : "",
        [METRICS.WEIGHT]: weight,
        [METRICS.TEMP]: defaultValues.temperature,
        [METRICS.HEART_RATE]: defaultValues.heart_rate,
        [METRICS.BP]: defaultValues.blood_pressure
      };
    } else {
      // if there are no default values, set the initial values to empty strings
      return {
        feet: "",
        inches: "",
        [METRICS.WEIGHT]: "",
        [METRICS.TEMP]: "",
        [METRICS.BP]: ""
      };
    }
  }, [defaultValues]);

  // When initialValues changes (the memoized default values)
  // update those fields in the form
  useEffect(() => {
    Object.keys(initialValues).forEach(key => {
      if (initialValues[key]) {
        setValue(key, initialValues[key]);
      }
    });
  }, [initialValues, setValue]);

  return (
    <div className={clsx(styles.Vitals)} data-cy="vitals">
      <div className={styles.formType}>
        <div className={styles.inputWrapper}>
          {[
            METRICS.FEET,
            METRICS.INCHES,
            METRICS.WEIGHT,
            METRICS.TEMP,
            METRICS.HEART_RATE,
            METRICS.BP
          ].map(metric => (
            <Fragment key={metric}>
              {editMode ? (
                <Input
                  label={METRIC_LABELS[metric]}
                  name={metric}
                  id={metric}
                  type={metric === METRICS.BP ? "text" : "number"}
                  rules={{
                    onBlur: () => handleSubmit()
                  }}
                  register={register}
                />
              ) : (
                <div className={styles.metricDisplay}>
                  <p className={styles.label}>
                    {METRIC_LABELS[metric] ||
                      metric[0].toUpperCase() + metric.slice(1)}
                  </p>
                  <p className={styles.metricData}>
                    {initialValues && initialValues[metric]
                      ? initialValues[metric]
                      : "-"}
                  </p>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      </div>
    </div>
  );
}

import Button from "@/components/button";
import {
  CreateCaseModal,
  CreateCaseModalProps
} from "@/components/modalV2/CreateCaseModal";
import {
  ModalController,
  useModal
} from "@/components/modalV2/ModalController";

import { STYLES } from "@/globals/constants";

import { PatientInfo } from "@/store/services/patient";

type CreateCaseCTAProps = {
  patientId?: PatientInfo["user_id"];
};

/**
 * A component that provides a button to create a new case and manages the associated modal.
 * When clicked, it opens a modal to create a case for the specified patient.
 *
 * @component
 * @param {string} [props.patientId] - Optional, ID of the patient to create a case for.
 * This is passed to the CreateCaseModal component. If not provided, the CreateCaseModal
 * will render a dropdown to select a patient.
 *
 * @returns {JSX.Element} A button that opens the CreateCaseModal when clicked,
 * and the modal controller to manage the modal state.
 */
export const CreateCaseCTA = ({ patientId }: CreateCaseCTAProps) => {
  const [createCaseModalActions, createCaseModalState] =
    useModal<CreateCaseModalProps>();

  return (
    <>
      <Button
        style={STYLES.SECONDARY}
        onClick={() => {
          createCaseModalActions.open({
            patientId,
            closeModal: createCaseModalActions.close
          });
        }}
      >
        Create Case
      </Button>
      <ModalController
        modalState={createCaseModalState}
        modalComponent={CreateCaseModal}
        close={createCaseModalActions.close}
        title="Create Case"
      />
    </>
  );
};

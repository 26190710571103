/* Note Section */
/* External Imports */
import clsx from "clsx";
// styles
import { useCallback, useEffect, useState } from "react";
import { FormProvider } from "react-hook-form";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import AudioRecorder from "@/components/audioRecorder";
import TabMenu from "@/components/tabbed";
// store
import { setTabId } from "@/components/tabbed/tabSlice";

// constants
import { STYLES } from "@/globals/constants";

import { EncounterInfo } from "@/store/services/encounter";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

import styles from "./styles.module.scss";

import NoteEditor from "./NoteEditor";
import TranscriptTabItem from "./TranscriptTabItem";
import useNoteAutosave from "./useNoteAutosave";
import useNoteForm from "./useNoteForm";
import useTranscriptTab from "./useNoteTabs";

/* Component Typescript Interface */
interface NoteProps {
  encounter: EncounterInfo;
  isEditable?: boolean;
  isPetrified?: boolean;
}

export default function Note({
  encounter,
  isEditable = false,
  isPetrified = false
}: NoteProps) {
  // Local State
  const dispatch = useDispatch();
  // track whether form has been saved
  const [isEditMode, setIsEditMode] = useState<boolean>(
    isEditable && !encounter.note
  );

  /* Redux */
  const { data } = useGetFeatureFlagsQuery();

  /* Hooks */
  const { initialSelectedTab, transcriptData, setTranscriptData } =
    useTranscriptTab({ encounter });
  const { form, currentNote } = useNoteForm({ encounter });
  const { autosaveNotification, isAutosaveError } = useNoteAutosave({
    isEditable,
    noteText: currentNote,
    encounter
  });

  useEffect(() => {
    setIsEditMode(isEditable);
  }, [isEditable]);

  const handleNoteGeneration = useCallback(
    (noteContent: Array<string>) => {
      const contentString = noteContent.reduce((acc, curr) => acc + curr, "");
      const oldNote = form.getValues("note");
      form.setValue("note", oldNote + "\n" + contentString);
      dispatch(setTabId("note"));
    },
    [form, dispatch]
  );

  const isTranscriptionEnabled =
    data?.nabla_enabled && !isPetrified && initialSelectedTab;

  const handleTranscriptChange = (
    newTranscriptData: Array<Record<string, string | number | boolean>>
  ) => {
    setTranscriptData(newTranscriptData);
  };

  const handleStartTranscribing = () => {
    dispatch(setTabId("transcript"));
  };

  return (
    <FormProvider {...form}>
      <div className={clsx(styles.Note)} data-cy="encounter-note">
        {isTranscriptionEnabled ? (
          <TabMenu
            tabs={[
              { id: "note", label: "Note" },
              { id: "transcript", label: "Transcript" }
            ]}
            style={STYLES.SECONDARY}
            initialSelectedTab={initialSelectedTab}
            customElement={
              <AudioRecorder
                onNoteGeneration={handleNoteGeneration}
                onStartTranscribing={handleStartTranscribing}
                onTranscriptChange={handleTranscriptChange}
                recordingIsDisabled={!isEditable}
              />
            }
          >
            {tab => {
              if (tab === "note") {
                return (
                  <NoteEditor
                    encounter={encounter}
                    isEditMode={isEditMode}
                    isAutosaveError={isAutosaveError}
                    autosaveNotification={autosaveNotification}
                  />
                );
              } else {
                return <TranscriptTabItem transcriptData={transcriptData} />;
              }
            }}
          </TabMenu>
        ) : (
          <NoteEditor
            encounter={encounter}
            isEditMode={isEditMode}
            isAutosaveError={isAutosaveError}
            autosaveNotification={autosaveNotification}
          />
        )}
      </div>
    </FormProvider>
  );
}

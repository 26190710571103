/* Laceration form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
/* Local Imports */

// components
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

// constants
import { FORM_METRIC_LABELS, FORM_VALUES } from "./constants";
import { EVENTS } from "@/components/flows/labor/constants";
import { STYLES } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";

/* Lacerationform Typescript Interface */
interface LacerationformProps {
  onSubmit: (data: any) => void;
  defaultValues: any;
  startTime: number;
}

export default function Lacerationform({
  onSubmit,
  defaultValues,
  startTime
}: LacerationformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    setValue
  } = form;
  /* Redux */

  /* Local State */
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);

  const FIELDS = Object.entries(FORM_VALUES[EVENTS.LACERATION]);

  return (
    <form
      className={clsx(styles.LaborForm, styles.Lacerationform)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="start_time"
        control={control}
        render={({ field: { onChange, name, value } }) => {
          return (
            <DateTimeStamp
              name={name}
              onChange={onChange}
              initialValue={
                (defaultValues && defaultValues.start_time) || startTime
              }
              value={value}
            />
          );
        }}
      />
      {FIELDS &&
        FIELDS.map(([key, val]) => (
          <ControlledCombobox
            key={key}
            options={val}
            label={FORM_METRIC_LABELS[key]}
            name={key}
            control={control}
            errors={errors}
            isHorizontalLayout={false}
            fullWidth
          />
        ))}
      <Input
        type="text"
        label="Suture type"
        name="suture_type"
        id="suture_type"
        fullWidth
        register={register}
      />
      <Input
        type="text"
        label="Anesthesia"
        name="anesthesia"
        id="anesthesia"
        fullWidth
        register={register}
      />
      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          rows={textAreaFocused ? 4 : 1}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
          onBlur={() => {
            setTextAreaFocused(false);
          }}
        />
      </div>
      <div className={styles.fullWidth}>
        <Button style={STYLES.FULL_WIDTH} type="submit">
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}

import KeyValueGrid from "../keyValueGrid";
import KeyValueItem from "../keyValueItem";
import ReadOnlySection from "../readOnlySection";
import SectionCol from "../sectionCol";
import SectionRow from "../sectionRow";
import ObjectTableItem from "../objectTableItem";
import { PatientInfo } from "@/store/services/patient";
import { useMemo } from "react";
import dayjs from "dayjs";
import { METRIC_LABELS } from "@/globals/constants";

interface SummaryPatientInfoProp {
  patient: PatientInfo;
}

export default function SummaryPatientInfo({
  patient
}: SummaryPatientInfoProp) {
  const ALLERGY_HEADERS = ["name", "ICD-10", "reaction", "severity"];

  const allergies = useMemo(() => {
    return (patient.medical_history.allergies || []).map(allergy => ({
      name: allergy.icd10_code_details
        ? `${allergy.icd10_code_details.description}`
        : allergy.name,
      "ICD-10": allergy.icd10_code_details
        ? `${allergy.icd10_code_details.name}`
        : "-",
      reaction: allergy.reaction,
      severity: `${allergy.severity?.slice(0, 1)}${allergy.severity?.slice(1).toLowerCase()}`
    }));
  }, [patient]);

  return (
    <ReadOnlySection header="Patient Information">
      <SectionRow>
        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="Client ID:">
              <p>{patient.user_id}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="DOB:">
              <p>{dayjs(`${patient.dob}`, "YYYYMMDD").format("MM/DD/YYYY")}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="EDD">
              {patient.pregnancy?.edd ? (
                <p>
                  {dayjs(`${patient.pregnancy?.edd.estimated_due_date}`).format(
                    "MM/DD/YYYY"
                  )}
                </p>
              ) : (
                <p>
                  <em>EDD not set in patient chart</em>
                </p>
              )}
            </KeyValueItem>
            <KeyValueItem keyLabel="GBS Status:">
              {patient.pregnancy?.gbs_status != undefined ? (
                <p>{METRIC_LABELS[patient.pregnancy?.gbs_status]}</p>
              ) : (
                <p>
                  <em>GBS Status not set in patient chart</em>
                </p>
              )}
            </KeyValueItem>
            <KeyValueItem keyLabel="Blood Type:">
              {patient.medical_history.blood_type ? (
                <p>{patient.medical_history.blood_type}</p>
              ) : (
                <p>
                  <em>Blood type not set in patient chart</em>
                </p>
              )}
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
        <SectionCol>
          <KeyValueGrid>
            {/* facepage Josh' code */}
            <KeyValueItem keyLabel="Gravida:">
              <p>{patient.medical_history.pregnancies_gs_and_ps?.gravida}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Term Births:">
              <p>{patient.medical_history.pregnancies_gs_and_ps?.term_births}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Preterm Births:">
              <p>{patient.medical_history.pregnancies_gs_and_ps?.preterm_births}</p>
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="Living:">
              <p>{patient.medical_history.pregnancies_gs_and_ps?.living_children}</p>
            </KeyValueItem>
            <KeyValueItem keyLabel="Abortions/Miscarriages:">
              <p>{patient.medical_history.pregnancies_gs_and_ps?.abortions_miscarriages}</p>
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
      </SectionRow>
      <SectionRow>
        <p style={{ paddingBottom: "1rem" }}>
          <strong>Allergies</strong>
        </p>
      </SectionRow>
      <SectionRow>
        {patient.medical_history.allergies ? (
          <ObjectTableItem
            orderedHeaders={ALLERGY_HEADERS}
            objectGrid={allergies}
          />
        ) : (
          <p>None</p>
        )}
      </SectionRow>
    </ReadOnlySection>
  );
}

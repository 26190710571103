import * as generated from "./generated/staff";

const staff = generated.staff.enhanceEndpoints({
  endpoints: {
    staffUserList: {
      providesTags: ["Staff"]
    },
    staffUserCreate: {
      invalidatesTags: ["Staff"]
    },
    staffUserGetInfo: {
      providesTags: ["Staff"]
    },
    staffUserUpdate: {
      invalidatesTags: ["Staff"]
    }
  }
});

export * from "./generated/staff";
export default staff;
export const {
  useStaffUserListQuery,
  useLazyStaffUserListQuery,
  useStaffUserCreateMutation,
  useStaffUserGetInfoQuery,
  useLazyStaffUserGetInfoQuery,
  useStaffUserUpdateMutation
} = generated;

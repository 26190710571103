// External
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Inter } from "next/font/google";
import { UseFormSetValue, FieldValues, Path } from "react-hook-form";
import clsx from "clsx";
import { motion } from "framer-motion";

// TipTap
import { AnyExtension, EditorContent, Extension, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import { DotPhrases } from "@/components/textArea/dotPhrases";
import Highlight from '@tiptap/extension-highlight'

// Components
import MenuBar from "./menuBar";
import Icon from "../icons";

// API hooks
import { usePhraseListQuery } from "@/store/services/phrase";
import { useLazyPhraseTextQuery } from "@/store/services/phrase";

// Custom TipTap Implementation
import dotPhraseSuggestionOptions from "@/components/textArea/dotPhrasesSuggestions";
// State
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";
import { getDefaultExtensions } from "./textAreaHelpers";

const font = Inter({
  subsets: ["latin"]
});

export interface TextAreaProps<T extends FieldValues = FieldValues> {
  label: string;
  name: Path<T>;
  id: string;
  rows?: number;
  cols?: number;
  placeholder?: string;
  onChange?: (content: any) => void;
  hiddenLabel?: boolean;
  noBorder?: boolean;
  useMenuBar?: boolean;
  onSubmit?: (data: any) => void;
  content?: string;
  setValue?: UseFormSetValue<T>;
  submitOnEnter?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
}

export default function TextArea<T extends FieldValues = FieldValues>({
  label,
  name,
  id,
  rows = 6,
  cols = 0,
  placeholder = "",
  onChange,
  hiddenLabel = false,
  noBorder = false,
  useMenuBar = true,
  onSubmit,
  content = "",
  setValue,
  submitOnEnter = false,
  onFocus,
  onBlur,
  disabled = false
}: TextAreaProps<T>) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { data } = usePhraseListQuery(
    {
      scope: "practice",
      id: sessionInfo?.practice_id as number
    },
    {
      skip: !sessionInfo?.practice_id
    }
  );
  const [getPhraseText] = useLazyPhraseTextQuery();

  const dotphraseConfig = {
    suggestion: {
      ...dotPhraseSuggestionOptions,
      // The items for the popup to display filtered by a search query
      items: ({ query }: { query: string }) => {
        const queriedPhrases: Array<any> = [];
        data?.forEach((phrase, index) => {
          const nameSearch = phrase.name
            .toLowerCase()
            .startsWith(query.toLowerCase());
          const descriptionSearch = phrase.description
            ?.toLowerCase()
            .startsWith(query.toLowerCase());
          if (nameSearch || descriptionSearch) {
            queriedPhrases.push(phrase);
          }
        });

        return queriedPhrases;
      },
      command: ({ editor, range, props }: any) => {
        if (editor.getText() == "/") {
          getPhraseText({
            id: sessionInfo?.practice_id as number,
            phrase: props.phrase.name,
            scope: "practice"
          }).then(({ data }) => {
            editor
              .chain()
              .focus()
              // Setting content doesn't cause the update event to fire
              // which messes with the controlling of the input so instead
              // we delete the "/" because we know that's all that's in the
              // text area and then insert the content which does trigger
              // an update
              .command(({ tr }: any) => {
                tr.delete(0, 2);
                return true;
              })
              .insertContent(data)
              .focus("start")
              .scrollIntoView()
              .run();

            //editor.commands.selectNextVariable();
          });
        } else {
          getPhraseText({
            id: sessionInfo?.practice_id as number,
            phrase: props.phrase.name,
            scope: "practice"
          }).then(({ data }) => {
            editor
              .chain()
              .focus()
              .insertContentAt({ from: range.from - 1, to: range.to }, data, {
                updateSelection: false,
                parseOptions: {
                  preserveWhiteSpace: "full"
                }
              })
              .scrollIntoView()
              .run();

            //editor.commands.selectNextVariable();
          });
        }
      }
    }
  };

  const submitOnEnterFunction = () => {
    onSubmit && onSubmit({message_content: editor?.getHTML() || ""})
  }

  const defaultExtensions: AnyExtension[] = getDefaultExtensions({ placeholder, submitOnEnterFunction})

  // If the user is a patient, we don't want to show the dot phrases
  const extensions = sessionInfo?.is_patient
    ? defaultExtensions
    : [...defaultExtensions, DotPhrases.configure(dotphraseConfig)];
  let editor = useEditor(
    {
      immediatelyRender: false,
      editable: !disabled,
      extensions,
      content,
      editorProps: {
        attributes: {
          class: clsx(styles.proseMirror),
          id
        }
      },
      onUpdate({ editor }: any) {
        if (onChange) {
          onChange(editor?.getHTML() || "");
        }

        if (setValue) {
          setValue(name, editor?.getHTML() || "");
        }
      },
      onFocus({ editor }: any) {
        if (onFocus) {
          onFocus();
        }
      },
      onBlur(e: any) {
        if (onBlur) {
          onBlur();
        }
      }
    },
    [data, disabled]
  );

  useEffect(() => {
    if (editor) {
      // If there's no change in content, we don't need to update.
      // This is a fix for a weird issue with TipTap and whitespace
      if (editor.getHTML() !== content) {
        editor?.commands.setContent(content);
      }
    }
  }, [content]);

  const [menuBarOpen, setMenuBarOpen] = useState<boolean>(false);
  const [menuArrowFlipped, setMenuArrowFlipped] = useState<boolean>(false);

  // For the Animate properties
  const containerVariants = {
    visible: {
      overflow: "unset",
      transition: {
        when: "afterChildren"
      }
    },
    hidden: {
      overflow: "hidden",
      transition: {
        when: "beforeChildren"
      }
    }
  };

  const dotPhraseHintVariants = {
    visible: {
      opacity: 1,
      transfrom: "translateY(0px)",
      transition: { delay: 0.25 }
    },
    hidden: { opacity: 0, transform: "translateY(20px)" }
  };

  const variants = {
    visible: { opacity: 1, width: "auto", transform: "translateX(0px)" },
    hidden: {
      opacity: 0,
      width: "0px",
      transform: "translateX(-200px)",
      transition: { delay: 0.25 }
    }
  };

  return (
    <div
      className={clsx(styles.TextArea, font.className, {
        [styles.noBorder]: noBorder
      })}
    >
      <label
        htmlFor={name}
        className={clsx(styles.textAreaLabel, { [styles.hidden]: hiddenLabel })}
      >
        {label}
      </label>
      <div className={styles.textAreaBorder}>
        {editor && (
          <div className={clsx(styles.textAreaContent)} data-cy="textarea">
            <EditorContent
              style={{
                height: `${rows * 36}px`,
                width: cols > 0 ? `${cols}rem` : "100%"
              }}
              editor={editor}
            />
          </div>
        )}
        {editor && useMenuBar && (
          <motion.div
            animate={menuBarOpen ? "visible" : "hidden"}
            variants={containerVariants}
            onAnimationComplete={() => setMenuArrowFlipped(!menuArrowFlipped)}
            className={clsx(styles.menuBarContainer, {
              [styles.menuBarOpen]: menuBarOpen,
              [styles.menuBarGap]: menuArrowFlipped
            })}
          >
            <motion.div variants={variants}>
              <MenuBar editor={editor} textAreaClassName={styles.TextArea} />
            </motion.div>
            <button
              type="button"
              onClick={e => {
                e.stopPropagation();
                setMenuBarOpen(!menuBarOpen);
              }}
              className={styles.menuButton}
            >
              <Icon svg="chevron_right" flipped={!menuArrowFlipped} />
            </button>
            <motion.div variants={dotPhraseHintVariants}>
              <p className={styles.dotPhraseHint}>
                Type "/" to search for dot phrases
              </p>
            </motion.div>
          </motion.div>
        )}
      </div>
    </div>
  );
}

import TranscriptRenderer from "@/components/audioRecorder/transcriptRenderer";

interface TranscriptTabItemProps {
  transcriptData?: Record<string, string | number | boolean>[];
}

export default function TranscriptTabItem({
  transcriptData
}: TranscriptTabItemProps) {
  if (!transcriptData) return null;

  return (
    <div id="note">
      <TranscriptRenderer transcriptData={transcriptData} />
    </div>
  );
}

import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    setClaimNonBillable: build.mutation<
      SetClaimNonBillableApiResponse,
      SetClaimNonBillableApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/claim_non_billable`,
        method: "POST"
      })
    }),
    labList: build.query<LabListApiResponse, LabListApiArg>({
      query: () => ({ url: `/lab/list` })
    }),
    labGetInfo: build.query<LabGetInfoApiResponse, LabGetInfoApiArg>({
      query: queryArg => ({ url: `/lab/${queryArg.labHgId}/info` })
    }),
    labOrderCreate: build.mutation<
      LabOrderCreateApiResponse,
      LabOrderCreateApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/create`,
        method: "POST",
        body: queryArg.labOrderCreateRequest
      })
    }),
    labOrderRead: build.query<LabOrderReadApiResponse, LabOrderReadApiArg>({
      query: queryArg => ({ url: `/lab/order/${queryArg.orderId}` })
    }),
    labOrderUpdate: build.mutation<
      LabOrderUpdateApiResponse,
      LabOrderUpdateApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/update`,
        method: "POST",
        body: queryArg.labOrderUpdateRequest
      })
    }),
    labDeleteOrder: build.mutation<
      LabDeleteOrderApiResponse,
      LabDeleteOrderApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/delete`,
        method: "DELETE"
      })
    }),
    labOrderTransmissionError: build.query<
      LabOrderTransmissionErrorApiResponse,
      LabOrderTransmissionErrorApiArg
    >({
      query: queryArg => ({ url: `/lab/order/${queryArg.orderId}/errors` })
    }),
    labListTests: build.query<LabListTestsApiResponse, LabListTestsApiArg>({
      query: queryArg => ({ url: `/lab/${queryArg.labHgId}/tests` })
    }),
    labGetAoeQuestions: build.query<
      LabGetAoeQuestionsApiResponse,
      LabGetAoeQuestionsApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.labHgId}/aoe-questions`,
        params: { codes: queryArg.codes }
      })
    }),
    labSearchLocations: build.mutation<
      LabSearchLocationsApiResponse,
      LabSearchLocationsApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.labHgId}/search`,
        method: "POST",
        body: queryArg.labSearch
      })
    }),
    labBookmarkLocation: build.mutation<
      LabBookmarkLocationApiResponse,
      LabBookmarkLocationApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.locationId}/${queryArg.labHgId}/location/${queryArg.lablocationId}/bookmark`,
        method: "POST"
      })
    }),
    labListBookmarkedLocations: build.query<
      LabListBookmarkedLocationsApiResponse,
      LabListBookmarkedLocationsApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.locationId}/${queryArg.labHgId}/list-bookmarked-locations`
      })
    }),
    labGetLocationInfo: build.query<
      LabGetLocationInfoApiResponse,
      LabGetLocationInfoApiArg
    >({
      query: queryArg => ({
        url: `/lab/location/${queryArg.lablocationId}/info`
      })
    }),
    labDeleteBookmarkedLocation: build.mutation<
      LabDeleteBookmarkedLocationApiResponse,
      LabDeleteBookmarkedLocationApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.locationId}/${queryArg.labHgId}/location/${queryArg.lablocationId}/delete-bookmark`,
        method: "DELETE"
      })
    }),
    labListTestBundles: build.query<
      LabListTestBundlesApiResponse,
      LabListTestBundlesApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.locationId}/${queryArg.labHgId}/test-bundle/list`
      })
    }),
    labCreateTestBundle: build.mutation<
      LabCreateTestBundleApiResponse,
      LabCreateTestBundleApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.locationId}/${queryArg.labHgId}/test-bundle/create`,
        method: "POST",
        body: queryArg.labCreateUpdateTestBundle
      })
    }),
    labUpdateTestBundle: build.mutation<
      LabUpdateTestBundleApiResponse,
      LabUpdateTestBundleApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.locationId}/${queryArg.labHgId}/test-bundle/${queryArg.bundleUuid}/update`,
        method: "PUT",
        body: queryArg.labCreateUpdateTestBundle
      })
    }),
    labDeleteTestBundle: build.mutation<
      LabDeleteTestBundleApiResponse,
      LabDeleteTestBundleApiArg
    >({
      query: queryArg => ({
        url: `/lab/${queryArg.locationId}/${queryArg.labHgId}/test-bundle/${queryArg.bundleUuid}/delete`,
        method: "DELETE"
      })
    }),
    labSendOrder: build.mutation<LabSendOrderApiResponse, LabSendOrderApiArg>({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/send`,
        method: "POST",
        body: queryArg.labSendOrderRequest
      })
    }),
    labGetOrderResultsPdf: build.query<
      LabGetOrderResultsPdfApiResponse,
      LabGetOrderResultsPdfApiArg
    >({
      query: queryArg => ({ url: `/lab/order/${queryArg.orderId}/results` })
    }),
    labGetOrderRequisitionPdf: build.query<
      LabGetOrderRequisitionPdfApiResponse,
      LabGetOrderRequisitionPdfApiArg
    >({
      query: queryArg => ({ url: `/lab/order/${queryArg.orderId}/requisition` })
    }),
    labGetOrderObservations: build.query<
      LabGetOrderObservationsApiResponse,
      LabGetOrderObservationsApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/observations`
      })
    }),
    labToggleOrderReviewed: build.mutation<
      LabToggleOrderReviewedApiResponse,
      LabToggleOrderReviewedApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/toggle-reviewed`,
        method: "POST"
      })
    }),
    labGetOrderAbn: build.query<
      LabGetOrderAbnApiResponse,
      LabGetOrderAbnApiArg
    >({
      query: queryArg => ({ url: `/lab/order/${queryArg.orderId}/abn` })
    }),
    labGetOrderLetterLabels: build.query<
      LabGetOrderLetterLabelsApiResponse,
      LabGetOrderLetterLabelsApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/labels/letter`
      })
    }),
    labGetOrderDymoLabels: build.query<
      LabGetOrderDymoLabelsApiResponse,
      LabGetOrderDymoLabelsApiArg
    >({
      query: queryArg => ({ url: `/lab/order/${queryArg.orderId}/labels/dymo` })
    }),
    labFlowsheetCreate: build.mutation<
      LabFlowsheetCreateApiResponse,
      LabFlowsheetCreateApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.patientId}/create`,
        method: "POST",
        body: queryArg.labFlowsheetCreateParams
      })
    }),
    labFlowsheetRead: build.query<
      LabFlowsheetReadApiResponse,
      LabFlowsheetReadApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}/read`
      })
    }),
    labFlowsheetDelete: build.mutation<
      LabFlowsheetDeleteApiResponse,
      LabFlowsheetDeleteApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}/delete`,
        method: "DELETE"
      })
    }),
    labFlowsheetDeleteDay: build.mutation<
      LabFlowsheetDeleteDayApiResponse,
      LabFlowsheetDeleteDayApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}/${queryArg.date}/delete`,
        method: "DELETE"
      })
    }),
    labFlowsheetList: build.query<
      LabFlowsheetListApiResponse,
      LabFlowsheetListApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/patient/${queryArg.patientId}/list`,
        params: {
          lab_flowsheet_template_id: queryArg.labFlowsheetTemplateId,
          pregnancy_id: queryArg.pregnancyId
        }
      })
    }),
    labFlowsheetDeleteEntries: build.mutation<
      LabFlowsheetDeleteEntriesApiResponse,
      LabFlowsheetDeleteEntriesApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}`,
        method: "DELETE",
        body: queryArg.body
      })
    }),
    labFlowsheetInsertEntries: build.mutation<
      LabFlowsheetInsertEntriesApiResponse,
      LabFlowsheetInsertEntriesApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}`,
        method: "POST",
        body: queryArg.body
      })
    }),
    labFlowsheetUpdateEntries: build.mutation<
      LabFlowsheetUpdateEntriesApiResponse,
      LabFlowsheetUpdateEntriesApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}`,
        method: "PUT",
        body: queryArg.body
      })
    }),
    labFlowsheetRemoveFile: build.mutation<
      LabFlowsheetRemoveFileApiResponse,
      LabFlowsheetRemoveFileApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}/files`,
        method: "DELETE",
        body: queryArg.labFlowsheetFileAttach
      })
    }),
    labFlowsheetAttachFile: build.mutation<
      LabFlowsheetAttachFileApiResponse,
      LabFlowsheetAttachFileApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}/files`,
        method: "POST",
        body: queryArg.labFlowsheetFileAttach
      })
    }),
    labFlowsheetUpdateDate: build.mutation<
      LabFlowsheetUpdateDateApiResponse,
      LabFlowsheetUpdateDateApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/${queryArg.labFlowsheetId}/update_date`,
        method: "POST",
        body: queryArg.labFlowsheetUpdateDateParams
      })
    }),
    labFlowsheetCreateTemplate: build.mutation<
      LabFlowsheetCreateTemplateApiResponse,
      LabFlowsheetCreateTemplateApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/create`,
        method: "POST",
        body: queryArg.labFlowsheetTemplateCreateParams
      })
    }),
    labFlowsheetReadTemplate: build.query<
      LabFlowsheetReadTemplateApiResponse,
      LabFlowsheetReadTemplateApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/read`
      })
    }),
    labFlowsheetUpdateTemplate: build.mutation<
      LabFlowsheetUpdateTemplateApiResponse,
      LabFlowsheetUpdateTemplateApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/update`,
        method: "POST",
        body: queryArg.labFlowsheetTemplateUpdateParams
      })
    }),
    labFlowsheetDeleteTemplate: build.mutation<
      LabFlowsheetDeleteTemplateApiResponse,
      LabFlowsheetDeleteTemplateApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/delete`,
        method: "DELETE"
      })
    }),
    labFlowsheetTemplateSwapTests: build.mutation<
      LabFlowsheetTemplateSwapTestsApiResponse,
      LabFlowsheetTemplateSwapTestsApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/swap-tests`,
        method: "POST",
        body: queryArg.labFlowsheetTemplateSwapTestsParams
      })
    }),
    labFlowsheetTemplateSwapAnalytes: build.mutation<
      LabFlowsheetTemplateSwapAnalytesApiResponse,
      LabFlowsheetTemplateSwapAnalytesApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/tests/${queryArg.labFlowsheetTemplateTestId}/swap-analytes`,
        method: "POST",
        body: queryArg.labFlowsheetTemplateSwapAnalytesParams
      })
    }),
    labFlowsheetListTemplates: build.query<
      LabFlowsheetListTemplatesApiResponse,
      LabFlowsheetListTemplatesApiArg
    >({
      query: () => ({ url: `/lab-flowsheet/templates/list` })
    }),
    labFlowsheetTemplateInsertTests: build.mutation<
      LabFlowsheetTemplateInsertTestsApiResponse,
      LabFlowsheetTemplateInsertTestsApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/insert-entries`,
        method: "POST",
        body: queryArg.body
      })
    }),
    labFlowsheetTemplateUpdateTest: build.mutation<
      LabFlowsheetTemplateUpdateTestApiResponse,
      LabFlowsheetTemplateUpdateTestApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/tests/${queryArg.labFlowsheetTemplateTestId}/update`,
        method: "POST",
        body: queryArg.labFlowsheetTemplateUpdateTestParams
      })
    }),
    labFlowsheetTemplateDeleteTest: build.mutation<
      LabFlowsheetTemplateDeleteTestApiResponse,
      LabFlowsheetTemplateDeleteTestApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/tests/${queryArg.labFlowsheetTemplateTestId}/delete`,
        method: "DELETE"
      })
    }),
    labFlowsheetTemplateInsertAnalytes: build.mutation<
      LabFlowsheetTemplateInsertAnalytesApiResponse,
      LabFlowsheetTemplateInsertAnalytesApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/tests/${queryArg.labFlowsheetTemplateTestId}/insert-analytes`,
        method: "POST",
        body: queryArg.body
      })
    }),
    labFlowsheetTemplateUpdateAnalyte: build.mutation<
      LabFlowsheetTemplateUpdateAnalyteApiResponse,
      LabFlowsheetTemplateUpdateAnalyteApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/tests/${queryArg.labFlowsheetTemplateTestId}/analytes/${queryArg.labFlowsheetTemplateAnalyteId}/update`,
        method: "POST",
        body: queryArg.labFlowsheetTemplateUpdateAnalyteParams
      })
    }),
    labFlowsheetTemplateDeleteAnalyte: build.mutation<
      LabFlowsheetTemplateDeleteAnalyteApiResponse,
      LabFlowsheetTemplateDeleteAnalyteApiArg
    >({
      query: queryArg => ({
        url: `/lab-flowsheet/templates/${queryArg.labFlowsheetTemplateId}/tests/${queryArg.labFlowsheetTemplateTestId}/analytes/${queryArg.labFlowsheetTemplateAnalyteId}/delete`,
        method: "DELETE"
      })
    }),
    labOrderAnnotationCreate: build.mutation<
      LabOrderAnnotationCreateApiResponse,
      LabOrderAnnotationCreateApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/annotation/create`,
        method: "POST",
        body: queryArg.labOrderAnnotationCreateRequest
      })
    }),
    labOrderAnnotationUpdate: build.mutation<
      LabOrderAnnotationUpdateApiResponse,
      LabOrderAnnotationUpdateApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/annotation/${queryArg.orderAnnotationId}/update`,
        method: "POST",
        body: queryArg.labOrderAnnotationUpdateRequest
      })
    }),
    labOrderAnnotationDelete: build.mutation<
      LabOrderAnnotationDeleteApiResponse,
      LabOrderAnnotationDeleteApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/annotation/${queryArg.orderAnnotationId}/delete`,
        method: "DELETE"
      })
    }),
    labOrderAnnotationList: build.query<
      LabOrderAnnotationListApiResponse,
      LabOrderAnnotationListApiArg
    >({
      query: queryArg => ({
        url: `/lab/order/${queryArg.orderId}/annotation/list`
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as lab };
export type SetClaimNonBillableApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimNonBillableApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type LabListApiResponse =
  /** status 200 Sent in response to a request for labs that accept lab orders.
   */ LabListItem[];
export type LabListApiArg = void;
export type LabGetInfoApiResponse =
  /** status 200 Sent in response to a request for all the information about a particular lab.
   */ LabOrgInfo;
export type LabGetInfoApiArg = {
  labHgId: HgOrgId;
};
export type LabOrderCreateApiResponse =
  /** status 200 Sent in response to successfully upserting a lab order.
   */ LabOrderId;
export type LabOrderCreateApiArg = {
  /** Payload for creating a new lab order.
   */
  labOrderCreateRequest: LabOrderCreateRequest;
};
export type LabOrderReadApiResponse =
  /** status 200 Sent in response to successfully reading a lab order.
   */ LabOrderInfo;
export type LabOrderReadApiArg = {
  orderId: LabOrderId;
};
export type LabOrderUpdateApiResponse =
  /** status 200 Sent in response to successfully upserting a lab order.
   */ LabOrderId;
export type LabOrderUpdateApiArg = {
  orderId: LabOrderId;
  /** Payload for updating an existing lab order.
   */
  labOrderUpdateRequest: LabOrderUpdateRequest;
};
export type LabDeleteOrderApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type LabDeleteOrderApiArg = {
  orderId: LabOrderId;
};
export type LabOrderTransmissionErrorApiResponse =
  /** status 200 Sent in response to successfully fetching the latest transmission error for an order.
   */ TransmissionError;
export type LabOrderTransmissionErrorApiArg = {
  orderId: LabOrderId;
};
export type LabListTestsApiResponse =
  /** status 200 Sent in response to successfully fetching the test compendium of a lab.
   */ LabCompendiumItem[];
export type LabListTestsApiArg = {
  labHgId: HgOrgId;
};
export type LabGetAoeQuestionsApiResponse =
  /** status 200 Sent in responses to successfully fetching AOE questions.
   */ AoeQuestion[];
export type LabGetAoeQuestionsApiArg = {
  labHgId: HgOrgId;
  /** Comma-delimited list of test codes from a lab
   */
  codes: string;
};
export type LabSearchLocationsApiResponse =
  /** status 200 Sent in response to a successful lab location search.
   */ LabLocationInfo[];
export type LabSearchLocationsApiArg = {
  labHgId: HgOrgId;
  /** Payload for searching for lab locations in a radius around a postal code.
   */
  labSearch: LabSearch;
};
export type LabBookmarkLocationApiResponse =
  /** status 200 Sent in response to successfully bookmarking a lab location.
   */ void;
export type LabBookmarkLocationApiArg = {
  locationId: LocationId;
  labHgId: HgOrgId;
  lablocationId: HgLocationId;
};
export type LabListBookmarkedLocationsApiResponse =
  /** status 200 Sent in response to successfully listing the bookmarked locations
of a given lab.
 */ LabLocationInfo[];
export type LabListBookmarkedLocationsApiArg = {
  locationId: LocationId;
  labHgId: HgOrgId;
};
export type LabGetLocationInfoApiResponse =
  /** status 200 Sent in response to a request for all the information about a particular
lab location.
 */ LabLocationInfo;
export type LabGetLocationInfoApiArg = {
  lablocationId: HgLocationId;
};
export type LabDeleteBookmarkedLocationApiResponse =
  /** status 200 Sent in response to successfully deleting a bookmarked location.
   */ void;
export type LabDeleteBookmarkedLocationApiArg = {
  locationId: LocationId;
  labHgId: HgOrgId;
  lablocationId: HgLocationId;
};
export type LabListTestBundlesApiResponse =
  /** status 200 Sent in response to successfully listing the test bundles associated
with a given lab.
 */ LocationTestBundles;
export type LabListTestBundlesApiArg = {
  locationId: LocationId;
  labHgId: HgOrgId;
};
export type LabCreateTestBundleApiResponse =
  /** status 200 Sent in response to successfully creating a new test bundle for a given
lab.
 */ BundleUuid;
export type LabCreateTestBundleApiArg = {
  locationId: LocationId;
  labHgId: HgOrgId;
  /** Payload for creating a test bundle.
   */
  labCreateUpdateTestBundle: LabCreateUpdateTestBundle;
};
export type LabUpdateTestBundleApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type LabUpdateTestBundleApiArg = {
  locationId: LocationId;
  labHgId: HgOrgId;
  bundleUuid: BundleUuid;
  /** Payload for updating a test bundle.
   */
  labCreateUpdateTestBundle: LabCreateUpdateTestBundle;
};
export type LabDeleteTestBundleApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type LabDeleteTestBundleApiArg = {
  locationId: LocationId;
  labHgId: HgOrgId;
  bundleUuid: BundleUuid;
};
export type LabSendOrderApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type LabSendOrderApiArg = {
  orderId: LabOrderId;
  /** Payload for sending a lab order.
   */
  labSendOrderRequest: LabSendOrderRequest;
};
export type LabGetOrderResultsPdfApiResponse =
  /** status 200 Sent in response to successfully fetching an order's results PDF.
   */ string;
export type LabGetOrderResultsPdfApiArg = {
  orderId: LabOrderId;
};
export type LabGetOrderRequisitionPdfApiResponse =
  /** status 200 Sent in response to successfully fetching an order's requisition PDF.
   */ string;
export type LabGetOrderRequisitionPdfApiArg = {
  orderId: LabOrderId;
};
export type LabGetOrderObservationsApiResponse =
  /** status 200 Sent in response to successfully fetching an order's observations.
   */ LabObservationInfo[];
export type LabGetOrderObservationsApiArg = {
  orderId: LabOrderId;
};
export type LabToggleOrderReviewedApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type LabToggleOrderReviewedApiArg = {
  orderId: LabOrderId;
};
export type LabGetOrderAbnApiResponse =
  /** status 200 Sent in response to successfully fetching an order's ABN PDF.
   */ string;
export type LabGetOrderAbnApiArg = {
  orderId: LabOrderId;
};
export type LabGetOrderLetterLabelsApiResponse =
  /** status 200 Sent in response to successfully fetching an order's labels for printing on letter paper.
   */ string;
export type LabGetOrderLetterLabelsApiArg = {
  orderId: LabOrderId;
};
export type LabGetOrderDymoLabelsApiResponse =
  /** status 200 Sent in response to successfully fetching an order's labels for printing with a Dymo printer.
   */ OrderDymoLabelsResponse;
export type LabGetOrderDymoLabelsApiArg = {
  orderId: LabOrderId;
};
export type LabFlowsheetCreateApiResponse =
  /** status 200 Sent in response to successfully creating a new lab flowsheet.
   */ LabFlowsheet;
export type LabFlowsheetCreateApiArg = {
  patientId: UserId;
  /** Payload for creating a lab lab flowsheet
   */
  labFlowsheetCreateParams: LabFlowsheetCreateParams;
};
export type LabFlowsheetReadApiResponse =
  /** status 200 Sent in response to successfully reading a lab flowhseet.
   */ LabFlowsheet;
export type LabFlowsheetReadApiArg = {
  labFlowsheetId: LabFlowsheetId;
};
export type LabFlowsheetDeleteApiResponse = /** status 200 No Content */ void;
export type LabFlowsheetDeleteApiArg = {
  labFlowsheetId: LabFlowsheetId;
};
export type LabFlowsheetDeleteDayApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetDeleteDayApiArg = {
  labFlowsheetId: LabFlowsheetId;
  date: DateRfc3339;
};
export type LabFlowsheetListApiResponse =
  /** status 200 Sent in response to successfully listing lab flowsheets.
   */ LabFlowsheetListItem[];
export type LabFlowsheetListApiArg = {
  patientId: UserId;
  labFlowsheetTemplateId?: LabFlowsheetTemplateId;
  pregnancyId?: PregnancyId;
};
export type LabFlowsheetDeleteEntriesApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetDeleteEntriesApiArg = {
  labFlowsheetId: LabFlowsheetId;
  /** Payload for deleting lab flowsheet entries.
   */
  body: LabFlowsheetEntryId[];
};
export type LabFlowsheetInsertEntriesApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetInsertEntriesApiArg = {
  labFlowsheetId: LabFlowsheetId;
  /** Payload for creating lab flowsheet entries.
   */
  body: LabFlowsheetInsertEntry[];
};
export type LabFlowsheetUpdateEntriesApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetUpdateEntriesApiArg = {
  labFlowsheetId: LabFlowsheetId;
  /** Payload for updating lab flowsheet entries.
   */
  body: LabFlowsheetUpdateEntry[];
};
export type LabFlowsheetRemoveFileApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type LabFlowsheetRemoveFileApiArg = {
  labFlowsheetId: LabFlowsheetId;
  /** Payload for attaching a file to a lab flowsheet entry.
   */
  labFlowsheetFileAttach: LabFlowsheetFileAttach;
};
export type LabFlowsheetAttachFileApiResponse =
  /** status 201 API call successful. No extra information available.
   */ ErrorId;
export type LabFlowsheetAttachFileApiArg = {
  labFlowsheetId: LabFlowsheetId;
  /** Payload for attaching a file to a lab flowsheet entry.
   */
  labFlowsheetFileAttach: LabFlowsheetFileAttach;
};
export type LabFlowsheetUpdateDateApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetUpdateDateApiArg = {
  labFlowsheetId: LabFlowsheetId;
  /** Payload for updating the date of a all entries and files associated with a date in the lab flowsheet.
   */
  labFlowsheetUpdateDateParams: LabFlowsheetUpdateDateParams;
};
export type LabFlowsheetCreateTemplateApiResponse =
  /** status 201 Sent in response to successfully creating a lab flowsheet template.
   */ LabFlowsheetTemplate;
export type LabFlowsheetCreateTemplateApiArg = {
  /** Payload for creating a lab flowsheet template.
   */
  labFlowsheetTemplateCreateParams: LabFlowsheetTemplateCreateParams;
};
export type LabFlowsheetReadTemplateApiResponse =
  /** status 200 Sent in response to successfully reading a lab flowsheet template.
   */ LabFlowsheetTemplate;
export type LabFlowsheetReadTemplateApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
};
export type LabFlowsheetUpdateTemplateApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetUpdateTemplateApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  /** Payload for updating a lab flowsheet template.
   */
  labFlowsheetTemplateUpdateParams: LabFlowsheetTemplateUpdateParams;
};
export type LabFlowsheetDeleteTemplateApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetDeleteTemplateApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
};
export type LabFlowsheetTemplateSwapTestsApiResponse =
  /** status 200 Empty OK response */ void;
export type LabFlowsheetTemplateSwapTestsApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  /** Payload for swapping two tests within a lab flowsheet template.
   */
  labFlowsheetTemplateSwapTestsParams: LabFlowsheetTemplateSwapTestsParams;
};
export type LabFlowsheetTemplateSwapAnalytesApiResponse =
  /** status 200 Empty OK response */ void;
export type LabFlowsheetTemplateSwapAnalytesApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  labFlowsheetTemplateTestId: LabFlowsheetTemplateTestId;
  /** Payload for swapping two analytes within a lab flowsheet template.
   */
  labFlowsheetTemplateSwapAnalytesParams: LabFlowsheetTemplateSwapAnalytesParams;
};
export type LabFlowsheetListTemplatesApiResponse =
  /** status 200 Sent in response to successfully listing lab flowsheet templates.
   */ LabFlowsheetTemplateModel[];
export type LabFlowsheetListTemplatesApiArg = void;
export type LabFlowsheetTemplateInsertTestsApiResponse =
  /** status 201 No Content */ void;
export type LabFlowsheetTemplateInsertTestsApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  /** Payload for inserting tests into a lab flowsheet template.
   */
  body: LabFlowsheetTemplateInsertTestParams[];
};
export type LabFlowsheetTemplateUpdateTestApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetTemplateUpdateTestApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  labFlowsheetTemplateTestId: LabFlowsheetTemplateTestId;
  /** Payload for updating tests in a lab flowsheet template.
   */
  labFlowsheetTemplateUpdateTestParams: LabFlowsheetTemplateUpdateTestParams;
};
export type LabFlowsheetTemplateDeleteTestApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetTemplateDeleteTestApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  labFlowsheetTemplateTestId: LabFlowsheetTemplateTestId;
};
export type LabFlowsheetTemplateInsertAnalytesApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetTemplateInsertAnalytesApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  labFlowsheetTemplateTestId: LabFlowsheetTemplateTestId;
  /** Payload for inserting analytes into a lab flowsheet template.
   */
  body: LabFlowsheetTemplateInsertAnalyteParams[];
};
export type LabFlowsheetTemplateUpdateAnalyteApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetTemplateUpdateAnalyteApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  labFlowsheetTemplateTestId: LabFlowsheetTemplateTestId;
  labFlowsheetTemplateAnalyteId: LabFlowsheetTemplateAnalyteId;
  /** Payload for updating analytes in a lab flowsheet template.
   */
  labFlowsheetTemplateUpdateAnalyteParams: LabFlowsheetTemplateUpdateAnalyteParams;
};
export type LabFlowsheetTemplateDeleteAnalyteApiResponse =
  /** status 200 No Content */ void;
export type LabFlowsheetTemplateDeleteAnalyteApiArg = {
  labFlowsheetTemplateId: LabFlowsheetTemplateId;
  labFlowsheetTemplateTestId: LabFlowsheetTemplateTestId;
  labFlowsheetTemplateAnalyteId: LabFlowsheetTemplateAnalyteId;
};
export type LabOrderAnnotationCreateApiResponse =
  /** status 200 Sent in response to successfully creating an annotation for a lab order.
   */ LabOrderAnnotationId;
export type LabOrderAnnotationCreateApiArg = {
  orderId: LabOrderId;
  /** Payload for creating a new lab order annotation.
   */
  labOrderAnnotationCreateRequest: LabOrderAnnotationCreateRequest;
};
export type LabOrderAnnotationUpdateApiResponse =
  /** status 200 Sent in response to successfully updating an annotation for a lab order. */ LabOrderAnnotationId;
export type LabOrderAnnotationUpdateApiArg = {
  orderId: LabOrderId;
  orderAnnotationId: LabOrderAnnotationId;
  /** Payload for updating an existing lab order annotation.
   */
  labOrderAnnotationUpdateRequest: LabOrderAnnotationUpdateRequest;
};
export type LabOrderAnnotationDeleteApiResponse =
  /** status 200 Sent in response to successfully deleting an annotation for a lab order.
   */ LabOrderAnnotationId;
export type LabOrderAnnotationDeleteApiArg = {
  orderId: LabOrderId;
  orderAnnotationId: LabOrderAnnotationId;
};
export type LabOrderAnnotationListApiResponse =
  /** status 200 Sent in response to successfully fetching a list of annotations for a lab order.
   */ LabOrderAnnotation[];
export type LabOrderAnnotationListApiArg = {
  orderId: LabOrderId;
};
export type ErrorId = number;
export type ClaimId = string;
export type LabListItem = {
  lab_id: string;
  display: string;
  from_hg: boolean;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type LabOrgInfo = {
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  id: string;
  name: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type HgOrgId = string;
export type LabOrderId = string;
export type LabOrderUrgency = "ROUTINE" | "URGENT" | "ASAP" | "STAT";
export type LabOrderBillRecipient =
  | "CLIENT"
  | "PATIENT"
  | "GUARANTOR"
  | "THIRD_PARTY";
export type InsuranceType = "PRIMARY" | "SECONDARY" | "TERTIARY";
export type AoeAnswer = {
  aoe_id: string;
  response: string;
};
export type LabTestOptions = {
  /** Code used by the lab to identify a test.
   */
  code: string;
  /** Human-readable version of a lab code.
   */
  display: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note?: string;
  aoe_answers?: AoeAnswer[];
};
export type LabOrderCreateRequest = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  lab_id: string;
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** A human-readable label for the order.
   */
  label: string;
  /** A note on the order to be sent along to the servicing lab.
   */
  note?: string;
  urgency?: LabOrderUrgency;
  bill_recipient?: LabOrderBillRecipient;
  insurance_type?: InsuranceType;
  /** A Pario date in UTC represented as an RFC-3339 string.
   */
  specimen_collected?: string | null;
  fasting?: boolean;
  tests?: LabTestOptions[];
  icd10_ids?: number[];
};
export type LabOrderPatientInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
};
export type LabObservationStatus =
  | "registered"
  | "preliminary"
  | "final"
  | "amended"
  | "cancelled"
  | "corrected";
export type ObservationSource = "LAB" | "MANUAL";
export type ValueQuantity = {
  value: string;
  unit?: string;
};
export type ReferenceRange = {
  low?: ValueQuantity;
  high?: ValueQuantity;
  text?: string;
};
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type Address = {
  /** Address line 1. Mandatory. This can include the street number.
   */
  line1?: string;
  /** Optional line 2 of 3 for an address.
   */
  line2?: string;
  /** Optional line 3 of 3 for an address.
   */
  line3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** This is the optional, human-readable representation of the address. This is used
    when communicating addresses that come from sources that don't parse their addresses
    down into its constituent parts, like Health Gorilla.
     */
  display?: string;
};
export type PerformingSite = {
  name?: string;
  address?: Address;
  contact?: string;
};
export type LabObservationInfo = {
  /** System-assigned unique ID of an observation stored in the Pario system.
    Not assigned to observations stored in Health Gorilla.
     */
  observation_id: string;
  /** An ID for a lab test.
   */
  test_id: string;
  /** Health Gorilla ID for an observation from lab results.
   */
  hg_id: string;
  status: LabObservationStatus;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id?: number;
  source: ObservationSource;
  display: string;
  code: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  issued: string;
  value?: string;
  unit?: string;
  reference_range?: ReferenceRange;
  interpretation?: string;
  note?: string;
  performing_site?: PerformingSite;
};
export type LabOrderStatus =
  | "NEW"
  | "SENT"
  | "IN_PROGRESS"
  | "ERROR"
  | "FULFILLED"
  | "CORRECTED"
  | "REVIEWED"
  | "UNKNOWN";
export type LabTestInfo = {
  /** An ID for a lab test.
   */
  test_id: string;
  /** An ID for a lab order.
   */
  order_id?: string;
  /** Code used by the lab to identify a test.
   */
  code: string;
  /** Code used by the lab to identify a test.
   */
  display: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_to_lab?: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_from_lab?: string;
  observations: LabObservationInfo[];
  status: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  aoe_answers?: AoeAnswer[];
};
export type Icd10CodeDetails = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type LabOrderMetadata = {
  hg_request_group_url?: string;
  hg_request_result_url?: string;
};
export type LabOrderInfo = {
  /** An ID for a lab order.
   */
  order_id: string;
  patient: LabOrderPatientInfo;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  lab_id: string;
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** A human-readable label for the order.
   */
  label: string;
  /** A note on the order to be sent along to the servicing lab.
   */
  note?: string;
  /** A note sent back from the lab to be displayed at the order level.
   */
  result_note?: string;
  urgency: LabOrderUrgency;
  bill_recipient: LabOrderBillRecipient;
  insurance_type?: InsuranceType;
  specimen_collected?: string;
  fasting: boolean;
  tests: LabTestInfo[];
  icd10_codes?: Icd10CodeDetails[];
  status: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  sent?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  fulfilled?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed?: string;
  has_abn: boolean;
  metadata: LabOrderMetadata;
};
export type LabOrderUpdateRequest = {
  /** An ID for a lab order.
   */
  order_id: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  lab_id?: string;
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  status?: LabOrderStatus;
  /** A human-readable label for the order.
   */
  label?: string;
  /** A note on the order to be sent along to the servicing lab.
   */
  note?: string;
  urgency?: LabOrderUrgency;
  bill_recipient?: LabOrderBillRecipient;
  insurance_type?: InsuranceType;
  /** A Pario date in UTC represented as an RFC-3339 string.
   */
  specimen_collected?: string | null;
  fasting?: boolean;
  tests?: LabTestOptions[];
  icd10_ids?: number[];
  metadata?: LabOrderMetadata;
};
export type SplitError = {
  type: "SPLIT";
  error: string;
  recommendation: string[][];
};
export type ValidationError = {
  type: "VALIDATION";
  error: string;
  details: string[];
};
export type UnknownError = {
  type: "UNKNOWN";
  error: string;
};
export type TransmissionError = SplitError | ValidationError | UnknownError;
export type LabCompendiumItem = {
  code: string;
  lab_id: string;
  display: string;
};
export type AoeQuestionType = "string" | "choice" | "date" | "open-choice";
export type AoeQuestion = {
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  lab_id: string;
  /** Code used by the lab to identify a test.
   */
  test_code: string;
  /** The lab-defined ID for this AOE question.
   */
  aoe_id: string;
  /** The text to be displayed to the user.
   */
  text: string;
  type: AoeQuestionType;
  options?: string[];
  initially_selected_option?: string;
  required: boolean;
};
export type LabLocationInfo = {
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id: string;
  name: string;
  address: Address;
  lab?: LabOrgInfo;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabSearch = {
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  radiusInMiles?: number;
};
export type LocationId = number;
export type HgLocationId = string;
export type LabTests = LabTestInfo[];
export type LabTestBundle = {
  /** System-assigned ID for a bundle. Formatted as a UUID.
   */
  bundle_uuid?: string;
  /** User-defined name for a bundle.
   */
  name: string;
  tests: LabTests;
};
export type LocationTestBundles = LabTestBundle[];
export type BundleUuid = string;
export type LabCreateUpdateTestBundle = {
  /** User-defined name for a bundle.
   */
  name?: string;
  tests?: LabTestInfo[];
};
export type LabSendOrderRequest = {
  send_electronically: boolean;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax?: string;
};
export type OrderDymoLabelsResponse = {
  /** HTML for order Dymo labels, provided by Health Gorilla, and encoded in base 64. */
  html: string;
};
export type LabFlowsheetTemplateAnalyte = {
  analyte_id: string;
  name: string;
  units?: string;
  display_order: number;
  loinc_codes: string[];
};
export type LabFlowsheetTemplateTest = {
  test_id: string;
  name: string;
  description?: string;
  display_order: number;
  analytes: LabFlowsheetTemplateAnalyte[];
};
export type LabFlowsheetTemplate = {
  template_id: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id?: number;
  name: string;
  description?: string;
  tests: LabFlowsheetTemplateTest[];
};
export type FileId = number;
export type Analyte = {
  entry_id: string;
  name: string;
  units: string;
  value: string;
  interpretation: string;
  comments?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author_id: number;
  from_health_gorilla: boolean;
};
export type LabFlowsheetResultsByTest = {
  test_name: string;
  analytes: Analyte[];
};
export type LabFlowsheetResultsByDay = {
  date: string;
  file_ids?: FileId[];
  tests: LabFlowsheetResultsByTest[];
};
export type LabFlowsheet = {
  lab_flowsheet_id: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  deleted_at?: string;
  template?: LabFlowsheetTemplate;
  results_by_day: LabFlowsheetResultsByDay[];
};
export type UserId = number;
export type LabFlowsheetCreateParams = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  template_id?: string;
};
export type LabFlowsheetId = string;
export type DateRfc3339 = string;
export type LabFlowsheetListItem = {
  lab_flowsheet_id: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  deleted_at?: string;
  template?: LabFlowsheetTemplate;
};
export type LabFlowsheetTemplateId = string;
export type PregnancyId = number;
export type LabFlowsheetEntryId = string;
export type LabFlowsheetInsertEntry = {
  test_name: string;
  entry_date: string;
  analyte_name: string;
  analyte_units?: string;
  analyte_value: string;
  /** An ID for a lab order.
   */
  order_id?: string;
  from_health_gorilla: boolean;
  interpretation: string;
  comments?: string;
};
export type LabFlowsheetUpdateEntry = {
  entry_id: string;
  test_name?: string;
  entry_date?: string;
  analyte_name?: string;
  analyte_units?: string;
  analyte_value?: string;
  /** An ID for a lab order.
   */
  order_id?: string;
  from_health_gorilla?: boolean;
  interpretation?: string;
  comments?: string;
};
export type LabFlowsheetFileAttach = {
  file_date: string;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
};
export type LabFlowsheetUpdateDateParams = {
  old_date: string;
  new_date: string;
};
export type LabFlowsheetTemplateCreateParams = {
  name: string;
  description?: string;
  from_template_id?: string;
};
export type LabFlowsheetTemplateUpdateParams = {
  name?: string;
  description?: string;
};
export type LabFlowsheetTemplateSwapTestsParams = {
  test_id_1: string;
  test_id_2: string;
};
export type LabFlowsheetTemplateTestId = string;
export type LabFlowsheetTemplateSwapAnalytesParams = {
  analyte_id_1: string;
  analyte_id_2: string;
};
export type LabFlowsheetTemplateAnalyteModel = {
  template_analyte_id: string;
  template_test_id: string;
  name: string;
  units?: string;
  loinc_code?: string;
  display_order: number;
};
export type LabFlowsheetTemplateTestModel = {
  template_test_id: string;
  template_id: string;
  name: string;
  description?: string;
  display_order: number;
  analytes: LabFlowsheetTemplateAnalyteModel[];
};
export type LabFlowsheetTemplateModel = {
  template_id: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id?: number;
  name: string;
  description?: string;
  entries: LabFlowsheetTemplateTestModel[];
};
export type LabFlowsheetTemplateInsertTestParams = {
  name: string;
  description?: string;
  display_order: number;
};
export type LabFlowsheetTemplateUpdateTestParams = {
  name?: string;
  description?: string;
  display_order?: number;
};
export type LabFlowsheetTemplateInsertAnalyteParams = {
  name: string;
  units?: string;
  loinc_codes?: string[];
  display_order: number;
};
export type LabFlowsheetTemplateAnalyteId = string;
export type LabFlowsheetTemplateUpdateAnalyteParams = {
  name?: string;
  units?: string;
  loinc_codes?: string[];
  display_order?: number;
};
export type LabOrderAnnotationId = string;
export type LabOrderAnnotationCreateRequest = {
  /** An ID for a lab order.
   */
  order_id: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author_id: number;
  annotation: string;
};
export type LabOrderAnnotationUpdateRequest = {
  /** internal ID for a lab order annotation in format: order_annotation_$ULID.
   */
  annotation_id: string;
  annotation: string;
};
export type LabOrderAnnotation = {
  /** internal ID for a lab order annotation in format: order_annotation_$ULID.
   */
  annotation_id: string;
  /** An ID for a lab order.
   */
  order_id: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author_id: number;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  annotation: string;
};
export const {
  useSetClaimNonBillableMutation,
  useLabListQuery,
  useLazyLabListQuery,
  useLabGetInfoQuery,
  useLazyLabGetInfoQuery,
  useLabOrderCreateMutation,
  useLabOrderReadQuery,
  useLazyLabOrderReadQuery,
  useLabOrderUpdateMutation,
  useLabDeleteOrderMutation,
  useLabOrderTransmissionErrorQuery,
  useLazyLabOrderTransmissionErrorQuery,
  useLabListTestsQuery,
  useLazyLabListTestsQuery,
  useLabGetAoeQuestionsQuery,
  useLazyLabGetAoeQuestionsQuery,
  useLabSearchLocationsMutation,
  useLabBookmarkLocationMutation,
  useLabListBookmarkedLocationsQuery,
  useLazyLabListBookmarkedLocationsQuery,
  useLabGetLocationInfoQuery,
  useLazyLabGetLocationInfoQuery,
  useLabDeleteBookmarkedLocationMutation,
  useLabListTestBundlesQuery,
  useLazyLabListTestBundlesQuery,
  useLabCreateTestBundleMutation,
  useLabUpdateTestBundleMutation,
  useLabDeleteTestBundleMutation,
  useLabSendOrderMutation,
  useLabGetOrderResultsPdfQuery,
  useLazyLabGetOrderResultsPdfQuery,
  useLabGetOrderRequisitionPdfQuery,
  useLazyLabGetOrderRequisitionPdfQuery,
  useLabGetOrderObservationsQuery,
  useLazyLabGetOrderObservationsQuery,
  useLabToggleOrderReviewedMutation,
  useLabGetOrderAbnQuery,
  useLazyLabGetOrderAbnQuery,
  useLabGetOrderLetterLabelsQuery,
  useLazyLabGetOrderLetterLabelsQuery,
  useLabGetOrderDymoLabelsQuery,
  useLazyLabGetOrderDymoLabelsQuery,
  useLabFlowsheetCreateMutation,
  useLabFlowsheetReadQuery,
  useLazyLabFlowsheetReadQuery,
  useLabFlowsheetDeleteMutation,
  useLabFlowsheetDeleteDayMutation,
  useLabFlowsheetListQuery,
  useLazyLabFlowsheetListQuery,
  useLabFlowsheetDeleteEntriesMutation,
  useLabFlowsheetInsertEntriesMutation,
  useLabFlowsheetUpdateEntriesMutation,
  useLabFlowsheetRemoveFileMutation,
  useLabFlowsheetAttachFileMutation,
  useLabFlowsheetUpdateDateMutation,
  useLabFlowsheetCreateTemplateMutation,
  useLabFlowsheetReadTemplateQuery,
  useLazyLabFlowsheetReadTemplateQuery,
  useLabFlowsheetUpdateTemplateMutation,
  useLabFlowsheetDeleteTemplateMutation,
  useLabFlowsheetTemplateSwapTestsMutation,
  useLabFlowsheetTemplateSwapAnalytesMutation,
  useLabFlowsheetListTemplatesQuery,
  useLazyLabFlowsheetListTemplatesQuery,
  useLabFlowsheetTemplateInsertTestsMutation,
  useLabFlowsheetTemplateUpdateTestMutation,
  useLabFlowsheetTemplateDeleteTestMutation,
  useLabFlowsheetTemplateInsertAnalytesMutation,
  useLabFlowsheetTemplateUpdateAnalyteMutation,
  useLabFlowsheetTemplateDeleteAnalyteMutation,
  useLabOrderAnnotationCreateMutation,
  useLabOrderAnnotationUpdateMutation,
  useLabOrderAnnotationDeleteMutation,
  useLabOrderAnnotationListQuery,
  useLazyLabOrderAnnotationListQuery
} = injectedRtkApi;

import React from "react";

import InactivityMonitor from "@/components/sessionMonitor/InactivityMonitor";
import TokenExpiryMonitor from "@/components/sessionMonitor/TokenExpiryMonitor";

export interface SessionMonitorProps {
  lookbackPeriod?: number;
  inactivityThreshold?: number;
  inactivityResponseTime?: number;
  activityEvents?: string[];
  checkInterval?: number;
}

export default function SessionMonitor({
  lookbackPeriod = 1000 * 60 * 15, // default: 15 minutes
  inactivityThreshold = 1000 * 60 * 60, // default: 1 hour
  inactivityResponseTime = 1000 * 60, // default: 1 minute
  activityEvents = ["mousemove", "keydown", "touchstart"],
  checkInterval = 500
}: SessionMonitorProps) {
  return (
    <React.Fragment>
      <TokenExpiryMonitor
        lookbackPeriod={lookbackPeriod}
        checkInterval={checkInterval}
      />
      <InactivityMonitor
        inactivityThreshold={inactivityThreshold}
        inactivityResponseTime={inactivityResponseTime}
        activityEvents={activityEvents}
        checkInterval={checkInterval}
      />
    </React.Fragment>
  );
}

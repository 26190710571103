import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { skipToken } from "@reduxjs/toolkit/query";
import { useRouter } from "next/router";
import path from "path";
import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import Select, { MultiValue, StylesConfig } from "react-select";

import Input from "@/components/input";
import ArrowLeft from "@/components/svgIcons/ArrowLeft";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import ViewBody from "@/components/viewBody";

import { STATUS_KEYS } from "@/globals/constants";

import { FileListItem, useFileUpdateMutation } from "@/store/services/file";
import { TagListItem, useTagsListQuery } from "@/store/services/tag";
import { RootState } from "@/store/store";

import Edit from "../../../public/svgs/edit_simple.svg";
import TagIcon from "../../../public/svgs/tag.svg";
import FileRenderer from "./fileRenderer";
// styles
import styles from "./styles.module.scss";

interface FileViewerProps {
  file: FileListItem;
  onClose: () => void;
  isReadOnly?: boolean;
}

type TagOptions = { value: TagListItem["tag_id"]; label: TagListItem["name"] };

const FileViewer: React.FC<FileViewerProps> = ({
  file,
  onClose,
  isReadOnly
}) => {
  const router = useRouter();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [updateFile] = useFileUpdateMutation();
  const { data: availableTags = [] } = useTagsListQuery(
    sessionInfo?.practice_id
      ? { practiceId: sessionInfo.practice_id }
      : skipToken
  );

  const tagOptions: TagOptions[] = useMemo(() => {
    return availableTags.map(tag => ({
      value: tag.tag_id,
      label: tag.name
    }));
  }, [availableTags]);

  const activeFileTags = useMemo(() => {
    return availableTags
      .filter(tag => Object.keys(file.tags).includes(tag.tag_id.toString()))
      .map(tag => ({ value: tag.tag_id, label: tag.name }));
  }, [availableTags, file.tags]);

  const onSelectOptions = (selectedOptions: MultiValue<TagOptions>) => {
    const tags = selectedOptions.map(option => option.value);
    updateFile({
      fileId: file.file_id,
      fileUpdateRequest: { name: file.name, tags }
    })
      .unwrap()
      .catch(() => {
        addAlertToToastTrough({
          message: "failed to update file",
          type: STATUS_KEYS.ERROR
        });
      });
  };

  const filepath = path.join("", file.name);
  const parts = filepath.split("/");
  const filename = parts[parts.length - 1];
  const [isEditing, setIsEditing] = useState(false);
  const [editedFilename, setEditedFilename] = useState(filename);
  const handleFilepathClick = () => {
    setIsEditing(true);
  };

  const handleRenameFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedFilename(event.target.value);
  };

  return (
    <div className={styles.FileViewer} data-cy="file-viewer">
      {!isReadOnly && (
        <div className={styles.header}>
          <div className={styles.fileName}>
            <ArrowLeft
              className={styles.backIcon}
              onClick={() => {
                router.back();
                onClose();
              }}
            />
            <div className={styles.filepath}>
              {isEditing ? (
                <Input
                  type="text"
                  name="file_name"
                  id="file_name"
                  label="Change file name"
                  hiddenLabel
                  value={editedFilename}
                  onChange={handleRenameFile}
                  onSubmit={() => {
                    setIsEditing(false);
                    updateFile({
                      fileId: file.file_id,
                      fileUpdateRequest: {
                        name: editedFilename,
                        tags: Object.keys(file.tags).map(t => parseInt(t))
                      }
                    });
                  }}
                />
              ) : (
                <>
                  <span onClick={handleFilepathClick}>{filepath}</span>
                  <Edit
                    onClick={handleFilepathClick}
                    stroke={styles.gray500}
                    width={16}
                    height={16}
                  />
                </>
              )}
            </div>
          </div>
          <div className={styles.tagsContainer}>
            <div className={styles.tagTitle}>
              <TagIcon stroke={styles.gray500} width={14} height={14} />
              <p>Tags</p>
            </div>
            <Select<TagOptions, true>
              isMulti
              onChange={onSelectOptions}
              options={tagOptions}
              placeholder="Select tags"
              styles={SelectDefaultStyles as StylesConfig<TagOptions, true>}
              theme={SelectDefaultTheme}
              value={activeFileTags}
            />
          </div>
        </div>
      )}
      <ViewBody fileId={file.file_id}>
        <div className={styles.contents}>{<FileRenderer file={file} />}</div>
      </ViewBody>
    </div>
  );
};

export default FileViewer;

import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { ForwardedRef, forwardRef, useImperativeHandle, useMemo } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";

import { NEWBORN_FORM } from "@/components/forms/_labor/constants";
import { NewbornFormPartsProps } from "@/components/forms/_labor/newbornExam/newborn";
import styles from "@/components/forms/_labor/styles.module.scss";
import ControlledOptionsField from "@/components/forms/generated/options";

import { usePatientGetInfoQuery } from "@/store/services/patient";

import { exam_fields } from "./constants";
import NewbornPhysicalSelect from "./newbornPhysicalSelect";
import { ModalController, useModal } from "@/components/modalV2/ModalController";
import { ConfirmModal, ConfirmModalProps } from "@/components/modalV2/ConfirmationModal";

interface NewbornPhysicalProps extends NewbornFormPartsProps {
  newbornId: number;
}

export default forwardRef(function NewbornPhysical(
  { disabled = false, previousExamData, newbornId, form }: NewbornPhysicalProps,
  ref: ForwardedRef<any>
) {
  const { control } = form;

  const { data: infantInfo } = usePatientGetInfoQuery({
    patientId: newbornId
  });
  const [modalActions, modalState] = useModal<ConfirmModalProps>();

  const isVersion2 = useMemo(() => {
    return !(previousExamData && previousExamData?.version !== "2");
  }, [previousExamData]);

  /**
   * Filter out the genitourinary field based on sex of infant
   */
  const formFields = useMemo(() => {
    // If we don't know the sex, don't filter at all.
    if (infantInfo?.sex === "UNKNOWN") {
      return exam_fields;
    }
    // If the patient is male, filter out the female one and vice versa
    const genitourinaryFieldFilter =
      infantInfo?.sex == "MALE" ? "genitourinary_female" : "genitourinary_male";
    return exam_fields.filter(({ id }) => id != genitourinaryFieldFilter);
  }, [infantInfo]);

  const setPhysicalValuesNormal = () => {
    formFields.map(({ id, normalText }) => {
      form.setValue(id, "normal", { shouldDirty: true });
      const commentValue = form.getValues(`${id}-comment`);
      if (commentValue === "") {
        form.setValue(`${id}-comment`, normalText, { shouldDirty: true });
      }
    });
  }

  useImperativeHandle(ref, () => {
    return {
      getFormData: () => {
        return form.getValues();
      },
      markAllAsNormal: () => {
        if (!formFields.some(({ id }) => form.getValues(id))) {
          setPhysicalValuesNormal();
          return;
        }
        modalActions.open({
          message: "This will overwrite any existing selections and empty comments",
          onConfirm: () => {
            setPhysicalValuesNormal();
            modalActions.close();
          }
        });
      },
      resetForm: (values: any) => {
        form.reset(values);
      }
    };
  });

  return (
    <>
      {isVersion2
        ? formFields.map(field => (
            <NewbornPhysicalSelect examField={field} form={form} key={field.id} />
          ))
        : NEWBORN_FORM.physical.map(({ id, label, options }) =>
            id === "thorax" ? (
              <div key={id} className={styles.row}>
                <label>{label}</label>
                <ControlledOptionsField
                  label={label}
                  name={id}
                  key={id}
                  control={control}
                  options={[
                    { id: "yes", label: "Yes" },
                    { id: "no", label: "No" }
                  ]}
                  hiddenLabel
                  disabled={disabled}
                />
              </div>
            ) : (
              <label className="horizontalLabel" key={id}>
                <span>{label}</span>
                <Controller
                  name={id}
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      theme={SelectDefaultTheme}
                      styles={SelectDefaultStyles}
                      onChange={(newValue: any) => onChange(newValue?.value)}
                      value={options?.find(option => option.label === value)}
                      options={options}
                    />
                  )}
                />
              </label>
            )
          )}

      <ModalController
        modalState={modalState}
        modalComponent={ConfirmModal}
        close={modalActions.close}
        title="Mark all fields as normal"
      />
    </>
  );
});
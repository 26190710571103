import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    caseCreate: build.mutation<CaseCreateApiResponse, CaseCreateApiArg>({
      query: queryArg => ({
        url: `/cases/create`,
        method: "POST",
        body: queryArg.casesCreateRequest
      })
    }),
    caseRead: build.query<CaseReadApiResponse, CaseReadApiArg>({
      query: queryArg => ({ url: `/cases/${queryArg.caseId}/info` })
    }),
    caseUpdate: build.mutation<CaseUpdateApiResponse, CaseUpdateApiArg>({
      query: queryArg => ({
        url: `/cases/${queryArg.caseId}/update`,
        method: "POST",
        body: queryArg.caseUpdateRequest
      })
    }),
    caseDelete: build.mutation<CaseDeleteApiResponse, CaseDeleteApiArg>({
      query: queryArg => ({
        url: `/cases/${queryArg.caseId}/delete`,
        method: "DELETE"
      })
    }),
    caseCommentCreate: build.mutation<
      CaseCommentCreateApiResponse,
      CaseCommentCreateApiArg
    >({
      query: queryArg => ({
        url: `/cases/${queryArg.caseId}/create-comment`,
        method: "POST",
        body: queryArg.caseCommentCreateRequest
      })
    }),
    caseCommentUpdate: build.mutation<
      CaseCommentUpdateApiResponse,
      CaseCommentUpdateApiArg
    >({
      query: queryArg => ({
        url: `/cases/${queryArg.caseId}/comment/${queryArg.caseCommentId}/update`,
        method: "PUT",
        body: queryArg.caseCommentCreateRequest
      })
    }),
    caseCommentDelete: build.mutation<
      CaseCommentDeleteApiResponse,
      CaseCommentDeleteApiArg
    >({
      query: queryArg => ({
        url: `/cases/${queryArg.caseId}/comment/${queryArg.caseCommentId}/delete`,
        method: "DELETE"
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as cases };
export type CaseCreateApiResponse =
  /** status 200 Sent in response to a request to create a new case.
   */ CaseInfo;
export type CaseCreateApiArg = {
  /** Payload for creating a new case.
   */
  casesCreateRequest: CasesCreateRequest;
};
export type CaseReadApiResponse =
  /** status 200 Sent in response to a request for all the information about a particular case.
   */ CaseInfo;
export type CaseReadApiArg = {
  caseId: CaseId;
};
export type CaseUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CaseUpdateApiArg = {
  caseId: CaseId;
  /** Payload for updating a case.
   */
  caseUpdateRequest: CaseUpdateRequest;
};
export type CaseDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CaseDeleteApiArg = {
  caseId: CaseId;
};
export type CaseCommentCreateApiResponse =
  /** status 200 Sent in response to a request to create a new comment on a case.
   */ CaseCommentId;
export type CaseCommentCreateApiArg = {
  caseId: CaseId;
  /** Payload for creating a new comment on a case.
   */
  caseCommentCreateRequest: CaseCommentCreateRequest;
};
export type CaseCommentUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CaseCommentUpdateApiArg = {
  caseId: CaseId;
  caseCommentId: CaseCommentId;
  /** Payload for updating a comment on a case.
   */
  caseCommentCreateRequest: CaseCommentCreateRequest;
};
export type CaseCommentDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CaseCommentDeleteApiArg = {
  caseId: CaseId;
  caseCommentId: CaseCommentId;
};
export type Staff = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  staff_id: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
};
export type CaseCommentsInner = {
  /** System-assigned unique ID of a case comment.
   */
  comment_id: string;
  staff: Staff;
  /** The comment of the case.
   */
  comment: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at: string;
};
export type CaseComments = CaseCommentsInner[];
export type CaseInfo = {
  /** System-assigned unique ID of a case.
   */
  case_id: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  staff: Staff;
  comments: CaseComments;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** The title of the case.
   */
  title: string;
  /** The note of the case.
   */
  note: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  date_time?: string;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type CasesCreateRequest = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  staff_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** The title of the case.
   */
  title: string;
  /** The note of the case.
   */
  note?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  date_time: string;
};
export type CaseId = string;
export type ErrorId = number;
export type CaseUpdateRequest = {
  /** The title of the case.
   */
  title?: string;
  /** The note of the case.
   */
  note?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  date_time?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  staff_id?: number;
};
export type CaseCommentId = string;
export type CaseCommentCreateRequest = {
  /** The comment of the case.
   */
  comment: string;
};
export const {
  useCaseCreateMutation,
  useCaseReadQuery,
  useLazyCaseReadQuery,
  useCaseUpdateMutation,
  useCaseDeleteMutation,
  useCaseCommentCreateMutation,
  useCaseCommentUpdateMutation,
  useCaseCommentDeleteMutation
} = injectedRtkApi;

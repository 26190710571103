import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { motion, AnimatePresence } from "framer-motion";
import clsx from "clsx";
import FocusTrap from "focus-trap-react"; // Import FocusTrap

// components
import Icon from "../icons";

// constants
import { MODAL_DISPATCHER } from "./dispatcher";

// store
import { RootState } from "@/store/store";
import { setModalContent, setModalIsOpen } from "./modalSlice";

// styles
import styles from "./styles.module.scss";

export interface ModalProps {}

export default function Modal({}: ModalProps) {
  const dispatch = useDispatch();

  /* State */
  const isOpen = useSelector((state: RootState) => state.modal.isOpen);

  const { type, props } = useSelector(
    (state: RootState) => state.modal.content || {}
  );

  /* Local State */
  const [active, setActive] = useState(false);

  /* Effects */
  // For accessibility - close modal on press of `Esc` key
  useEffect(() => {
    function onEscape(e: KeyboardEvent) {
      if (e.code === "Escape") {
        handleClose();
      }
    }
    document.addEventListener("keydown", onEscape);

    return () => document.removeEventListener("keydown", onEscape);
  }, []);

  // Activate focus trap when modal opens
  useEffect(() => {
    setActive(isOpen);
    return () => {
      if (!isOpen) setActive(false);
    };
  }, [isOpen]);

  // Event handlers
  const handleClose = useCallback(() => {
    // close modal
    dispatch(setModalIsOpen(false));

    setTimeout(() => {
      dispatch(setModalContent(null));
    }, 500);
  }, [dispatch]);

  const modalVariant = {
    initial: {
      opacity: 0,
      y: 50,
      transition: {
        duration: 0.5,
        damping: 10,
        delay: 0.4
      }
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        type: "spring",
        duration: 0.5,
        damping: 10,
        delay: 0.2
      }
    }
  };

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div
          layout
          className={clsx(styles.Modal, {
            [styles.isOpen]: isOpen,
            [styles.fullWidth]: props?.fullWidth
          })}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <FocusTrap
            active={active}
            focusTrapOptions={{ allowOutsideClick: true }}
          >
            <motion.div
              className={styles.card}
              role="dialog"
              aria-modal="true"
              onClick={e => e.stopPropagation()} // stops click event on modal card from closing modal
              variants={modalVariant}
              initial="initial"
              animate="animate"
              exit="initial"
              data-cy="modal"
              data-testid="modal"
            >
              <div className={styles.header}>
                <h4>{props?.title}</h4>{" "}
                <button
                  type="button"
                  onClick={handleClose}
                  className={styles.buttonClose}
                  data-cy="modal-close"
                >
                  <Icon svg="close_grey" width={24} />
                </button>
              </div>
              {type && props && getTemplate(type, props)}
            </motion.div>
          </FocusTrap>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

function getTemplate(type: string, props: { [key: string]: any }) {
  const Content = MODAL_DISPATCHER[type];
  return <Content {...props} />;
}

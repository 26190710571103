import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    encounterCreate: build.mutation<
      EncounterCreateApiResponse,
      EncounterCreateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.patientId}/${queryArg.providerId}/create`,
        method: "POST",
        body: queryArg.encounterCreateUpdatePayload
      })
    }),
    encounterList: build.query<EncounterListApiResponse, EncounterListApiArg>({
      query: queryArg => ({
        url: `/encounter/${queryArg.id}/${queryArg.scope}/list`,
        params: {
          start: queryArg.start,
          end: queryArg.end,
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          superbill_status: queryArg.superbillStatus,
          encounter_status: queryArg.encounterStatus,
          encounter_type: queryArg.encounterType
        }
      })
    }),
    encounterBriefList: build.query<
      EncounterBriefListApiResponse,
      EncounterBriefListApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.id}/${queryArg.scope}/list_brief`,
        params: {
          start: queryArg.start,
          end: queryArg.end,
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          encounter_status: queryArg.encounterStatus,
          encounter_type: queryArg.encounterType,
          sort_by: queryArg.sortBy,
          sort_order: queryArg.sortOrder,
          status_not_in: queryArg.statusNotIn
        }
      })
    }),
    encounterUpdate: build.mutation<
      EncounterUpdateApiResponse,
      EncounterUpdateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/update`,
        method: "POST",
        body: queryArg.encounterCreateUpdatePayload
      })
    }),
    encounterListVersions: build.query<
      EncounterListVersionsApiResponse,
      EncounterListVersionsApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/versions`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    encounterInfo: build.query<EncounterInfoApiResponse, EncounterInfoApiArg>({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}`,
        params: { version: queryArg.version }
      })
    }),
    encounterSubmit: build.mutation<
      EncounterSubmitApiResponse,
      EncounterSubmitApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/${queryArg.version}/submit`,
        method: "POST"
      })
    }),
    encounterDelete: build.mutation<
      EncounterDeleteApiResponse,
      EncounterDeleteApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/delete`,
        method: "DELETE"
      })
    }),
    encounterLaborInfo: build.query<
      EncounterLaborInfoApiResponse,
      EncounterLaborInfoApiArg
    >({
      query: queryArg => ({ url: `/encounter/${queryArg.encounterId}/labor` })
    }),
    encounterLaborSheet: build.query<
      EncounterLaborSheetApiResponse,
      EncounterLaborSheetApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/sheet`
      })
    }),
    encounterLaborUpsert: build.mutation<
      EncounterLaborUpsertApiResponse,
      EncounterLaborUpsertApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/upsert`,
        method: "POST",
        body: queryArg.laborModel
      })
    }),
    encounterLaborEventUpsert: build.mutation<
      EncounterLaborEventUpsertApiResponse,
      EncounterLaborEventUpsertApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.laborEvent}/upsert`,
        method: "PUT",
        body: queryArg.laborEventUpdate
      })
    }),
    devLaborEventUpsert: build.mutation<
      DevLaborEventUpsertApiResponse,
      DevLaborEventUpsertApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.laborEvent}/dev-upsert`,
        method: "PUT",
        body: queryArg.laborEventUpsert
      })
    }),
    encounterLaborEventDelete: build.mutation<
      EncounterLaborEventDeleteApiResponse,
      EncounterLaborEventDeleteApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.laborEvent}/delete`,
        method: "DELETE"
      })
    }),
    encounterLaborRecurringEventUpdate: build.mutation<
      EncounterLaborRecurringEventUpdateApiResponse,
      EncounterLaborRecurringEventUpdateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.scope}/${queryArg.laborEventId}`,
        method: "PUT",
        body: queryArg.laborEventUpdate
      })
    }),
    encounterLaborRecurringEventCreate: build.mutation<
      EncounterLaborRecurringEventCreateApiResponse,
      EncounterLaborRecurringEventCreateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.scope}/create`,
        method: "PUT",
        body: queryArg.laborEventCreate
      })
    }),
    encounterLaborRecurringEventDelete: build.mutation<
      EncounterLaborRecurringEventDeleteApiResponse,
      EncounterLaborRecurringEventDeleteApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/labor/${queryArg.stage}/${queryArg.scope}/${queryArg.laborEventId}/delete`,
        method: "DELETE"
      })
    }),
    locationGetUserList: build.query<
      LocationGetUserListApiResponse,
      LocationGetUserListApiArg
    >({
      query: queryArg => ({
        url: `/location/${queryArg.locationId}/users`,
        params: {
          filters: queryArg.filters,
          page: queryArg.page,
          pagesz: queryArg.pagesz
        }
      })
    }),
    medicationCreateForPatient: build.mutation<
      MedicationCreateForPatientApiResponse,
      MedicationCreateForPatientApiArg
    >({
      query: queryArg => ({
        url: `/medications/${queryArg.userId}/create`,
        method: "POST",
        body: queryArg.medicationCreate
      })
    }),
    medicationUpdateForPatient: build.mutation<
      MedicationUpdateForPatientApiResponse,
      MedicationUpdateForPatientApiArg
    >({
      query: queryArg => ({
        url: `/medications/${queryArg.userId}/update`,
        method: "POST",
        body: queryArg.medicationUpdate
      })
    }),
    medicationDeleteFromPatient: build.mutation<
      MedicationDeleteFromPatientApiResponse,
      MedicationDeleteFromPatientApiArg
    >({
      query: queryArg => ({
        url: `/medications/${queryArg.userId}/${queryArg.uuid}/delete`,
        method: "DELETE"
      })
    }),
    patientUpsert: build.mutation<
      PatientUpsertApiResponse,
      PatientUpsertApiArg
    >({
      query: queryArg => ({
        url: `/patient/upsert`,
        method: "POST",
        body: queryArg.patientUpsertRequest
      })
    }),
    patientDelete: build.mutation<
      PatientDeleteApiResponse,
      PatientDeleteApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/delete`,
        method: "DELETE"
      })
    }),
    patientGetInfo: build.query<
      PatientGetInfoApiResponse,
      PatientGetInfoApiArg
    >({
      query: queryArg => ({ url: `/patient/${queryArg.patientId}/info` })
    }),
    patientInfoExport: build.query<
      PatientInfoExportApiResponse,
      PatientInfoExportApiArg
    >({
      query: queryArg => ({ url: `/patient/${queryArg.patientId}/info/export` })
    }),
    patientGetForm: build.query<
      PatientGetFormApiResponse,
      PatientGetFormApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/form/${queryArg.formId}`
      })
    }),
    patientSubmitForm: build.mutation<
      PatientSubmitFormApiResponse,
      PatientSubmitFormApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/form/${queryArg.formId}/submit`,
        method: "POST",
        body: queryArg.patientSubmitFormRequest
      })
    }),
    patientSendInviteLink: build.mutation<
      PatientSendInviteLinkApiResponse,
      PatientSendInviteLinkApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/send-invite-link`,
        method: "POST"
      })
    }),
    patientPediatricTransferRecordsPdf: build.query<
      PatientPediatricTransferRecordsPdfApiResponse,
      PatientPediatricTransferRecordsPdfApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/pediatric-transfer-pdf`
      })
    }),
    getHealthHistory: build.query<
      GetHealthHistoryApiResponse,
      GetHealthHistoryApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/health-history`
      })
    }),
    createtHealthHistoryEntry: build.mutation<
      CreatetHealthHistoryEntryApiResponse,
      CreatetHealthHistoryEntryApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/health-history`,
        method: "POST",
        body: queryArg.patientHealthHistoryCreate
      })
    }),
    deleteHealthHistoryEntry: build.mutation<
      DeleteHealthHistoryEntryApiResponse,
      DeleteHealthHistoryEntryApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/health-history/${queryArg.healthHistoryId}`,
        method: "DELETE"
      })
    }),
    updateHealthHistoryEntry: build.mutation<
      UpdateHealthHistoryEntryApiResponse,
      UpdateHealthHistoryEntryApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/health-history/${queryArg.healthHistoryId}`,
        method: "PATCH",
        body: queryArg.patientHealthHistoryUpdate
      })
    }),
    patientGeneratePdf: build.mutation<
      PatientGeneratePdfApiResponse,
      PatientGeneratePdfApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/generate-pdf`,
        method: "POST",
        body: queryArg.patientGeneratePdfPayload
      })
    }),
    patientGetCareHistory: build.query<
      PatientGetCareHistoryApiResponse,
      PatientGetCareHistoryApiArg
    >({
      query: queryArg => ({
        url: `/patients/${queryArg.patientId}/care-history`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    patientGetFiles: build.query<
      PatientGetFilesApiResponse,
      PatientGetFilesApiArg
    >({
      query: queryArg => ({ url: `/patient/${queryArg.patientId}/files` })
    }),
    patientUploadFiles: build.mutation<
      PatientUploadFilesApiResponse,
      PatientUploadFilesApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/files/upload`,
        method: "POST"
      })
    }),
    patientDeleteFiles: build.mutation<
      PatientDeleteFilesApiResponse,
      PatientDeleteFilesApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/files/delete`,
        method: "POST"
      })
    }),
    patientGetInfantGrowthSheet: build.query<
      PatientGetInfantGrowthSheetApiResponse,
      PatientGetInfantGrowthSheetApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/infant-growth-sheet`
      })
    }),
    patientArchive: build.mutation<
      PatientArchiveApiResponse,
      PatientArchiveApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/archive`,
        method: "POST"
      })
    }),
    patientGetDoseSpotSsoUrl: build.query<
      PatientGetDoseSpotSsoUrlApiResponse,
      PatientGetDoseSpotSsoUrlApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/rx/dosespot-sso`
      })
    }),
    patientCloseDoseSpot: build.mutation<
      PatientCloseDoseSpotApiResponse,
      PatientCloseDoseSpotApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/dosespot-close`,
        method: "POST"
      })
    }),
    patientListRx: build.query<PatientListRxApiResponse, PatientListRxApiArg>({
      query: queryArg => ({ url: `/patient/${queryArg.patientId}/rx/list` })
    }),
    patientUnarchive: build.mutation<
      PatientUnarchiveApiResponse,
      PatientUnarchiveApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/unarchive`,
        method: "POST"
      })
    }),
    problemUpsert: build.mutation<
      ProblemUpsertApiResponse,
      ProblemUpsertApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/problem/upsert`,
        method: "POST",
        body: queryArg.problemUpsertRequest
      })
    }),
    problemInfo: build.query<ProblemInfoApiResponse, ProblemInfoApiArg>({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/problem/${queryArg.problemId}/info`
      })
    }),
    patientDiagnosisHistory: build.query<
      PatientDiagnosisHistoryApiResponse,
      PatientDiagnosisHistoryApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/diagnoses/${queryArg.icdId}/history`
      })
    }),
    patientArchiveDiagnosis: build.mutation<
      PatientArchiveDiagnosisApiResponse,
      PatientArchiveDiagnosisApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/diagnoses/${queryArg.icd10Code}/archive`,
        method: "POST"
      })
    }),
    patientGetLabOrders: build.query<
      PatientGetLabOrdersApiResponse,
      PatientGetLabOrdersApiArg
    >({
      query: queryArg => ({ url: `/patient/${queryArg.patientId}/orders` })
    }),
    patientDeletePharmacy: build.mutation<
      PatientDeletePharmacyApiResponse,
      PatientDeletePharmacyApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/pharmacy/delete`,
        method: "DELETE",
        params: { pharmacy_id: queryArg.pharmacyId }
      })
    }),
    patientGetBalance: build.query<
      PatientGetBalanceApiResponse,
      PatientGetBalanceApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/balance`,
        params: { encounter: queryArg.encounter }
      })
    }),
    patientGetTransactions: build.query<
      PatientGetTransactionsApiResponse,
      PatientGetTransactionsApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/transactions`,
        params: {
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          start_date: queryArg.startDate,
          end_date: queryArg.endDate,
          order: queryArg.order,
          encounter: queryArg.encounter
        }
      })
    }),
    patientCreateTransaction: build.mutation<
      PatientCreateTransactionApiResponse,
      PatientCreateTransactionApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/transactions/create`,
        method: "POST",
        body: queryArg.patientTransactionCreatePayload
      })
    }),
    patientUpdateTransaction: build.mutation<
      PatientUpdateTransactionApiResponse,
      PatientUpdateTransactionApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/transactions/${queryArg.transactionId}/update`,
        method: "PUT",
        body: queryArg.patientTransactionUpdatePayload
      })
    }),
    pregnancyUpsert: build.mutation<
      PregnancyUpsertApiResponse,
      PregnancyUpsertApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/pregnancy`,
        method: "POST",
        body: queryArg.pregnancyUpsertRequest
      })
    }),
    listPastPregnancies: build.query<
      ListPastPregnanciesApiResponse,
      ListPastPregnanciesApiArg
    >({
      query: queryArg => ({
        url: `/patient/${queryArg.patientId}/past-pregnancies`
      })
    }),
    pregnancyInfo: build.query<PregnancyInfoApiResponse, PregnancyInfoApiArg>({
      query: queryArg => ({ url: `/pregnancy/${queryArg.pregnancyId}/info` })
    }),
    pregnancyLaborInfo: build.query<
      PregnancyLaborInfoApiResponse,
      PregnancyLaborInfoApiArg
    >({
      query: queryArg => ({ url: `/pregnancy/${queryArg.pregnancyId}/labor` })
    }),
    pregnancyLaborSheet: build.query<
      PregnancyLaborSheetApiResponse,
      PregnancyLaborSheetApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/sheet`
      })
    }),
    pregnancyLaborUpsert: build.mutation<
      PregnancyLaborUpsertApiResponse,
      PregnancyLaborUpsertApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/upsert`,
        method: "POST",
        body: queryArg.laborModel
      })
    }),
    pregnancyLaborEventUpsert: build.mutation<
      PregnancyLaborEventUpsertApiResponse,
      PregnancyLaborEventUpsertApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.laborEvent}/upsert`,
        method: "PUT",
        body: queryArg.laborEventUpdate
      })
    }),
    pregnancyLaborEventDelete: build.mutation<
      PregnancyLaborEventDeleteApiResponse,
      PregnancyLaborEventDeleteApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.laborEvent}/delete`,
        method: "DELETE"
      })
    }),
    pregnancyLaborRecurringEventUpdate: build.mutation<
      PregnancyLaborRecurringEventUpdateApiResponse,
      PregnancyLaborRecurringEventUpdateApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.scope}/${queryArg.laborEventId}`,
        method: "PUT",
        body: queryArg.laborEventUpdate
      })
    }),
    pregnancyLaborRecurringEventCreate: build.mutation<
      PregnancyLaborRecurringEventCreateApiResponse,
      PregnancyLaborRecurringEventCreateApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.scope}/create`,
        method: "PUT",
        body: queryArg.laborEventCreate
      })
    }),
    pregnancyLaborRecurringEventDelete: build.mutation<
      PregnancyLaborRecurringEventDeleteApiResponse,
      PregnancyLaborRecurringEventDeleteApiArg
    >({
      query: queryArg => ({
        url: `/pregnancy/${queryArg.pregnancyId}/labor/${queryArg.stage}/${queryArg.scope}/${queryArg.laborEventId}/delete`,
        method: "DELETE"
      })
    }),
    listPatientRecalls: build.query<
      ListPatientRecallsApiResponse,
      ListPatientRecallsApiArg
    >({
      query: queryArg => ({
        url: `/recall`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    createPatientRecall: build.mutation<
      CreatePatientRecallApiResponse,
      CreatePatientRecallApiArg
    >({
      query: queryArg => ({
        url: `/recall`,
        method: "POST",
        body: queryArg.patientRecallCreate
      })
    }),
    deletePatientRecall: build.mutation<
      DeletePatientRecallApiResponse,
      DeletePatientRecallApiArg
    >({
      query: queryArg => ({
        url: `/recall/${queryArg.recallId}`,
        method: "DELETE"
      })
    }),
    getPatientRecall: build.query<
      GetPatientRecallApiResponse,
      GetPatientRecallApiArg
    >({
      query: queryArg => ({ url: `/recall/${queryArg.recallId}` })
    }),
    updatePatientRecall: build.mutation<
      UpdatePatientRecallApiResponse,
      UpdatePatientRecallApiArg
    >({
      query: queryArg => ({
        url: `/recall/${queryArg.recallId}`,
        method: "PATCH",
        body: queryArg.patientRecallUpdate
      })
    }),
    taskCreate: build.mutation<TaskCreateApiResponse, TaskCreateApiArg>({
      query: queryArg => ({
        url: `/task/create`,
        method: "POST",
        body: queryArg.taskCreatePayload
      })
    }),
    taskUpdate: build.mutation<TaskUpdateApiResponse, TaskUpdateApiArg>({
      query: queryArg => ({
        url: `/task/${queryArg.taskId}/update`,
        method: "POST",
        body: queryArg.taskUpdatePayload
      })
    }),
    taskInfo: build.query<TaskInfoApiResponse, TaskInfoApiArg>({
      query: queryArg => ({ url: `/task/${queryArg.taskId}/info` })
    }),
    taskDelete: build.mutation<TaskDeleteApiResponse, TaskDeleteApiArg>({
      query: queryArg => ({
        url: `/task/${queryArg.taskId}/delete`,
        method: "DELETE"
      })
    }),
    taskComplete: build.mutation<TaskCompleteApiResponse, TaskCompleteApiArg>({
      query: queryArg => ({
        url: `/task/${queryArg.taskId}/complete`,
        method: "PATCH"
      })
    }),
    taskMarkIncomplete: build.mutation<
      TaskMarkIncompleteApiResponse,
      TaskMarkIncompleteApiArg
    >({
      query: queryArg => ({
        url: `/task/${queryArg.taskId}/mark-incomplete`,
        method: "PATCH"
      })
    }),
    taskList: build.query<TaskListApiResponse, TaskListApiArg>({
      query: queryArg => ({
        url: `/task/list`,
        params: {
          status: queryArg.status,
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          type: queryArg["type"],
          assignee: queryArg.assignee,
          assignee_type: queryArg.assigneeType,
          assigner: queryArg.assigner,
          search: queryArg.search,
          sort_by: queryArg.sortBy,
          sort_order: queryArg.sortOrder,
          task_id_in: queryArg.taskIdIn
        }
      })
    }),
    taskGeneratePdf: build.mutation<
      TaskGeneratePdfApiResponse,
      TaskGeneratePdfApiArg
    >({
      query: queryArg => ({
        url: `/task/${queryArg.taskId}/generate_pdf`,
        method: "POST",
        body: queryArg.taskGeneratePdfPayload
      })
    }),
    taskListAssignables: build.query<
      TaskListAssignablesApiResponse,
      TaskListAssignablesApiArg
    >({
      query: queryArg => ({
        url: `/task/list-assignables`,
        params: {
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          search: queryArg.search
        }
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as patient };
export type EncounterCreateApiResponse =
  /** status 200 Sent in response to a successful encounter create API call.
   */ EncounterId;
export type EncounterCreateApiArg = {
  patientId: UserId;
  providerId: UserId;
  /** Payload for creating/updating an encounter.
   */
  encounterCreateUpdatePayload: EncounterCreateUpdatePayload;
};
export type EncounterListApiResponse =
  /** status 200 Abbreviated list of patients suitable for dropdown list population.
   */ EncounterInfo[];
export type EncounterListApiArg = {
  id: Id;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  /** Start date for searching for a range of dates, including this date.
   */
  start?: Timestamp;
  /** End date for searching for a range of dates, including this date.
   */
  end?: Timestamp;
  page?: Page;
  pagesz?: PageSize;
  /** The status of the superbill.
   */
  superbillStatus?: SuperbillStatus;
  encounterStatus?: EncounterStatus;
  encounterType?: EncounterType;
};
export type EncounterBriefListApiResponse =
  /** status 200 List of encounters with brief information.
   */ EncounterBriefList;
export type EncounterBriefListApiArg = {
  id: Id;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  /** Start date for searching for a range of dates, including this date.
   */
  start?: Timestamp;
  /** End date for searching for a range of dates, including this date.
   */
  end?: Timestamp;
  page?: Page;
  pagesz?: PageSize;
  encounterStatus?: EncounterStatus;
  encounterType?: EncounterType;
  /** The field to sort encounters by.
   */
  sortBy?:
    | "start"
    | "patient_last_name"
    | "appointment_status"
    | "location_name"
    | "provider_last_name";
  /** The order to sort encounters by. Must be one of: asc or desc.
   */
  sortOrder?: "asc" | "desc";
  /** Statuses to exclude from search.
   */
  statusNotIn?: AppointmentStatus[];
};
export type EncounterUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterUpdateApiArg = {
  encounterId: EncounterId;
  /** Payload for creating/updating an encounter.
   */
  encounterCreateUpdatePayload: EncounterCreateUpdatePayload;
};
export type EncounterListVersionsApiResponse =
  /** status 200 List of all changes to an encounter (version history).
   */ EncounterVersions;
export type EncounterListVersionsApiArg = {
  encounterId: EncounterId;
  page?: Page;
  pagesz?: PageSize;
};
export type EncounterInfoApiResponse =
  /** status 200 Full details of a specific encounter version / event.
   */ EncounterInfo;
export type EncounterInfoApiArg = {
  encounterId: EncounterId;
  version?: Version;
};
export type EncounterSubmitApiResponse =
  /** status 200 Response to submitting an encounter.
   */ EncounterInfo;
export type EncounterSubmitApiArg = {
  encounterId: EncounterId;
  version: Version;
};
export type EncounterDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterDeleteApiArg = {
  encounterId: EncounterId;
};
export type EncounterLaborInfoApiResponse =
  /** status 200 Provides the current state of a pregnancy's labor.
   */ LaborModel;
export type EncounterLaborInfoApiArg = {
  encounterId: EncounterId;
};
export type EncounterLaborSheetApiResponse =
  /** status 200 Provides the current state of a pregnancy's labor sheet.
   */ LaborSheet;
export type EncounterLaborSheetApiArg = {
  encounterId: EncounterId;
};
export type EncounterLaborUpsertApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterLaborUpsertApiArg = {
  encounterId: EncounterId;
  /** Payload for creating/updating a labor.
   */
  laborModel: LaborModel;
};
export type EncounterLaborEventUpsertApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type EncounterLaborEventUpsertApiArg = {
  encounterId: EncounterId;
  stage: string;
  laborEvent: string;
  /** Payload for updating a labor.
   */
  laborEventUpdate: LaborEventUpdate;
};
export type DevLaborEventUpsertApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type DevLaborEventUpsertApiArg = {
  encounterId: EncounterId;
  stage: string;
  laborEvent: string;
  /** Payload for updating a labor.
   */
  laborEventUpsert: LaborEventUpsert;
};
export type EncounterLaborEventDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterLaborEventDeleteApiArg = {
  encounterId: EncounterId;
  stage: string;
  laborEvent: string;
};
export type EncounterLaborRecurringEventUpdateApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type EncounterLaborRecurringEventUpdateApiArg = {
  encounterId: EncounterId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  laborEventId: string;
  /** Payload for updating a labor.
   */
  laborEventUpdate: LaborEventUpdate;
};
export type EncounterLaborRecurringEventCreateApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type EncounterLaborRecurringEventCreateApiArg = {
  encounterId: EncounterId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  /** Payload for creating a labor.
   */
  laborEventCreate: LaborEventCreate;
};
export type EncounterLaborRecurringEventDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type EncounterLaborRecurringEventDeleteApiArg = {
  encounterId: EncounterId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  laborEventId: string;
};
export type LocationGetUserListApiResponse =
  /** status 200 Abbreviated list of users of a given type in a given location.
   */ LocationUsersList;
export type LocationGetUserListApiArg = {
  locationId: LocationId;
  filters?: LocationUserRoleFilters;
  page?: Page;
  pagesz?: PageSize;
};
export type MedicationCreateForPatientApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type MedicationCreateForPatientApiArg = {
  userId: UserId;
  /** Add a medication to a patient.
   */
  medicationCreate: MedicationCreate;
};
export type MedicationUpdateForPatientApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type MedicationUpdateForPatientApiArg = {
  userId: UserId;
  /** Update an existing medication of a patient.
   */
  medicationUpdate: MedicationUpdate;
};
export type MedicationDeleteFromPatientApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type MedicationDeleteFromPatientApiArg = {
  userId: UserId;
  /** UUID of a medication.
   */
  uuid: Uuid;
};
export type PatientUpsertApiResponse =
  /** status 200 This is sent in reply to a create patient request.
   */ PatientInfo;
export type PatientUpsertApiArg = {
  /** Create a new patient for a location. Patients can not be assigned to a practice, only a location.
    On success, return the newly created `patient_id`.
     */
  patientUpsertRequest: PatientUpsertRequest;
};
export type PatientDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PatientDeleteApiArg = {
  patientId: UserId;
};
export type PatientGetInfoApiResponse =
  /** status 200 Sent to describe a single patient in detail.
   */ PatientInfo;
export type PatientGetInfoApiArg = {
  patientId: UserId;
};
export type PatientInfoExportApiResponse =
  /** status 200 The patient info export PDF.
   */ EncounterExport200Response;
export type PatientInfoExportApiArg = {
  patientId: UserId;
};
export type PatientGetFormApiResponse =
  /** status 200 Sent in response to successfully getting a patient's latest form submission.
   */ FormSubmission;
export type PatientGetFormApiArg = {
  patientId: UserId;
  /** The ID of an existing form.
   */
  formId: FormId;
};
export type PatientSubmitFormApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PatientSubmitFormApiArg = {
  patientId: UserId;
  /** The ID of an existing form.
   */
  formId: FormId;
  /** Submit a form for the patient.
   */
  patientSubmitFormRequest: PatientSubmitFormRequest;
};
export type PatientSendInviteLinkApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PatientSendInviteLinkApiArg = {
  patientId: UserId;
};
export type PatientPediatricTransferRecordsPdfApiResponse =
  /** status 200 The pediatric transfer PDF file.
   */ EncounterExport200Response;
export type PatientPediatricTransferRecordsPdfApiArg = {
  patientId: UserId;
};
export type GetHealthHistoryApiResponse =
  /** status 200 The patient's health history.
   */ PatientHealthHistory;
export type GetHealthHistoryApiArg = {
  patientId: UserId;
};
export type CreatetHealthHistoryEntryApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CreatetHealthHistoryEntryApiArg = {
  patientId: UserId;
  /** Payload for creating a health history for a patient.
   */
  patientHealthHistoryCreate: PatientHealthHistoryCreate;
};
export type DeleteHealthHistoryEntryApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type DeleteHealthHistoryEntryApiArg = {
  patientId: UserId;
  healthHistoryId: HealthHistoryId;
};
export type UpdateHealthHistoryEntryApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UpdateHealthHistoryEntryApiArg = {
  patientId: UserId;
  healthHistoryId: HealthHistoryId;
  /** Payload for updating a health history for a patient.
   */
  patientHealthHistoryUpdate: PatientHealthHistoryUpdate;
};
export type PatientGeneratePdfApiResponse =
  /** status 200 The generated PDF file.
   */ PatientGeneratePdf200Response;
export type PatientGeneratePdfApiArg = {
  patientId: UserId;
  /** Payload for generating a PDF for a patient.
   */
  patientGeneratePdfPayload: PatientGeneratePdfPayload;
};
export type PatientGetCareHistoryApiResponse =
  /** status 200 The patient's care history.
   */ PatientCareHistory;
export type PatientGetCareHistoryApiArg = {
  patientId: UserId;
  page?: Page;
  pagesz?: PageSize;
};
export type PatientGetFilesApiResponse =
  /** status 200 Sent in response to successfully listing a patient's files.
   */ FileListItem[];
export type PatientGetFilesApiArg = {
  patientId: UserId;
};
export type PatientUploadFilesApiResponse =
  /** status 200 Sent in response to successfully uploading files associated with a patient.
   */ PatientFile[];
export type PatientUploadFilesApiArg = {
  patientId: UserId;
};
export type PatientDeleteFilesApiResponse =
  /** status 200 Sent in response to succesfully deleting files associated with a patient.
   */ PatientFile[];
export type PatientDeleteFilesApiArg = {
  patientId: UserId;
};
export type PatientGetInfantGrowthSheetApiResponse =
  /** status 200 Growth sheet for an infant.
   */ InfantGrowthSheet;
export type PatientGetInfantGrowthSheetApiArg = {
  patientId: UserId;
};
export type PatientArchiveApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PatientArchiveApiArg = {
  patientId: UserId;
};
export type PatientGetDoseSpotSsoUrlApiResponse =
  /** status 200 Sent in response to successfully getting a DoseSpot authentication token.
   */ DosespotSsoInfo;
export type PatientGetDoseSpotSsoUrlApiArg = {
  patientId: UserId;
};
export type PatientCloseDoseSpotApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PatientCloseDoseSpotApiArg = {
  patientId: UserId;
};
export type PatientListRxApiResponse = /** status 200 A list of prescriptions.
 */ RxList;
export type PatientListRxApiArg = {
  patientId: UserId;
};
export type PatientUnarchiveApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PatientUnarchiveApiArg = {
  patientId: UserId;
};
export type ProblemUpsertApiResponse =
  /** status 200 Sent in response to successfully upserting a problem.
   */ ProblemId;
export type ProblemUpsertApiArg = {
  patientId: UserId;
  /** Sent as the payload to upsert a problem.
   */
  problemUpsertRequest: ProblemUpsertRequest;
};
export type ProblemInfoApiResponse =
  /** status 200 Sent in response to successfully requesting the information about a problem.
   */ ProblemInfo;
export type ProblemInfoApiArg = {
  problemId: ProblemId;
  patientId: UserId;
};
export type PatientDiagnosisHistoryApiResponse =
  /** status 200 Sent in response to successfully fetching a patient's history for a given diagnosis'
   */ PatientDiagnosisInfo[];
export type PatientDiagnosisHistoryApiArg = {
  patientId: UserId;
  icdId: Icd10Id;
};
export type PatientArchiveDiagnosisApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PatientArchiveDiagnosisApiArg = {
  patientId: UserId;
  icd10Code: Icd10Name;
};
export type PatientGetLabOrdersApiResponse =
  /** status 200 Orders for a patient.
   */ LabOrderInfo[];
export type PatientGetLabOrdersApiArg = {
  patientId: UserId;
};
export type PatientDeletePharmacyApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PatientDeletePharmacyApiArg = {
  patientId: UserId;
  /** Search for pharmacy by ID.
   */
  pharmacyId: string;
};
export type PatientGetBalanceApiResponse = /** status 200 The patient's balance.
 */ CurrencyAmount;
export type PatientGetBalanceApiArg = {
  patientId: UserId;
  encounter?: EncounterId;
};
export type PatientGetTransactionsApiResponse =
  /** status 200 The patient's transactions.
   */ PatientTransaction[];
export type PatientGetTransactionsApiArg = {
  patientId: UserId;
  page?: Page;
  pagesz?: PageSize;
  startDate?: DateRfc3339;
  endDate?: DateRfc3339;
  order?: "newest_first" | "oldest_first";
  encounter?: EncounterId;
};
export type PatientCreateTransactionApiResponse =
  /** status 200 A transaction created or updated for the patient.
   */ TransactionId;
export type PatientCreateTransactionApiArg = {
  patientId: UserId;
  /** Payload for creating a transaction for a patient.
   */
  patientTransactionCreatePayload: PatientTransactionCreatePayload;
};
export type PatientUpdateTransactionApiResponse =
  /** status 200 A transaction created or updated for the patient.
   */ TransactionId;
export type PatientUpdateTransactionApiArg = {
  patientId: UserId;
  transactionId: TransactionId;
  /** Payload for updating a transaction for a patient.
   */
  patientTransactionUpdatePayload: PatientTransactionUpdatePayload;
};
export type PregnancyUpsertApiResponse =
  /** status 200 Sent in response to a successful upsert of a pregnancy.
   */ PregnancyId;
export type PregnancyUpsertApiArg = {
  patientId: UserId;
  /** Payload for creating/updating a pregnancy.
   */
  pregnancyUpsertRequest: PregnancyUpsertRequest;
};
export type ListPastPregnanciesApiResponse =
  /** status 200 List of pregnancies for a patient.
   */ PregnancyList;
export type ListPastPregnanciesApiArg = {
  patientId: UserId;
};
export type PregnancyInfoApiResponse =
  /** status 200 Full information for a single pregnancy.
   */ Pregnancy;
export type PregnancyInfoApiArg = {
  pregnancyId: PregnancyId;
};
export type PregnancyLaborInfoApiResponse =
  /** status 200 Provides the current state of a pregnancy's labor.
   */ LaborModel;
export type PregnancyLaborInfoApiArg = {
  pregnancyId: PregnancyId;
};
export type PregnancyLaborSheetApiResponse =
  /** status 200 Provides the current state of a pregnancy's labor sheet.
   */ LaborSheet;
export type PregnancyLaborSheetApiArg = {
  pregnancyId: PregnancyId;
};
export type PregnancyLaborUpsertApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PregnancyLaborUpsertApiArg = {
  pregnancyId: PregnancyId;
  /** Payload for creating/updating a labor.
   */
  laborModel: LaborModel;
};
export type PregnancyLaborEventUpsertApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type PregnancyLaborEventUpsertApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  laborEvent: string;
  /** Payload for updating a labor.
   */
  laborEventUpdate: LaborEventUpdate;
};
export type PregnancyLaborEventDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PregnancyLaborEventDeleteApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  laborEvent: string;
};
export type PregnancyLaborRecurringEventUpdateApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type PregnancyLaborRecurringEventUpdateApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  laborEventId: string;
  /** Payload for updating a labor.
   */
  laborEventUpdate: LaborEventUpdate;
};
export type PregnancyLaborRecurringEventCreateApiResponse =
  /** status 200 Provides the current state of the upserted event.
   */ LaborEventUpsertResponse;
export type PregnancyLaborRecurringEventCreateApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  /** Payload for creating a labor.
   */
  laborEventCreate: LaborEventCreate;
};
export type PregnancyLaborRecurringEventDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type PregnancyLaborRecurringEventDeleteApiArg = {
  pregnancyId: PregnancyId;
  stage: string;
  /** Which type of ID does the previous ID element refer to.
   */
  scope: IdType;
  laborEventId: string;
};
export type ListPatientRecallsApiResponse =
  /** status 200 List of recalls for the patient.
   */ PatientRecall[];
export type ListPatientRecallsApiArg = {
  page?: Page;
  pagesz?: PageSize;
};
export type CreatePatientRecallApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CreatePatientRecallApiArg = {
  /** Payload for creating a recall for a patient.
   */
  patientRecallCreate: PatientRecallCreate;
};
export type DeletePatientRecallApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type DeletePatientRecallApiArg = {
  recallId: RecallId;
};
export type GetPatientRecallApiResponse =
  /** status 200 A recall created or updated for the patient.
   */ PatientRecall;
export type GetPatientRecallApiArg = {
  recallId: RecallId;
};
export type UpdatePatientRecallApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UpdatePatientRecallApiArg = {
  recallId: RecallId;
  /** Payload for updating a recall for a patient.
   */
  patientRecallUpdate: PatientRecallUpdate;
};
export type TaskCreateApiResponse =
  /** status 200 Sent in response to a successful task create API call. Returns the task ID.
   */ TaskId;
export type TaskCreateApiArg = {
  /** Payload for creating a new patient task.
   */
  taskCreatePayload: TaskCreatePayload;
};
export type TaskUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type TaskUpdateApiArg = {
  taskId: TaskId;
  /** Payload for updating an existing patient task.
   */
  taskUpdatePayload: TaskUpdatePayload;
};
export type TaskInfoApiResponse =
  /** status 200 Sent to describe a single task in detail.
   */ TaskInfo;
export type TaskInfoApiArg = {
  taskId: TaskId;
};
export type TaskDeleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type TaskDeleteApiArg = {
  taskId: TaskId;
};
export type TaskCompleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type TaskCompleteApiArg = {
  taskId: TaskId;
};
export type TaskMarkIncompleteApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type TaskMarkIncompleteApiArg = {
  taskId: TaskId;
};
export type TaskListApiResponse = /** status 200 List of requested tasks.
 */ TaskPagination;
export type TaskListApiArg = {
  /** The status of tasks to list. Must be one of: pending, completed, or all.
   */
  status?: "COMPLETED" | "IN_PROGRESS" | "ALL";
  page?: Page;
  pagesz?: PageSize;
  /** The type of tasks to list. Must be one of: FILE, FORM, GENERIC, DEMOGRAPHICS, UPLOAD, DOCUMENT_REVIEW.
   */
  type?: TaskType;
  /** The ID of the user who the task is assigned to
   */
  assignee?: UserId;
  /** If provided, only tasks assigned to users of the specified type will be returned.
   */
  assigneeType?: "staff" | "patient";
  /** The ID of the user who assigned the task.
   */
  assigner?: UserId;
  /** Search term to filter the list of tasks. Searches the assigner, assignee, and task title
   */
  search?: string;
  /** The field to sort tasks by.
   */
  sortBy?:
    | "created_at"
    | "updated_at"
    | "assignee_first_name"
    | "assignee_last_name"
    | "due_date";
  /** The order to sort tasks by. Must be one of: asc or desc.
   */
  sortOrder?: "asc" | "desc";
  /** A list of task IDs to filter the list of tasks.
   */
  taskIdIn?: TaskId[];
};
export type TaskGeneratePdfApiResponse =
  /** status 200 Sent in response to a successful task generate PDF API call. Returns the file id of the generated PDF.
   */ FileId;
export type TaskGeneratePdfApiArg = {
  taskId: TaskId;
  /** Payload for generating a PDF of a patient task.
   */
  taskGeneratePdfPayload: TaskGeneratePdfPayload;
};
export type TaskListAssignablesApiResponse =
  /** status 200 List of objects that can be assigned to a task or appointment.
   */ TaskListAssignables;
export type TaskListAssignablesApiArg = {
  page?: Page;
  pagesz?: PageSize;
  /** Search term to filter the list of objects that can be assigned to a task.
   */
  search?: string;
};
export type EncounterId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type UserId = number;
export type EncounterType =
  | "WELL_WOMAN"
  | "PRENATAL"
  | "LABOR"
  | "POSTPARTUM"
  | "INFANT"
  | "PHONE";
export type HgLabTestId = string;
export type DiagnosisInfo = {
  cpt: number[];
  lab_tests?: HgLabTestId[];
  prescriptions?: number[];
};
export type EncounterDiagnoses = {
  [key: string]: DiagnosisInfo;
};
export type CptModifierId = number;
export type BillRecipients =
  | "PATIENT"
  | "PRIMARY_INSURANCE"
  | "SECONDARY_INSURANCE"
  | "TERTIARY_INSURANCE";
export type Coding = {
  cpt_id?: number;
  is_billable?: boolean;
  is_private?: boolean;
  modifier_ids?: CptModifierId[];
  icd10_ids?: number[];
  units?: number;
  place_of_service?: string;
  type_of_service?: string;
  bill_recipient?: BillRecipients;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  fee?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  charge?: string;
  comment?: string;
};
export type Codings = Coding[];
export type EncounterStatus = "IN_PROGRESS" | "SUBMITTED" | "DELETED";
export type LocationId = number;
export type AltLocation = string;
export type EncounterLocation = LocationId | AltLocation;
export type VitalsInfo = {
  /** Height of a person in CM. This is always stored as rounded-up centimeters although the visual presentation may
    be in feet and inches, or centimeters with a fractional part, but this is how it is stored.
     */
  height?: number;
  /** Weight of a non-infant. This is stored as kilograms.
   */
  weight?: number;
  /** Temperature in Fahrenheit.
   */
  temperature?: number;
  blood_pressure?: string;
  heart_rate?: string;
};
export type InfantGrowthMeasurements = {
  date?: string;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight_grams?: number;
  standing_height_cm?: number;
  recumbent_length_cm?: number;
  head_circumference_cm?: number;
  respiratory_rate?: number;
  blood_pressure?: string;
  /** Temperature in Fahrenheit.
   */
  temperature?: number;
  pulse?: number;
};
export type EncounterMaternityNeighborhoodMetadata = {
  episode_id?: string;
  encounter_id?: number;
};
export type EncounterCreateUpdatePayload = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  encounter_type: EncounterType;
  /** A ulid for an appointment type. */
  appointment_type_id?: string;
  note?: string;
  diagnoses?: EncounterDiagnoses;
  codings?: Codings;
  status?: EncounterStatus;
  type_specific?: object;
  related?: EncounterId[];
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  location?: EncounterLocation;
  vitals?: VitalsInfo;
  infant_growth_measurements?: InfantGrowthMeasurements;
  mn_metadata?: EncounterMaternityNeighborhoodMetadata;
  is_billable?: boolean;
  is_private?: boolean;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
};
export type ParentIds = UserId[];
export type Sex = "MALE" | "FEMALE" | "UNKNOWN";
export type Statecode =
  | "AK"
  | "AL"
  | "AR"
  | "AZ"
  | "CA"
  | "CO"
  | "CT"
  | "DC"
  | "DE"
  | "FL"
  | "GA"
  | "HI"
  | "IA"
  | "ID"
  | "IL"
  | "IN"
  | "KS"
  | "KY"
  | "LA"
  | "MA"
  | "MD"
  | "ME"
  | "MI"
  | "MN"
  | "MO"
  | "MS"
  | "MT"
  | "NC"
  | "ND"
  | "NE"
  | "NH"
  | "NJ"
  | "NM"
  | "NV"
  | "NY"
  | "OH"
  | "OK"
  | "OR"
  | "PA"
  | "PR"
  | "RI"
  | "SC"
  | "SD"
  | "TN"
  | "TX"
  | "UT"
  | "VA"
  | "VI"
  | "VT"
  | "WA"
  | "WI"
  | "WV"
  | "WY";
export type Address = {
  /** Address line 1. Mandatory. This can include the street number.
   */
  line1?: string;
  /** Optional line 2 of 3 for an address.
   */
  line2?: string;
  /** Optional line 3 of 3 for an address.
   */
  line3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  /** This is the optional, human-readable representation of the address. This is used
    when communicating addresses that come from sources that don't parse their addresses
    down into its constituent parts, like Health Gorilla.
     */
  display?: string;
};
export type ProblemStatus = "ACTIVE" | "ARCHIVED" | "DELETED";
export type ProblemType = "NOTE" | "DIAGNOSIS";
export type ProblemInfo = {
  /** System-assigned identifier for a problem.
   */
  id: number;
  type: ProblemType;
  status: ProblemStatus;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  date_of_onset?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  created_by: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  archived?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  archived_by?: number;
};
export type Icd10CodeDetails = {
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id: number;
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type ProblemNote = {
  /** Optional note limited to 4K in length.
   */
  content: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  at: number;
};
export type ProblemDiagnosisDetails = {
  diagnosis: Icd10CodeDetails;
  note?: ProblemNote;
};
export type ProblemDiagnosisInfo = ProblemInfo & ProblemDiagnosisDetails;
export type PatientProblemDiagnosis = {
  /** The actual ICD-10 code as provided by the WHO (no, not the rock band).
   */
  name: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  lastUpdated: number;
  status: ProblemStatus;
  title: string;
  instances: ProblemDiagnosisInfo[];
};
export type PatientProblemDiagnoses = PatientProblemDiagnosis[];
export type ProblemNoteDetails = {
  title: string;
  note: ProblemNote;
};
export type ProblemNoteInfo = ProblemInfo & ProblemNoteDetails;
export type PatientProblemNotes = ProblemNoteInfo[];
export type PatientProblems = {
  diagnoses: PatientProblemDiagnoses;
  notes: PatientProblemNotes;
};
export type RelationshipToSubscriber = "SELF" | "SPOUSE" | "GUARDIAN" | "OTHER";
export type Insurance = {
  insurance_id?: string;
  insurance_id_type?: 1 | 2 | 3 | 4 | 5 | 6;
  payor?: string;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  hg_org_id?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  payor_phone?: string;
  payor_address?: Address;
  plan_type?: string;
  effective_from?: string;
  termination_date?: string;
  policy_or_group_number?: string;
  subscriber_id?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  subscriber_first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  subscriber_last_name?: string;
  /** A Pario date in YYYYMMDD format.
   */
  subscriber_dob?: number;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  subscriber_ssn?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  subscriber_phone?: string;
  subscriber_address?: Address;
  subscriber_sex?: Sex;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  subscriber_email?: string;
  relationship_to_subscriber?: RelationshipToSubscriber;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  guarantor_first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  guarantor_last_name?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  guarantor_addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  guarantor_addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  guarantor_addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  guarantor_city?: string;
  guarantor_state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  guarantor_zip?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  guarantor_phone?: string;
};
export type UserInsurance = {
  self_pay?: boolean;
  primary?: Insurance;
  secondary?: Insurance;
  tertiary?: Insurance;
};
export type EddMethod = "LMP" | "ULTRASOUND" | "DATE_OF_CONCEPTION" | "UNKNOWN";
export type EstimatedDueDate = {
  method: EddMethod;
  estimated_due_date?: string;
  date_of_service?: string;
  date_of_method?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  estimate_id?: string;
  comment?: string;
  lmp_cycle_length_days?: number;
  file_id?: number;
};
export type Lmp = {
  last_menstrual_period?: string;
  estimate_id?: string;
};
export type PregnancyState =
  | "GESTATING"
  | "DELIVERED"
  | "MISCARRIED"
  | "STILLBORN"
  | "ECTOPIC"
  | "TERMINATED"
  | "POSTPARTUM"
  | "UNSPECIFIED"
  | "DELETED";
export type PrenatalFlowSheetEntry = {
  weeks?: string;
  days?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  encounter_start?: number;
  weight?: number;
  blood_pressure?: string;
  temperature?: number;
  fundus?: string;
  fundal_height?: string;
  fetal_heart_tones?: string;
  fetal_position?: string;
  fetal_activity?: string;
  edema?: string;
  pulse?: number;
  note?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
};
export type PrenatalFlowSheet = PrenatalFlowSheetEntry[];
export type GbsStatus = "POSITIVE" | "NEGATIVE" | "DECLINED" | "UNKNOWN";
export type LabOrderId = string;
export type LabOrders = LabOrderId[];
export type UserIdList = UserId[];
export type LaborEvent = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: UserIdList;
  forms?: object;
};
export type LaborStage = {
  events: {
    [key: string]: LaborEvent;
  };
  progress_updates: LaborEvent[];
  medications: LaborEvent[];
  infant_progress_updates?: LaborEvent[];
  infant_medications?: LaborEvent[];
  recurring_events?: LaborEvent[];
  notes: LaborEvent[];
};
export type LaborModel = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  transfer?: object;
  stages: {
    [key: string]: LaborStage;
  };
  note?: string;
};
export type PregnancyOutcome =
  | "UNKNOWN"
  | "TAB (therapeautic abortion)"
  | "EAB (elective abortion)"
  | "SAB (miscarriage)"
  | "SBVD (stillbirth vaginal delivery)"
  | "SBCS (stillbirth cesarean delivery)"
  | "NSVD (vaginal delivery)"
  | "NSVB (vaginal birth)"
  | "VAVD (vacuum vaginal delivery)"
  | "FAVD (forceps vaginal delivery)"
  | "CS (cesarean)"
  | "VBAC (vaginal birth after previous cesarean)"
  | "NSVD (vaginal delivery) - Water"
  | "VBAC (vaginal birth after previous cesarean) - Water"
  | "EP (ectopic pregnancy)";
export type MaternityNeighborhoodPregnancyMetadata = {
  episode_of_care_id?: string;
  past_pregnancy_id?: string;
};
export type ClientCarePregnancyMetadata = {
  pregnancy_id?: string;
};
export type PregnancyMetadata = {
  maternity_neighborhood?: MaternityNeighborhoodPregnancyMetadata;
  client_care?: ClientCarePregnancyMetadata;
};
export type Pregnancy = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  edd?: EstimatedDueDate;
  lmp?: Lmp;
  state: PregnancyState;
  flow_sheet: PrenatalFlowSheet;
  /** Where the patient would like to have labor.
   */
  desired_birthplace?: string;
  gbs_status?: GbsStatus;
  fetal_sex?: Sex;
  /** The weight of the patient before pregnancy.
   */
  pre_pregnancy_weight?: string;
  lab_orders?: LabOrders;
  labor?: LaborModel;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** The gestational age of the baby at the time of delivery, measured
    in days.
     */
  gestational_age_at_delivery?: number;
  /** The length of labor in minutes.
   */
  length_of_labor_minutes?: number;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  living?: boolean;
  outcome?: PregnancyOutcome;
  /** Where the pregnancy was concluded.
   */
  birthplace?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
  comment?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  pregnancy_completion_time?: number;
  metadata: PregnancyMetadata;
};
export type PatientHgMetadata = {
  /** A URL identifying a FHIR-R4 Resource in Health Gorilla's systems.
   */
  resource_url?: string;
};
export type PatientLocations = LocationId[];
export type MeridianMetadata = {
  meridian_id: string;
};
export type PatientCollaborateMd = {
  collaborate_id: string;
};
export type PatientMetadata = {
  health_gorilla?: PatientHgMetadata;
  locations: PatientLocations;
  meridian?: MeridianMetadata;
  collaborate_md?: PatientCollaborateMd;
};
export type PatientType =
  | "PROSPECTIVE"
  | "OB"
  | "GYN"
  | "INACTIVE"
  | "INFANT"
  | "PEDIATRIC"
  | "POSTPARTUM"
  | "LACTATION"
  | "PRIMARY_CARE";
export type PracticeData = {
  type: PatientType;
};
export type BloodType =
  | "A+"
  | "A-"
  | "B+"
  | "B-"
  | "AB+"
  | "AB-"
  | "O+"
  | "O-"
  | "UNKNOWN";
export type Diabetes = "TYPE_1" | "TYPE_2" | "GESTATIONAL" | "NONE";
export type HivStatus = "POSITIVE" | "NEGATIVE" | "UNKNOWN";
export type AllergySeverity = "MILD" | "MODERATE" | "SEVERE" | "FATAL";
export type Allergy = {
  icd10_code_details?: Icd10CodeDetails;
  name?: string;
  reaction?: string;
  severity?: AllergySeverity;
  notes?: string;
  date_of_onset?: string;
  allergy_id?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Allergies = Allergy[];
export type Immunization = {
  name: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt: number;
  /** A Pario date in YYYYMMDD format.
   */
  date?: number;
  notes?: string;
};
export type Immunizations = Immunization[];
export type MedicationStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "NONCOMPLIANT"
  | "DELETED";
export type Medication = {
  name: string;
  dosage?: string;
  comment?: string;
  status: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  /** Medispan dispensable drug identifer. */
  dispensable_drug_id?: number;
  /** Medispan routed doseform drug identifier. */
  routed_doseform_drug_id?: number;
  dosespot_id?: number;
  days_supply?: number;
  is_prescription?: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
};
export type Medications = {
  [key: string]: Medication;
};
export type Pregnancies = Pregnancy[];
export type GsAndPs = {
  /** The number of times the patient has been pregnant, regardless of
    the outcome of the pregnancies.
     */
  gravida: number;
  /** The number of times the patient has given birth to a baby at or
    after 37 weeks gestation.
     */
  term_births: number;
  /** The number of times the patient has given birth to a baby before
    37 weeks gestation.
     */
  preterm_births: number;
  /** The number of times the patient has had a miscarriage or abortion.
   */
  abortions_miscarriages: number;
  /** The number of living children the patient has.
   */
  living_children: number;
};
export type AntibodyScreenDat = "NEGATIVE" | "POSITIVE" | "DECLINED";
export type Met1 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type Met2 = "NORMAL" | "ABNORMAL" | "DECLINED";
export type UserMedicalHistory = {
  blood_type?: BloodType;
  /** Height of a person in CM. This is always stored as rounded-up centimeters although the visual presentation may
    be in feet and inches, or centimeters with a fractional part, but this is how it is stored.
     */
  height?: number;
  /** Weight of a non-infant. This is stored as kilograms.
   */
  weight?: number;
  diabetic?: Diabetes;
  hiv_status?: HivStatus;
  allergies?: Allergies;
  immunizations?: Immunizations;
  medications?: Medications;
  pregnancies?: Pregnancies;
  pregnancies_gs_and_ps?: GsAndPs;
  no_known_drug_allergies?: boolean;
  no_known_medications?: boolean;
  no_known_problems?: boolean;
  antibody_screen_DAT?: AntibodyScreenDat;
  met_1?: Met1;
  met_2?: Met2;
};
export type Bmi = string;
export type PatientMedicalHistory = UserMedicalHistory & {
  bmi: Bmi;
};
export type RoleId = number;
export type UserPracticeRoles = RoleId[];
export type UserMetaPractice = {
  roles: UserPracticeRoles;
  /** A yes/no or true/false value.
   */
  is_ma: boolean;
  /** A yes/no or true/false value.
   */
  is_provider: boolean;
  /** A yes/no or true/false value.
   */
  is_admin: boolean;
  /** A yes/no or true/false value.
   */
  is_superadmin: boolean;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
  /** A yes/no or true/false value.
   */
  is_biller: boolean;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_email?: number;
};
export type UserMetaPractices = {
  [key: string]: UserMetaPractice;
};
export type UserMetaLast = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  role_id: number;
};
export type UserDosespotMeta = {
  patient_id?: number;
  proxy_clinician_id?: number;
  prescribing_clinician_id?: number;
};
export type UserMetadata = {
  practices: UserMetaPractices;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** Drug Enforcement Administration (DEA) number.
   */
  dea_id?: string;
  last?: UserMetaLast;
  dosespot: UserDosespotMeta;
};
export type UserListItem = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  metadata: UserMetadata;
};
export type UserEmergencyContact = {
  name?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  phone_type?: "Home" | "Work" | "Mobile";
  relationship?: string;
};
export type UserEmergencyContacts = UserEmergencyContact[];
export type PatientInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  parent_ids?: ParentIds;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  sex: Sex;
  address?: Address;
  problems?: PatientProblems;
  location_data?: object;
  form_data?: object;
  insurance?: UserInsurance;
  pregnancy?: Pregnancy;
  metadata?: PatientMetadata;
  practice_data: PracticeData;
  medical_history: PatientMedicalHistory;
  primary_pharmacy_id?: string;
  note?: string;
  secret_note?: string;
  children: UserListItem[];
  emergency_contacts?: UserEmergencyContacts;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  ssn?: string;
  signup_completed: boolean;
  active_lab_flowsheet_id?: string;
};
export type ProviderHgMetadata = {
  /** A URL identifying a FHIR-R4 Resource in Health Gorilla's systems.
   */
  resource_url?: string;
};
export type ProviderLocations = LocationId[];
export type ProviderMeridianMetadata = {
  meridian_id: string;
};
export type ProviderMetadata = {
  health_gorilla?: ProviderHgMetadata;
  locations?: ProviderLocations;
  meridian?: ProviderMeridianMetadata;
};
export type ProviderInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  sex?: Sex;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email: string;
  address: Address;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  preferences?: object;
  metadata?: ProviderMetadata;
};
export type AppointmentTypeModel = {
  /** A ulid for an appointment type. */
  appointment_type_id: string;
  /** Each practice and location can define their own list of standard appointment types, along with default
    durations, personnel and notes etc. This is the name of such an appointment type. It must be all lower case
    and have no spaces or special characters other than a hyphen. This will be unique within a practice
    and a location, each of which has their own lists, with the location list overriding any practice type
    that has the same name.
     */
  appointment_type: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  default_duration: number;
  encounter_type: EncounterType;
  /** A color hex code that will be used to represent this appointment type in the calendar. This is optional
    and if not provided, a default color will be used.
     */
  appointment_hex?: string;
  file_bundle_id?: string;
  cpt_bundle_id?: string;
};
export type LabOrderPatientInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
};
export type LabOrderUrgency = "ROUTINE" | "URGENT" | "ASAP" | "STAT";
export type LabOrderBillRecipient =
  | "CLIENT"
  | "PATIENT"
  | "GUARANTOR"
  | "THIRD_PARTY";
export type InsuranceType = "PRIMARY" | "SECONDARY" | "TERTIARY";
export type LabObservationStatus =
  | "registered"
  | "preliminary"
  | "final"
  | "amended"
  | "cancelled"
  | "corrected";
export type ObservationSource = "LAB" | "MANUAL";
export type ValueQuantity = {
  value: string;
  unit?: string;
};
export type ReferenceRange = {
  low?: ValueQuantity;
  high?: ValueQuantity;
  text?: string;
};
export type PerformingSite = {
  name?: string;
  address?: Address;
  contact?: string;
};
export type LabObservationInfo = {
  /** System-assigned unique ID of an observation stored in the Pario system.
    Not assigned to observations stored in Health Gorilla.
     */
  observation_id: string;
  /** An ID for a lab test.
   */
  test_id: string;
  /** Health Gorilla ID for an observation from lab results.
   */
  hg_id: string;
  status: LabObservationStatus;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id?: number;
  source: ObservationSource;
  display: string;
  code: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  issued: string;
  value?: string;
  unit?: string;
  reference_range?: ReferenceRange;
  interpretation?: string;
  note?: string;
  performing_site?: PerformingSite;
};
export type LabOrderStatus =
  | "NEW"
  | "SENT"
  | "IN_PROGRESS"
  | "ERROR"
  | "FULFILLED"
  | "CORRECTED"
  | "REVIEWED"
  | "UNKNOWN";
export type AoeAnswer = {
  aoe_id: string;
  response: string;
};
export type LabTestInfo = {
  /** An ID for a lab test.
   */
  test_id: string;
  /** An ID for a lab order.
   */
  order_id?: string;
  /** Code used by the lab to identify a test.
   */
  code: string;
  /** Code used by the lab to identify a test.
   */
  display: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_to_lab?: string;
  /** A note about a test that will be sent along to/from the servicing lab.
   */
  note_from_lab?: string;
  observations: LabObservationInfo[];
  status: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  aoe_answers?: AoeAnswer[];
};
export type LabOrderMetadata = {
  hg_request_group_url?: string;
  hg_request_result_url?: string;
};
export type LabOrderInfo = {
  /** An ID for a lab order.
   */
  order_id: string;
  patient: LabOrderPatientInfo;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  lab_id: string;
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** A human-readable label for the order.
   */
  label: string;
  /** A note on the order to be sent along to the servicing lab.
   */
  note?: string;
  /** A note sent back from the lab to be displayed at the order level.
   */
  result_note?: string;
  urgency: LabOrderUrgency;
  bill_recipient: LabOrderBillRecipient;
  insurance_type?: InsuranceType;
  specimen_collected?: string;
  fasting: boolean;
  tests: LabTestInfo[];
  icd10_codes?: Icd10CodeDetails[];
  status: LabOrderStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  sent?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  fulfilled?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed?: string;
  has_abn: boolean;
  metadata: LabOrderMetadata;
};
export type UserInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** Address line 1. Mandatory. This can include the street number.
   */
  addr_line_1?: string;
  /** Optional line 2 of 3 for an address.
   */
  addr_line_2?: string;
  /** Optional line 3 of 3 for an address.
   */
  addr_line_3?: string;
  /** Name of the city. Must be provided.
   */
  city?: string;
  state?: Statecode;
  /** Standard US / Canadian zip code, either in 5 digit or 5-4 digit form.
   */
  zip?: string;
  metadata: UserMetadata;
  medical_history: UserMedicalHistory;
};
export type LocationType =
  | "MIDWIFE"
  | "BIRTHING_CENTER"
  | "MIDWIFERY_CLINIC"
  | "OBGYN_CLINIC"
  | "HOSPITAL"
  | "INTERNAL";
export type PhraseModel = {
  /** A unique phrase defined by a practice as one of its "dot-phrases". This must be alphanumeric, lower case, start with
    an alphabetic character, and between 1 and 16 characters in length. It must be unique across a practice.
     */
  phrase: string;
  /** Description of a dot-phrase, used in selection dropdown.
   */
  description?: string;
  /** Expansion for a dot-phrase.
   */
  text: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  last_editor: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_edited: number;
};
export type PhrasePreferences = {
  [key: string]: PhraseModel;
};
export type LabOrgInfo = {
  /** Health Gorilla ID for Organization FHIR/R4 resource.
   */
  id: string;
  name: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabLocationInfo = {
  /** Health Gorilla ID for Location FHIR/R4 resource
   */
  location_id: string;
  name: string;
  address: Address;
  lab?: LabOrgInfo;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax: string;
};
export type LabTests = LabTestInfo[];
export type LabTestBundle = {
  /** System-assigned ID for a bundle. Formatted as a UUID.
   */
  bundle_uuid?: string;
  /** User-defined name for a bundle.
   */
  name: string;
  tests: LabTests;
};
export type LocationBookmarkedLab = {
  locations: {
    [key: string]: LabLocationInfo;
  };
  bundles: {
    [key: string]: LabTestBundle;
  };
};
export type LocationBookmarkedLabs = {
  [key: string]: LocationBookmarkedLab;
};
export type LocationGroupListItem = {
  /** System-assigned unique ID for a group of users.
   */
  group_id: number;
  /** The name of a location group. This is a collection of users (most likely staff but patients are allowed too)
    within the location. For example, all front desk staff.
     */
  name: string;
  /** System level and defined flags. Very little should ever see let alone be able to set these.
   */
  flags: number;
  members: UserIdList;
};
export type LocationGroups = {
  [key: string]: LocationGroupListItem;
};
export type LocationPreferences = {
  phrases: PhrasePreferences;
  labs?: LocationBookmarkedLabs;
  prenatal_test_sequence?: object;
  groups?: LocationGroups;
};
export type LocationDosespotMeta = {
  clinic_id?: number;
  clinic_key?: string;
};
export type CollaborateMd1 = {
  facility_id: string;
};
export type LocationMetadata = {
  dosespot?: LocationDosespotMeta;
  collaborate_md?: CollaborateMd1;
};
export type LocationInfo = {
  /** System-assigned unique location identifier.
   */
  location_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** User ID of the user who owns the practice or location. An owner has extra priviliges. Every practice
    or location has one and only one owner, and by definition this user can see, modify or create anything
    within that practice / location. However, just because a user is an owner of one practice does not
    infer any rights or priviliges too other practices. Likewise a location owner has no extra powers
    outside of their location.
     */
  owner_id?: number;
  /** The name of the location. There is no requirement that this be unique as locations are referenced
    throughout the system by their ID, not their name.
     */
  name: string;
  location_type: LocationType;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  contact?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  fax?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  contact_email?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  public_email?: string;
  address: Address;
  fields?: object;
  preferences: LocationPreferences;
  metadata: LocationMetadata;
  /** Tax identification number.
   */
  tin?: string;
  /** Clinical Laboratory Improvement Amendments ID. CLIA is a federal regulation that applies to labs in the
    United States.
     */
  clia?: string;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** A timezone in the form of a string. This is a standard timezone string, such as 'America/New_York'.
   */
  timezone?: string;
};
export type AppointmentLocation = LocationInfo | AltLocation;
export type AppointmentStatus =
  | "SCHEDULED"
  | "CONFIRMED"
  | "CANCELED"
  | "NO_SHOW"
  | "CHECKED_IN"
  | "IN_PROGRESS"
  | "COMPLETED"
  | "CHECKED_OUT"
  | "RESCHEDULED";
export type AppointmentAttachment = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  display: string;
};
export type AppointmentAttachments = AppointmentAttachment[];
export type TaskType =
  | "FORM"
  | "FILE"
  | "UPLOAD"
  | "GENERIC"
  | "DEMOGRAPHICS"
  | "DOCUMENT_REVIEW";
export type AppointmentTask = {
  display: string;
  /** System-assigned ID of a patient task.
   */
  task_id?: number;
  task_type: TaskType;
  /** Generic system ID, for cases where a single API call can use an ID for multiple entities. Some other mechanism,
    usually a query or path parameter, will instruct the system as to the type of ID.
     */
  resource_id: number;
};
export type AppointmentTasks = AppointmentTask[];
export type CollaborateMd = {
  collaborate_id: string;
};
export type AppointmentMetadata = {
  meridian?: MeridianMetadata;
  collaborate_md?: CollaborateMd;
};
export type AppointmentInfo = {
  /** System-assigned unique ID of an appointment. Used as a table linkage. Every appointment the system
    creates is given a unique ID that is used by other types to refer to that specific appointment
    (for example, all messages sent by the system to a user regarding a specific appointment).
     */
  appointment_id: number;
  appointment_type: AppointmentTypeModel;
  patient: PatientInfo;
  provider: ProviderInfo;
  appointment_location?: AppointmentLocation;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** The local time of the appointment start. This is a string in the format
    'YYYY-MM-DD HH:MM'. Hours are in 24-hour format.
     */
  start_time_local?: string;
  /** The local time of the appointment start. This is a string in the format
    'YYYY-MM-DD HH:MM'. Hours are in 24-hour format.
     */
  end_time_local?: string;
  /** The time_zone of the appointment start and end times. This is a string
    in the format 'Region/City'.
     */
  time_zone?: string;
  encounter_status?: EncounterStatus;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  starts: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  ends: number;
  status: AppointmentStatus;
  chief_complaint?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  referring_provider_id?: number;
  referring_external_provider?: string;
  message?: string;
  attachments?: AppointmentAttachments;
  tasks?: AppointmentTasks;
  flagged: boolean;
  metadata?: AppointmentMetadata;
  incomplete_tasks_count: number;
};
export type SuperbillStatus =
  | "IN_PROGRESS"
  | "SUBMITTED"
  | "DENIED"
  | "APPROVED"
  | "REQUIRES_CHANGES";
export type SuperbillCodes = {
  [key: string]: string;
};
export type SuperbillDetails = {
  cpt_codes: SuperbillCodes;
  icd10_codes: SuperbillCodes;
};
export type SuperbillInfo = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id: number;
  /** The amount owed by the patient in cents.
   */
  patient_balance: number;
  status: SuperbillStatus;
  details?: SuperbillDetails;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  submitted?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  submitted_by?: number;
};
export type EncounterMetadata = {
  related?: EncounterId[];
  maternity_neighborhood?: EncounterMaternityNeighborhoodMetadata;
};
export type InfantGrowthDerivedMeasurements = {
  /** Body Mass Index. This is a computed value based on the patient's height and weight.
   */
  bmi?: string;
  /** A percentage value. Imagine the percent sign is there.
   */
  who_weight_for_length_percentile?: number;
  /** A percentage value. Imagine the percent sign is there.
   */
  who_head_circumference_for_age_percentile?: number;
  /** A percentage value. Imagine the percent sign is there.
   */
  who_length_for_age_percentile?: number;
  /** A percentage value. Imagine the percent sign is there.
   */
  who_weight_for_age_percentile?: number;
  /** A percentage value. Imagine the percent sign is there.
   */
  percent_change_weight_since_birth?: number;
};
export type InfantGrowth = InfantGrowthMeasurements &
  InfantGrowthDerivedMeasurements;
export type EncounterInfo = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  /** System-assigned version of a resource. Many system types can change over time. Where they are referenced
    in othe types, the version number of the resource is also stored. This allows us to identify a resource as it
    existed at a specific point in time. For example, a patient info form may undergo many changes, and the data
    used and stored at any given time will be a specific version number of that form. Thus in the future if we
    need to query (perhaps for legal reasons) what exactly was the information a provider was using when they
    provided service, we can reference the patient form as it existed at that version.
     */
  version: number;
  patient: PatientInfo;
  provider: ProviderInfo;
  encounter_type?: EncounterType;
  appointment_type?: AppointmentTypeModel;
  status: EncounterStatus;
  note?: string;
  diagnoses?: EncounterDiagnoses;
  codings?: Codings;
  /** System-assigned version of a resource. Many system types can change over time. Where they are referenced
    in othe types, the version number of the resource is also stored. This allows us to identify a resource as it
    existed at a specific point in time. For example, a patient info form may undergo many changes, and the data
    used and stored at any given time will be a specific version number of that form. Thus in the future if we
    need to query (perhaps for legal reasons) what exactly was the information a provider was using when they
    provided service, we can reference the patient form as it existed at that version.
     */
  health_history_version: number;
  orders?: LabOrderInfo[];
  type_specific?: object;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  submitted?: number;
  submitted_by?: UserInfo;
  appointment?: AppointmentInfo;
  superbill?: SuperbillInfo;
  metadata: EncounterMetadata;
  location: EncounterLocation;
  vitals?: VitalsInfo;
  infant_growth?: InfantGrowth;
  is_billable: boolean;
  is_private: boolean;
  claim_id?: string;
};
export type Id = number;
export type IdType =
  | "form"
  | "group"
  | "message"
  | "practice"
  | "patient"
  | "medical_assistant"
  | "administrative"
  | "biller"
  | "location"
  | "pregnancy"
  | "provider"
  | "template"
  | "user"
  | "medication"
  | "progress_update"
  | "note"
  | "infant_medication"
  | "infant_progress_update"
  | "recurring_event";
export type Timestamp = number;
export type Page = number;
export type PageSize = number;
export type PatientBriefInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  sex: Sex;
};
export type ProviderBriefInfo = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  /** National Provider Identifier (NPI).
   */
  npi?: string;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
};
export type EncounterBriefListing = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  patient: PatientBriefInfo;
  provider: ProviderBriefInfo;
  encounter_type?: EncounterType;
  /** Each practice and location can define their own list of standard appointment types, along with default
    durations, personnel and notes etc. This is the name of such an appointment type. It must be all lower case
    and have no spaces or special characters other than a hyphen. This will be unique within a practice
    and a location, each of which has their own lists, with the location list overriding any practice type
    that has the same name.
     */
  appointment_type?: string;
  appointment_status?: AppointmentStatus;
  status: EncounterStatus;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  submitted?: number;
  /** The location name or alternate location */
  location: string;
  is_private: boolean;
};
export type EncounterBriefList = {
  pages: number;
  count: number;
  /** List of all encounters with abbreviated information for the calling entity.
   */
  items: EncounterBriefListing[];
};
export type ErrorId = number;
export type EncounterVersionsItem = {
  num?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  when?: number;
  status?: EncounterStatus;
};
export type EncounterVersions = EncounterVersionsItem[];
export type Version = number;
export type LaborSheetEventType =
  | "EVENT"
  | "PROGRESS_UPDATE"
  | "MEDICATION"
  | "NOTES"
  | "RECURRING_EVENT"
  | "INFANT_PROGRESS_UPDATE"
  | "INFANT_MEDICATION";
export type LaborEventAuthor = {
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name?: string;
};
export type LaborEventInfo = {
  event_id: string;
  stage: string;
  type: LaborSheetEventType;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  authors?: LaborEventAuthor[];
  forms?: object;
};
export type LaborSheetEvents = LaborEventInfo[];
export type LaborSheet = {
  [key: string]: LaborSheetEvents;
};
export type LaborEventUpsertResponse = {
  stage: string;
  labor_event_id?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
};
export type LaborEventUpdate = {
  event_id: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  forms?: object;
};
export type RomMilestoneForm = {
  date: string;
  time: string;
  fluid_appearance: string;
  volume: string;
  rupture_type: string;
  comment: string;
};
export type BirthMilestoneForm = {
  date: string;
  time: string;
  maternal_position: string;
  presentation: string;
  fetal_position: string;
  delivery_type: string;
  nuchal_cord: string;
  nuchal_hand: string;
  comment: string;
};
export type PlacentaMilestoneForm = {
  date: string;
  time: string;
  delivery: string;
  presentation_at_delivery: string;
  vessels: string;
  intact: string;
  appearance: string;
  comment: string;
};
export type LacerationMilestoneForm = {
  date: string;
  time: string;
  episiotomy_type: string;
  laceration_degree: string;
  repair: string;
  suture_type: string;
  anesthesia: string;
  comment: string;
};
export type BloodLossMilestoneForm = {
  date: string;
  time: string;
  ebl_qbl: string;
  comment: string;
};
export type FirstLatchMilestoneForm = {
  date: string;
  time: string;
  latch?: number;
  audible_swallowing?: number;
  type_of_nipple?: number;
  comfort_of_mother?: number;
  hold?: number;
};
export type ApgarMilestoneForm = {
  date: string;
  time: string;
  heart_rate?: string;
  respiratory_rate?: string;
  muscle_tone?: string;
  reflex_irritability?: string;
  color?: string;
};
export type LaborMilestoneEventForms =
  | RomMilestoneForm
  | BirthMilestoneForm
  | PlacentaMilestoneForm
  | LacerationMilestoneForm
  | BloodLossMilestoneForm
  | FirstLatchMilestoneForm
  | ApgarMilestoneForm;
export type LaborMilestoneEvent = {
  event_id?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  timestamp?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author?: number;
  forms?: LaborMilestoneEventForms;
};
export type LaborMedicationEntry = {
  recipient: string;
  name: string;
  dose: string;
  units: string;
  route: string;
  site_given: string;
  lot_number: string;
  expiration: string;
  indication: string;
};
export type LaborProgressUpdateForm = {
  fht?: string;
  baseline_shift?: string;
  relative_to_contractions?: string;
  accelerations?: string;
  decelerations?: string;
  listening_device?: string;
  frequency?: string;
  duration?: string;
  strength?: string;
  dilation?: string;
  effacement?: string;
  station?: string;
  heart_rate?: string;
  blood_pressure?: string;
  temperature?: string;
  temperature_location?: string;
  maternal_position?: string;
  intake?: string;
  output?: string;
  hydrotherapy?: string;
  water_temperature?: string;
  medication?: LaborMedicationEntry;
  comment?: string;
};
export type PostpartumProgressUpdateForm = {
  maternal_heart_rate?: string;
  maternal_blood_pressure?: string;
  maternal_temperature?: string;
  maternal_temperature_location?: string;
  maternal_position?: string;
  fundus?: string;
  lochia?: string;
  intake?: string;
  output?: string;
  infant_heart_rate?: string;
  infant_respirations?: string;
  infant_temperature?: string;
  infant_temperature_location?: string;
  pulse_oxygen?: string;
  color?: string;
  nose_flaring?: string;
  grunting?: string;
  retractions?: string;
  void?: string;
  meconium?: string;
  medication?: LaborMedicationEntry;
};
export type LaborProgressUpdateEventForms =
  | LaborProgressUpdateForm
  | PostpartumProgressUpdateForm;
export type LaborProgressUpdateEvent = {
  event_id?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  timestamp?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author?: number;
  forms?: LaborProgressUpdateEventForms;
};
export type LaborEventCommentEntry = {
  comment: string;
};
export type LaborRecurringEventForm =
  | LaborMedicationEntry
  | LaborEventCommentEntry;
export type LaborRecurringEvent = {
  event_id?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  timestamp?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author?: number;
  forms?: LaborRecurringEventForm;
};
export type LaborEventUpsertEvent =
  | LaborMilestoneEvent
  | LaborProgressUpdateEvent
  | LaborRecurringEvent;
export type LaborEventUpsert = {
  event_id?: string;
  event?: LaborEventUpsertEvent;
};
export type LaborEventCreate = {
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  start?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  end?: number;
  forms?: object;
};
export type LocationUsersListItem = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** A role which can be assigned to a user at the practice. The role ID is defined at the practice level but
    a given user can have a different role at each location. This is used to determine what the user can do at the
    location level. For example, a user may be a patient at one location and a front desk person at another. Each
    user in a practice has a practice role too, which is used to determine what they can do at the practice level.
    So a user will have a practice role and a location role (or multiple location roles).
     */
  location_role: number;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  phone?: string;
  address?: Address;
  metadata: UserMetadata;
};
export type LocationUsersList = LocationUsersListItem[];
export type LocationUserRoleFilter =
  | "patient"
  | "provider"
  | "medical-assistant"
  | "admin"
  | "biller";
export type LocationUserRoleFilters = LocationUserRoleFilter[];
export type MedicationCreate = {
  name: string;
  dosage?: string;
  comment?: string;
  status: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  /** Medispan dispensable drug identifer. */
  dispensable_drug_id?: any;
  /** Medispan routed doseform drug identifier. */
  routed_doseform_drug_id?: number;
  days_supply?: number;
};
export type MedicationUpdate = {
  /** An RFC-4122 compliant universally unique identifier (UUID). This is a 128-bit number represented as a
    36-character string in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx where each x is a hexadecimal digit.
     */
  uuid: string;
  dosespot_id?: number;
  name?: string;
  dosage?: string;
  comment?: string;
  status?: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  lexicomp_product_id?: number;
  lexicomp_drug_id?: string;
  lexicomp_synonym_id?: number;
  lexicomp_synonym_type?: number;
  days_supply?: number;
};
export type Uuid = string;
export type LocationIds = LocationId[];
export type PatientUpsertRequest = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id?: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name?: string;
  /** An email address. The front-end should attempt to remove any of the '+data' style email addresses.
    For example convert `john+pario@doe.com` to `john@doe.com` in order for de-duping to make more
    sense. This is not enforced by the backend, as we may use that for testing, but the front-end logic
    that creates an account should remove this.
     */
  email?: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  sex?: Sex;
  /** This is how the person identifies themselves and not necessarily medically accurate. See `sex`.
   */
  pronouns?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob?: number;
  location_ids?: LocationIds;
  address?: Address;
  insurance?: UserInsurance;
  location_data?: object;
  form_data?: object;
  metadata?: PatientMetadata;
  practice_data?: PracticeData;
  medical_history?: UserMedicalHistory;
  note?: string;
  secret_note?: string;
  parent_ids?: ParentIds;
  emergency_contacts?: UserEmergencyContacts;
  /** Standard US social security number in XXX-XX-XXXX format.
   */
  ssn?: string;
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
};
export type EncounterExport200Response = {
  content: string;
};
export type FormSubmission = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
  /** Version number of a form.
   */
  form_version: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  when: number;
  data: object;
};
export type FormId = number;
export type PatientSubmitFormRequest = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
  data: object;
};
export type HealthHistoryEntry = {
  id: string;
  title: string;
  note?: string;
  date_of_onset?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  created_by: number;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at?: string;
};
export type PatientHealthHistory = {
  medical: HealthHistoryEntry[];
  surgical: HealthHistoryEntry[];
  family: HealthHistoryEntry[];
  gyn: HealthHistoryEntry[];
  social: HealthHistoryEntry[];
  medication: HealthHistoryEntry[];
  allergy: HealthHistoryEntry[];
  problem: HealthHistoryEntry[];
};
export type HealthHistorySection =
  | "MEDICAL"
  | "SURGICAL"
  | "FAMILY"
  | "GYN"
  | "SOCIAL"
  | "MEDICATION"
  | "ALLERGY"
  | "PROBLEM";
export type PatientHealthHistoryCreate = {
  title: string;
  note?: string;
  date_of_onset?: string;
  section: HealthHistorySection;
};
export type HealthHistoryId = string;
export type PatientHealthHistoryUpdate = {
  title?: string;
  note?: string;
  date_of_onset?: string;
};
export type PatientGeneratePdf200Response = {
  content: string;
  file_errors?: ExtraErrorItem[];
};
export type PatientPdfCoverpage = {
  to_name: string;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  to_fax: string;
  subject?: string;
  comment?: string;
};
export type PatientPdfSection =
  | "demographics"
  | "insurance"
  | "encounter_list"
  | "problem_list"
  | "medication_list"
  | "allergy_list"
  | "health_history"
  | "prenatal_flowsheet"
  | "pregnancy_edd"
  | "past_pregnancies"
  | "pediatric_record"
  | "labor_transfer"
  | "mother_demographics"
  | "infant_growth_sheet";
export type FileId = number;
export type FileIds = FileId[];
export type NewbornExamPdfParams = {
  encounter_ids: EncounterId[];
};
export type EncounterListPdfParams = {
  encounter_ids: EncounterId[];
  include_newborn_exams?: boolean;
  include_codes?: boolean;
};
export type TransferSubject = "mother" | "infant";
export type TransferStatus = "stable" | "unstable";
export type LaborTransferPdfParams = {
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id: number;
  subject_of_transfer: TransferSubject;
  reason_for_transfer: string;
  assessment?: string;
  recommendation?: string;
  transportation_mode?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  transportation_called_at?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  transportation_arrived_at?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  transportation_departed_at?: string;
  patient_status_at_time_of_transport?: TransferStatus;
};
export type PatientGeneratePdfPayload = {
  coverpage?: PatientPdfCoverpage;
  sections: PatientPdfSection[];
  extra_file_ids?: FileIds;
  newborn_exam_params?: NewbornExamPdfParams;
  encounter_list_params?: EncounterListPdfParams;
  labor_transfer_params?: LaborTransferPdfParams;
};
export type CareHistoryEntityType = "ENCOUNTER" | "APPOINTMENT" | "CASE";
export type Provider = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
};
export type Location = {
  /** System-assigned unique location identifier.
   */
  location_id: number;
  /** The name of the location. There is no requirement that this be unique as locations are referenced
    throughout the system by their ID, not their name.
     */
  location_name: string;
};
export type PatientCareHistoryBase = {
  /** Represents the ID of the entity, such as the encounter ID, appointment ID, or case ID.
   */
  entity_id: string;
  entity_type: CareHistoryEntityType;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  date_time?: string;
  provider: Provider;
  location?: Location;
  /** A non-clinic location where an appointment or encounter can take place.
    For example: A patient's home, the phone, or a video call.
    This is configured per practice
     */
  alt_location?: string;
};
export type AppointmentType = string;
export type PatientCareHistoryAppointment = PatientCareHistoryBase & {
  appointment_type?: AppointmentType;
  appointment_status: AppointmentStatus;
  encounter_id?: EncounterId;
};
export type PatientCareHistoryEncounter = PatientCareHistoryBase & {
  appointment_type?: AppointmentType;
  encounter_status: EncounterStatus;
  encounter_type?: EncounterType;
  is_private: boolean;
};
export type CaseTitle = string;
export type PatientCareHistoryCase = PatientCareHistoryBase & {
  case_title: CaseTitle;
};
export type PatientCareHistoryItemsInner =
  | ({
      entity_type: "APPOINTMENT";
    } & PatientCareHistoryAppointment)
  | ({
      entity_type: "ENCOUNTER";
    } & PatientCareHistoryEncounter)
  | ({
      entity_type: "CASE";
    } & PatientCareHistoryCase);
export type PatientCareHistory = {
  total: number;
  /** A paginated list containing the patient's care history, including appointments, encounters, and cases.
    Each entry provides detailed information about the associated entity.
     */
  items: PatientCareHistoryItemsInner[];
};
export type FileUploader = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id?: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name?: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name?: string;
};
export type FileListItem = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** Name of a file (max 128 characters).
   */
  name: string;
  /** In a number of places (most notably files) we allow the attachment of an arbitrary list of tags.
    Most tags are simple booleans but a few can have more complex information. A location and a practice
    can define the valid list of tags. Because this is a dictionary it guarantees unique tags.
    This is the type for a collection of tags (i.e one of these will be attached to each practice or location).
     */
  tags: object;
  size: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  viewable_by_patient?: boolean;
  url: string;
  uploaded_by: FileUploader;
  has_annotations?: boolean;
  reviewed_by?: UserInfo;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  reviewed_at?: string;
};
export type PatientFile = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  filepath: string;
};
export type Percentage = number;
export type InfantGrowthSheetInner = InfantGrowth & {
  percent_change_weight_since_last_entry?: Percentage;
  encounter_note?: string;
  encounter_id?: EncounterId;
};
export type InfantGrowthSheet = InfantGrowthSheetInner[];
export type DosespotSsoInfo = {
  /** DoseSpot SSO URL used for invoking their UI in an iframe.
   */
  url: string;
};
export type PrescriptionDiagnosis = {
  diagnosisId?: number;
  diagnosisCode?: string;
  diagnosisDescription?: string;
};
export type StateSchedules = {
  stateName?: string;
  schedule?: number;
};
export type CompoundIngredient = {
  lexiGenProductId?: number;
  lexiDrugSynId?: number;
  lexiSynonymTypeId?: number;
  freeText?: string;
  dispenseUnitId?: number;
  quantity?: string;
  isFreeTextEPCS?: boolean;
  schedule?: number;
  stateSchedules?: StateSchedules[];
};
export type DosespotRx = {
  /** The dosespot ID of the prescription.
   */
  prescriptionId?: number;
  writtenDate?: string;
  directions?: string;
  quantity?: string;
  dispenseUnitId?:
    | 35
    | 39
    | 44
    | 4
    | 32
    | 52
    | 8
    | 33
    | 54
    | 57
    | 11
    | 90
    | 13
    | 15
    | 21
    | 64
    | 28
    | 83
    | 70
    | 73
    | 75
    | 76
    | 23
    | 77
    | 26
    | 81
    | 19
    | 82;
  dispenseUnitDescription?: string;
  refills?: string;
  daysSupply?: number;
  pharmacyId?: number;
  pharmacyNotes?: string;
  noSubstitutions?: boolean;
  effectiveDate?: string;
  lastFillDate?: string;
  prescriberId?: number;
  prescriberAgentId?: number;
  rxReferenceNumber?: string;
  status?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;
  formulary?: boolean;
  eligibilityId?: number;
  type?: 1 | 2 | 3;
  nonDoseSpotPrescriptionId?: string;
  errorIgnored?: boolean;
  supplyId?: number;
  compoundId?: number;
  freeTextType?: 1 | 2 | 3;
  clinicId?: number;
  supervisorId?: number;
  isUrgent?: boolean;
  isRxRenewal?: boolean;
  rxRenewalNote?: string;
  firstPrescriptionDiagnosis?: PrescriptionDiagnosis;
  secondPrescriptionDiagnosis?: PrescriptionDiagnosis;
  patientMedicationId?: number;
  medicationStatus?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13;
  comment?: string;
  dateInactive?: string;
  encounter?: string;
  doseForm?: string;
  route?: string;
  strength?: string;
  genericProductName?: string;
  lexiGenProductId?: number;
  lexiDrugSynId?: number;
  lexiSynonymTypeId?: number;
  lexiGenDrugId?: string;
  rxCUI?: string;
  otc?: boolean;
  ndc?: string;
  schedule?: string;
  displayName?: string;
  monographPath?: string;
  drugClassification?: string;
  stateSchedules?: StateSchedules[];
  brand?: boolean;
  compoundIngredients?: CompoundIngredient[];
};
export type RxList = DosespotRx[];
export type ProblemId = number;
export type ProblemUpsertRequest = {
  /** System-assigned identifier for a problem.
   */
  id?: number;
  type: ProblemType;
  status?: ProblemStatus;
  date_of_onset?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** Optional note limited to 4K in length.
   */
  note?: string;
  title?: string;
  /** The system-generated ID for the ICD-10 code. We always use this ID and not the ICD10 code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  icd_id?: number;
};
export type PatientDiagnosisInfo = {
  details: Icd10CodeDetails;
  note: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  when?: number;
};
export type Icd10Id = number;
export type Icd10Name = string;
export type CurrencyAmount = string;
export type TransactionType =
  | "PATIENT_PAYMENT"
  | "PAYER_PAYMENT"
  | "SERVICE_CHARGES"
  | "FEES"
  | "ADJUSTMENT";
export type TransactionUser = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** The first name of the participant.
   */
  first_name: string;
  /** The last name of the participant.
   */
  last_name: string;
};
export type PaymentMethod = "cash" | "check" | "credit";
export type PatientTransaction = {
  transaction_id: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  amount: string;
  date_of_service: string;
  type: TransactionType;
  description?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at?: string;
  created_by: TransactionUser;
  /** Adjustments to the transaction, such as refunds or discounts.
    These adjusted transactions will not have their own adjustments.
     */
  adjustments?: PatientTransaction[];
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id?: number;
  payment_method?: PaymentMethod;
};
export type DateRfc3339 = string;
export type TransactionId = string;
export type PatientTransactionCreatePayload = {
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  amount: string;
  type: TransactionType;
  date_of_service: string;
  modifies_transaction_id?: string;
  description?: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id?: number;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  payment_method?: PaymentMethod;
};
export type PatientTransactionUpdatePayload = {
  description: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id?: number;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  payment_method?: PaymentMethod;
};
export type PregnancyId = number;
export type PregnancyUpsertRequest = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id?: number;
  state?: PregnancyState;
  flow_sheet?: PrenatalFlowSheet;
  outcome?: PregnancyOutcome;
  desired_birthplace?: string;
  gbs_status?: GbsStatus;
  fetal_sex?: Sex;
  /** The weight of the patient before pregnancy.
   */
  pre_pregnancy_weight?: string;
  /** A Pario date in YYYYMMDD format.
   */
  delivery_date?: number;
  /** In days
   */
  gestational_age_at_delivery?: number;
  length_of_labor_minutes?: number;
  living?: boolean;
  /** The weight of an infant, in grams. This differs from the mothers weight which is in kilograms. The actual
    visual display may be converted to ounces, or kilograms with a fractional part, but this is how it is stored.
     */
  weight?: number;
  sex?: Sex;
  name?: string;
  birthplace?: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  completed?: number;
  comment?: string;
  is_past_pregnancy?: boolean;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  infant_id?: number;
};
export type PregnancyListItem = {
  /** System-assigned sequence number for a unique pregnancy, regardless of state or outcome.
   */
  pregnancy_id: number;
  outcome?: PregnancyOutcome;
  delivery_or_loss_date?: string;
  birthplace?: string;
};
export type PregnancyList = PregnancyListItem[];
export type PatientRecall = {
  recall_id: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  patient_first_name?: string;
  patient_last_name?: string;
  patient_phone?: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  due_date: string;
  reason?: string;
};
export type PatientRecallCreate = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id: number;
  due_date: string;
  reason?: string;
};
export type RecallId = string;
export type PatientRecallUpdate = {
  due_date?: string;
  reason?: string;
};
export type TaskId = number;
export type FormTaskCreate = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
};
export type FileTaskCreate = {
  /** Unique file ID to reference a file stored in S3.
   */
  template_file_id: number;
};
export type UploadTaskCreate = {
  /** Prompt for an upload task.
   */
  prompt: string;
};
export type GenericTaskCreate = {
  /** Title of a generic task.
   */
  task_title: string;
  /** Text of a generic task.
   */
  task_text: string;
};
export type DocumentReviewTaskCreate = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** System-assigned unique ID of a fax.
   */
  fax_id?: number;
};
export type TaskCreatePayload = {
  task_type: TaskType;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_assignee?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  staff_assignee?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  associated_patient?: number;
  /** System-assigned ID of a patient task.
   */
  parent_task?: number;
  form?: FormTaskCreate;
  file?: FileTaskCreate;
  upload?: UploadTaskCreate;
  generic?: GenericTaskCreate;
  document_review?: DocumentReviewTaskCreate;
  /** A Pario date in UTC represented as an RFC-3339 string.
   */
  due_date?: string | null;
};
export type FormTaskUpd = {
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id?: number;
};
export type FileTaskUpd = {
  /** Unique file ID to reference a file stored in S3.
   */
  template_file_id?: number;
  /** Unique file ID to reference a file stored in S3.
   */
  submitted_file_id?: number;
  /** The Anvil forge EID. This isn't used anywere, but we store it for now.
   */
  anvil_forge_eid?: string;
  /** The Anvil weld data eid. Used for downloading the completed PDF
   */
  anvil_weld_data_eid?: string;
  /** The Anvil weld EID. Used for linking webhook events to Pario tasks.
   */
  anvil_weld_eid?: string;
  /** The Anvil submission eid.
   */
  anvil_submission_eid?: string;
};
export type UploadTaskUpd = {
  /** Unique file ID to reference a file stored in S3.
   */
  uploaded_file_id?: number;
};
export type GenericTaskUpd = {
  /** Title of a generic task.
   */
  task_title?: string;
  /** Text of a generic task.
   */
  task_text?: string;
};
export type DocumentTaskUpd = {
  /** Unique file ID to reference a file stored in S3.
   */
  file_id?: number;
  fax_id?: any | null;
};
export type TaskUpdatePayload = {
  task_type?: TaskType;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_assignee?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  staff_assignee?: number;
  associated_patient?: number | null;
  /** System-assigned ID of a patient task.
   */
  parent_task?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created_at?: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  completed_at?: number;
  form?: FormTaskUpd;
  file?: FileTaskUpd;
  upload?: UploadTaskUpd;
  generic?: GenericTaskUpd;
  document_review?: DocumentTaskUpd;
};
export type FormTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Unique ID for each form defined within the system. Used as a table linkage. Forms can vary not only from
    practice to practice, but also from location to location within a given practice. However, all forms are
    stored in a single table and this is each form's identifier.
     */
  form_id: number;
};
export type FileTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Unique file ID to reference a file stored in S3.
   */
  template_file_id: number;
  /** Unique file ID to reference a file stored in S3.
   */
  submitted_file_id?: number;
};
export type GenericTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Title of a generic task.
   */
  task_title: string;
  /** Text of a generic task.
   */
  task_text: string;
};
export type UploadTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Text of a generic task.
   */
  prompt: string;
};
export type DocumentReviewTaskModel = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** Unique file ID to reference a file stored in S3.
   */
  file_id: number;
  /** System-assigned unique ID of a fax.
   */
  fax_id?: number;
};
export type TaskInfo = {
  /** System-assigned ID of a patient task.
   */
  task_id: number;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  task_type: TaskType;
  assignee: UserInfo;
  assigner: UserInfo;
  associated_patient?: UserInfo;
  title: string;
  /** System-assigned ID of a patient task.
   */
  parent_task?: number;
  form?: FormTaskModel;
  file?: FileTaskModel;
  generic?: GenericTaskModel;
  upload?: UploadTaskModel;
  document_review?: DocumentReviewTaskModel;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created_at: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  updated_at: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  completed_at?: number;
  /** The url slug for the Anvil webform (forge) associated with this file.
   */
  anvil_forge_slug?: string;
  /** The Anvil submission eid.
   */
  anvil_submission_eid?: string;
  /** A Pario date in UTC represented as an RFC-3339 string.
   */
  due_date?: string | null;
};
export type TaskList = TaskInfo[];
export type TaskPagination = {
  items: TaskList;
  has_next_page: boolean;
  total_items: number;
};
export type TaskGeneratePdfPayload = {
  /** The Anvil weld data eid.
   */
  weldDataEid: string;
};
export type TaskListAssignable = {
  /** Generic system ID, for cases where a single API call can use an ID for multiple entities. Some other mechanism,
    usually a query or path parameter, will instruct the system as to the type of ID.
     */
  resource_id: number;
  resource_type: TaskType;
  display: string;
};
export type TaskListAssignables = {
  items: TaskListAssignable[];
  has_next_page: boolean;
  total_items: number;
};
export const {
  useEncounterCreateMutation,
  useEncounterListQuery,
  useLazyEncounterListQuery,
  useEncounterBriefListQuery,
  useLazyEncounterBriefListQuery,
  useEncounterUpdateMutation,
  useEncounterListVersionsQuery,
  useLazyEncounterListVersionsQuery,
  useEncounterInfoQuery,
  useLazyEncounterInfoQuery,
  useEncounterSubmitMutation,
  useEncounterDeleteMutation,
  useEncounterLaborInfoQuery,
  useLazyEncounterLaborInfoQuery,
  useEncounterLaborSheetQuery,
  useLazyEncounterLaborSheetQuery,
  useEncounterLaborUpsertMutation,
  useEncounterLaborEventUpsertMutation,
  useDevLaborEventUpsertMutation,
  useEncounterLaborEventDeleteMutation,
  useEncounterLaborRecurringEventUpdateMutation,
  useEncounterLaborRecurringEventCreateMutation,
  useEncounterLaborRecurringEventDeleteMutation,
  useLocationGetUserListQuery,
  useLazyLocationGetUserListQuery,
  useMedicationCreateForPatientMutation,
  useMedicationUpdateForPatientMutation,
  useMedicationDeleteFromPatientMutation,
  usePatientUpsertMutation,
  usePatientDeleteMutation,
  usePatientGetInfoQuery,
  useLazyPatientGetInfoQuery,
  usePatientInfoExportQuery,
  useLazyPatientInfoExportQuery,
  usePatientGetFormQuery,
  useLazyPatientGetFormQuery,
  usePatientSubmitFormMutation,
  usePatientSendInviteLinkMutation,
  usePatientPediatricTransferRecordsPdfQuery,
  useLazyPatientPediatricTransferRecordsPdfQuery,
  useGetHealthHistoryQuery,
  useLazyGetHealthHistoryQuery,
  useCreatetHealthHistoryEntryMutation,
  useDeleteHealthHistoryEntryMutation,
  useUpdateHealthHistoryEntryMutation,
  usePatientGeneratePdfMutation,
  usePatientGetCareHistoryQuery,
  useLazyPatientGetCareHistoryQuery,
  usePatientGetFilesQuery,
  useLazyPatientGetFilesQuery,
  usePatientUploadFilesMutation,
  usePatientDeleteFilesMutation,
  usePatientGetInfantGrowthSheetQuery,
  useLazyPatientGetInfantGrowthSheetQuery,
  usePatientArchiveMutation,
  usePatientGetDoseSpotSsoUrlQuery,
  useLazyPatientGetDoseSpotSsoUrlQuery,
  usePatientCloseDoseSpotMutation,
  usePatientListRxQuery,
  useLazyPatientListRxQuery,
  usePatientUnarchiveMutation,
  useProblemUpsertMutation,
  useProblemInfoQuery,
  useLazyProblemInfoQuery,
  usePatientDiagnosisHistoryQuery,
  useLazyPatientDiagnosisHistoryQuery,
  usePatientArchiveDiagnosisMutation,
  usePatientGetLabOrdersQuery,
  useLazyPatientGetLabOrdersQuery,
  usePatientDeletePharmacyMutation,
  usePatientGetBalanceQuery,
  useLazyPatientGetBalanceQuery,
  usePatientGetTransactionsQuery,
  useLazyPatientGetTransactionsQuery,
  usePatientCreateTransactionMutation,
  usePatientUpdateTransactionMutation,
  usePregnancyUpsertMutation,
  useListPastPregnanciesQuery,
  useLazyListPastPregnanciesQuery,
  usePregnancyInfoQuery,
  useLazyPregnancyInfoQuery,
  usePregnancyLaborInfoQuery,
  useLazyPregnancyLaborInfoQuery,
  usePregnancyLaborSheetQuery,
  useLazyPregnancyLaborSheetQuery,
  usePregnancyLaborUpsertMutation,
  usePregnancyLaborEventUpsertMutation,
  usePregnancyLaborEventDeleteMutation,
  usePregnancyLaborRecurringEventUpdateMutation,
  usePregnancyLaborRecurringEventCreateMutation,
  usePregnancyLaborRecurringEventDeleteMutation,
  useListPatientRecallsQuery,
  useLazyListPatientRecallsQuery,
  useCreatePatientRecallMutation,
  useDeletePatientRecallMutation,
  useGetPatientRecallQuery,
  useLazyGetPatientRecallQuery,
  useUpdatePatientRecallMutation,
  useTaskCreateMutation,
  useTaskUpdateMutation,
  useTaskInfoQuery,
  useLazyTaskInfoQuery,
  useTaskDeleteMutation,
  useTaskCompleteMutation,
  useTaskMarkIncompleteMutation,
  useTaskListQuery,
  useLazyTaskListQuery,
  useTaskGeneratePdfMutation,
  useTaskListAssignablesQuery,
  useLazyTaskListAssignablesQuery
} = injectedRtkApi;

/* PregnancyUpsertInput Name */
/* External Imports */
import { ChangeEvent, FocusEvent, useEffect, useState } from "react";

import { useDispatch } from "react-redux";

import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { STATUS_KEYS } from "@/globals/constants";

import { UserId } from "@/store/services/claim";
import { Pregnancy } from "@/store/services/encounter";
import { usePregnancyUpsertMutation } from "@/store/services/patient";

// styles
import styles from "./styles.module.scss";

import {
  PregnancyFieldType,
  createUpdatePayload,
  hasValueChanged
} from "./helpers";

/* PregnancyUpsertInput Typescript Interface */
interface PregnancyUpsertInputProps {
  patientId: UserId;
  pregnancy: Pregnancy;
  field: PregnancyFieldType;
  label: string;
  title?: string;
}

export default function PregnancyUpsertInput({
  patientId,
  pregnancy,
  field,
  label,
  title
}: PregnancyUpsertInputProps) {
  /* Redux */
  const dispatch = useDispatch();
  const [upsertPregnancy] = usePregnancyUpsertMutation();

  /* Local State */
  const [inputValue, setInputValue] = useState<string>("");

  /* Effects */
  useEffect(() => {
    // Initialize the input value with the current value from pregnancy
    if (pregnancy && pregnancy[field]) {
      setInputValue(pregnancy[field].toString());
    }
  }, [pregnancy, field]);

  /* Event Handlers */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    // Create the update payload
    const updatePayload = createUpdatePayload(pregnancy, field, newValue);

    if (!hasValueChanged(pregnancy, field, newValue)) {
      console.warn("No change detected");
      return;
    }

    const messageLabel = title ? title.toLowerCase() : label.toLowerCase();

    // Send the update
    await upsertPregnancy({
      patientId: patientId,
      pregnancyUpsertRequest: updatePayload
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: `Updated ${messageLabel}`,
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: `Failed to update ${messageLabel}`,
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <>
      <p title={title}>{label}</p>
      <input
        className={styles.blueThemeInput}
        value={inputValue}
        onChange={handleChange}
        onBlur={handleBlur}
        id={field}
        name={field}
        aria-label={label}
        placeholder="Enter..."
      />
    </>
  );
}

// External
import { useState } from "react";

// Components & Store
import { HealthHistorySection } from "@/store/services/patient";

import { HistoryCard } from "./historyCard";
// Styles
import styles from "./styles.module.scss";

interface HealthHistoryProps {
  patientId: number;
}

export const HEALTH_HISTORY_SECTIONS: Array<HealthHistorySection> = [
  "PROBLEM",
  "ALLERGY",
  "MEDICATION",
  "MEDICAL",
  "SURGICAL",
  "FAMILY",
  "GYN",
  "SOCIAL"
];

export const HEALTH_HISTORY_LABEL: Record<HealthHistorySection, string> = {
  ALLERGY: "Allergies",
  FAMILY: "Family History",
  GYN: "Gyn History",
  SOCIAL: "Social History",
  MEDICAL: "Past Medical History",
  MEDICATION: "Medications",
  PROBLEM: "Problems",
  SURGICAL: "Past Surgical History"
};

export function HealthHistory({ patientId }: HealthHistoryProps) {
  // This is just a utility array for opening all of the sections
  const [openCards, setOpenCards] = useState<Array<number>>([]);
  const allHistorySections = HEALTH_HISTORY_SECTIONS.map((_, index) => index);

  const toggleOpenCard = (index: number) => {
    if (openCards.includes(index)) {
      setOpenCards(openCards.filter(el => el != index));
    } else {
      setOpenCards(prev => [...prev, index]);
    }
  };

  return (
    <div className={styles.HealthHistory}>
      <div className={styles.header}>
        <h3 className="gray800 smBld">Health History</h3>
        <a
          onClick={() => {
            if (openCards.length > 0) {
              setOpenCards([]);
            } else {
              setOpenCards(allHistorySections);
            }
          }}
        >
          {openCards.length > 0 ? "Collapse all" : "Expand all"}
        </a>
      </div>
      <div className={styles.historyCards}>
        {HEALTH_HISTORY_SECTIONS.map((val, index) => {
          return (
            <HistoryCard
              key={`history-card-${patientId}-${val}`}
              cardIndex={index}
              onOpenToggle={toggleOpenCard}
              isOpen={openCards.includes(index)}
              patientId={patientId}
              section={val}
            />
          );
        })}
      </div>
    </div>
  );
}

/* AssignGenericStaffTask Name */
/* External Imports */
import clsx from "clsx";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
/* Local Imports */

// components
import Button from "@/components/button";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import Input from "@/components/input";
import StaffPicker from "@/components/staffPicker";
import PatientPicker from "@/components/patientPicker";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";
// store
import {
  useTaskCreateMutation,
  TaskCreatePayload
} from "@/store/services/task";
import { UserId } from "@/store/services/patient";
import { setModalIsOpen } from "../../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";

/* AssignGenericStaffTask Typescript Interface */
interface AssignGenericStaffTaskProps {
  associatedPatientID?: UserId;
}

export default function AssignGenericStaffTask({
  associatedPatientID
}: AssignGenericStaffTaskProps) {
  /* Redux */
  const dispatch = useDispatch();
  type FormData = {
    providerID: UserId;
    taskTitle: string;
    taskDescription: string;
    dueDate: string;
    associatedPatientID?: UserId;
  };
  const form = useForm<FormData>();

  const { register, handleSubmit } = form;
  const [createTask] = useTaskCreateMutation();

  /* Local State */

  /* Effects */

  useEffect(() => {
    if (associatedPatientID) {
      form.setValue("associatedPatientID", associatedPatientID);
    }
  }, [associatedPatientID]);

  /* Event Handlers */

  const onSubmit = (data: FormData) => {
    const payload: TaskCreatePayload = {
      task_type: "GENERIC",
      staff_assignee: data.providerID,
      due_date: data.dueDate,
      generic: {
        task_text: data.taskDescription,
        task_title: data.taskTitle
      }
    };

    if (data.associatedPatientID) {
      payload.associated_patient = data.associatedPatientID;
    }

    createTask({
      taskCreatePayload: payload
    })
      .unwrap()
      .then(() => {
        form.reset();
        dispatch(
          addAlertToToastTrough({
            message: "Task assigned",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to assign task",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCancel = () => {
    form.reset();
    dispatch(setModalIsOpen(false));
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={clsx(styles.AssignGenericStaffTask)}
    >
      <p className="t5">Staff assignee*</p>
      <Controller
        name="providerID"
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <StaffPicker initialStaffId={value} onUpdateStaff={onChange} />
        )}
        rules={{ required: true }}
      />
      <p className="t5">Associated patient</p>
      <Controller
        name="associatedPatientID"
        control={form.control}
        render={({ field: { onChange, value } }) => (
          <PatientPicker
            initialPatientIds={value ? [value] : []}
            onUpdatePatient={onChange}
            isCompact={false}
          />
        )}
      />
      <Input
        type="text"
        name="taskTitle"
        id="taskTitle"
        label="Task title"
        register={register}
        fullWidth
        required
      />
      <Input
        type="date"
        name="dueDate"
        id="dueDate"
        label="Due Date"
        register={register}
        fullWidth
      />
      <ControlledTextArea
        label="Task description"
        placeholder="Enter instructions here"
        name="taskDescription"
        id="taskDescription"
        form={form}
        rows={3}
      />
      <div className={styles.buttons}>
        <Button type="button" style={STYLES.SECONDARY} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          nativeButtonProps={{ disabled: !form.formState.isValid }}
        >
          Assign Task
        </Button>
      </div>
    </form>
  );
}

import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    listUserConversations: build.query<
      ListUserConversationsApiResponse,
      ListUserConversationsApiArg
    >({
      query: queryArg => ({
        url: `/conversations/list`,
        params: {
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          read: queryArg.read,
          archived: queryArg.archived,
          important: queryArg.important,
          subject: queryArg.subject,
          content: queryArg.content,
          patient_id: queryArg.patientId
        }
      })
    }),
    userGetUnreadConversations: build.query<
      UserGetUnreadConversationsApiResponse,
      UserGetUnreadConversationsApiArg
    >({
      query: () => ({ url: `/conversations/unread` })
    }),
    listPracticeConversations: build.query<
      ListPracticeConversationsApiResponse,
      ListPracticeConversationsApiArg
    >({
      query: queryArg => ({
        url: `/conversations/practice/list`,
        params: {
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          read: queryArg.read,
          archived: queryArg.archived,
          important: queryArg.important,
          subject: queryArg.subject,
          content: queryArg.content,
          patient_id: queryArg.patientId
        }
      })
    }),
    practiceGetUnreadConversations: build.query<
      PracticeGetUnreadConversationsApiResponse,
      PracticeGetUnreadConversationsApiArg
    >({
      query: () => ({ url: `/conversations/practice/unread` })
    }),
    createConversation: build.mutation<
      CreateConversationApiResponse,
      CreateConversationApiArg
    >({
      query: queryArg => ({
        url: `/conversations/create`,
        method: "POST",
        body: queryArg.conversationCreateParams
      })
    }),
    sendMessage: build.mutation<SendMessageApiResponse, SendMessageApiArg>({
      query: queryArg => ({
        url: `/conversations/${queryArg.conversationId}/send-message`,
        method: "POST",
        body: queryArg.conversationSendMessageParams
      })
    }),
    getConversationInfo: build.query<
      GetConversationInfoApiResponse,
      GetConversationInfoApiArg
    >({
      query: queryArg => ({
        url: `/${queryArg.inboxType}/conversations/${queryArg.conversationId}/read`
      })
    }),
    updateConversation: build.mutation<
      UpdateConversationApiResponse,
      UpdateConversationApiArg
    >({
      query: queryArg => ({
        url: `/${queryArg.inboxType}/conversations/${queryArg.conversationId}/update`,
        method: "POST",
        body: queryArg.conversationUpdateParams
      })
    }),
    deleteConversation: build.mutation<
      DeleteConversationApiResponse,
      DeleteConversationApiArg
    >({
      query: queryArg => ({
        url: `/conversations/${queryArg.conversationId}/delete`,
        method: "DELETE"
      })
    }),
    bulkUpdateConversation: build.mutation<
      BulkUpdateConversationApiResponse,
      BulkUpdateConversationApiArg
    >({
      query: queryArg => ({
        url: `/${queryArg.inboxType}/conversations/bulk/update`,
        method: "POST",
        body: queryArg.conversationBulkUpdateParams
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as conversation };
export type ListUserConversationsApiResponse =
  /** status 200 Sent in response to a successfully handled request to list conversations.
   */ ConversationListing;
export type ListUserConversationsApiArg = {
  page?: Page;
  pagesz?: PageSize;
  read?: boolean;
  archived?: boolean;
  important?: boolean;
  subject?: string;
  content?: string;
  patientId?: UserId;
};
export type UserGetUnreadConversationsApiResponse =
  /** status 200 Sent in response to a successfully handled request for getting the
number of unread conversations.
 */ number;
export type UserGetUnreadConversationsApiArg = void;
export type ListPracticeConversationsApiResponse =
  /** status 200 Sent in response to a successfully handled request to list conversations.
   */ ConversationListing;
export type ListPracticeConversationsApiArg = {
  page?: Page;
  pagesz?: PageSize;
  read?: boolean;
  archived?: boolean;
  important?: boolean;
  subject?: string;
  content?: string;
  patientId?: UserId;
};
export type PracticeGetUnreadConversationsApiResponse =
  /** status 200 Sent in response to a successfully handled request for getting the
number of unread conversations.
 */ number;
export type PracticeGetUnreadConversationsApiArg = void;
export type CreateConversationApiResponse =
  /** status 200 Sent in response to a successfully handled request to create a
new conversation.
 */ ConversationInfo;
export type CreateConversationApiArg = {
  /** HTTP request body to create a new conversation.
   */
  conversationCreateParams: ConversationCreateParams;
};
export type SendMessageApiResponse =
  /** status 200 Sent in response to a successfully handled request to send a new
message in an existing conversation.
 */ MessageInfo;
export type SendMessageApiArg = {
  conversationId: ConversationId;
  /** HTTP request body to send a new message in a conversation.
   */
  conversationSendMessageParams: ConversationSendMessageParams;
};
export type GetConversationInfoApiResponse =
  /** status 200 Sent in response to a successfully handled request to read an existing
conversation.
 */ ConversationInfo;
export type GetConversationInfoApiArg = {
  conversationId: ConversationId;
  inboxType: InboxType;
};
export type UpdateConversationApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UpdateConversationApiArg = {
  conversationId: ConversationId;
  inboxType: InboxType;
  /** HTTP request body to update an existing conversation.
   */
  conversationUpdateParams: ConversationUpdateParams;
};
export type DeleteConversationApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type DeleteConversationApiArg = {
  conversationId: ConversationId;
};
export type BulkUpdateConversationApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type BulkUpdateConversationApiArg = {
  inboxType: InboxType;
  /** HTTP request body to update multiple existing conversations.
   */
  conversationBulkUpdateParams: ConversationBulkUpdateParams;
};
export type ConversationRecipient = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob?: number;
  is_patient: boolean;
};
export type ConversationListItem = {
  /** System-assigned unique ID of a conversation
   */
  conversation_id: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  subject: string;
  recipients: ConversationRecipient[];
  has_unread_messages: boolean;
  has_attachments: boolean;
  has_linked_patient: boolean;
  archived: boolean;
  important: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  deleted_at?: string;
};
export type ConversationListing = {
  items: ConversationListItem[];
  pages: number;
  count: number;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type Page = number;
export type PageSize = number;
export type UserId = number;
export type PatientRecipient = {
  included: boolean;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** Standard E.164 phone number including the leading plus sign. To summarize the format, it is
    `+[country_code][area_code][number]` with the leading `+` begin literal. Since we are only
    dealing with American and Canadian customers, the country code is always 1, the area code is
    always 3 digits, and the number is always 7 digits. The stored phone number must never contain
    spaces, dashes or any other punctuation. For example, +18005551212 is valid but 503-711-1234
    is not.
     */
  mobile?: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
};
export type StaffRecipient = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
};
export type MessageAuthor = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  staff_id?: number;
};
export type MessageInfo = {
  message_id: string;
  /** System-assigned unique ID of a conversation
   */
  conversation_id: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  staff_only: boolean;
  content?: string;
  author: MessageAuthor;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  sent_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  deleted_at?: string;
};
export type ConversationInfo = {
  /** System-assigned unique ID of a conversation
   */
  conversation_id: string;
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  subject: string;
  patient?: PatientRecipient;
  staff: StaffRecipient[];
  messages: MessageInfo[];
  has_unread_messages: boolean;
  archived: boolean;
  important: boolean;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  deleted_at?: string;
};
export type PatientParticipant = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  included: boolean;
};
export type ConversationCreateParams = {
  subject: string;
  staff_participants: UserId[];
  patient_participant?: PatientParticipant;
  content: string;
};
export type ConversationId = string;
export type ConversationSendMessageParams = {
  content: string;
  staff_only: boolean;
};
export type InboxType = "practice" | "staff" | "patient";
export type ErrorId = number;
export type PatientParticipantUpdate = {
  user_id: any | null;
  included: boolean;
};
export type ConversationUpdateParams = {
  subject?: string;
  archived?: boolean;
  read?: boolean;
  important?: boolean;
  staff_participants?: UserId[];
  patient_participant?: PatientParticipantUpdate;
};
export type ConversationBulkUpdateParams = {
  conversation_ids: ConversationId[];
  archived?: boolean;
  read?: boolean;
  important?: boolean;
};
export const {
  useListUserConversationsQuery,
  useLazyListUserConversationsQuery,
  useUserGetUnreadConversationsQuery,
  useLazyUserGetUnreadConversationsQuery,
  useListPracticeConversationsQuery,
  useLazyListPracticeConversationsQuery,
  usePracticeGetUnreadConversationsQuery,
  useLazyPracticeGetUnreadConversationsQuery,
  useCreateConversationMutation,
  useSendMessageMutation,
  useGetConversationInfoQuery,
  useLazyGetConversationInfoQuery,
  useUpdateConversationMutation,
  useDeleteConversationMutation,
  useBulkUpdateConversationMutation
} = injectedRtkApi;

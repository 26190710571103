/* PatientPicker  */
/* External Imports */
import clsx from "clsx";
import { useMemo, useState } from "react";
import Select, { StylesConfig, GroupBase } from "react-select";

/* Local Imports */

// components

// constants
import { nameAndDOB } from "@/globals/helpers/formatters";

// store
import { UserId } from "@/store/services/user";
import {
  PatientListItem,
  usePatientGetListQuery
} from "@/store/services/practice";

// styles
import styles from "./styles.module.scss";
import {
  SelectCompactStyles,
  SelectCompactTheme,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

// Type the styles configuration
const typedSelectStyles: StylesConfig<
  PatientOptionType,
  false,
  GroupBase<PatientOptionType>
> = {
  ...SelectCompactStyles
} as StylesConfig<PatientOptionType, false, GroupBase<PatientOptionType>>;

export type PatientOptionType = {
  value: PatientListItem;
  label: string;
};

/* PatientPicker Typescript Interface */
interface PatientPickerProps {
  initialPatientIds?: UserId[];
  onUpdatePatient: (patientId: UserId | null) => void;
  isCompact?: boolean;
}

export default function PatientPicker({
  initialPatientIds,
  onUpdatePatient,
  isCompact = true
}: PatientPickerProps) {
  const [query, setQuery] = useState("");

  /* Redux */
  const { data: patientList } = usePatientGetListQuery({
    pagesz: 100,
    query,
    patientIds: initialPatientIds
  });

  /* Local State */

  // memoize the patient options
  const patientOptions: PatientOptionType[] = useMemo(() => {
    if (!patientList || !patientList.patients) return [];
    return patientList.patients.map(patient => ({
      value: patient,
      label: nameAndDOB(patient)
    }));
  }, [patientList]);

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.PatientPicker)}>
      <Select<PatientOptionType, false>
        options={patientOptions}
        value={
          initialPatientIds?.[0]
            ? patientOptions.find(
                option => initialPatientIds[0] === option.value.user_id
              )
            : null
        }
        onInputChange={(newValue, { action }) => {
          // Only update query when user is typing
          if (action === "input-change") {
            setQuery(newValue);
          }
        }}
        onChange={selectedOption =>
          onUpdatePatient(selectedOption ? selectedOption.value.user_id : null)
        }
        theme={isCompact ? SelectCompactTheme : SelectDefaultTheme}
        styles={isCompact ? typedSelectStyles : {}}
        placeholder="Select patient..."
        isClearable
      />
    </div>
  );
}

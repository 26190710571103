// third-party
import {
  FieldValues,
  FieldErrors,
  Path,
  Controller,
  Control
} from "react-hook-form";

// components
import ComboboxSelect from "./combobox";

// constants
import { METRIC_LABELS } from "@/globals/constants";

interface ControlledComboboxProps<T extends FieldValues, V> {
  label: string; // input label
  name: Path<T>; // input name
  errors?: FieldErrors<T>; // errors received from parent form
  control: Control<T>;
  options: V[];
  hiddenLabel?: boolean;
  isHorizontalLayout?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  required?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  labelAcc?: (item: any) => string;
  valueKey?: string;
}

/**
 * ControlledCombobox is a wrapper component for a combobox input field that integrates with react-hook-form.
 * It uses the Controller component from react-hook-form to manage the form state and validation.
 *
 * @template T - The type of the form values.
 * @template V - The type of the options array.
 *
 * @param {string} label - The label for the combobox input. Required for accessibility.
 * @param {Path<T>} name - The name of the input field, used to register the field in the form.
 * @param {FieldErrors<T>} [errors] - The errors object from react-hook-form, used to display validation errors.
 * @param {Control<T>} control - The control object from react-hook-form, used to manage the form state.
 * @param {V[]} options - The array of options to be displayed in the combobox.
 * @param {boolean} [hiddenLabel] - If true, the label will be visually hidden but still accessible to screen readers.
 * @param {boolean} [isHorizontalLayout=true] - If true, the combobox will be displayed in a horizontal layout.
 * @param {boolean} [fullWidth] - If true, the combobox will take up the full width of its container.
 * @param {boolean} [disabled=false] - If true, the combobox will be disabled and not interactable.
 * @param {boolean} [required=false] - If true, the combobox will be a required field in the form.
 * @param {(item: any) => string} [labelAcc] - A function to access the label of an option item. Defaults to using METRIC_LABELS or item.label.
 * @param {string} [valueKey] - The key to access the value of an option item.
 *
 * @returns {JSX.Element} The rendered ControlledCombobox component.
 */
export default function ControlledCombobox<T extends FieldValues, V>({
  label,
  name,
  errors,
  control,
  options,
  hiddenLabel,
  isHorizontalLayout = true,
  fullWidth,
  disabled = false,
  required = false,
  labelAcc,
  valueKey
}: ControlledComboboxProps<T, V>) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <Controller<T>
      name={name}
      control={control}
      rules={{ required }}
      render={({
        field: { onChange, value, name },
      }) => (
        <ComboboxSelect
          hiddenLabel={hiddenLabel}
          label={label}
          options={options}
          name={name}
          isHorizontalLayout={isHorizontalLayout}
          onChange={v => onChange(v)}
          labelAcc={item =>
            labelAcc
              ? labelAcc(item)
              : METRIC_LABELS[item] || item.label || item
          }
          initialValue={value}
          error={
            errors?.[name] &&
            ((errors?.[name]?.message as string) ||
              "This field is " + errors?.[name]?.type)
          }
          fullWidth={fullWidth}
          isDisabled={disabled}
          value={value}
          valueKey={valueKey}
        />
      )}
    />
  );
}

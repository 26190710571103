import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    listClaims: build.query<ListClaimsApiResponse, ListClaimsApiArg>({
      query: queryArg => ({
        url: `/claims`,
        params: {
          page: queryArg.page,
          pagesz: queryArg.pagesz,
          status_in: queryArg.statusIn,
          claim_id: queryArg.claimId,
          date_of_service: queryArg.dateOfService,
          provider_id: queryArg.providerId,
          patient_id: queryArg.patientId,
          patient_dob: queryArg.patientDob
        }
      })
    }),
    getClaimNotifications: build.query<
      GetClaimNotificationsApiResponse,
      GetClaimNotificationsApiArg
    >({
      query: queryArg => ({
        url: `/claims/notifications`,
        params: { status_in: queryArg.statusIn, patient_id: queryArg.patientId }
      })
    }),
    submitClaim: build.mutation<SubmitClaimApiResponse, SubmitClaimApiArg>({
      query: queryArg => ({
        url: `/claims/submit`,
        method: "POST",
        body: queryArg.submitClaimRequest
      })
    }),
    deleteClaims: build.mutation<DeleteClaimsApiResponse, DeleteClaimsApiArg>({
      query: queryArg => ({
        url: `/claims/delete`,
        method: "DELETE",
        body: queryArg.submitClaimRequest
      })
    }),
    getClaimTimeline: build.query<
      GetClaimTimelineApiResponse,
      GetClaimTimelineApiArg
    >({
      query: queryArg => ({ url: `/claims/${queryArg.claimId}/timeline` })
    }),
    createClaimComment: build.mutation<
      CreateClaimCommentApiResponse,
      CreateClaimCommentApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/comment`,
        method: "POST",
        body: queryArg.createClaimCommentRequest
      })
    }),
    markClaimReadyToBill: build.mutation<
      MarkClaimReadyToBillApiResponse,
      MarkClaimReadyToBillApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/ready-to-bill`,
        method: "POST"
      })
    }),
    uploadClaimHcfa: build.mutation<
      UploadClaimHcfaApiResponse,
      UploadClaimHcfaApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/hcfa`,
        method: "POST",
        body: queryArg.body
      })
    }),
    uploadClaimEob: build.mutation<
      UploadClaimEobApiResponse,
      UploadClaimEobApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/eob`,
        method: "POST",
        body: queryArg.body
      })
    }),
    requestClaimChanges: build.mutation<
      RequestClaimChangesApiResponse,
      RequestClaimChangesApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/request-changes`,
        method: "POST",
        body: queryArg.createClaimCommentRequest
      })
    }),
    setClaimNonBillable: build.mutation<
      SetClaimNonBillableApiResponse,
      SetClaimNonBillableApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/claim_non_billable`,
        method: "POST"
      })
    }),
    setClaimOnHold: build.mutation<
      SetClaimOnHoldApiResponse,
      SetClaimOnHoldApiArg
    >({
      query: queryArg => ({
        url: `/claim/${queryArg.claimId}/claim_on_hold`,
        method: "POST"
      })
    }),
    setClaimCorrectedAndResubmitted: build.mutation<
      SetClaimCorrectedAndResubmittedApiResponse,
      SetClaimCorrectedAndResubmittedApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/resubmit_correction`,
        method: "POST"
      })
    }),
    setClaimAppealSubmitted: build.mutation<
      SetClaimAppealSubmittedApiResponse,
      SetClaimAppealSubmittedApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/appeal_submitted`,
        method: "POST"
      })
    }),
    setClaimAtSecondary: build.mutation<
      SetClaimAtSecondaryApiResponse,
      SetClaimAtSecondaryApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/claim_at_secondary`,
        method: "POST"
      })
    }),
    acceptClaim: build.mutation<AcceptClaimApiResponse, AcceptClaimApiArg>({
      query: queryArg => ({
        url: `/claims/accept`,
        method: "POST",
        body: queryArg.submitClaimRequest
      })
    }),
    setClaimPatientBalance: build.mutation<
      SetClaimPatientBalanceApiResponse,
      SetClaimPatientBalanceApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/patient_balance`,
        method: "POST"
      })
    }),
    setClaimDeniedPendingProviderReview: build.mutation<
      SetClaimDeniedPendingProviderReviewApiResponse,
      SetClaimDeniedPendingProviderReviewApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/denied_pending_provider_review`,
        method: "POST"
      })
    }),
    approveClaim: build.mutation<ApproveClaimApiResponse, ApproveClaimApiArg>({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/approve`,
        method: "POST"
      })
    }),
    denyClaim: build.mutation<DenyClaimApiResponse, DenyClaimApiArg>({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/deny`,
        method: "POST"
      })
    }),
    setClaimPartiallyDenied: build.mutation<
      SetClaimPartiallyDeniedApiResponse,
      SetClaimPartiallyDeniedApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/partially_deny`,
        method: "POST"
      })
    }),
    setClaimRejectedByClearinghouse: build.mutation<
      SetClaimRejectedByClearinghouseApiResponse,
      SetClaimRejectedByClearinghouseApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/rejected_by_clearinghouse`,
        method: "POST"
      })
    }),
    updateClaimEvent: build.mutation<
      UpdateClaimEventApiResponse,
      UpdateClaimEventApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/timeline/${queryArg.eventId}/update`,
        method: "POST",
        body: queryArg.createClaimCommentRequest
      })
    }),
    createClaimOutcome: build.mutation<
      CreateClaimOutcomeApiResponse,
      CreateClaimOutcomeApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/outcome/create`,
        method: "POST",
        body: queryArg.createClaimOutcomeRequest
      })
    }),
    updateClaimOutcome: build.mutation<
      UpdateClaimOutcomeApiResponse,
      UpdateClaimOutcomeApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/outcome/update`,
        method: "POST",
        body: queryArg.updateClaimOutcomeRequest
      })
    }),
    listClaimOutcomes: build.query<
      ListClaimOutcomesApiResponse,
      ListClaimOutcomesApiArg
    >({
      query: queryArg => ({
        url: `/claims/${queryArg.claimId}/outcome/list`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as claim };
export type ListClaimsApiResponse =
  /** status 200 A list of claims */ ClaimListResponse;
export type ListClaimsApiArg = {
  page?: Page;
  pagesz?: PageSize;
  /** Filter claims by status */
  statusIn?: ClaimStatus[];
  /** Filter claims by claim ID */
  claimId?: ClaimId;
  /** Filter claims by date of service */
  dateOfService?: string;
  /** Filter claims by provider ID */
  providerId?: UserId;
  /** Filter claims by patient ID */
  patientId?: UserId;
  /** Filter claims by patient date of birth */
  patientDob?: string;
};
export type GetClaimNotificationsApiResponse =
  /** status 200 A total number of claims */ number;
export type GetClaimNotificationsApiArg = {
  /** Filter claims by status */
  statusIn?: ClaimStatus[];
  /** Filter claims by patient ID */
  patientId?: UserId;
};
export type SubmitClaimApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SubmitClaimApiArg = {
  submitClaimRequest: SubmitClaimRequest;
};
export type DeleteClaimsApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type DeleteClaimsApiArg = {
  submitClaimRequest: SubmitClaimRequest;
};
export type GetClaimTimelineApiResponse =
  /** status 200 A list of claim events */ ClaimTimelineItem[];
export type GetClaimTimelineApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type CreateClaimCommentApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CreateClaimCommentApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
  createClaimCommentRequest: CreateClaimCommentRequest;
};
export type MarkClaimReadyToBillApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type MarkClaimReadyToBillApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type UploadClaimHcfaApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UploadClaimHcfaApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
  /** Payload required when a file is created. This provides the basic metadata stored for the file and makes it
    possible to upload the file contents to S3.
     */
  body: FormData;
};
export type UploadClaimEobApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UploadClaimEobApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
  /** Payload required when a file is created. This provides the basic metadata stored for the file and makes it
    possible to upload the file contents to S3.
     */
  body: FormData;
};
export type RequestClaimChangesApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type RequestClaimChangesApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
  createClaimCommentRequest: CreateClaimCommentRequest;
};
export type SetClaimNonBillableApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimNonBillableApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type SetClaimOnHoldApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimOnHoldApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type SetClaimCorrectedAndResubmittedApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimCorrectedAndResubmittedApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type SetClaimAppealSubmittedApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimAppealSubmittedApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type SetClaimAtSecondaryApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimAtSecondaryApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type AcceptClaimApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type AcceptClaimApiArg = {
  submitClaimRequest: SubmitClaimRequest;
};
export type SetClaimPatientBalanceApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimPatientBalanceApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type SetClaimDeniedPendingProviderReviewApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimDeniedPendingProviderReviewApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type ApproveClaimApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type ApproveClaimApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type DenyClaimApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type DenyClaimApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type SetClaimPartiallyDeniedApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimPartiallyDeniedApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type SetClaimRejectedByClearinghouseApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SetClaimRejectedByClearinghouseApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
};
export type UpdateClaimEventApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UpdateClaimEventApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
  /** The 0 based index of the claim timeline event. */
  eventId: number;
  createClaimCommentRequest: CreateClaimCommentRequest;
};
export type CreateClaimOutcomeApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type CreateClaimOutcomeApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
  createClaimOutcomeRequest: CreateClaimOutcomeRequest;
};
export type UpdateClaimOutcomeApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type UpdateClaimOutcomeApiArg = {
  /** The ID of the claim */
  claimId: ClaimId;
  updateClaimOutcomeRequest: UpdateClaimOutcomeRequest;
};
export type ListClaimOutcomesApiResponse =
  /** status 200 A list of claim outcomes */ ListClaimOutcomes200ResponseInner[];
export type ListClaimOutcomesApiArg = {
  page?: Page;
  pagesz?: PageSize;
  /** The ID of the claim */
  claimId: ClaimId;
};
export type ClaimStatus =
  | "CREATED"
  | "IN_REVIEW"
  | "HOLD"
  | "NON_BILLABLE"
  | "CHANGES_REQUIRED"
  | "ACCEPTED"
  | "SUBMITTED"
  | "CORRECTED_AND_RESUBMITTED"
  | "APPEAL_SUBMITTED"
  | "CLAIM_AT_SECONDARY"
  | "APPROVED"
  | "REJECTED_BY_CLEARINGHOUSE"
  | "PATIENT_BALANCE"
  | "DENIED_PENDING_PROVIDER_REVIEW"
  | "DENIED"
  | "PARTIALLY_DENIED"
  | "DELETED";
export type FileId = number;
export type ClaimListItem = {
  claim_id?: string;
  /** System-assigned ID of an encounter, unique for a patient. Every time a patient visits a location for service,
    a new encounter is created in which to store the results and purpose of the encounter.
     */
  encounter_id?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  patient_id?: number;
  /** A human's common name - almost always just the first and last name concatenated with a space.
   */
  patient_name?: string;
  insurance_name?: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  provider_id?: number;
  /** A human's common name - almost always just the first and last name concatenated with a space.
   */
  provider_name?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  claimed_amount?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  contracted_amount?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  reimbursed_amount?: string;
  status?: ClaimStatus;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at?: string;
  date_of_service?: string;
  hcfa_ids?: FileId[];
  eob_ids?: FileId[];
};
export type ClaimListResponse = {
  pages: number;
  items: ClaimListItem[];
};
export type Page = number;
export type PageSize = number;
export type ClaimId = string;
export type UserId = number;
export type ErrorId = number;
export type SubmitClaimRequest = {
  claim_ids: ClaimId[];
};
export type ClaimTimelineItem = {
  timeline_id: string;
  status?: ClaimStatus;
  comment?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  at: string;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  creator_id?: number;
  creator_first_name?: string;
  creator_last_name?: string;
};
export type CreateClaimCommentRequest = {
  comment: string;
};
export type ClaimResult = "APPROVED" | "DENIED";
export type CreateClaimOutcomeRequest = {
  claim_id: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id: number;
  modifier_code?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  charge_amount: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  insurance_paid: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  allowed: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  patient_balance: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  applied_deductible?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  coinsurance?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  copay?: string;
  denial_code?: string;
  result: ClaimResult;
};
export type UpdateClaimOutcomeRequest = {
  claim_id: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id: number;
  modifier_code?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  charge_amount?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  insurance_paid?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  allowed?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  patient_balance?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  applied_deductible?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  coinsurance?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  copay?: string;
  denial_code?: string;
  result?: ClaimResult;
};
export type CptModifierCodes = string[];
export type CptCodeDetails = {
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id: number;
  /** The actual CPT code.
   */
  cpt_code: string;
  /** The human-readable description of the code.
   */
  description: string;
};
export type ClaimOutcome = {
  claim_id: string;
  /** The system-generated ID for the CPT code. We always use this ID and not the CPT code itself, as the codes
    change year by year and we need to make sure we preserve the code across such changes. Thus these codes are
    versioned, and this is how we reference a code at a particular version.
     */
  cpt_id: number;
  modifier_code?: string;
  modifier_codes?: CptModifierCodes;
  cpt_code_details?: CptCodeDetails;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  charge_amount: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  insurance_paid: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  allowed: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  patient_balance: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  applied_deductible?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  coinsurance?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  copay?: string;
  result?: ClaimResult;
  denial_code?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  created_at?: string;
  /** A Pario timestamp in UTC represented as an RFC-3339 string.
   */
  updated_at?: string;
};
export type CptModifierId = number;
export type BillRecipients =
  | "PATIENT"
  | "PRIMARY_INSURANCE"
  | "SECONDARY_INSURANCE"
  | "TERTIARY_INSURANCE";
export type Coding = {
  cpt_id?: number;
  is_billable?: boolean;
  is_private?: boolean;
  modifier_ids?: CptModifierId[];
  icd10_ids?: number[];
  units?: number;
  place_of_service?: string;
  type_of_service?: string;
  bill_recipient?: BillRecipients;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  fee?: string;
  /** A currency amount in the form of a string. This is a string because we don't want to lose precision
    for large values. The currency is always USD.
     */
  charge?: string;
  comment?: string;
};
export type CptCode = string;
export type ListClaimOutcomes200ResponseInner = ClaimOutcome & {
  coding?: Coding;
  cpt_code: CptCode;
  description?: string;
};
export const {
  useListClaimsQuery,
  useLazyListClaimsQuery,
  useGetClaimNotificationsQuery,
  useLazyGetClaimNotificationsQuery,
  useSubmitClaimMutation,
  useDeleteClaimsMutation,
  useGetClaimTimelineQuery,
  useLazyGetClaimTimelineQuery,
  useCreateClaimCommentMutation,
  useMarkClaimReadyToBillMutation,
  useUploadClaimHcfaMutation,
  useUploadClaimEobMutation,
  useRequestClaimChangesMutation,
  useSetClaimNonBillableMutation,
  useSetClaimOnHoldMutation,
  useSetClaimCorrectedAndResubmittedMutation,
  useSetClaimAppealSubmittedMutation,
  useSetClaimAtSecondaryMutation,
  useAcceptClaimMutation,
  useSetClaimPatientBalanceMutation,
  useSetClaimDeniedPendingProviderReviewMutation,
  useApproveClaimMutation,
  useDenyClaimMutation,
  useSetClaimPartiallyDeniedMutation,
  useSetClaimRejectedByClearinghouseMutation,
  useUpdateClaimEventMutation,
  useCreateClaimOutcomeMutation,
  useUpdateClaimOutcomeMutation,
  useListClaimOutcomesQuery,
  useLazyListClaimOutcomesQuery
} = injectedRtkApi;

/* Medication form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect } from "react";
import dayjs from "dayjs";
/* Local Imports */

// components
import ControlledCombobox from "@/components/input/controlledCombobox";
import Input from "@/components/input";
import Button from "@/components/button";
import Clock from "../../../../public/svgs/clock.svg";

// constants

import { STYLES } from "@/globals/constants";

// utils
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// store

// styles
import styles from "./styles.module.scss";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* Medicationform Typescript Interface */
interface MedicationformProps {
  onSubmit: (data: any, reset: (values: any) => void) => void;
  defaultValues?: any;
  startTime?: number;
  disabled?: boolean;
}

export default function Medicationform({
  onSubmit,
  defaultValues,
  startTime,
  disabled = false,
}: MedicationformProps) {
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
    reset,
    setValue
  } = useForm({ defaultValues });

  useEffect(() => {
    if (defaultValues) {
      reset({
        ...defaultValues,
      }, {
        keepValues:  true,
      });
    }
  }, [defaultValues]);

  useEffect(() => {
    setValue("start_time", startTime);
  }, [startTime]);

  return (
    <form
      className={clsx(styles.LaborForm, styles.Medicationform)}
      onSubmit={handleSubmit((data: any) => {
        onSubmit(data, reset);
      })}
    >
      <Controller
        name="start_time"
        control={control}
        render={({ field: { onChange, name, value } }) => {
          return (
            <DateTimeStamp
              name={name}
              onChange={onChange}
              initialValue={defaultValues && defaultValues.start_time || startTime}
              value={value}
            />
          );
        }}
      />
      <Input
        type="text"
        label="Medication"
        name="name"
        id="name"
        fullWidth
        register={register}
        disabled={disabled}
      />
      <Input
        type="text"
        label="Dose"
        name="dose"
        id="dose"
        fullWidth
        register={register}
        disabled={disabled}
      />
      <ControlledCombobox
        options={[
          "intravenous",
          "intramuscular",
          "inhalation",
          "oral",
          "rectal",
          "sublingual/buccal",
        ]}
        label="Route"
        name={"med_route"}
        control={control}
        isHorizontalLayout={false}
        errors={errors}
        fullWidth
        disabled={disabled}
      />
      <Input
        type="date"
        label="Expiration"
        name="expiration"
        id="expiration"
        fullWidth
        register={register}
        disabled={disabled}
      />
      <Input
        type="text"
        label="Lot #"
        name="lot_num"
        id="lot_num"
        fullWidth
        register={register}
        disabled={disabled}
      />
      <Input
        type="text"
        label="Units"
        name="units"
        id="units"
        fullWidth
        register={register}
        disabled={disabled}
      />
      <ControlledCombobox
        options={[
          "induction",
          "pain relief",
          "augmentation",
          "comfort",
          "other",
        ]}
        label="Reason"
        name={"site"}
        control={control}
        isHorizontalLayout={false}
        errors={errors}
        fullWidth
        disabled={disabled}
      />
      <div className={styles.fullWidth}>
        <Input
          type="text"
          label="Comments"
          name="comments"
          id="comments"
          fullWidth
          register={register}
          disabled={disabled}
        />
      </div>
      <div className={styles.fullWidth}>
        <Button
          style={STYLES.FULL_WIDTH}
          type="submit"
          nativeButtonProps={{ disabled }}
        >
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}

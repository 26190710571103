import { METRICS, METRIC_LABELS } from "@/globals/constants";

import { Sex } from "@/store/services/patient";
import {
  GbsStatus,
  Pregnancy,
  PregnancyUpsertRequest
} from "@/store/services/pregnancy";

// Type definitions for options
export type GbsStatusOption = {
  label: string;
  value: GbsStatus | "";
  isNotable: boolean;
};
export type BirthplaceOptionType = {
  label: string;
  value: string;
  isNotable?: boolean;
};

// Type for the field prop
export type PregnancyFieldType =
  | "gbs_status"
  | "desired_birthplace"
  | "fetal_sex";

// Union type for all possible option types
export type OptionType = GbsStatusOption | BirthplaceOptionType;

type FieldValueTypes = {
  gbs_status: GbsStatus;
  desired_birthplace: string;
  fetal_sex: Sex;
};

// Helper function to generate options based on field type
export function getOptions(
  field: PregnancyFieldType,
  practiceDesiredBirthplaces: string[] | undefined
): OptionType[] {
  switch (field) {
    case "gbs_status":
      return [
        {
          value: METRICS.POSITIVE as GbsStatus,
          label: METRIC_LABELS[METRICS.POSITIVE],
          isNotable: true
        },
        {
          value: METRICS.NEGATIVE as GbsStatus,
          label: METRIC_LABELS[METRICS.NEGATIVE],
          isNotable: false
        },
        {
          value: METRICS.DECLINED as GbsStatus,
          label: METRIC_LABELS[METRICS.DECLINED],
          isNotable: false
        }
      ];
    case "fetal_sex":
      return [
        {
          value: METRICS.FEMALE,
          label: METRIC_LABELS[METRICS.FEMALE],
          isNotable: false
        },
        {
          value: METRICS.MALE,
          label: METRIC_LABELS[METRICS.MALE],
          isNotable: false
        },
        {
          value: METRICS.UNKNOWN,
          label: METRIC_LABELS[METRICS.UNKNOWN],
          isNotable: false
        }
      ];
    case "desired_birthplace":
      return practiceDesiredBirthplaces
        ? practiceDesiredBirthplaces.map(place => ({
            value: place,
            label: place,
            isNotable: false
          }))
        : [];
    default:
      return [];
  }
}

// Helper function to get current value
export function getCurrentValue(
  pregnancy: Pregnancy,
  field: PregnancyFieldType,
  options: OptionType[]
): OptionType | undefined {
  switch (field) {
    case "gbs_status":
      return options.find(v => v.value === pregnancy.gbs_status);
    case "fetal_sex":
      return options.find(v => v.value === pregnancy.fetal_sex);
    case "desired_birthplace":
      return options.find(v => v.value === pregnancy?.desired_birthplace);
    default:
      return undefined;
  }
}

// Create update payload based on field and new value
export function createUpdatePayload<F extends keyof FieldValueTypes>(
  pregnancy: Pregnancy,
  field: F,
  newValue: FieldValueTypes[F]
): PregnancyUpsertRequest {
  const payload: PregnancyUpsertRequest = {
    pregnancy_id: pregnancy.pregnancy_id
  };

  if (field === "gbs_status") {
    payload.gbs_status = newValue as GbsStatus;
  } else if (field === "fetal_sex") {
    payload.fetal_sex = newValue as Sex; // this is because it could be an empty string
  } else if (field === "desired_birthplace") {
    payload.desired_birthplace = newValue;
  }

  return payload;
}

// Helper to check if value has changed
export function hasValueChanged<F extends keyof FieldValueTypes>(
  pregnancy: Pregnancy,
  field: F,
  newValue: FieldValueTypes[F]
): boolean {
  return newValue !== pregnancy[field];
}

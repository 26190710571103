import { useDispatch } from "react-redux";
import {
  EVENTS,
  EVENT_LABELS,
  STAGES,
  STAGE_LABELS
} from "@/components/flows/labor/constants";
import { STATUS_KEYS } from "@/globals/constants";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { setModalIsOpen } from "../modalSlice";
import {
  EncounterInfo,
  IdType,
  LaborEvent,
  useEncounterLaborEventUpsertMutation,
  useEncounterLaborInfoQuery,
  useEncounterLaborRecurringEventUpdateMutation
} from "@/store/services/encounter";
import MaternalProgressform from "@/components/forms/_labor/maternalProgress";
import Birthform from "@/components/forms/_labor/birth";
import BloodLossform from "@/components/forms/_labor/bloodLoss";
import Lacerationform from "@/components/forms/_labor/laceration";
import Placentaform from "@/components/forms/_labor/placenta";
import ROMform from "@/components/forms/_labor/ROM";
import NewbornForm from "@/components/forms/_labor/newbornExam/newborn";
import Transportform from "@/components/forms/_labor/transport";
import ApgarForm from "@/components/forms/_labor/apgar";
import Apgar5Form from "@/components/forms/_labor/apgar5";
import Apgar10Form from "@/components/forms/_labor/apgar10";
import FirstLatchform from "@/components/forms/_labor/firstLatch";
import MaternalVitalsForm from "@/components/forms/_labor/detailTimelineForms/maternalVitals";
import NewbornVitalsForm from "@/components/forms/_labor/detailTimelineForms/newbornVitals";
import Timestampform from "@/components/forms/_labor/timestamp";
import dayjs from "dayjs";
import { useMemo } from "react";

interface EditLaborProgressUpdateProps {
  selectedStage: string;
  encounter?: EncounterInfo;
  editingEvent: LaborEvent;
}

export default function EditLaborMilestoneForm({
  selectedStage,
  encounter,
  editingEvent
}: EditLaborProgressUpdateProps) {
  const dispatch = useDispatch();
  const [upsertLaborEvent] = useEncounterLaborEventUpsertMutation();

  const { data: labor } = useEncounterLaborInfoQuery(
    {
      encounterId: encounter?.encounter_id as number
    },
    { skip: !encounter?.encounter_id }
  );

  const launchToast = (message: string, type: string) => {
    dispatch(
      addAlertToToastTrough({
        message,
        type
      })
    );
  };

  const handleSubmit = (formData: any, event: string) => {
    const laborEventUpdate = {
      event_id: event,
      id: event,
      forms: formData,
      start: parseInt(formData.start_time)
    };

    // if labor already exists for this pregnancy,
    // update this specific event
    if (encounter) {
      // Overwrite the global state specifically for the newborn exam
      let _selectedStage = selectedStage;

      if (event == EVENTS.NEWBORN_EXAM) {
        _selectedStage = STAGES.POSTPARTUM;
      }

      upsertLaborEvent({
        encounterId: encounter.encounter_id,
        stage: _selectedStage,
        laborEvent: event, // will always be a string by the time we get here

        /** Payload for creating/updating a labor.
         */
        laborEventUpdate
      })
        .unwrap()
        .then(res => {
          launchToast(
            `${
              (event && EVENT_LABELS[event]) || STAGE_LABELS[_selectedStage]
            } successfully updated`,
            STATUS_KEYS.SUCCESS
          );
          dispatch(setModalIsOpen(false));
        })
        .catch(err =>
          launchToast(
            `Oops! ${event && EVENT_LABELS[event]} could not be updated`,
            STATUS_KEYS.ERROR
          )
        );
    }
  };

  // Render Form
  const handleRenderForm = (event: string) => {
    switch (event) {
      case EVENTS.BIRTH:
        return (
          <Birthform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.BIRTH]?.forms
            }
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.BIRTH]
                ?.start as number
            }
          />
        );
      case EVENTS.BLOOD_LOSS:
        return (
          <BloodLossform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.BLOOD_LOSS]?.forms
            }
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.BLOOD_LOSS]
                ?.start as number
            }
          />
        );
      case EVENTS.LACERATION:
        return (
          <Lacerationform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.LACERATION]?.forms
            }
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.LACERATION]
                ?.start as number
            }
          />
        );
      case EVENTS.PROGRESS_UPDATE:
        return (
          <MaternalProgressform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.HOSPITAL_NOT]?.forms
            }
          />
        );
      case EVENTS.PLACENTA:
        return (
          <Placentaform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.PLACENTA]?.forms
            }
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.PLACENTA]
                ?.start as number
            }
          />
        );
      case EVENTS.ROM:
        return (
          <ROMform
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={{
              start_time: labor?.stages[selectedStage]?.events?.[EVENTS.ROM]
                ?.start as number,
              ...labor?.stages[selectedStage]?.events?.[EVENTS.ROM]?.forms
            }}
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.ROM]
                ?.start as number
            }
            endTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.ROM]?.end as number
            }
          />
        );
      case EVENTS.NEWBORN_EXAM:
        return (
          <NewbornForm
            // Hardcoding event because newborn exam isn't with the other events
            onSubmit={v => handleSubmit(v, EVENTS.NEWBORN_EXAM)}
            patientId={encounter?.patient.user_id as number}
            encounterId={encounter?.encounter_id as number}
            examType="LABOR"
          />
        );
      case EVENTS.TRANSPORT:
        return <Transportform onSubmit={v => handleSubmit(v, event)} />;
      case EVENTS.APGAR_1:
        return (
          <ApgarForm
            onSubmit={v => handleSubmit(v, event)}
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_1]
                ?.start as number
            }
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_1]?.forms
            }
            apgarMin={1}
          />
        );
      case EVENTS.APGAR_5:
        return (
          <Apgar5Form
            onSubmit={v => handleSubmit(v, event)}
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_5]
                ?.start as number
            }
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_5]?.forms
            }
            apgarMin={5}
          />
        );
      case EVENTS.APGAR_10:
        return (
          <Apgar10Form
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_10]
                ?.start as number
            }
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.APGAR_10]?.forms
            }
            apgarMin={10}
          />
        );
      case EVENTS.LATCH:
        return (
          <FirstLatchform
            startTime={
              labor?.stages[selectedStage]?.events?.[EVENTS.LATCH]
                ?.start as number
            }
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={
              labor?.stages[selectedStage]?.events?.[EVENTS.LATCH]?.forms
            }
          />
        );
      case EVENTS.MATERNAL_VITALS:
        return (
          <MaternalVitalsForm
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={{
              start_time: dayjs().utc().format("YYYYMMDDHHmmss"),
              ...labor?.stages[selectedStage]?.events?.[EVENTS.PARENT_DISCHARGE]
                ?.forms
            }}
          />
        );
      case EVENTS.NEWBORN_VITALS:
        return (
          <NewbornVitalsForm
            onSubmit={v => handleSubmit(v, event)}
            defaultValues={{
              start_time: dayjs().utc().format("YYYYMMDDHHmmss")
            }}
          />
        );
      default:
        return (
          <Timestampform
            eventId={event}
            onSubmit={v => handleSubmit(v, event)}
            startTime={
              labor?.stages[selectedStage]?.events?.[event as string]
                ?.start as number
            }
            endTime={
              labor?.stages[selectedStage]?.events?.[event as string]
                ?.end as number
            }
            defaultValues={
              labor?.stages[selectedStage]?.events?.[event as string]
                ?.forms || { comments: "" }
            }
          />
        );
    }
  };

  // const eventName = useMemo(() => {
  //   console.log(editingEvent);
  //   const eventForms = editingEvent.forms as Record<string, any>;
  //   return eventForms.name;
  // }, [editingEvent])

  return (
    <div>{editingEvent && encounter && handleRenderForm(editingEvent.event_id)}</div>
  );
}

import clsx from "clsx";
import {
  ChangeEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  ReactNode
} from "react";
import { FieldValues, Path, UseFormRegister } from "react-hook-form";

// styles
import styles from "./styles.module.scss";

export interface InputProps<T extends FieldValues> {
  label: string;
  name: Path<T>;
  id: string;
  type: HTMLInputTypeAttribute;
  maxLength?: HTMLInputElement["maxLength"];
  required?: boolean;
  value?: string | number;
  checked?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  customElement?: ReactNode;
  onSubmit?: (value: string) => void;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  autofocus?: boolean;
  hiddenLabel?: boolean;
  fitWidth?: boolean;
  register?: UseFormRegister<T>;
  error?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  // Is there are better way to handle small styling tweaks like this?
  marginBottom?: boolean;
  isSmall?: boolean;
  isCondensed?: boolean;
  isLarge?: boolean;
  isHorizontalLayout?: boolean;
  // Validation rules for react form hook
  rules?: Record<string, any>;
  noMaxWidth?: boolean;
  controlled?: boolean;
}

function Input<T extends FieldValues>({
  label,
  name,
  id,
  type,
  required,
  value,
  checked,
  onChange,
  placeholder,
  customElement,
  onSubmit = () => {},
  onBlur = () => {},
  autofocus = false,
  hiddenLabel = false,
  fitWidth = false,
  disabled = false,
  register,
  error,
  fullWidth = false,
  marginBottom = false,
  isSmall = false,
  isCondensed = false,
  isLarge = false,
  isHorizontalLayout = false,
  rules = {},
  noMaxWidth = false,
  controlled = false,
  maxLength
}: InputProps<T>) {
  return (
    <div
      className={clsx(styles.Input, {
        [styles.fitWidth]: fitWidth,
        [styles.fullWidth]: fullWidth,
        [styles.small]: isSmall,
        [styles.large]: isLarge,
        [styles.marginBottom]: marginBottom,
        [styles.condensed]: isCondensed,
        [styles.horizontal]: isHorizontalLayout,
        [styles.noMaxWidth]: noMaxWidth
      })}
      data-cy={`input-${type}`}
      data-cy-name={name}
    >
      <label htmlFor={name} className={clsx({ [styles.hidden]: hiddenLabel })}>
        {label}
        {required && "*"}
      </label>
      <div
        className={clsx(styles.inputWrapper, {
          [styles.centered]: type === "checkbox"
        })}
      >
        <input
          step={type == "time" ? 1 : "any"}
          data-cy="input-el"
          type={type}
          id={id}
          name={name}
          maxLength={maxLength}
          required={required}
          placeholder={placeholder || label}
          defaultValue={controlled ? undefined : value}
          value={controlled ? value : undefined}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          onKeyDown={(e: KeyboardEvent<HTMLInputElement>) =>
            e.key === "Enter" && onSubmit(e.currentTarget.value)
          }
          autoFocus={autofocus}
          // if using with useform library
          {...(register && name && register(name, rules))}
        />
        <div className={styles.customElement}>{customElement}</div>
      </div>
      {error && <div className={styles.validationError}>{error}</div>}
    </div>
  );
}

export default Input;

import * as generated from "./generated/message";

const message = generated.message.enhanceEndpoints({
  endpoints: {
    messageInbox: {},
    messageInboxUser: {
      providesTags: ["Inbox"]
    },
    messageList: {
      providesTags: ["Inbox"]
    },
    messageChatList: {
      invalidatesTags: ["Inbox"]
    },
    messageSend: {
      invalidatesTags: ["Inbox"]
    }
  }
});

export * from "./generated/message";
export default message;
export const {
  useMessageSendMutation,
  useMessageReadMutation,
  useMessageReplyMutation,
  useMessageListQuery,
  useLazyMessageListQuery,
  useMessageInboxQuery,
  useLazyMessageInboxQuery,
  useMessageChatListMutation,
  useMessageReadByQuery,
  useLazyMessageReadByQuery,
  useMessageUnreadByQuery,
  useLazyMessageUnreadByQuery,
  useMessageFlagControlMutation,
  useMessageListContactsQuery,
  useLazyMessageListContactsQuery,
  useMessageInboxUserQuery,
  useLazyMessageInboxUserQuery
} = message;

/* ViewInteractiveFile Name */
/* External Imports */
import clsx from "clsx";
import { useSelector } from "react-redux";
import dynamic from "next/dynamic";

/* Local Imports */

// components
import AnvilWorkflow from "@/components/interactiveForms";

const InteractiveForm = dynamic(
  () => import("@/components/interactiveForms/legacyIndex"),
  { ssr: false }
);

// constants

// store
import { RootState } from "@/store/store";
import { UserId } from "@/store/services/patient";
import { LocationId } from "@/store/services/location";
import { TaskId } from "@/store/services/task";
import { useTagsListQuery } from "@/store/services/tag";

// styles
import styles from "../styles.module.scss";

/* ViewInteractiveFile Typescript Interface */
interface ViewInteractiveFileProps {
  title: string;
  url: string;
  fileName: string;
  fileId: number;
  reviewed?: boolean;
  patientId: UserId;
  taskId: TaskId;
  locationId: LocationId;
  taskCompleted?: boolean;
  tags?: string[];
  anvilForgeSlug?: string;
  anvilSubmissionEid?: string;
  type: "FILE";
}

export default function ViewInteractiveFile({
  title,
  url,
  fileName,
  fileId,
  patientId,
  taskId,
  locationId,
  taskCompleted,
  tags,
  anvilForgeSlug,
  anvilSubmissionEid,
  type = "FILE"
}: ViewInteractiveFileProps) {
  /* Redux */
  const { sessionInfo: cookie } = useSelector((state: RootState) => state.auth);

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const getFileExtension = (filename: string) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
  };

  const { data: tagsList } = useTagsListQuery(
    {
      practiceId: cookie?.practice_id as number
    },
    { skip: !cookie?.practice_id }
  );
  const infoTag = tagsList?.find(t => t.name.toLowerCase() == "info");

  const renderFileContent = () => {
    const fileExtension = getFileExtension(title).toLowerCase();
    switch (fileExtension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <img src={url} alt="Displayed File" width={720} height={500} />;
      case "pdf":
        return type === "FILE" && anvilForgeSlug && !taskCompleted ? (
          <AnvilWorkflow
            taskId={taskId}
            anvilForgeSlug={anvilForgeSlug as string}
            anvilSubmissionEid={anvilSubmissionEid}
          />
        ) : (
          <InteractiveForm
            fileUrl={url}
            fileName={fileName}
            fileId={fileId}
            patientId={patientId}
            type={type}
            taskId={taskId}
            locationId={locationId}
            taskCompleted={taskCompleted}
            isInfo={
              tags &&
              infoTag &&
              !!tags.find(x => x == infoTag.tag_id.toString())
            }
          />
        );
      default:
        return <p>Unsupported file type.</p>;
    }
  };

  return (
    <div className={clsx(styles.ViewInteractiveFile)}>
      {renderFileContent()}
    </div>
  );
}

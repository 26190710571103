import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { skipToken } from "@reduxjs/toolkit/query";
import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Select, { SingleValue } from "react-select";

import Button from "@/components/button";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";
import ControlledOptionsField from "@/components/forms/generated/options";
import Input from "@/components/input";
import OptionsMenu from "@/components/optionsMenu";
import TextArea from "@/components/textArea";

import { STYLES } from "@/globals/constants";

import { EncounterId } from "@/store/services/encounter";
import {
  DevLaborEventUpsertApiArg,
  LaborEventUpsertEvent,
  LaborMedicationEntry,
  LaborProgressUpdateEvent,
  LaborProgressUpdateForm,
  PostpartumProgressUpdateForm,
  UserId,
  useDevLaborEventUpsertMutation
} from "@/store/services/patient";
import { usePracticeGetProvidersQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";

import { FORMAT } from "@/globals/helpers/formatters";

import Clock from "../../../../public/svgs/clock.svg";
import styles from "./styles.module.scss";

interface PostpartumProgressUpdateFormProps {
  encounterId: EncounterId;
}

type FormWithTimestamp = PostpartumProgressUpdateForm & {
  timestamp: string;
  author: UserId;
};
type FormPart = {
  label: string;
  id: keyof PostpartumProgressUpdateForm;
};

const temp_vitals: FormPart[] = [
  {
    label: "Heart rate",
    id: "maternal_heart_rate"
  },
  {
    label: "Blood pressure",
    id: "maternal_blood_pressure"
  },
  {
    label: "Temperature",
    id: "maternal_temperature"
  },
  {
    label: "Temperature Location",
    id: "maternal_temperature_location"
  },
  {
    label: "Maternal Position",
    id: "maternal_position"
  },
  {
    label: "Fundus",
    id: "fundus"
  },
  {
    label: "Lochia",
    id: "lochia"
  },
  {
    label: "Intake",
    id: "intake"
  },
  {
    label: "Output",
    id: "output"
  }
];

type MedicationFormPart = {
  label: string;
  id: keyof LaborMedicationEntry;
};

const maternal_medication: MedicationFormPart[] = [
  {
    label: "Recipient",
    id: "recipient"
  },
  {
    label: "Name",
    id: "name"
  },
  {
    label: "Dose",
    id: "dose"
  },
  {
    label: "Units",
    id: "units"
  },
  {
    label: "Route",
    id: "route"
  },
  {
    label: "Site Given",
    id: "site_given"
  },
  {
    label: "Lot Number",
    id: "lot_number"
  },
  {
    label: "Expiration",
    id: "expiration"
  },
  {
    label: "Indication",
    id: "indication"
  }
];

const infant_vitals: FormPart[] = [
  {
    label: "Heart Rate",
    id: "infant_heart_rate"
  },
  {
    label: "Respirations",
    id: "infant_respirations"
  },
  {
    label: "Temperature",
    id: "infant_temperature"
  },
  {
    label: "Temperature Location",
    id: "infant_temperature_location"
  },
  {
    label: "Pulse Ox",
    id: "pulse_oxygen"
  },
  {
    label: "Color",
    id: "color"
  },
  {
    label: "Nose Flaring",
    id: "nose_flaring"
  },
  {
    label: "Grunting",
    id: "grunting"
  },
  {
    label: "Retractions",
    id: "retractions"
  },
  {
    label: "Void",
    id: "void"
  },
  {
    label: "Meconium",
    id: "meconium"
  }
];

type ProviderOptionType = {
  label: string;
  value: number;
};

export default function PostpartumProgressUpdate({
  encounterId
}: PostpartumProgressUpdateFormProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [laborUpsert] = useDevLaborEventUpsertMutation();

  const { data: providers = [] } = usePracticeGetProvidersQuery(
    sessionInfo ? { practiceId: sessionInfo.practice_id } : skipToken
  );

  const form = useForm<FormWithTimestamp>();
  const { control, register, setValue } = form;

  const providerOptions: ProviderOptionType[] = useMemo(() => {
    return providers.map(provider => ({
      label: FORMAT.name(provider),
      value: provider.user_id
    }));
  }, [providers]);

  const onSubmit = (data: FormWithTimestamp) => {
    console.log(data);
    const { timestamp, author, ...forms } = data;

    const typedForms: LaborProgressUpdateForm = forms;

    const event: LaborProgressUpdateEvent = {
      event_id: Math.random() + "rand",
      timestamp: 3,
      author: author,
      forms: typedForms
    };

    const arg: DevLaborEventUpsertApiArg = {
      encounterId,
      stage: "LABOR",
      laborEvent: "Progress Update",
      laborEventUpsert: {
        event
      }
    };

    laborUpsert(arg)
      .unwrap()
      .then(val => console.log(val));
  };

  return (
    <form
      className={styles.ProgressUpdateForm}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className={clsx(styles.spanTwo, styles.columnContainer)}>
        <fieldset className={clsx(styles.bgLight)}>
          <legend>Entry Information</legend>
          {/* TODO: THIS CAN BE A COMPONENT SINCE IT'S SHARED VV */}
          <div className={styles.inputs}>
            <div className={styles.dateTimeContainer}>
              {/* NOTE: I'm using the native datetime picker because we're migrating
              away from pTime anyway and this is a much better option than what we had before */}
              <Input
                type="datetime-local"
                label="Entry time"
                id="timestamp"
                name="timestamp"
                register={register}
              />
            </div>
            <div className={styles.nowButtonContainer}>
              <button
                className={styles.nowButton}
                onClick={() => {
                  setValue(
                    "timestamp",
                    dayjs().format("YYYY-MM-DD[T]hh:mm:ss")
                  );
                }}
                type={"button"}
              >
                <Clock stroke={styles.primary700} width={16} height={16} />
                Now
              </button>
            </div>
            <Controller
              name="author"
              control={control}
              render={({ field: { onChange, value } }) => (
                <label className="standardLabel">
                  <span>Author:</span>
                  <Select
                    options={providerOptions}
                    value={
                      providerOptions.find(item => item.value === value) || null
                    }
                    onChange={(item: any) => onChange(item?.value)}
                    theme={SelectDefaultTheme}
                    styles={SelectDefaultStyles}
                  />
                </label>
              )}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Medication</legend>
          <div className={styles.inputs}>
            {maternal_medication.map(item => (
              <Input
                label={item.label as string}
                type="text"
                id={item.id}
                key={item.id}
                name={`medication.${item.id}`}
                placeholder={item.label}
                register={register}
              />
            ))}
          </div>
        </fieldset>
      </div>
      <div className={clsx(styles.spanFour, styles.columnContainer)}>
        <fieldset>
          <legend>Maternal Vitals & Assessment</legend>
          <div className={styles.inputs}>
            {temp_vitals.map(item => (
              <Input
                label={item.label as string}
                type="text"
                key={item.id}
                id={item.id}
                name={item.id}
                placeholder={item.label}
                register={register}
              />
            ))}
          </div>
        </fieldset>
        <fieldset>
          <legend>Infant Vitals & Assessment</legend>
          <div className={clsx(styles.inputs, styles.fitRow)}>
            {infant_vitals.map(item => (
              <Input
                label={item.label as string}
                type="text"
                id={item.id}
                key={item.id}
                name={item.id}
                placeholder={item.label}
                register={register}
              />
            ))}
          </div>
        </fieldset>
      </div>

      <fieldset className={styles.spanSix}>
        <legend>Notes / Comments</legend>
        <TextArea label="Notes" hiddenLabel name="notes" id="notes" rows={4} />
      </fieldset>
      <Button style={STYLES.FULL_WIDTH} type="submit">
        Add to flowsheet
      </Button>
    </form>
  );
}

/* Copy Fax to Paitent Modal Template */

// External
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";
import Select, { StylesConfig } from "react-select";
import { Controller, useForm } from "react-hook-form";

// components
import Button from "@/components/button";
import Input from "@/components/input";
import Icon from "@/components/icons";

// globals, constants, helpers
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";

// store
import { setModalIsOpen } from "../modalSlice";
import { ProviderInfo } from "@/store/services/patient";
import { PatientListItem } from "@/store/services/practice";
import { RootState } from "@/store/store";
import { usePatientGetListQuery, usePracticeSearchUsersQuery } from "@/store/services/practice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { useTagsListQuery } from "@/store/services/tag";
import { useTaskCreateMutation } from "@/store/services/task";
import {
  useLazyFileDetailsQuery,
  useFileUpdateMutation
} from "@/store/services/file";
import {
  FaxId,
  useFaxCopyToPatientMutation,
  useFaxInboundUpdateMutation
} from "@/store/services/fax";

// styles
import styles from "../styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import dayjs from "dayjs";

export interface SendFaxForReviewModalProps {
  faxId: FaxId;
}

export default function SendFaxForReview({
  faxId
}: SendFaxForReviewModalProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [updateFile, updateFileReq] = useFileUpdateMutation();
  const [copyFaxToPatient] =
    useFaxCopyToPatientMutation();
  const [updateFax] = useFaxInboundUpdateMutation();
  const [getFileDetails, { data: file }] = useLazyFileDetailsQuery();

  const [createTask, createTaskReq] = useTaskCreateMutation();

  const { data: users, isSuccess: gotUsers } = usePracticeSearchUsersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );
  const [patientQuery, setPatientQuery] = useState<string | undefined>();
  const { data: patientListing, isLoading: isLoadingPatients } = usePatientGetListQuery({query: patientQuery});

  const form = useForm<{
    select_patient: PatientOption | undefined;
    select_provider: ProviderInfo | null;
    file_name: string;
    file_tags: TagOption[];
  }>({});

  const { data: availableTags } = useTagsListQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );

  const {
    handleSubmit,
    control,
    reset
  } = form;

  // Memoized values
  const providerOptions = useMemo(() => {
    const allUsers = users?.providers.concat(users.medical_assistants);
    return allUsers?.map(user => ({
      label: FORMAT.name(user),
      value: user
    }));
  }, [users]);

  type TagOption = {
    label: string;
    value: number;
  };

  const tagOptions: TagOption[] | undefined = useMemo(() => {
    return availableTags?.map(tag => ({
      label: tag.name,
      value: tag.tag_id
    }));
  }, [availableTags]);

  type PatientOption = {
    value: PatientListItem;
    label: string;
  };

  const patientOptions: PatientOption[] = useMemo(() => {
    return (
      patientListing?.patients?.map(p => ({
        value: p,
        label: nameAndDOB(p)
      })) || []
    );
  }, [patientListing]);

  // populate the form with the selected file's name on open
  useMemo(() => {
    // file tags are in the shape of {tag_id: boolean}
    // so I have to do some really icky data transformations here to populate the default tags
    if (!file) return;
    const fileTags = tagOptions?.filter(tag =>
      Object.keys(file.tags)?.includes(tag.value.toString())
    );
    const patient = patientOptions?.find(
      p => p.value.user_id === file.patient_id
    );
    reset({
      select_patient: patient,
      select_provider: null,
      file_name: file.name,
      file_tags: fileTags
    });
  }, [file?.name, file?.tags, availableTags, patientOptions]);

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf(".");
    return lastDotIndex > -1 ? filename.substring(lastDotIndex + 1) : "";
  };

  const onSubmit = (data: any) => {
    copyFaxToPatient({
      patientId: data.select_patient.value.user_id,
      faxCopyToPatientRequest: {
        fax_ids: [faxId]
      }
    })
      .unwrap()
      .then(([fileId]) => {
        // then get the file details
        getFileDetails({ fileId })
          .unwrap()
          .then(file => {
            // create the DOCUMENT_REVIEW task assigned to the selected provider
            createTask({
              taskCreatePayload: {
                task_type: "DOCUMENT_REVIEW",
                staff_assignee: data.select_provider.user_id,
                associated_patient: data.select_patient.value.user_id,
                document_review: {
                  file_id: file.file_id,
                  fax_id: faxId
                }
              }
            })
              .unwrap()
              .then(_ => {
                // Log the update payload
                const updatePayload = {
                  fileId: file.file_id,
                  fileUpdateRequest: {
                    name: (data.file_name as string).trim() + "." + getFileExtension(file.name),
                    tags: data.file_tags?.map((tag: any) => tag.value),
                    patient_id: data.select_patient.user_id
                  }
                };

                updateFile(updatePayload)
                  .unwrap()
                  .then(_ => {
                    updateFax({
                      faxInboundUpdateRequest: {
                        sent_for_review_at: dayjs().toISOString(),
                        fax_ids: [faxId]
                      }
                    })
                      .unwrap()
                      .then(() => {
                        dispatch(
                          addAlertToToastTrough({
                            message: "Fax sent for review",
                            status: STATUS_KEYS.SUCCESS
                          })
                        );
                        dispatch(setModalIsOpen(false));
                      })
                      .catch(error => {
                        console.error("Failed to update fax", {
                          faxId,
                          error
                        });
                        dispatch(
                          addAlertToToastTrough({
                            message: "Failed to send fax for review",
                            status: STATUS_KEYS.ERROR
                          })
                        );
                      });
                  })
                  .catch(error => {
                    // More detailed error logging
                    console.error("Failed to update file", {
                      fileId: file.file_id,
                      error,
                      payload: updatePayload
                    });
                    dispatch(
                      addAlertToToastTrough({
                        message: "Failed to send fax for review",
                        status: STATUS_KEYS.ERROR
                      })
                    );
                  });
              })
              .catch(error => {
                console.error("Failed to create task for file", {
                  fileId: file.file_id,
                  error
                });
                dispatch(
                  addAlertToToastTrough({
                    message: "Failed to send fax for review",
                    status: STATUS_KEYS.ERROR
                  })
                );
              });
          })
          .catch(error => {
            console.error("Failed to get file details", {
              fileId,
              error
            });
            dispatch(
              addAlertToToastTrough({
                message: "Failed to send fax for review",
                status: STATUS_KEYS.ERROR
              })
            );
          });
      })
      .catch(error => {
        console.error("Failed to copy fax to patient", {
          patientId: data.select_patient.user_id,
          faxId,
          error
        });
        dispatch(
          addAlertToToastTrough({
            message: "Failed to send fax for review",
            status: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <form
      className={clsx(styles.SendFilesForReview)}
      onSubmit={handleSubmit(onSubmit)}
    >
      {gotUsers ? (
        <>
          <div className={styles.message}>
            <div>
              <p>Please select a patient to copy the fax to:</p>
              <Controller
                name="select_patient"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select<PatientOption>
                    isClearable
                    styles={SelectDefaultStyles as StylesConfig<PatientOption>}
                    theme={SelectDefaultTheme}
                    options={patientOptions}
                    value={value}
                    onChange={newValue => onChange(newValue)}
                    onInputChange={setPatientQuery}
                    required
                    isLoading={isLoadingPatients}
                  />
                )}
              />
            </div>
            <div>
              <p>Please select a provider to send fax to for review:</p>

              <Controller
                name="select_provider"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={providerOptions}
                    // @ts-ignore
                    value={providerOptions?.find(
                      item => item.value.user_id === value?.user_id
                    )}
                    //@ts-ignore
                    onChange={newValue => onChange(newValue?.value)}
                    required
                  />
                )}
              />
            </div>
            <div>
              <p>
                Rename the file:<sup>*</sup>
              </p>

              <Controller
                name="file_name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="text"
                    name="file_name"
                    id="file_name"
                    label="Change file name"
                    hiddenLabel
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    required
                  />
                )}
              />
            </div>
            <div>
              <p>Tags:</p>
              <Controller
                name="file_tags"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    isMulti
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={tagOptions}
                    value={value}
                    onChange={onChange}
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <Button style={STYLES.SECONDARY} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              style={STYLES.PRIMARY}
              type="submit"
              loading={createTaskReq.isLoading || updateFileReq.isLoading}
            >
              <Icon svg="check-done-wh" />
              Confirm
            </Button>
          </div>
        </>
      ) : (
        <Skeleton count={6} height={20} />
      )}
    </form>
  );
}

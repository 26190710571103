/* StaffPicker  */
/* External Imports */
import { useMemo } from "react";
import Select, { StylesConfig, GroupBase } from "react-select";
import { MultiValueRemoveProps, components } from "react-select";

/* Local Imports */

// components
import { FORMAT } from "@/globals/helpers/formatters";

// constants

// store
import { useStaffUserListQuery } from "@/store/services/staff";
import { StaffInfo } from "@/store/services/generated/staff";
import { UserId } from "@/store/services/user";

// styles
import {
  SelectCompactStyles,
  SelectCompactTheme,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

// Type the styles configuration
const typedSelectStyles: StylesConfig<
  StaffOptionType,
  true,
  GroupBase<StaffOptionType>
> = {
  ...SelectCompactStyles
} as StylesConfig<StaffOptionType, true, GroupBase<StaffOptionType>>;

export type StaffOptionType = {
  value: StaffInfo;
  label: string;
};

interface StaffMultiPickerProps {
  initialStaffIds?: UserId[];
  onUpdateStaff: (staffIds: UserId[]) => void;
  isCompact?: boolean;
  isClearable?: boolean;
  isSchedulableStaff?: boolean;
  // do not list logged in user in the picker
  excludeLoggedInUser?: boolean;
  // require at least one staff to be selected
  requireOneSelection?: boolean;
}

export default function StaffMultiPicker({
  initialStaffIds = [],
  onUpdateStaff,
  isCompact = false,
  isClearable = false,
  isSchedulableStaff,
  excludeLoggedInUser = false,
  requireOneSelection = false
}: StaffMultiPickerProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { data: staffList } = useStaffUserListQuery({
    isSchedulable: isSchedulableStaff,
    includeSuperadmins: false,
    includeInactive: false
  });

  // options to display as already picked
  const options = useMemo(() => {
    return (
      staffList?.map(staff => ({
        value: staff,
        label: FORMAT.name(staff)
      })) ?? []
    );
  }, [staffList]);

  // options to display for picking
  const filteredOptions = excludeLoggedInUser
    ? options.filter(option => option.value.user_id !== sessionInfo?.user_id)
    : options;

  // Customized remove button on the multi-value
  // component so we can disable removing the last value
  // when there must be at least one staff selected
  const MultiValueRemove = (
    props: MultiValueRemoveProps<StaffOptionType, true>
  ) => {
    const values = props.selectProps.value as StaffOptionType[];
    const isLastValue = values && values.length === 1;

    return (
      <components.MultiValueRemove
        {...props}
        innerProps={{
          ...props.innerProps,
          onClick: isLastValue ? undefined : props.innerProps.onClick,
          style: {
            ...props.innerProps.style,
            cursor: isLastValue ? "not-allowed" : "pointer",
            opacity: isLastValue ? 0.4 : 1
          }
        }}
      />
    );
  };

  return (
    <Select<StaffOptionType, true>
      isMulti
      options={filteredOptions}
      value={options.filter(option =>
        initialStaffIds.includes(option.value.user_id)
      )}
      onChange={selectedOptions => {
        onUpdateStaff(
          selectedOptions?.map(option => option.value.user_id) ?? []
        );
      }}
      components={requireOneSelection ? { MultiValueRemove } : undefined}
      hideSelectedOptions
      isClearable={isClearable}
      theme={isCompact ? SelectCompactTheme : SelectDefaultTheme}
      styles={isCompact ? typedSelectStyles : {}}
      placeholder="Select staff..."
    />
  );
}

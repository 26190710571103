/* OptionTile  */
/* External Imports */
import clsx from "clsx";

/* Local Imports */

// components
import Tag from "@/components/tag";
import Icon from "@/components/icons";

// constants
import { STATUS_KEYS } from "@/globals/constants";

// store

// styles
import styles from "./styles.module.scss";

/* OptionTile Typescript Interface */
interface OptionTileProps {
  title: string;
  description: string;
  notificationNumber?: number;
  isSelected?: boolean;
  svgName: string;
  onClick: () => void;
}

export default function OptionTile({
  title,
  description,
  notificationNumber,
  isSelected,
  svgName,
  onClick
}: OptionTileProps) {
  /* Redux */

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const handleClick = () => {
    onClick();
  };

  return (
    <button
      className={clsx(styles.OptionTile, { [styles.selected]: isSelected })}
      onClick={handleClick}
    >
      <div className={styles.content}>
        <div className={styles.title}>
          <Icon svg={svgName} width={24} height={24} />
          {title}
        </div>
        <div className={styles.description}>{description}</div>
      </div>
      {!!notificationNumber && (
        <Tag label={`${notificationNumber}`} type={STATUS_KEYS.WARNING} />
      )}
    </button>
  );
}

import * as generated from "./generated/conversation";

const conversation = generated.conversation.enhanceEndpoints({
  addTagTypes: ["Conversation"],
  endpoints: {
    listUserConversations: {
      providesTags: ["Conversation"]
    },
    listPracticeConversations: {
      providesTags: ["Conversation"]
    },
    createConversation: {
      invalidatesTags: ["Conversation"]
    },
    sendMessage: {
      invalidatesTags: ["Conversation"]
    },
    getConversationInfo: {
      providesTags: (_resp, _err, req) => {
        return [
          { type: "Conversation", id: req.conversationId }
        ];
      },
    },
    updateConversation: {
      invalidatesTags: (_resp, _error, req) => {
        return [
          "Conversation",
          { type: "Conversation", id: req.conversationId }
        ]
      }
    },
    deleteConversation: {
      invalidatesTags: (_resp, _error, req) => {
        return [
          "Conversation",
          { type: "Conversation", id: req.conversationId }
        ]
      }
    },
    bulkUpdateConversation: {
      invalidatesTags: (_resp, _error, req) => {
        return [
          "Conversation",
          ...req.conversationBulkUpdateParams.conversation_ids.map(id => ({
            type: "Conversation",
            id
          } as { type: "Conversation", id: string })),
        ]
      },
    },
    userGetUnreadConversations: {
      providesTags: ["Conversation"],
      keepUnusedDataFor: 0
    },
    practiceGetUnreadConversations: {
      providesTags: ["Conversation"],
      keepUnusedDataFor: 0
    }
  }
});

export * from "./generated/conversation";
export default conversation;
export const {
  useListUserConversationsQuery,
  useLazyListUserConversationsQuery,
  useListPracticeConversationsQuery,
  useLazyListPracticeConversationsQuery,
  useCreateConversationMutation,
  useSendMessageMutation,
  useGetConversationInfoQuery,
  useLazyGetConversationInfoQuery,
  useUpdateConversationMutation,
  useDeleteConversationMutation,
  useBulkUpdateConversationMutation,
  usePracticeGetUnreadConversationsQuery,
  useUserGetUnreadConversationsQuery
} = conversation;

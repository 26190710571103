import { useEffect, useState } from "react";

import { EncounterInfo } from "@/store/services/encounter";

type NoteTabProps = {
  encounter: EncounterInfo;
};

export default function useTranscriptTab({ encounter }: NoteTabProps) {
  const [initialSelectedTab, setInitialSelectedTab] = useState<
    null | "transcript" | "note"
  >(null);
  const [transcriptData, setTranscriptData] = useState<
    Array<Record<string, string | number | boolean>>
  >([]);

  // Set initial tab
  useEffect(() => {
    if (encounter.note !== "") {
      setInitialSelectedTab("note");
    } else {
      setInitialSelectedTab("transcript");
    }
  }, [encounter.note]);

  return {
    initialSelectedTab,
    transcriptData,
    setTranscriptData
  };
}

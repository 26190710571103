import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { SingleValue } from "react-select";

import Button from "@/components/button";
import Input from "@/components/input";
import Checkbox from "@/components/input/checkbox";
import { setModalIsOpen } from "@/components/modal/modalSlice";
import styles from "@/components/modal/styles.module.scss";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import {
  METRICS,
  METRIC_LABELS,
  STATUS_KEYS,
  STYLES
} from "@/globals/constants";

import {
  StaffInfo,
  StaffType,
  StaffUserCreateApiArg,
  StaffUserUpdateApiArg,
  useStaffUserCreateMutation,
  useStaffUserUpdateMutation
} from "@/store/services/generated/staff";

import { SelectDefaultTheme } from "@/styles/themes/selectDefaultTheme";

type SelectableStaffType = {
  value: StaffType;
  label: string;
};

export interface StaffUpsertProps {
  title: string;
  staff?: StaffInfo;
}

export default function StaffUpsert({ staff }: StaffUpsertProps) {
  const { register, handleSubmit, watch, control } = useForm<StaffInfo>({
    defaultValues: staff
  });

  const [updateStaff] = useStaffUserUpdateMutation();
  const [createStaff] = useStaffUserCreateMutation();

  const onUpdate = (updated: StaffInfo) => {
    const request: StaffUserUpdateApiArg = {
      staffId: staff?.user_id as number,
      staffUpdatePayload: { ...updated }
    };
    return updateStaff(request);
  };

  const onCreate = (created: StaffInfo) => {
    const request: StaffUserCreateApiArg = {
      staffCreatePayload: { ...created }
    };
    return createStaff(request);
  };

  const dispatch = useDispatch();
  const onSubmit: SubmitHandler<StaffInfo> = (data: StaffInfo) => {
    const updateFunc = staff ? onUpdate : onCreate;
    updateFunc(data)
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: staff ? "Staff updated" : "Staff created",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: staff
              ? "Failed to update staff"
              : "Failed to create staff",
            type: STATUS_KEYS.ERROR
          })
        );
      });
    dispatch(setModalIsOpen(false));
  };

  const selectableTypesForm: SelectableStaffType[] = [
    { label: METRIC_LABELS[METRICS.BILLER], value: "BILLER" },
    { label: METRIC_LABELS[METRICS.ADMIN], value: "ADMIN" },
    { label: METRIC_LABELS[METRICS.MA], value: "MA" },
    { label: METRIC_LABELS[METRICS.PROVIDER], value: "PROVIDER" }
  ];

  const isProviderStaffType = watch("type") === "PROVIDER";
  const isMAStaffType = watch("type") === "MA";

  const fieldSection1 = [
    {
      label: METRIC_LABELS[METRICS.FIRST_NAME],
      id: "first_name",
      type: "text",
      required: true
    },
    {
      label: METRIC_LABELS[METRICS.LAST_NAME],
      id: "last_name",
      type: "text",
      required: true
    },
    { label: METRIC_LABELS[METRICS.SUFFIX], id: "suffix", type: "text" },
    {
      label: METRIC_LABELS[METRICS.EMAIL],
      id: "email",
      type: "email",
      required: true
    },
    {
      label: METRIC_LABELS[METRICS.MOBILE],
      id: "mobile",
      type: "text",
      required: isProviderStaffType || isMAStaffType
    }
  ];

  const fieldSection2 = [
    {
      label: METRIC_LABELS[METRICS.DATE_OF_BIRTH],
      id: "dob",
      type: "date",
      required: true
    },
    {
      label: METRIC_LABELS[METRICS.ADDRESS_LINE_1],
      id: "addr_line_1",
      type: "text",
      required: isProviderStaffType || isMAStaffType
    },
    {
      label: METRIC_LABELS[METRICS.ADDRESS_LINE_2],
      id: "addr_line_2",
      type: "text"
    },
    {
      label: METRIC_LABELS[METRICS.CITY],
      id: "city",
      type: "text",
      required: isProviderStaffType || isMAStaffType
    },
    {
      label: METRIC_LABELS[METRICS.STATE],
      id: "state",
      type: "text",
      required: isProviderStaffType || isMAStaffType
    },
    {
      label: METRIC_LABELS[METRICS.ZIP],
      id: "zip",
      type: "text",
      required: isProviderStaffType || isMAStaffType
    }
  ];

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.StaffUpsertModal}>
      {fieldSection1.map(({ label, id, type, required }) => (
        <div key={id} className={styles.fieldWrapper}>
          <Input
            label={label}
            id={id}
            name={id as keyof StaffInfo}
            type={type}
            required={required || false}
            register={register}
          />
        </div>
      ))}
      <div className={styles.fieldWrapper}>
        <label htmlFor="type">Staff Type*</label>
        <Controller
          name="type"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              theme={SelectDefaultTheme}
              aria-label="Staff Type"
              options={selectableTypesForm}
              value={selectableTypesForm.find(op => op.value === value)}
              onChange={(newValue: SingleValue<SelectableStaffType>) => {
                if (newValue) {
                  onChange(newValue.value);
                }
              }}
            />
          )}
        />
      </div>

      {fieldSection2.map(({ label, id, type, required }) => (
        <div key={id} className={styles.fieldWrapper}>
          <Input
            label={label}
            id={id}
            name={id as keyof StaffInfo}
            type={type}
            required={required || false}
            register={register}
          />
        </div>
      ))}

      {
        // Need to get the NPI and DEA fields only for providers
        watch("type") === "PROVIDER" && (
          <>
            <div className={styles.fieldWrapper}>
              <Input
                label="NPI"
                id="npi"
                name="npi"
                type="text"
                required={true}
                register={register}
              />
            </div>
            <div className={styles.fieldWrapper}>
              <Input
                label="DEA"
                id="dea_id"
                name="dea_id"
                type="text"
                register={register}
              />
            </div>
          </>
        )
      }

      <div className={styles.fieldWrapper}>
        <Checkbox
          label="Is Schedulable"
          name="is_schedulable"
          id="is_schedulable"
          register={register}
        />
      </div>

      <div className={styles.fieldWrapper}>
        <Checkbox
          label="Is Billable"
          name="is_billable"
          id="is_billable"
          register={register}
        />
      </div>

      {
        // Only deactivate staff during update
        staff && (
          <div className={styles.fieldWrapper}>
            <Checkbox
              label="Is Active"
              name="is_active"
              id="is_active"
              register={register}
            />
          </div>
        )
      }
      <div className={styles.buttons}>
        <Button type="submit" style={STYLES.FULL_WIDTH}>
          {staff ? "Update Staff" : "Create Staff"}
        </Button>
      </div>
    </form>
  );
}

import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    messageSend: build.mutation<MessageSendApiResponse, MessageSendApiArg>({
      query: queryArg => ({
        url: `/messaging/send`,
        method: "POST",
        body: queryArg.messageSendRequest
      })
    }),
    messageRead: build.mutation<MessageReadApiResponse, MessageReadApiArg>({
      query: queryArg => ({
        url: `/messaging/${queryArg.messageId}/read`,
        method: "POST"
      })
    }),
    messageReply: build.mutation<MessageReplyApiResponse, MessageReplyApiArg>({
      query: queryArg => ({
        url: `/messaging/${queryArg.messageId}/reply`,
        method: "POST",
        body: queryArg.messageReplyRequest
      })
    }),
    messageList: build.query<MessageListApiResponse, MessageListApiArg>({
      query: queryArg => ({
        url: `/messaging/${queryArg.id}/${queryArg.scope}/list`,
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          pagesz: queryArg.pagesz
        }
      })
    }),
    messageInbox: build.query<MessageInboxApiResponse, MessageInboxApiArg>({
      query: queryArg => ({
        url: `/messaging/inbox`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    messageInboxUser: build.query<
      MessageInboxUserApiResponse,
      MessageInboxUserApiArg
    >({
      query: queryArg => ({
        url: `/messaging/inbox/user/${queryArg.userId}`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    }),
    messageChatList: build.mutation<
      MessageChatListApiResponse,
      MessageChatListApiArg
    >({
      query: queryArg => ({
        url: `/messaging/${queryArg.messageId}/chat`,
        method: "POST",
        params: {
          filter: queryArg.filter,
          page: queryArg.page,
          pagesz: queryArg.pagesz
        }
      })
    }),
    messageReadBy: build.query<MessageReadByApiResponse, MessageReadByApiArg>({
      query: queryArg => ({
        url: `/messaging/list/${queryArg.messageId}/read-by`
      })
    }),
    messageUnreadBy: build.query<
      MessageUnreadByApiResponse,
      MessageUnreadByApiArg
    >({
      query: queryArg => ({
        url: `/messaging/list/${queryArg.messageId}/unread-by`
      })
    }),
    messageFlagControl: build.mutation<
      MessageFlagControlApiResponse,
      MessageFlagControlApiArg
    >({
      query: queryArg => ({
        url: `/messaging/${queryArg.messageId}/flag`,
        method: "POST",
        body: queryArg.messageFlagRequest
      })
    }),
    messageListContacts: build.query<
      MessageListContactsApiResponse,
      MessageListContactsApiArg
    >({
      query: () => ({ url: `/messaging/list/contacts` })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as message };
export type MessageSendApiResponse =
  /** status 200 Sent in response to sending a message.
   */ LegacyMessageId;
export type MessageSendApiArg = {
  /** Data required to send a message to an individual or group.
   */
  messageSendRequest: MessageSendRequest;
};
export type MessageReadApiResponse =
  /** status 200 This is sent in reply to a message read request.
   */ MessageRead;
export type MessageReadApiArg = {
  messageId: LegacyMessageId;
};
export type MessageReplyApiResponse =
  /** status 200 Sent in response to replying to a message.
   */ LegacyMessageId;
export type MessageReplyApiArg = {
  messageId: LegacyMessageId;
  /** Data required to reply to a message either privately or to the original recipients.
   */
  messageReplyRequest: MessageReplyRequest;
};
export type MessageListApiResponse =
  /** status 200 Sent in response to a message list request. There are a number of ways the front-end can tailor this response
based on query parameters.
 */ MessageList;
export type MessageListApiArg = {
  id: Id;
  scope: MessageScope;
  filter?: MessageFilter;
  page?: Page;
  pagesz?: PageSize;
};
export type MessageInboxApiResponse =
  /** status 200 Sent in response to an inbox request.
   */ MessageInbox;
export type MessageInboxApiArg = {
  page?: Page;
  pagesz?: PageSize;
};
export type MessageInboxUserApiResponse =
  /** status 200 Sent in response to an inbox request.
   */ MessageInbox;
export type MessageInboxUserApiArg = {
  userId: UserId;
  page?: Page;
  pagesz?: PageSize;
};
export type MessageChatListApiResponse =
  /** status 200 Sent in response to a chat list request.
   */ MessageChat;
export type MessageChatListApiArg = {
  messageId: LegacyMessageId;
  filter?: MessageFilter;
  page?: Page;
  pagesz?: PageSize;
};
export type MessageReadByApiResponse =
  /** status 200 Sent in response to a message list request. There are a number of ways the front-end can tailor this response
based on query parameters.
 */ MessageList;
export type MessageReadByApiArg = {
  messageId: LegacyMessageId;
};
export type MessageUnreadByApiResponse =
  /** status 200 Sent in response to a message list request. There are a number of ways the front-end can tailor this response
based on query parameters.
 */ MessageList;
export type MessageUnreadByApiArg = {
  messageId: LegacyMessageId;
};
export type MessageFlagControlApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type MessageFlagControlApiArg = {
  messageId: LegacyMessageId;
  /** Adjust a messages flags, setting or unsetting any number of them.
   */
  messageFlagRequest: MessageFlagRequest;
};
export type MessageListContactsApiResponse =
  /** status 200 Sent in response to a message contact list request.
   */ MessageContactList;
export type MessageListContactsApiArg = void;
export type LegacyMessageId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type UserId = number;
export type UserIdList = UserId[];
export type GroupId = number;
export type GroupIdList = GroupId[];
export type FileId = number;
export type FileIds = FileId[];
export type MessageSendRequest = {
  recipients?: UserIdList;
  groups?: GroupIdList;
  /** The subject of a message.
   */
  subject: string;
  /** The contents of an actual message, limited to 64K fully JSON-escaped.
   */
  body: string;
  files?: FileIds;
  /** Whether or not read receipts must be generated. By default they always are, so the only meaningful
    value for this is `false` to disable that behavior.
     */
  receipts?: boolean;
  /** Set to `true` to automatically flag this message as important.
   */
  important?: boolean;
};
export type MessageRead = {
  /** Unique ID for each message created by the system.
   */
  message_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** The subject of a message.
   */
  subject: string;
  /** The contents of an actual message, limited to 64K fully JSON-escaped.
   */
  body: string;
  /** Unique ID for each message created by the system.
   */
  root: number;
  /** Unique ID for each message created by the system.
   */
  parent: number;
  files?: FileIds;
};
export type MessageReplyRequest = {
  /** Set to `true` if this is a private reply sent directly to the sender, and not to the same set of
    recipients as the original message was. If the original message was private setting this has no
    effect.
     */
  private?: boolean;
  /** The contents of an actual message, limited to 64K fully JSON-escaped.
   */
  body: string;
};
export type MessageListItem = {
  /** Unique ID for each message created by the system.
   */
  message_id: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  author: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** The subject of a message.
   */
  subject: string;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  created: number;
  /** Number of times the message has been replied to.
   */
  replies?: number;
  /** Unique ID for each message created by the system.
   */
  parent: number;
  /** Unique ID for each message created by the system.
   */
  root: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  first_read: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  last_read: number;
  /** System level and defined flags. Very little should ever see let alone be able to set these.
   */
  flags: number;
};
export type MessageList = MessageListItem[];
export type Id = number;
export type MessageScope = "to" | "from" | "thread";
export type MessageFilterType =
  | "all"
  | "unread"
  | "recent"
  | "deleted"
  | "important"
  | "archived"
  | "task";
export type MessageFilter = MessageFilterType[];
export type Page = number;
export type PageSize = number;
export type MessageInboxItem = {
  /** Unique ID for each message created by the system.
   */
  m: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  a: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  c: number;
  /** The subject of a message.
   */
  s: string;
  /** Number of times the message has been replied to.
   */
  r: number;
  u: UserIdList;
  unread: number;
};
export type MessageInboxList = MessageInboxItem[];
export type RecipientInfoList = {
  [key: string]: string;
};
export type MessageInbox = {
  messages: MessageInboxList;
  recipients: RecipientInfoList;
};
export type MessageChatItem = {
  /** Unique ID for each message created by the system.
   */
  i: number;
  /** Unique ID for each message created by the system.
   */
  p?: number;
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  a: number;
  /** A Pario timestamp in UTC represented as an integer in the form YYYYMMDDhhmmss.
   */
  t: number;
  f?: FileIds;
  c: string;
};
export type MessageChatList = MessageChatItem[];
export type MessageChat = {
  recipients: RecipientInfoList;
  messages: MessageChatList;
  /** The subject of a message.
   */
  subject: string;
};
export type ErrorId = number;
export type MessageFlagRequest = string;
export type MessageContact = {
  /** System-assigned unique user identifier. Used as a linkage between most tables. This is the ID that identifies
    an individual human. It is not repeated in the system even if the person belongs to more than one practice.
     */
  user_id: number;
  /** Optional name title (Dr., Sir, The Hon., Rev., Lady etc).
   */
  title?: string | null;
  /** A user's first name. This only applies to humans, not practice or location names.
   */
  first_name: string;
  /** A user's last name. This only applies to humans, not practice or location names.
   */
  last_name: string;
  /** A Pario date in YYYYMMDD format.
   */
  dob: number;
  /** Optional name suffix (Jr., III etc).
   */
  suffix?: string | null;
  /** A yes/no or true/false value.
   */
  is_patient: boolean;
};
export type MessageContactList = MessageContact[];
export const {
  useMessageSendMutation,
  useMessageReadMutation,
  useMessageReplyMutation,
  useMessageListQuery,
  useLazyMessageListQuery,
  useMessageInboxQuery,
  useLazyMessageInboxQuery,
  useMessageInboxUserQuery,
  useLazyMessageInboxUserQuery,
  useMessageChatListMutation,
  useMessageReadByQuery,
  useLazyMessageReadByQuery,
  useMessageUnreadByQuery,
  useLazyMessageUnreadByQuery,
  useMessageFlagControlMutation,
  useMessageListContactsQuery,
  useLazyMessageListContactsQuery
} = injectedRtkApi;

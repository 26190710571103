import { useEffect, useMemo, useRef, useState } from "react";

import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import Button from "@/components/button";
import Icon from "@/components/icons";
import AssignGenericStaffTask from "@/components/modal/triggers/assignGenericStaffTask";
import PatientPicker from "@/components/patientPicker";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import ViewBody from "@/components/viewBody";

import { CreateCaseCTA } from "@/modules/Cases/CreateCaseCTA";

import { STATUS_KEYS, STYLES } from "@/globals/constants";

import {
  useFileDetailsQuery,
  useFileUpdateMutation
} from "@/store/services/file";
import { UserId, usePatientGetInfoQuery } from "@/store/services/patient";
import { useLazyPatientGetListQuery } from "@/store/services/practice";
import { useStaffUserListQuery } from "@/store/services/staff";
import { useGetFeatureFlagsQuery } from "@/store/services/system";
import {
  TaskId,
  useTaskCompleteMutation,
  useTaskInfoQuery,
  useTaskMarkIncompleteMutation,
  useTaskUpdateMutation
} from "@/store/services/task";
import { RootState } from "@/store/store";

import { formatPhone, nameAndDOB } from "@/globals/helpers/formatters";

import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";

import styles from "../styles.module.scss";

interface DocumentReviewProps {
  url: string;
  fileName: string;
  fileId: number;
  patientId: UserId;
  taskId: TaskId;
}

export default function DocumentReview({
  url,
  fileName,
  fileId,
  patientId,
  taskId
}: DocumentReviewProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { sessionInfo: cookie } = useSelector((state: RootState) => state.auth);

  /* Local State */
  const titleRef = useRef<HTMLHeadingElement>(null);
  const [isTogglingReviewed, setIsTogglingReviewed] = useState(false);
  const [isTogglingReleased, setIsTogglingReleased] = useState(false);
  const [selectedPatientID, setSelectedPatientID] = useState<UserId>();

  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const { data: taskInfo } = useTaskInfoQuery({ taskId }, { skip: !cookie });
  const { data: patient, isLoading: isLoadingSelectedPatient } =
    usePatientGetInfoQuery(
      { patientId: selectedPatientID as UserId },
      { skip: !cookie || !selectedPatientID }
    );
  const {
    data: fileInfo,
    refetch: reloadFile,
    isLoading: loadingFile
  } = useFileDetailsQuery({ fileId }, { skip: !cookie });

  // Get list of patients
  const [listPatients, { data: patientListing, isLoading: isLoadingPatients }] =
    useLazyPatientGetListQuery();
  const patients = useMemo(
    () =>
      (patientListing?.patients || []).map(p => {
        return {
          value: p,
          label: nameAndDOB(p)
        };
      }),
    [patientListing]
  );

  // Get list of staff
  const { data: staffList, isLoading: isLoadingStaff } = useStaffUserListQuery({
    includeInactive: false,
    includeSuperadmins: false
  });
  const staff = useMemo(
    () =>
      (staffList || []).map(s => {
        return {
          value: s,
          label: `${s.first_name} ${s.last_name} (${s.type})`
        };
      }),
    [staffList]
  );

  const [updateTask] = useTaskUpdateMutation();
  const [updateFile] = useFileUpdateMutation();
  const [completeTask] = useTaskCompleteMutation();
  const [markIncompleteTask] = useTaskMarkIncompleteMutation();

  /* Effects */
  useEffect(() => {
    // Set the selected patient ID on mount
    setSelectedPatientID(patientId);
    // remove the selection on unmount
    return () => {
      setSelectedPatientID(undefined);
    };
  }, [patientId]);

  /* Event Handlers */
  const getFileExtension = (filename: string) => {
    const lastDotIndex = filename.lastIndexOf(".");
    return lastDotIndex > -1 ? filename.substring(lastDotIndex + 1) : "";
  };

  const fileExtension = getFileExtension(fileName).toLowerCase();
  const renderFileContent = () => {
    switch (fileExtension) {
      case "jpg":
      case "jpeg":
      case "png":
      case "gif":
        return <img src={url} alt="Displayed File" width={720} height={500} />;
      case "pdf":
        return <embed src={url} width="100%" height="100%" />;
      default:
        return <p>Unsupported file type: {fileExtension}</p>;
    }
  };

  const selectStaffAssignee = async (staff_assignee: UserId) => {
    try {
      await updateTask({
        taskId,
        taskUpdatePayload: {
          staff_assignee
        }
      });
    } catch (err) {
      console.error(err);
      dispatch(
        addAlertToToastTrough({
          message: "Failed to reassign",
          type: STATUS_KEYS.ERROR
        })
      );
    }

    dispatch(
      addAlertToToastTrough({
        message: "Reassigned",
        type: STATUS_KEYS.SUCCESS
      })
    );
  };

  const handleSelectPatient = (newPatientId: UserId | null | undefined) => {
    if (newPatientId === null) {
      newPatientId = undefined;
    }

    setSelectedPatientID(newPatientId);

    // TODO: Move this to a single transaction on the server, so that it can be
    // safely rolled back if one of the updates fails.

    // Change the patient associated with the task
    updateTask({
      taskId,
      taskUpdatePayload: {
        associated_patient: newPatientId
      }
    })
      .then(() =>
        // Move the file to the new patient
        updateFile({
          fileId,
          fileUpdateRequest: {
            name: fileName,
            patient_id: newPatientId,
            tags: Object.keys(fileInfo?.tags || []).map(tag => parseInt(tag)) // I hate this
          }
        })
      )
      .then(() =>
        // Show a success message
        dispatch(
          addAlertToToastTrough({
            message: "Patient updated",
            type: STATUS_KEYS.SUCCESS
          })
        )
      )
      .catch(err => {
        // Show an error message
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to change patient",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const toggleReleasedToPatient = async () => {
    setIsTogglingReleased(true);

    const isFileHiddenFromPatient = !fileInfo?.viewable_by_patient;

    try {
      await updateFile({
        fileId,
        fileUpdateRequest: {
          name: fileName,
          patient_id: selectedPatientID,
          tags: Object.keys(fileInfo?.tags || []).map(tag => parseInt(tag)), // I hate this
          viewable_by_patient: isFileHiddenFromPatient
        }
      }).unwrap();

      await reloadFile();

      dispatch(
        addAlertToToastTrough({
          message: isFileHiddenFromPatient
            ? "File released to patient"
            : "File revoked from patient",
          type: STATUS_KEYS.SUCCESS
        })
      );
    } catch (err) {
      console.error(err);
      dispatch(
        addAlertToToastTrough({
          message: "Failed to release file patient",
          type: STATUS_KEYS.ERROR
        })
      );
    }

    setIsTogglingReleased(false);
  };

  const toggleReviewed = async () => {
    setIsTogglingReviewed(true);

    if (taskInfo?.completed_at) {
      try {
        await markIncompleteTask({ taskId }).unwrap();

        await reloadFile();

        dispatch(
          addAlertToToastTrough({
            message: "File marked as not reviewed",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (err) {
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to mark file as not reviewed",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    } else {
      try {
        await completeTask({ taskId }).unwrap();

        await reloadFile();

        dispatch(
          addAlertToToastTrough({
            message: "File marked as reviewed",
            type: STATUS_KEYS.SUCCESS
          })
        );
      } catch (err) {
        console.error(err);
        dispatch(
          addAlertToToastTrough({
            message: "Failed to mark file as reviewed",
            type: STATUS_KEYS.ERROR
          })
        );
      }
    }

    setIsTogglingReviewed(false);
  };

  const handleTitleChange = async () => {
    if (!titleRef.current) return;

    const newName = titleRef.current.textContent?.trim();
    if (!newName || newName + "." + getFileExtension(fileName) === fileName)
      return;

    try {
      await updateFile({
        fileId,
        fileUpdateRequest: {
          name: newName + "." + getFileExtension(fileName),
          patient_id: selectedPatientID,
          tags: Object.keys(fileInfo?.tags || []).map(tag => parseInt(tag)),
          viewable_by_patient: fileInfo?.viewable_by_patient
        }
      }).unwrap();

      dispatch(
        addAlertToToastTrough({
          message: "Filename updated successfully",
          type: STATUS_KEYS.SUCCESS
        })
      );
    } catch (err) {
      console.error(err);
      // Reset to original filename
      if (titleRef.current) {
        titleRef.current.textContent = fileName.replaceAll(
          "." + getFileExtension(fileName),
          ""
        );
      }
      dispatch(
        addAlertToToastTrough({
          message: "Failed to update filename",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  };

  return (
    <div className={styles.DocumentReview}>
      <div className={styles.header}>
        <div className={styles.title}>
          <h1
            className={styles.editableTitle}
            contentEditable="true"
            spellCheck="true"
            role="textbox"
            aria-label="Document title"
            translate="no"
            ref={titleRef}
            onBlur={handleTitleChange}
            onKeyDown={e => {
              if (e.key === "Enter") {
                e.preventDefault();
                e.currentTarget.blur();
              }
            }}
          >
            {fileName.replaceAll("." + getFileExtension(fileName), "")}
          </h1>
          <div className={styles.editIcon}>
            <Icon svg="edit" />
          </div>
        </div>

        <div className={styles.actions}>
          <div className={styles.field}>
            <label>Assignee</label>
            <Select
              theme={SelectDefaultTheme}
              styles={SelectDefaultStyles}
              id="assignee"
              placeholder="Select a staff member"
              options={staff}
              onChange={v => {
                if (v) {
                  selectStaffAssignee(
                    (v as (typeof patients)[number]).value.user_id
                  );
                }
              }}
              value={staff.find(
                s => s.value.user_id === taskInfo?.assignee.user_id
              )}
              isLoading={isLoadingStaff}
            />
          </div>

          <div className={styles.field}>
            <label>Patient</label>
            <div className={styles.patientSelect}>
              <PatientPicker
                onUpdatePatient={handleSelectPatient}
                initialPatientIds={selectedPatientID ? [selectedPatientID] : []}
                isCompact={false}
              />
              {selectedPatientID && patient?.phone && (
                <p className={styles.phone}>
                  Patient Phone #: {formatPhone(patient.phone)}
                </p>
              )}
            </div>
          </div>

          <Button
            onClick={() => {
              window.open(`/patients/${selectedPatientID}`, "_blank");
            }}
            nativeButtonProps={{ disabled: !selectedPatientID }}
            loading={isLoadingSelectedPatient}
          >
            <Icon svg="user-wh" />
            Patient Chart
          </Button>
          <Button
            onClick={toggleReviewed}
            loading={isTogglingReviewed}
            style={taskInfo?.completed_at ? STYLES.SECONDARY : STYLES.PRIMARY}
          >
            <Icon
              svg={
                taskInfo?.completed_at ? "x_failure_outline" : "check-done-wh"
              }
            />
            {taskInfo?.completed_at ? "Mark Unreviewed" : "Mark Reviewed"}
          </Button>
          <Button
            onClick={toggleReleasedToPatient}
            loading={isTogglingReleased}
          >
            <Icon svg={fileInfo?.viewable_by_patient ? "hide-wh" : "eye-wh"} />
            {!loadingFile && fileInfo && fileInfo.viewable_by_patient
              ? "Revoke Patient Access"
              : "Release to Patient"}
          </Button>
          <AssignGenericStaffTask associatedPatientID={selectedPatientID} />
          {featureFlags?.dev_cases && (
            <CreateCaseCTA patientId={selectedPatientID} />
          )}
        </div>
      </div>

      <ViewBody fileId={fileId}>
        <div
          className={clsx(styles.body, {
            [styles.hasBackground]: fileExtension !== "pdf"
          })}
        >
          {renderFileContent()}
        </div>
      </ViewBody>
    </div>
  );
}

// external
import clsx from "clsx";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  BaseFlowSheetEntry,
  FlowSheetConfig
} from "@/components/flowSheet/types";
import EncounterDataFlowSheet from "@/components/flowSheet/EncounterDataFlowSheet";
import { convertUtcIntToLocalDate } from "@/components/scheduling/calendars/utils";
// store
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// constants
import { PAGES, STATUS_KEYS } from "@/globals/constants";

import {
  useEncounterInfoQuery
} from "@/store/services/encounter";
import { PatientInfo, Pregnancy } from "@/store/services/patient";
import {
  PrenatalFlowSheetEntry,
  usePregnancyUpsertMutation
} from "@/store/services/pregnancy";

// utils
import { getGestationalAgeObjectAtDate } from "@/globals/helpers";

// styles
import styles from "./styles.module.scss";

interface PrenatalFlowSheetEntryInterface extends BaseFlowSheetEntry {
  weeks?: string;
  days?: string;
  blood_pressure?: string;
  weight?: number;
  fundal_height?: string;
  fetal_heart_tones?: string;
  fetal_position?: string;
  fetal_activity?: string;
  edema?: string;
  pulse?: number;
  encounter_start?: number;
}

const prenatalFlowSheetConfig: FlowSheetConfig<PrenatalFlowSheetEntryInterface> =
  {
    fields: {
      weeks: {
        type: "displayString",
        label: "Weeks"
      },
      days: {
        type: "displayString",
        label: "Days"
      },
      encounter_start: {
        type: "pTime",
        label: "Date"
      },
      blood_pressure: {
        type: "string",
        label: "Blood Pressure"
      },
      weight: {
        type: "number",
        label: "Weight (lbs)"
      },
      fundal_height: {
        type: "string",
        label: "Fundal Height"
      },
      fetal_heart_tones: {
        type: "string",
        label: "Fetal Heart Tones"
      },
      fetal_position: {
        type: "string",
        label: "Fetal Position"
      },
      fetal_activity: {
        type: "string",
        label: "Fetal Activity"
      },
      edema: {
        type: "string",
        label: "Edema"
      },
      pulse: {
        type: "number",
        label: "Pulse"
      },
      note: {
        type: "note",
        label: "Note"
      }
    }
  };

export interface PrenatalFlowSheetProps {
  patient: PatientInfo;
  pregnancy: Pregnancy;
  isReadOnly?: boolean;
  encounterId?: number;
}
export default function PrenatalEncounterFlowSheet({
  patient,
  pregnancy,
  isReadOnly,
  encounterId
}: PrenatalFlowSheetProps) {
  const dispatch = useDispatch();
  const router = useRouter();

  const [updatePregnancy, updatePregnancyRequest] =
    usePregnancyUpsertMutation();
  const { data: encounter } = useEncounterInfoQuery(
    {
      encounterId: encounterId as number
    },
    { skip: !encounterId }
  );

  const data: PrenatalFlowSheetEntryInterface[] = useMemo(() => {
    // Get flowsheet data from pregnancy as FlowSheet type
    const flowSheetData = [...pregnancy.flow_sheet];
    // Sort the entries
    flowSheetData.sort(
      (a, b) => (b.encounter_start as number) - (a.encounter_start as number)
    );
    // Map flowsheet data to our interface type
    const flowSheetInterfaceData = flowSheetData.map(entry => {
      const newEntry: PrenatalFlowSheetEntryInterface = {
        ...entry,
        encounterId: (entry.encounter_id || encounterId) as number
      };
      return newEntry;
    });
    return flowSheetInterfaceData;
  }, [pregnancy, encounter]);

  // Effects
  useEffect(() => {
    if (updatePregnancyRequest.isError) {
      dispatch(
        addAlertToToastTrough({
          message: "Failed to update prenatal flow sheet",
          type: STATUS_KEYS.ERROR
        })
      );
    }
  }, [updatePregnancyRequest]);

  // Event handlers
  const submit = (formData: PrenatalFlowSheetEntryInterface) => {
    const newEntry: PrenatalFlowSheetEntry = {
      weeks: formData["weeks"],
      days: formData["days"],
      blood_pressure: formData["blood_pressure"],
      weight: Number(formData.weight) || undefined,
      fundal_height: formData["fundal_height"],
      fetal_heart_tones: formData["fetal_heart_tones"],
      fetal_position: formData["fetal_position"],
      fetal_activity: formData["fetal_activity"],
      edema: formData["edema"],
      pulse: Number(formData["pulse"]) || undefined,
      encounter_id: encounterId
    };

    const entryIndex = (pregnancy.flow_sheet || []).findIndex(
      entry => entry.encounter_id == encounterId
    );

    let flowSheet = [...pregnancy.flow_sheet];
    if (entryIndex === -1) {
      flowSheet = [newEntry, ...flowSheet];
    } else {
      flowSheet[entryIndex] = newEntry;
    }

    updatePregnancy({
      patientId: patient.user_id,
      pregnancyUpsertRequest: {
        pregnancy_id: patient.pregnancy?.pregnancy_id,
        flow_sheet: flowSheet
      }
    });
  };

  const defaultValues = useMemo(() => {
    if (!encounter) {
      return {};
    }

    const currentEntry = pregnancy.flow_sheet.find(
      entry => entry.encounter_id == encounter.encounter_id
    );
    const defaults = {
      ...currentEntry,
      encounter_start: (encounter?.start || encounter?.appointment?.starts || encounter?.created),
    };

    if (
      pregnancy.edd?.estimated_due_date &&
      (encounter?.start || encounter?.appointment?.starts || encounter?.created)
    ) {
      // TODO: Needs refactor
      const gestAgeObj = getGestationalAgeObjectAtDate(
        parseInt(dayjs(pregnancy.edd.estimated_due_date).format("YYYYMMDD")),
        parseInt(
          convertUtcIntToLocalDate(
            encounter?.start ||
              encounter?.appointment?.starts ||
              encounter?.created
          ).format("YYYYMMDD")
        )
      );
      defaults["weeks"] = String(gestAgeObj.weeks);
      defaults["days"] = String(gestAgeObj.days);
    }
    return defaults;
  }, [encounter]);

  return (
    <div
      className={clsx(styles.PrenatalFlowSheet)}
      data-cy="prenatal-flowsheet"
    >
      <EncounterDataFlowSheet<PrenatalFlowSheetEntryInterface>
        entries={data}
        config={prenatalFlowSheetConfig}
        title="Prenatal flow sheet"
        isReadOnly={isReadOnly}
        encounterId={encounterId as number}
        submitOnBlur
        onRowSubmit={submit}
        onRowNavigate={encounterId => {
          router.push(`${PAGES.ENCOUNTERS}/${encounterId}`);
        }}
        navigateButtonTitle="View encounter"
        defaultValues={defaultValues}
      />
    </div>
  );
}

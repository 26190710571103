/* PatientMedicalState  */
/* External Imports */
import React from "react";

// components
import PatientUpsertSelect from "@/components/facePage/patientMedicalState/patientUpsertSelect";
import PregnancyUpsertSelect from "@/components/facePage/patientMedicalState/pregnancyUpsertSelect";
import Input from "@/components/input";

/* Local Imports */
// constants
import { METRICS } from "@/globals/constants";

// store
import { PatientInfo } from "@/store/services/patient";

// styles
import styles from "./styles.module.scss";

import PregnancyUpsertInput from "./pregnancyUpsertInput";

/* PatientMedicalState Typescript Interface */
export interface PatientMedicalStateProps {
  patient: PatientInfo;
}

export default function PatientMedicalState({
  patient
}: PatientMedicalStateProps) {
  const activePregnantPatientType = ![
    METRICS.INFANT,
    METRICS.POSTPARTUM
  ].includes(patient.practice_data.type);
  const isInfant = patient.practice_data.type === METRICS.INFANT;

  return (
    <div className={styles.PatientMedicalState}>
      {/* Always visible fields */}
      <PatientUpsertSelect patient={patient} field="type" label="Patient" />
      <PatientUpsertSelect patient={patient} field="blood_type" label="Blood" />

      {/* Pregnancy-specific fields */}
      {/* leaving this condition outside of a variable
      because TS can't infer the type of patient.pregnancy when its stored in a variable */}
      {activePregnantPatientType && patient.pregnancy && (
        <>
          <PregnancyUpsertSelect
            pregnancy={patient.pregnancy}
            patientId={patient.user_id}
            field="gbs_status"
            label="GBS"
          />
          <PregnancyUpsertSelect
            pregnancy={patient.pregnancy}
            patientId={patient.user_id}
            field="desired_birthplace"
            label="Birthplace"
          />
        </>
      )}
      {/* leaving this condition outside of a variable
      because TS can't infer the type of patient.pregnancy when its stored in a variable */}
      {!isInfant && patient.pregnancy && (
        <>
          <PregnancyUpsertSelect
            pregnancy={patient.pregnancy}
            patientId={patient.user_id}
            field="fetal_sex"
            label="Fetal sex"
          />
          <PregnancyUpsertInput
            pregnancy={patient.pregnancy}
            patientId={patient.user_id}
            field="pre_pregnancy_weight"
            label="PPW"
            title="Pre-pregnancy weight"
          />
        </>
      )}

      {/* Infant-specific fields */}
      {isInfant && (
        <>
          <PatientUpsertSelect
            patient={patient}
            field="antibody_screen_DAT"
            label="DAT"
          />
          <PatientUpsertSelect patient={patient} field="met_1" label="Met 1" />
          <PatientUpsertSelect patient={patient} field="met_2" label="Met 2" />
        </>
      )}
    </div>
  );
}

/* PatientID Name */
/* External Imports */
import clsx from "clsx";
import { useDispatch } from "react-redux";

/* Local Imports */

import AvatarPlaceholder from "@/components/avatarPlaceholder";
import Icon from "@/components/icons";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";

import { formatNumberWithDashes } from "@/utils/strings";

import { PatientInfo } from "@/store/services/patient";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

import { FORMAT } from "@/globals/helpers/formatters";

import styles from "./styles.module.scss";

/* PatientID Typescript Interface */
interface PatientIDProps {
  patient: PatientInfo;
}

export default function PatientID({ patient }: PatientIDProps) {
  /* Redux */
  const dispatch = useDispatch();
  const { data: featureFlags } = useGetFeatureFlagsQuery();

  /* Local State */
  const avatarCharacter = patient.first_name
    ? patient?.first_name?.[0]
    : "" + patient?.last_name?.[0];
  const showPronouns = featureFlags?.pronouns_enabled && patient.pronouns;

  /* Effects */

  /* Event Handlers */
  const handleOpenSecretNote = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.SECRET_NOTE,
        props: { patient, title: "Private Note" }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  return (
    <div className={clsx(styles.PatientID)}>
      <AvatarPlaceholder
        isLarge
        userId={patient.user_id}
        character={avatarCharacter}
      />
      <div className={styles.headerData}>
        <div className={styles.nameNote}>
          <div>
            <h4>{FORMAT.name(patient)}</h4>
            {showPronouns && <h5>{patient.pronouns}</h5>}
          </div>

          <button
            type="button"
            onClick={handleOpenSecretNote}
            className={styles.privateNote}
          >
            <Icon
              svg={
                patient.secret_note && !/^<p><\/p>$/i.test(patient.secret_note) // this takes care of the case when the user has deleted all the content but the p tag is still there
                  ? "secret_note"
                  : "sticker-square"
              }
              width={20}
              height={20}
            />
          </button>
        </div>
        <p className="t5 xLight flex center">
          ID: # {formatNumberWithDashes(patient.user_id, 3, 9)}
          {patient.signup_completed && (
            <Icon
              svg="badge_check"
              width={14}
              height={14}
              title="Signup completed"
            />
          )}
        </p>
      </div>
    </div>
  );
}

/* ReadGenericStaffTask Name */
/* External Imports */
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { sanitize } from "dompurify";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";

/* Local Imports */

// components
import Button from "@/components/button";
import PatientPicker from "@/components/patientPicker";
import Tag from "@/components/tag";
import StaffPicker from "@/components/staffPicker";
import ContentRenderer from "@/components/textArea/contentRenderer";
import Icon from "@/components/icons";

// constants
import { STATUS_KEYS, STYLES, PAGES } from "@/globals/constants";
// store
import {
  useTaskUpdateMutation,
  useTaskCompleteMutation,
  TaskId,
  useTaskInfoQuery
} from "@/store/services/task";
import { setModalIsOpen } from "../../modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { UserId } from "@/store/services/patient";
import { setRightPaneOpen } from "@/components/drawer/drawerSlice";

// styles
import styles from "./styles.module.scss";

// utils
import { FORMAT, formatPhone } from "@/globals/helpers/formatters";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";
import { normalizePhoneNumber } from "@/globals/helpers";

/* ReadGenericStaffTask Typescript Interface */
interface ReadGenericStaffTaskProps {
  taskId: TaskId;
}

export default function ReadGenericStaffTask({
  taskId
}: ReadGenericStaffTaskProps) {
  /* Redux */
  const dispatch = useDispatch();

  const { data: task, isLoading } = useTaskInfoQuery({ taskId });
  const [updateTask] = useTaskUpdateMutation();
  const [completeTask, { isLoading: isCompleting }] = useTaskCompleteMutation();

  /* Local State */

  /* Effects */

  /* Event Handlers */

  const handleUpdateLinkedPatient = (patientId: UserId | null) => {
    updateTask({
      // assert task is not null since this handler
      // should only be called when task is loaded
      taskId: task!.task_id,
      taskUpdatePayload: {
        associated_patient: patientId ? patientId : null
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Task updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to update task",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleUpdateStaff = (staffId: UserId) => {
    updateTask({
      // assert task is not null since this handler
      // should only be called when task is loaded
      taskId: task!.task_id,
      taskUpdatePayload: {
        staff_assignee: staffId
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Task updated",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to update task",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCompleteTask = () => {
    completeTask({
      // assert task is not null since this handler
      // should only be called when task is loaded
      taskId: task!.task_id
    }).then(() => {
      dispatch(setRightPaneOpen(false));
    });
  };

  const handleOpenChart = () => {
    // open patient chart in new window
    // assert task is not null since this handler
    // should only be called when task is loaded
    window.open(
      `${PAGES.PATIENTS}/${task!.associated_patient?.user_id}`,
      "_blank"
    );
  };

  return (
    <div className={clsx(styles.ReadGenericStaffTask)}>
      {isLoading && <Skeleton count={5} />}
      {task && (
        <div className={styles.container}>
          <h4 className="t1 smBld">{task.title}</h4>
          <Tag
            label={task.completed_at ? "Completed" : "Pending"}
            type={task.completed_at ? STATUS_KEYS.SUCCESS : STATUS_KEYS.WARNING}
            customIconSvgPath={
              task.completed_at ? "check_success_outline" : "warning"
            }
          />

          {task.completed_at ? (
            <div className={styles.group}>
              <p className="gray700">Completed on</p>
              <p className="xLight">
                {convertUtcIntToLocalDatetime(task.completed_at).format(
                  "MM/DD/YY"
                )}
              </p>
            </div>
          ) : (
            <div className={styles.group}>
              <p className="gray700">Due date</p>
              <p className="xLight">
                {task.due_date ? dayjs(task.due_date).format("MM/DD/YY") : "-"}
              </p>
            </div>
          )}
          <div className={styles.group}>
            <p className="gray700">Assigned by</p>
            <p className="xLight">
              {FORMAT.name(task.assigner)} at{" "}
              {convertUtcIntToLocalDatetime(task.created_at).format(
                "h:mm A on MM/DD/YY"
              )}
            </p>
          </div>

          <div className={styles.group}>
            <p className="gray700">Assigned to</p>
            <StaffPicker
              initialStaffId={task.assignee.user_id}
              // there must be a staff assignee for a task
              onUpdateStaff={o => (o ? handleUpdateStaff(o) : null)}
              isCompact={false}
              isDisabled={!!task.completed_at}
            />
          </div>

          <div className={styles.group}>
            <p className="gray700">Associated patient</p>
            <div className={styles.patientWrapper}>
              <PatientPicker
                initialPatientIds={
                  task.associated_patient
                    ? [task.associated_patient.user_id]
                    : []
                }
                onUpdatePatient={handleUpdateLinkedPatient}
                isCompact={false}
              />
              <Button
                style={STYLES.TERTIARY}
                onClick={handleOpenChart}
                nativeButtonProps={{
                  disabled: !task.associated_patient?.user_id
                }}
              >
                Open Chart <Icon svg="chevron_right" />
              </Button>
            </div>
          </div>

          <div className={styles.group}>
            <p className="gray700">Patient contact number</p>
            <p className="xLight">
              {task.associated_patient?.mobile
                ? formatPhone(task.associated_patient?.mobile)
                : "-"}
            </p>
          </div>

          <div className={styles.group}>
            <p className="gray700">Description</p>
            {task.generic?.task_text ? (
              <ContentRenderer
                content={sanitize(task.generic?.task_text)}
                classes="xLight"
              />
            ) : (
              "-"
            )}
          </div>

          {!task.completed_at && (
            <div className={styles.buttons}>
              <Button type="button" style={STYLES.SECONDARY}>
                Cancel
              </Button>
              <Button onClick={handleCompleteTask} loading={isCompleting}>
                <Icon svg="check-done-wh" />
                Mark Complete
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

import clsx from "clsx";

import Button from "@/components/button";
import { ModalComponentProps } from "@/components/modalV2/ModalController";

import { STYLES } from "@/globals/constants";

import styles from "./styles.module.scss";

export type ConfirmModalProps = {
  message: string;
  onConfirm: () => void;
};

export const ConfirmModal = ({
  message,
  onConfirm,
  onClose
}: ModalComponentProps<ConfirmModalProps>) => {
  return (
    <div className={clsx(styles.Confirm)}>
      <div className={styles.message}>{message}</div>
      <div className={styles.buttons}>
        <Button style={STYLES.SECONDARY} onClick={onClose}>
          Cancel
        </Button>
        <Button style={STYLES.PRIMARY} onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </div>
  );
};

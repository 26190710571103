import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    medicationSearch: build.query<
      MedicationSearchApiResponse,
      MedicationSearchApiArg
    >({
      query: queryArg => ({
        url: `/medications/search`,
        params: { term: queryArg.term }
      })
    }),
    medicationDosageSearch: build.query<
      MedicationDosageSearchApiResponse,
      MedicationDosageSearchApiArg
    >({
      query: queryArg => ({ url: `/medications/${queryArg.name}/dosages` })
    }),
    medicationCreateForPatient: build.mutation<
      MedicationCreateForPatientApiResponse,
      MedicationCreateForPatientApiArg
    >({
      query: queryArg => ({
        url: `/medications/${queryArg.userId}/create`,
        method: "POST",
        body: queryArg.medicationCreate
      })
    }),
    medicationUpdateForPatient: build.mutation<
      MedicationUpdateForPatientApiResponse,
      MedicationUpdateForPatientApiArg
    >({
      query: queryArg => ({
        url: `/medications/${queryArg.userId}/update`,
        method: "POST",
        body: queryArg.medicationUpdate
      })
    }),
    medicationDeleteFromPatient: build.mutation<
      MedicationDeleteFromPatientApiResponse,
      MedicationDeleteFromPatientApiArg
    >({
      query: queryArg => ({
        url: `/medications/${queryArg.userId}/${queryArg.uuid}/delete`,
        method: "DELETE"
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as medication };
export type MedicationSearchApiResponse =
  /** status 200 Sent in response to a successful medication search.
   */ MedicationListItem[];
export type MedicationSearchApiArg = {
  /** Search term for medications.
   */
  term: string;
};
export type MedicationDosageSearchApiResponse =
  /** status 200 Sent in response to a successful medication dosage search.
   */ string[];
export type MedicationDosageSearchApiArg = {
  /** Name of a medication.
   */
  name: string;
};
export type MedicationCreateForPatientApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type MedicationCreateForPatientApiArg = {
  userId: UserId;
  /** Add a medication to a patient.
   */
  medicationCreate: MedicationCreate;
};
export type MedicationUpdateForPatientApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type MedicationUpdateForPatientApiArg = {
  userId: UserId;
  /** Update an existing medication of a patient.
   */
  medicationUpdate: MedicationUpdate;
};
export type MedicationDeleteFromPatientApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type MedicationDeleteFromPatientApiArg = {
  userId: UserId;
  /** UUID of a medication.
   */
  uuid: Uuid;
};
export type MedicationListItem = {
  display: string;
  dispensable_drug_id?: number;
  routed_doseform_drug_id?: number;
  ndc?: string;
  rx_cui?: number;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type ErrorId = number;
export type UserId = number;
export type MedicationStatus =
  | "ACTIVE"
  | "INACTIVE"
  | "NONCOMPLIANT"
  | "DELETED";
export type MedicationCreate = {
  name: string;
  dosage?: string;
  comment?: string;
  status: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  /** Medispan dispensable drug identifer. */
  dispensable_drug_id?: any;
  /** Medispan routed doseform drug identifier. */
  routed_doseform_drug_id?: number;
  days_supply?: number;
};
export type MedicationUpdate = {
  /** An RFC-4122 compliant universally unique identifier (UUID). This is a 128-bit number represented as a
    36-character string in the format xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx where each x is a hexadecimal digit.
     */
  uuid: string;
  dosespot_id?: number;
  name?: string;
  dosage?: string;
  comment?: string;
  status?: MedicationStatus;
  /** A Pario date in YYYYMMDD format.
   */
  start?: number;
  /** A Pario date in YYYYMMDD format.
   */
  end?: number;
  lexicomp_product_id?: number;
  lexicomp_drug_id?: string;
  lexicomp_synonym_id?: number;
  lexicomp_synonym_type?: number;
  days_supply?: number;
};
export type Uuid = string;
export const {
  useMedicationSearchQuery,
  useLazyMedicationSearchQuery,
  useMedicationDosageSearchQuery,
  useLazyMedicationDosageSearchQuery,
  useMedicationCreateForPatientMutation,
  useMedicationUpdateForPatientMutation,
  useMedicationDeleteFromPatientMutation
} = injectedRtkApi;

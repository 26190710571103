export type ExamField = {
    id: string;
    label: string;
    normalText: string;
    options: ExamFieldOption[];
}

export type ExamFieldOption = {
    value: "normal" | "abnormal" | "did_not_assess";
    label: "Normal" | "Abnormal" | "Did not assess";
}

const options: ExamFieldOption[] = [
    { value: "normal", label: "Normal" },
    { value: "abnormal", label: "Abnormal" },
    { value: "did_not_assess", label: "Did not assess"}
]

export const exam_fields: ExamField[] = [
    {
      id: "general_appearance",
      label: "General Appearance",
      normalText: "Active, alert, crying with stimulation",
      options
    },
    {
      id: "tone",
      label: "Tone",
      normalText: "Normal Well flexed, all extremities",
      options
    },
    {
      id: "skin",
      label: "Skin",
      normalText: "Warm, pink, dry, intact. Vernix present in creases of skin folds.",
      options
    },
    {
      id: "birthmarks",
      label: "Birthmarks",
      normalText: "None noted.",
      options
    },
    {
      id: "head_neck",
      label: "Head/Neck",
      normalText: "Normocephalic. Fontanells soft and flat. No cephalohematoma or caput. No webbing of neck.",
      options
    },
    {
      id: "eyes",
      label: "Eyes",
      normalText: "Sclera white, conjunctiva pink. No discharge.",
      options
    },
    {
      id: "red_reflexes",
      label: "Red Reflexes",
      normalText: "Noted bilaterally.",
      options
    },
    {
      id: "mouth_palate",
      label: "Mouth and Palate",
      normalText: "Pink, moist. Hard and soft palate intact. No lip or tongue tie noted. No epstein pearls.",
      options
    },
    {
      id: "ears",
      label: "Ears",
      normalText: "Cartilage soft with appropriate recoil. Pinna above canthus bilaterally. Not pits or tags.",
      options
    },
    {
      id: "heart_sounds",
      label: "Heart Sounds",
      normalText: "RRR. No murmurs appreciated.",
      options
    },
    {
      id: "pulses",
      label: "Pulses",
      normalText: "Brachial and femoral pulses strong and equal bilaterally.",
      options
    },
    {
      id: "lungs",
      label: "Lungs",
      normalText: "CTAB. No crackles or wheezes appreciated in all 4 quadrants.",
      options
    },
    {
      id: "abdomen",
      label: "Abdomen",
      normalText: "Soft, round, symmetrical. No palpable masses. Bowel sounds present.",
      options
    },
    {
      id: "umbilicus",
      label: "Umbilicus",
      normalText: "3VC. Cord clamp intact. No erythema, edema, heat, or discharge.",
      options
    },
    {
      id: "genitourinary_male",
      label: "Genitourinary",
      normalText: "Urethra appears intact. No obvious hypospadias or epispadias. Testes descended bilaterally.",
      options
    },
    {
      id: "genitourinary_female",
      label: "Genitourinary",
      normalText: "Labia majora covers labia minora. No pseudomenses. Urethra appears patent.",
      options
    },
    {
      id: "anus",
      label: "Anus",
      normalText: "Appears patent.",
      options
    },
    {
      id: "hips",
      label: "Hips",
      normalText: "Symmetric knee height and gluteal folds. Negative Barlow’s and Ortolani’s.",
      options
    },
    {
      id: "spine",
      label: "Spine",
      normalText: "Straight, intact. No sacral dimple, pitting, or hair tuft.",
      options
    },
    {
      id: "clavicles",
      label: "Clavicles",
      normalText: "Intact, no crepitus bilaterally.",
      options
    },
    {
      id: "musculoskeletal",
      label: "Musculoskeletal",
      normalText: "Full ROM all extremities.",
      options
    },
    {
      id: "muscle_tone",
      label: "Muscle Tone",
      normalText: "Normal",
      options
    },
    {
      id: "nipples",
      label: "Nipples",
      normalText: "Chest with normal contour with nipples near the mid-clavicular line. Round and symmetric with proper number and placement of nipples.",
      options
    },
    {
      id: "nares",
      label: "Nares",
      normalText: "Patent.",
      options
    }
  ]

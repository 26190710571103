/* WriteRx Modal Template */
/* External Imports */
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";

/* Local Imports */
// components
import PatientPicker from "@/components/patientPicker";
import Icon from "@/components/icons";
import Button from "@/components/button";

// constants
import { STATUS_KEYS } from "@/globals/constants";
import { PAGES, STYLES } from "@/globals/constants";

// store
import {
  ErrorInfo,
  useLazyPatientGetDoseSpotSsoUrlQuery,
  usePatientCloseDoseSpotMutation,
  usePatientGetInfoQuery
} from "@/store/services/patient";
import { useUserGetInfoQuery, UserId } from "@/store/services/user";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";

export interface RxModalProps {
  patientId?: UserId;
}
export default function RxModal({ patientId }: RxModalProps) {
  const dispatch = useDispatch();

  // initialize selected patient ID
  const [selectedPatientId, setSelectedPatientId] = useState<
    UserId | null | undefined
  >(patientId);

  // Get SSO URL from backend
  const [getSsoUrl, { data, error, isError }] =
    useLazyPatientGetDoseSpotSsoUrlQuery();

  const [handleClose] = usePatientCloseDoseSpotMutation();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: loggedInUser } = useUserGetInfoQuery(
    { userId: sessionInfo?.user_id as number },
    { skip: !sessionInfo?.user_id }
  );

  const { data: patient, isLoading: patientLoading } = usePatientGetInfoQuery(
    { patientId: selectedPatientId as number },
    { skip: !selectedPatientId }
  );

  const unauthorizedUser = !(
    sessionInfo?.is_superadmin ||
    sessionInfo?.is_provider ||
    sessionInfo?.is_ma
  );

  const missingData = useMemo(() => {
    if (unauthorizedUser) {
      dispatch(
        addAlertToToastTrough({
          message: "Not authorized to order prescriptions",
          type: STATUS_KEYS.ERROR
        })
      );
    }

    const missingData: string[] = [];
    if (!loggedInUser) {
      missingData.push("logged-in user's information");
    } else {
      if (loggedInUser.dob <= 19000000 || loggedInUser.dob >= 99999999) {
        missingData.push("logged-in user's date of birth");
      }
      if (
        !loggedInUser.addr_line_1 ||
        !loggedInUser.city ||
        !loggedInUser.state ||
        !loggedInUser.zip
      ) {
        missingData.push("logged-in user's address information is incomplete");
      }
      if (!loggedInUser.mobile) {
        missingData.push("logged-in user's phone number");
      }
      if (!patient) {
        missingData.push("patient information");
      } else {
        if (patient.dob <= 19000000 || patient.dob >= 99999999) {
          missingData.push("patient's date of birth");
        }
        if (
          !patient.address?.line1 ||
          !patient.address?.city ||
          !patient.address?.state ||
          !patient.address?.zip
        ) {
          missingData.push("patient's address information is incomplete");
        }
        if (!patient.phone) {
          missingData.push("missing patient's phone number");
        }
      }
    }

    return missingData?.length > 0 ? missingData : null;
  }, [loggedInUser, patient]);

  // If patient is passed in, set the selected patient ID
  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId);
    }
  }, [patientId]);

  useEffect(() => {
    if (selectedPatientId) {
      getSsoUrl({ patientId: selectedPatientId });
      return () => {
        handleClose({ patientId: selectedPatientId });
      };
    }
  }, [selectedPatientId]);

  useEffect(() => {
    if (isError) {
      // @ts-ignore
      const err: ErrorInfo = error.data as ErrorInfo;

      if (err?.user_facing) {
        dispatch(
          addAlertToToastTrough({
            message: err.message,
            type: STATUS_KEYS.ERROR
          })
        );

        for (const e of err?.extra_data || []) {
          dispatch(
            addAlertToToastTrough({
              message: e.message,
              type: STATUS_KEYS.ERROR
            })
          );
        }
      }
    }
  }, [error]);

  /* Event Handlers */
  const handleOpenChart = () => {
    window.open(`${PAGES.PATIENTS}/${patient?.user_id}`, "_blank");
  };

  return (
    <div className={styles.RxModal}>
      {/* if this is not invoked from a patient's chart with a default patient
        then show the patient picker */}
      {!patientId && (
        <div className={styles.patientWrapper}>
          <PatientPicker
            onUpdatePatient={id => setSelectedPatientId(id)}
            initialPatientIds={selectedPatientId ? [selectedPatientId] : []}
            isCompact={false}
          />
          <Button
            style={STYLES.TERTIARY}
            onClick={handleOpenChart}
            nativeButtonProps={{ disabled: !selectedPatientId }}
          >
            Open Chart <Icon svg="chevron_right" />
          </Button>
        </div>
      )}
      {missingData ? (
        <div className="error padded">
          <p className="smBld t4">
            We are unable to access DoseSpot prescription service because the
            following information is missing:
          </p>
          <ul className="padded flexCol">
            {missingData.map(md => (
              <li key={md}>{md}</li>
            ))}
          </ul>
          <p className="smBld">
            Please complete the required information and try again
          </p>
        </div>
      ) : (
        <iframe src={data?.url} />
      )}
    </div>
  );
}

import { BLOOD_TYPES, METRICS, METRIC_LABELS } from "@/globals/constants";

import {
  AntibodyScreenDat,
  BloodType,
  Met1,
  Met2,
  PatientInfo,
  PatientType,
  PatientUpsertRequest
} from "@/store/services/patient";

// Type definitions for options
export type PatientTypeOption = {
  label: string;
  value: PatientType;
  isNotable: boolean;
};
export type BloodTypeOption = {
  label: string;
  value: BloodType;
  isNotable: boolean;
};
export type MetabolicScreenOption = {
  label: string;
  value: "NORMAL" | "ABNORMAL" | "DECLINED" | "";
  isNotable: boolean;
};
export type DATScreenOption = {
  label: string;
  value: "POSITIVE" | "NEGATIVE" | "DECLINED" | "";
  isNotable: boolean;
};

// Type for the field prop
export type PatientFieldType =
  | "type"
  | "blood_type"
  | "antibody_screen_DAT"
  | "met_1"
  | "met_2";

// Union type for all possible option types
export type OptionType =
  | PatientTypeOption
  | BloodTypeOption
  | MetabolicScreenOption
  | DATScreenOption;

const DAT_VALUES = ["NEGATIVE", "POSITIVE", "DECLINED"] as const;
const METABOLIC_VALUES = ["NORMAL", "ABNORMAL", "DECLINED"] as const;

// Helper function to generate options based on field type
export function getOptions(
  field: PatientFieldType,
  customPatientTypes: PatientType[]
): OptionType[] {
  switch (field) {
    case "type":
      return customPatientTypes.map(type => ({
        label: METRIC_LABELS[type] || type,
        value: type,
        isNotable: type === METRICS.INACTIVE
      }));
    case "blood_type":
      return BLOOD_TYPES.map(type => ({
        value: type,
        label: type,
        isNotable: false
      }));
    case "antibody_screen_DAT":
      return DAT_VALUES.map(v => ({
        value: v,
        label: v ? METRIC_LABELS[v] : "-",
        isNotable: v === "POSITIVE"
      }));
    case "met_1":
    case "met_2":
      return METABOLIC_VALUES.map(v => ({
        value: v,
        label: v ? METRIC_LABELS[v] : "-",
        isNotable: v === "ABNORMAL"
      }));
    default:
      return [];
  }
}

// Helper function to get current value
export function getCurrentValue(
  patient: PatientInfo,
  field: PatientFieldType,
  options: OptionType[]
): OptionType | undefined {
  switch (field) {
    case "type":
      return options.find(v => v.value === patient.practice_data.type);
    case "blood_type":
      return options.find(v => v.value === patient.medical_history.blood_type);
    case "antibody_screen_DAT":
      return options.find(
        v => v.value === patient.medical_history.antibody_screen_DAT
      );
    case "met_1":
      return options.find(v => v.value === patient.medical_history.met_1);
    case "met_2":
      return options.find(v => v.value === patient.medical_history.met_2);
    default:
      return undefined;
  }
}

// Helper to get props for the select component
export function getSelectProps(field: PatientFieldType, label: string) {
  return {
    isClearable: field !== "type" && field !== "blood_type",
    hideSelectedOptions: true,
    "aria-label": field === "type" ? "Patient Type" : label,
    id: field === "antibody_screen_DAT" ? "dat-select" : undefined
  };
}

// Value types for each field
export type FieldValueTypes = {
  type: PatientType;
  blood_type: BloodType;
  antibody_screen_DAT: AntibodyScreenDat;
  met_1: Met1;
  met_2: Met2;
};

// Generic function that enforces relationship between field and value type
export function createUpdatePayload<F extends keyof FieldValueTypes>(
  patient: PatientInfo,
  field: F,
  newValue: FieldValueTypes[F]
): PatientUpsertRequest {
  const payload: PatientUpsertRequest = {
    user_id: patient.user_id
  };
  // we need this condition because the update handler
  // conditionally updates different parts of the patient object
  if (field === "type") {
    // TypeScript can't infer that newValue is PatientType here
    payload.practice_data = { type: newValue as PatientType };
  } else {
    // For all medical history fields
    payload.medical_history = {
      ...patient.medical_history,
      [field]: newValue
    };
  }

  return payload;
}

// Generic function that enforces relationship between field and value type
export function hasValueChanged<F extends keyof FieldValueTypes>(
  patient: PatientInfo,
  field: F,
  newValue: FieldValueTypes[F]
): boolean {
  switch (field) {
    case "type":
      return newValue !== patient.practice_data.type;
    case "blood_type":
      return newValue !== patient.medical_history.blood_type;
    case "antibody_screen_DAT":
      return newValue !== patient.medical_history.antibody_screen_DAT;
    case "met_1":
      return newValue !== patient.medical_history.met_1;
    case "met_2":
      return newValue !== patient.medical_history.met_2;
    default:
      return false;
  }
}

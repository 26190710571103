/* StaffPicker  */
import {
  SelectCompactStyles,
  SelectCompactTheme,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import Select, { GroupBase, MenuPlacement, StylesConfig } from "react-select";

import { StaffInfo } from "@/store/services/generated/staff";
import { useStaffUserListQuery } from "@/store/services/staff";
import { UserId } from "@/store/services/user";

import { FORMAT } from "@/globals/helpers/formatters";

// Type the styles configuration
const typedSelectStyles: StylesConfig<
  StaffOptionType,
  false,
  GroupBase<StaffOptionType>
> = {
  ...SelectCompactStyles
} as StylesConfig<StaffOptionType, false, GroupBase<StaffOptionType>>;

type StaffOptionType = {
  value: StaffInfo["user_id"];
  label: string;
};

/* StaffPicker Typescript Interface */
interface StaffPickerProps {
  initialStaffId?: UserId;
  onUpdateStaff: (staffId?: UserId) => void;
  isCompact?: boolean;
  isClearable?: boolean;
  // TRUE if only schedulable staff should be shown
  // FALSE if only non-schedulable staff should be shown
  // undefined if all staff should be shown
  isSchedulableStaff?: boolean;
  isDisabled?: boolean;
  // TRUE if only billable staff should be shown
  // FALSE if only non-billable staff should be shown
  // undefined if all staff should be shown
  isBillableStaff?: boolean;
  menuPlacement?: MenuPlacement;
}

export default function StaffPicker({
  initialStaffId,
  onUpdateStaff,
  isCompact = false,
  isClearable = false,
  isSchedulableStaff,
  isDisabled = false,
  isBillableStaff,
  menuPlacement
}: StaffPickerProps) {
  /* Redux */
  const { data: staffList = [] } = useStaffUserListQuery({
    isSchedulable: isSchedulableStaff,
    isBillable: isBillableStaff,
    includeSuperadmins: false,
    includeInactive: false
  });

  /* Local State */

  const options = staffList.map(staff => ({
    value: staff.user_id,
    label: FORMAT.name(staff)
  }));

  /* Effects */

  /* Event Handlers */

  return (
    <Select<StaffOptionType, false>
      options={options}
      menuPlacement={menuPlacement}
      value={
        initialStaffId
          ? options.find(option => option.value === initialStaffId)
          : null
      }
      onChange={selectedOption => {
        onUpdateStaff(selectedOption ? selectedOption.value : undefined);
      }}
      isClearable={isClearable}
      theme={isCompact ? SelectCompactTheme : SelectDefaultTheme}
      styles={isCompact ? typedSelectStyles : {}}
      placeholder="Select staff..."
      isDisabled={isDisabled}
    />
  );
}

/* StartNewConversation  */
/* External Imports */
import clsx from "clsx";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "next/router";

/* Local Imports */

// components
import OptionTile from "@/components/common/optionTile";
import TextArea from "@/components/textArea";
import Input from "@/components/input";
import Button from "@/components/button";
import PatientPicker from "@/components/patientPicker";
import StaffMultiPicker from "@/components/staffMultipicker";
import Icon from "@/components/icons";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { setModalIsOpen } from "../../modalSlice";
import {
  ConversationCreateParams,
  useCreateConversationMutation
} from "@/store/services/conversations";
import { UserId, UserIdList } from "@/store/services/practice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";

/* StartNewConversation Typescript Interface */
interface StartNewConversationProps {
  isPatientChartView?: boolean;
}

type ConversationType = "external" | "linked" | "internal";
const conversationTypes: ConversationType[] = [
  "external",
  "linked",
  "internal"
];
const conversationTypeConfig = {
  external: {
    title: "To Patient",
    description: "Start a conversation directly with a patient",
    svgName: "user"
  },
  linked: {
    title: "About Patient",
    description:
      "Privately discuss a patient, conversation will be linked to their chart but not visible to them",
    svgName: "clipboard"
  },
  internal: {
    title: "Internal",
    description: "Start a conversation with team members within your practice",
    svgName: "building-07"
  }
};

type CreateConversationFormFields = {
  subject: string;
  staff_ids: UserIdList;
  patient_id: UserId;
  message_content: string;
};

export default function StartNewConversation({
  isPatientChartView = false
}: StartNewConversationProps) {
  /* Redux */
  const dispatch = useDispatch();
  const router = useRouter();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [createConversation, { isLoading: isCreatingConversation }] =
    useCreateConversationMutation();

  /* Local State */
  const [selectedConversationType, setSelectedConversationType] =
    useState<ConversationType>("external");

  const form = useForm<CreateConversationFormFields>({});

  const dynamicConversationTypes = useMemo(() => {
    if (isPatientChartView) {
      // filter out internal conversation type if patient chart view
      return conversationTypes.filter(type => type !== "internal");
    } else if (sessionInfo?.is_patient) {
      // patients can only start internal conversations and don't have any options
      return [];
    } else {
      // in the messaging center, staff can start all conversation types
      return conversationTypes;
    }
  }, [isPatientChartView]);

  // get patient id from url if in the patient chart
  const patientId = useMemo(() => {
    if (isPatientChartView) {
      const { patientId } = router.query;
      if (patientId) return +patientId;
    }
    return null;
  }, [isPatientChartView, router.query]);

  const staffRequired =
    selectedConversationType === "internal" ||
    selectedConversationType === "linked";
  const patientRequired =
    selectedConversationType === "external" ||
    selectedConversationType === "linked";

  /* Effects */

  // set patient id to form if in patient chart view
  useEffect(() => {
    if (patientId) {
      form.setValue("patient_id", patientId);
    }
  }, [patientId]);

  useEffect(() => {
    const isPatient = sessionInfo?.is_patient;
    setSelectedConversationType(isPatient ? "internal" : "external");
  }, [sessionInfo?.is_patient]);

  /* Event Handlers */
  const onSubmit = async (data: CreateConversationFormFields) => {
    // parse data and send to create conversation mutation
    const conversationCreateParams: ConversationCreateParams = {
      subject: data.subject,
      staff_participants: data.staff_ids,
      content: data.message_content
    };
    // if the conversation is not internal, add patient participant
    if (selectedConversationType !== "internal") {
      let patientParticipant = {
        user_id: data.patient_id,
        // if the conversation is external, include the patient in the conversation
        included: selectedConversationType === "external"
      };
      conversationCreateParams.patient_participant = patientParticipant;
    }

    // If the logged-in user is a patient, add the patient as an included participant
    if (sessionInfo?.is_patient) {
      conversationCreateParams.patient_participant = {
        user_id: sessionInfo.user_id,
        included: true
      };
    }

    createConversation({
      conversationCreateParams
    })
      .unwrap()
      .then(() => {
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to create conversation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCancel = () => {
    dispatch(setModalIsOpen(false));
  };

  return (
    <form
      className={clsx(styles.StartNewConversation)}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className={styles.optionTiles}>
        {dynamicConversationTypes.map(type => (
          <OptionTile
            title={conversationTypeConfig[type].title}
            description={conversationTypeConfig[type].description}
            svgName={conversationTypeConfig[type].svgName}
            key={type}
            onClick={() => setSelectedConversationType(type)}
            isSelected={selectedConversationType === type}
          />
        ))}
      </div>

      {patientRequired && (
        <div className={styles.group}>
          <div className={styles.label}>Patient (Only One)</div>
          <Controller
            name="patient_id"
            control={form.control}
            rules={{
              required: patientRequired ? "Patient is required" : undefined
            }}
            render={({ field: { onChange, value } }) => (
              <PatientPicker
                aria-label="Select Patient Recipient"
                onUpdatePatient={onChange}
                initialPatientIds={[value]}
              />
            )}
          />
        </div>
      )}

      <div className={styles.group}>
        <div className={styles.label}>
          {!sessionInfo?.is_patient ? "Internal Team" : "To"}
          {!staffRequired && " (Optional)"}
        </div>
        <Controller
          name="staff_ids"
          control={form.control}
          rules={{ required: staffRequired ? "Staff is required" : undefined }}
          render={({ field: { onChange, value } }) => (
            <StaffMultiPicker
              onUpdateStaff={onChange}
              isClearable
              initialStaffIds={value}
              excludeLoggedInUser
            />
          )}
        />
      </div>
      <div className={styles.group}>
        <div className={styles.label}>Subject</div>
        <Controller
          name="subject"
          control={form.control}
          rules={{ required: "Subject is required" }}
          render={({ field: { onChange, value } }) => (
            <Input
              label="Subject"
              name="subject"
              id="subject"
              type="text"
              hiddenLabel
              onChange={onChange}
              value={value}
              placeholder="Enter subject..."
              fullWidth
            />
          )}
        />
      </div>
      <div className={styles.group}>
        <div className={styles.label}>Message</div>
        {selectedConversationType === "external" && (
          <div className="note warning bordered">
            <Icon svg="warning" width={20} height={20} />A patient is part of
            this conversation
          </div>
        )}
        {selectedConversationType === "linked" && (
          <div className="note">
            <Icon svg="circle-info" width={20} height={20} />
            All messages in this conversation are displayed on patient’s chart.
            Patient will not be included or notified of these messages.{" "}
          </div>
        )}
        <Controller
          name="message_content"
          defaultValue=""
          control={form.control}
          rules={{ required: "Message is required" }}
          render={({ field }) => (
            <TextArea
              label="Message"
              name="message_content"
              id="message_content"
              hiddenLabel
              onChange={field.onChange}
              content={field.value}
              rows={4}
              placeholder="Enter message..."
            />
          )}
        />
      </div>
      <div className={styles.actions}>
        <Button type="button" style={STYLES.SECONDARY} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="submit"
          nativeButtonProps={{
            disabled: !form.formState.isValid || isCreatingConversation
          }}
          loading={form.formState.isSubmitting}
        >
          Send Message
        </Button>
      </div>
    </form>
  );
}

// external
import { useEffect, useMemo, useState } from "react";
import {
  DefaultValues,
  FieldValues,
  Path,
  SubmitHandler,
  UseFormReturn,
  useForm
} from "react-hook-form";

// components
import Button from "@/components/button";
import DashboardCard from "@/components/cards/dashboardCard";
import Icon from "@/components/icons";
import ContentRenderer from "@/components/textArea/contentRenderer";

// constants
import { STYLES } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";

import FlowSheetDataDisplay from "./FlowSheetDataDisplay";
import FlowSheetInput from "./FlowSheetInput";
import {
  BaseFlowSheetEntry,
  FlowSheetConfig
} from "./types";

type EncounterDataFlowSheetProps<T extends BaseFlowSheetEntry & FieldValues> = {
  // The encounter for the currently editable entry. Required for an editable row
  encounterId?: number;
  // Will override the editable row to be readonly even if we have an associated encounter
  isReadOnly?: boolean;
  // The rows to display, including the current encounter (if applicable) data
  entries: T[];
  config: FlowSheetConfig<T>;
  defaultValues?: DefaultValues<T> | undefined;
  // On Submit for the editable row (if there is one)
  onRowSubmit?: (data: T) => void;
  // Should the form submit when any input is blurred
  submitOnBlur?: boolean;
  // On click event for non-editable rows
  onRowNavigate?: (encounterId: number) => void;
  // The navigate button a11y title
  navigateButtonTitle?: string;
  // If the data is loading
  isLoading?: boolean;
  // Title of the flow sheet that will display on the card
  title: string;
};

/**
 * A component that displays data from encounters (like prenatal or infant encounters) in a
 * table format with the first row optionally being editable. This editable row will be associated
 * with the current encounter.
 *
 * @example
 * ```tsx
 * <EncounterDataFlowSheet<MyDataObjectType>
 *  config={MyFlowSheetConfig<MyDataObjectType>}
 *  title="My Data Flow Sheet"
 *  data={myData as FlowSheetRow<MyDataObjectType>}
 * />
 * ```
 * See the `FetalGrowthFlowSheet` and `PrenatalFlowSheet` components for an in use example
 *
 * @param props
 * @returns {JSX.Element} The rendered component
 */
export default function EncounterDataFlowSheet<
  T extends BaseFlowSheetEntry & FieldValues
>({
  encounterId,
  entries,
  defaultValues,
  isReadOnly = false,
  onRowSubmit,
  submitOnBlur,
  onRowNavigate,
  navigateButtonTitle = "Row navigate",
  isLoading,
  config,
  title
}: EncounterDataFlowSheetProps<T>) {
  const form = useForm<T>({
    defaultValues: defaultValues
  });
  const { handleSubmit, reset } = form;

  // The ids of the rows that are toggled open (for showing the note below)
  const [openRowIds, setOpenRowIds] = useState<number[]>([]);

  // The entries from the rest of the data that aren't being currently edited
  const readOnlyEntries = useMemo(() => {
    if (!isReadOnly && encounterId) {
      return entries.filter(entry => entry.encounterId !== encounterId);
    }
    return entries;
  }, [isReadOnly, encounterId, entries]);

  /**
   * Helper function to update state based on toggling the current row
   * @param id The id of the current row
   */
  const handleToggleRowOpen = (id: number) => {
    let newOpenRowIds;
    // if row is already open, close
    if (openRowIds.includes(id)) {
      newOpenRowIds = openRowIds.filter(openRowId => openRowId !== id);
    } else {
      // otherwise open it
      newOpenRowIds = [...openRowIds, id];
    }
    setOpenRowIds(newOpenRowIds);
  };

  // Effects
  /**
   * This effect will hard reset the editable row meaning we won't keep
   * the "dirty" fields (fields that have been edited). This shouldn't
   * result in data loss, but it prevents data from carrying over if the
   * user navigates to another encounter without this component unrendering.
   */
  useEffect(() => {
    reset(defaultValues);
  }, [encounterId]);

  /**
   * This effect resets the form but keeps the dirty fields. This way,
   * we send the update request and update the default values, but if
   * the user has changed something during that process, it won't overwrite
   * their edits when backend returns new data.
   */
  useEffect(() => {
    reset(defaultValues, { keepValues: true });
  }, [entries]);

  // Event handlers
  const onSubmit = (formData: T) => {
    if (onRowSubmit) {
      onRowSubmit(formData);
    }
  };

  return (
    <div className={styles.FlowSheet}>
      <DashboardCard
        title={title}
        isReadOnly={isReadOnly}
        isEmpty={isReadOnly && entries.length === 0}
        isLoading={isLoading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <table className={styles.Datagrid}>
            <thead>
              <tr>
                {Object.entries(config.fields).map(([key, metadata]) => (
                  <th key={key}>{metadata?.label}</th>
                ))}
                <th>&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {/* Show input row if we have an encounterId and it's not readonly */}
              {!isReadOnly && encounterId && (
                <tr>
                  {Object.entries(config.fields).map(([fieldKey, metadata]) => (
                    <td key={fieldKey}>
                      {metadata && (
                        <FlowSheetInput<T>
                          fieldKey={fieldKey as Path<T>}
                          metadata={metadata}
                          form={form as UseFormReturn}
                          submitOnBlur={!!submitOnBlur}
                          onSubmit={onSubmit as SubmitHandler<FieldValues>}
                        />
                      )}
                    </td>
                  ))}
                </tr>
              )}

              {/* populate table with existing data */}
              {readOnlyEntries.map(entry => (
                <>
                  <tr
                    key={`data-${entry.encounterId}`}
                    data-cy="historical-entries"
                  >
                    {Object.entries(config.fields).map(
                      ([fieldKey, metadata]) => (
                        <td key={fieldKey}>
                          {metadata && entry[fieldKey as keyof T] && (
                            <FlowSheetDataDisplay
                              data={entry[fieldKey as keyof T]}
                              type={metadata.type}
                              id={entry.encounterId}
                              handleToggleRowOpen={() =>
                                handleToggleRowOpen(entry.encounterId)
                              }
                              openRowIds={openRowIds}
                            />
                          )}
                        </td>
                      )
                    )}
                    <td>
                      {
                        // if this row isn't apart of the current encounter, display a navigate button
                        entry.encounterId !== encounterId && (
                          <Button
                            style={STYLES.ICON}
                            onClick={() =>
                              onRowNavigate && onRowNavigate(entry.encounterId)
                            }
                            nativeButtonProps={{
                              title: navigateButtonTitle
                            }}
                          >
                            <Icon svg={"arrow_right_grey"} />
                          </Button>
                        )
                      }
                    </td>
                  </tr>
                  {openRowIds.includes(entry.encounterId) && (
                    <tr>
                      <td
                        colSpan={Object.entries(config.fields).length}
                        className={styles.drawer}
                      >
                        <ContentRenderer content={entry.note as string} />
                      </td>
                    </tr>
                  )}
                </>
              ))}
            </tbody>
          </table>
        </form>
      </DashboardCard>
    </div>
  );
}

import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    tagCreate: build.mutation<TagCreateApiResponse, TagCreateApiArg>({
      query: queryArg => ({
        url: `/tags/${queryArg.practiceId}/create`,
        method: "POST",
        body: queryArg.tagCreateOrUpdatePayload
      })
    }),
    tagsList: build.query<TagsListApiResponse, TagsListApiArg>({
      query: queryArg => ({
        url: `/tags/${queryArg.practiceId}/list`,
        params: { page: queryArg.page, pagesz: queryArg.pagesz }
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as tag };
export type TagCreateApiResponse =
  /** status 200 This is sent in reply to a successful tag create request.
   */ TagId;
export type TagCreateApiArg = {
  practiceId: PracticeId;
  /** Create or update a tag for a location or a practice.
   */
  tagCreateOrUpdatePayload: TagCreateOrUpdatePayload;
};
export type TagsListApiResponse =
  /** status 200 Abbreviated list of tags suitable for dropdown list population.
   */ TagList;
export type TagsListApiArg = {
  practiceId: PracticeId;
  page?: Page;
  pagesz?: PageSize;
};
export type TagId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type PracticeId = number;
export type TagJtype = "boolean" | "integer" | "string" | "object";
export type TagCreateOrUpdatePayload = {
  /** System-assigned unique ID of a tag. This is used as a table linkage.
   */
  tag_id?: number;
  /** The name of an individual tag.
   */
  name: string;
  /** Description of the tag for the user interface.
   */
  description?: string;
  jtype?: TagJtype;
};
export type TagListItem = {
  /** System-assigned unique ID of a tag. This is used as a table linkage.
   */
  tag_id: number;
  /** The name of an individual tag.
   */
  name: string;
  /** Description of the tag for the user interface.
   */
  desc?: string;
  jtype?: TagJtype;
};
export type TagList = TagListItem[];
export type Page = number;
export type PageSize = number;
export const { useTagCreateMutation, useTagsListQuery, useLazyTagsListQuery } =
  injectedRtkApi;

import dayjs, { Dayjs } from "dayjs";
import * as libphonenumber from "google-libphonenumber";
import { capitalize } from "lodash";

import { convertUtcIntToLocalDate } from "@/components/scheduling/calendars/utils";

import { AppointmentLocation } from "@/store/services/encounter";
import {
  ConversationRecipient,
  PatientRecipient
} from "@/store/services/generated/conversation";
import { Address } from "@/store/services/location";
import { LabOrderPatientInfo, PatientInfo } from "@/store/services/patient";
import { PatientListItem } from "@/store/services/practice";
import { AppointmentLocationUpsert } from "@/store/services/scheduling";

export type UserName = {
  prefix?: string;
  first_name?: string;
  last_name?: string;
  suffix?: string | null;
};

function apptLocationToUpsert(
  location: AppointmentLocation | undefined
): AppointmentLocationUpsert | undefined {
  return typeof location === "object" ? location.location_id : location;
}

function apptLocationName(
  location: AppointmentLocation | undefined
): string | undefined {
  return typeof location === "object" ? location.name : location;
}

function constructAddressString(addressData: Address) {
  // TODO: make location info & address uniform shape
  const { line1, line2, line3, city, state, zip } = addressData;

  // Create an array of address parts
  const addressParts = [line1, line2, line3, city, state?.toUpperCase(), zip];

  // Filter out empty parts and join them with a comma and space
  const formattedAddress = addressParts
    .filter(part => part && part != "")
    .join(", ");

  return formattedAddress;
}

const FORMAT = {
  string: (d: string) => d,
  capitalize: (d: string) => capitalize(d),
  name: ({ first_name = "", last_name = "", suffix = "" }: UserName) =>
    `${first_name ? first_name : ""}${last_name ? " " + last_name : ""}${suffix ? " " + suffix : ""}`,
  address: (addressData: Address) =>
    addressData && constructAddressString(addressData),
  ssn: (ssn: string) =>
    ssn.substring(0, 3) + "-" + ssn.substring(3, 5) + "-" + ssn.substring(5, 9)
};

/**
 * Formats a time amount in milliseconds to the format of "mm:ss" to display
 * transcription time stamps
 * @param milli a time amount in milliseconds
 * @returns a string in the format of "mm:ss"
 */
const msToTime = (milli: number) => {
  const seconds = Math.floor((milli / 1000) % 60);
  const minutes = Math.floor((milli / (60 * 1000)) % 60);

  return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(
    2,
    "0"
  )}`;
};

const nameAndDOB = (
  patient:
    | PatientInfo
    | PatientListItem
    | ConversationRecipient
    | PatientRecipient
    | LabOrderPatientInfo
) => {
  if (!patient || !patient.first_name || !patient.last_name) return "";
  if (!patient.dob) return FORMAT.name(patient);
  if (!convertUtcIntToLocalDate(patient.dob)) return FORMAT.name(patient);
  return `${FORMAT.name(patient)} - ${convertUtcIntToLocalDate(
    patient.dob
  ).format("MM/DD/YYYY")}`;
};

const formatPhone = (phone: string) => {
  if (!phone) return "";
  const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
  const number = phoneUtil.parse(phone, "US");
  let phoneStr = phoneUtil.formatInOriginalFormat(number, "US") || "";
  return phoneStr;
};

/**
 * Formats a date in Safari-compatible format (YYYY/MM/DD)
 * @param date - Date string, Date object, or Dayjs object
 * @returns Formatted date string in YYYY/MM/DD format
 */
const getSafariSafeDate = (date: string | Date | Dayjs): string => {
  return dayjs(date).format("YYYY/MM/DD");
};

export {
  FORMAT,
  msToTime,
  nameAndDOB,
  apptLocationToUpsert,
  apptLocationName,
  formatPhone,
  getSafariSafeDate
};

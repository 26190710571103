import { useRouter } from "next/router";
import posthog from "posthog-js";
import { useDispatch } from "react-redux";

import { setIsAuthenticated } from "@/components/auth/slice";

import { unsetCookie } from "@/utils/cookies";

export default function useAuth() {
  const router = useRouter();
  const dispatch = useDispatch();

  const logout = () => {
    unsetCookie("X-Pario-Auth");
    dispatch(setIsAuthenticated(false));
    posthog.reset();
    window.Intercom("update", {
      hide_default_launcher: true
    });
    router.replace("/login");
  };

  return { logout };
}

import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { Controller, UseFormReturn } from "react-hook-form";
import Select, { GroupBase, SingleValue, StylesConfig } from "react-select";

import styles from "@/components/forms/_labor/styles.module.scss";
import Input from "@/components/input";

import { ExamField, ExamFieldOption } from "./constants";

interface NewbornPhysicalSelectProps {
  form: UseFormReturn;
  examField: ExamField;
}

export default function NewbornPhysicalSelect({
  form,
  examField
}: NewbornPhysicalSelectProps) {
  const { id, label, normalText, options } = examField;
  const { control } = form;

  return (
    <div key={id} className={styles.fieldPair}>
      <label className="horizontalLabel">
        <span>{label}</span>
        <Controller
          name={id}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Select<ExamFieldOption>
              theme={SelectDefaultTheme}
              styles={
                SelectDefaultStyles as StylesConfig<
                  ExamFieldOption,
                  false,
                  GroupBase<ExamFieldOption>
                >
              }
              onChange={(newValue: SingleValue<ExamFieldOption>) => {
                onChange(newValue?.value);
                if (newValue?.value === "normal") {
                  // We don't want to overwrite anything so only autofill if the field is empty
                  if (form.getValues(`${id}-comment`) === "") {
                    form.setValue(`${id}-comment`, normalText);
                  }
                } else {
                  // Setting to abnormal or clearing the input clears the comment
                  form.setValue(`${id}-comment`, "");
                }
              }}
              value={options?.find(option => option.value === value || option.value === value?.value)}
              options={options}
              id={id}
              isClearable
            />
          )}
        />
      </label>
      <Input
        type="text"
        name={`${id}-comment`}
        id={`${id}-comment`}
        label="Comment"
        register={form.register}
        isHorizontalLayout={true}
      />
    </div>
  );
}

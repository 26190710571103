import { EVENTS } from "../flows/labor/constants";
import Medicationform from "../forms/_labor/medication";
import MaternalVitalsForm from "../forms/_labor/detailTimelineForms/maternalVitals";
import NewbornVitalsForm from "../forms/_labor/detailTimelineForms/newbornVitals";
import { LaborEventInfo } from "@/store/services/pregnancy";
import Timestampform from "../forms/_labor/timestamp";
import dayjs from "dayjs";
import { IdType } from "@/store/services/encounter";

interface DetailFormProps {
  event: LaborEventInfo;
  onSubmit: (values: any, eventId: string, eventType: IdType) => void;
}

export default function DetailForm({ event, onSubmit }: DetailFormProps) {
  const handleRenderForm = () => {
    if (!event) {
      return;
    }

    if (event.type === "MEDICATION") {
      return (
        <Medicationform
          startTime={event.start}
          defaultValues={event.forms}
          onSubmit={v => onSubmit(v, event.event_id, "medication")}
        />
      );
    }

    const forms = { name: "", ...event.forms };

    switch (forms.name) {
      case EVENTS.MATERNAL_VITALS:
        return (
          <MaternalVitalsForm
            onSubmit={v => onSubmit(v, event.event_id, "recurring_event")}
            defaultValues={{
              start_time: event.start,
              ...event.forms
            }}
          />
        );
      case EVENTS.NEWBORN_VITALS:
        return (
          <NewbornVitalsForm
            onSubmit={v => onSubmit(v, event.event_id, "recurring_event")}
            defaultValues={{
              start_time: event.start,
              ...event.forms
            }}
          />
        );
      default:
        return (
          <Timestampform
            eventId={event.event_id}
            onSubmit={v => onSubmit(v, event.event_id, "recurring_event")}
            startTime={
              event.start || parseInt(dayjs().utc().format("YYYYMMDDHHmmss"))
            }
            defaultValues={event.forms}
          />
        );
    }
  };

  return <>{handleRenderForm()}</>;
}

import { useAuth } from "@/hooks";
import { usePostHog } from "posthog-js/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setIsAuthenticated } from "@/components/auth/slice";
import {
  ModalController,
  useModal
} from "@/components/modalV2/ModalController";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { STATUS_KEYS } from "@/globals/constants";

import { useLazyAuthRefreshQuery } from "@/store/services/auth";
import { useReportFrontendErrorMutation } from "@/store/services/system";
import { RootState } from "@/store/store";

import styles from "./styles.module.scss";

interface TokenExpiryMonitorProps {
  lookbackPeriod: number;
  checkInterval: number;
}

export default function TokenExpiryMonitor({
  lookbackPeriod,
  checkInterval
}: TokenExpiryMonitorProps) {
  const dispatch = useDispatch();
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const posthog = usePostHog();
  const { logout } = useAuth();
  const [modalActions, modalState] = useModal();

  const [getRefreshToken] = useLazyAuthRefreshQuery();
  const [reportError] = useReportFrontendErrorMutation();

  const [timeUntilExpiry, setTimeUntilExpiry] = useState<number | null>(null);

  useEffect(() => {
    // Capture component mount event
    if (modalState.isOpen) {
      posthog.capture("token_expiry_monitor_modal_opened", {
        timestamp: new Date().toISOString()
      });
    }
    posthog.capture("token_expiry_monitor_initialized", {
      timestamp: new Date().toISOString()
    });

    // Component unmount event
    return () => {
      posthog.capture("token_expiry_monitor_removed");
    };
  }, [modalState.isOpen, posthog]);

  // Single ref to track when we can show the modal again
  const canShowModalAfter = useRef(0);

  const handleClose = () => {
    posthog.capture("token_expiry_monitor_modal_closed", {
      timestamp: new Date().toISOString()
    });
    modalActions.close();
    // Don't show warning again for 1 second to allow animation to complete
    canShowModalAfter.current = Date.now() + 1000;
  };

  const handleLogout = () => {
    posthog.capture("token_expiry_monitor_logout", {
      timestamp: new Date().toISOString()
    });
    logout();
  };

  // Function to refresh token
  const refreshToken = useCallback(() => {
    // First, capture the event
    posthog.capture("token_expiry_monitor_token_refresh", {
      timestamp: new Date().toISOString()
    });

    modalActions.close();
    // Call refresh and handle the Promise properly
    getRefreshToken()
      .unwrap()
      .then(() => {
        // Token refresh succeeded
        dispatch(setIsAuthenticated(true));

        posthog.capture("token_expiry_monitor_refresh_success", {
          timestamp: new Date().toISOString()
        });
      })
      .catch(error => {
        // Token refresh failed
        posthog.capture("token_expiry_monitor_refresh_failed", {
          timestamp: new Date().toISOString(),
          error: error.message || "Unknown error"
        });

        addAlertToToastTrough({
          message: "Failed to refresh token. Please log in again.",
          type: STATUS_KEYS.ERROR
        });

        reportError({
          reportSystemError: {
            message: "Failed to refresh token during session monitoring",
            stack_trace: (() => new Error().stack)()
          }
        });
        logout();
      })
      .finally(() => {
        // Don't show warning again for 1 second
        canShowModalAfter.current = Date.now() + 1000;
      });
  }, [modalActions, dispatch, getRefreshToken, posthog, logout, reportError]);

  // Check token expiry in a stable effect
  useEffect(() => {
    function checkTokenExpiry() {
      if (sessionInfo && "exp" in sessionInfo) {
        const cookieExpiry = sessionInfo.exp as number;
        const expiryTime = cookieExpiry * 1000;
        const currentTimeUntilExpiry = expiryTime - Date.now();

        // Update expiry time display
        setTimeUntilExpiry(currentTimeUntilExpiry);

        // Handle expired token
        if (currentTimeUntilExpiry <= 0) {
          posthog.capture("token_expiry_monitor_session_expired", {
            timestamp: new Date().toISOString()
          });
          console.error("Token has expired");
          logout();
          return;
        }

        // Only show warning if:
        // 1. We're within the lookback period
        // 2. Modal is not already open
        // 3. We're past the cooldown period
        if (
          currentTimeUntilExpiry <= lookbackPeriod &&
          !modalState.isOpen &&
          Date.now() > canShowModalAfter.current
        ) {
          modalActions.open({});
        }
      } else {
        console.error("No session info found");
        logout();
      }
    }

    // Initial check
    checkTokenExpiry();

    // Set up interval for checking
    const interval = setInterval(checkTokenExpiry, checkInterval);

    return () => {
      clearInterval(interval);
    };
  }, [
    checkInterval,
    logout,
    sessionInfo,
    lookbackPeriod,
    modalActions,
    modalState.isOpen,
    posthog
  ]);

  // Format the time remaining for display
  const formatTimeRemaining = () => {
    if (timeUntilExpiry === null) return "";

    const minutes = Math.floor(timeUntilExpiry / 60000);
    const seconds = Math.floor((timeUntilExpiry % 60000) / 1000);

    return `${minutes}m ${seconds}s`;
  };

  return (
    <ModalController
      title="Your session is about to expire"
      width={600}
      close={handleClose}
      isClosable={false}
      modalState={modalState}
      modalComponent={() => (
        <div className={styles.TokenExpiryModal}>
          <p>Your session will expire in {formatTimeRemaining()}.</p>
          <p>Do you want to continue your session?</p>
          <div className={styles.buttons}>
            <button className={styles.logout} onClick={handleLogout}>
              Logout
            </button>
            <button className={styles.continue} onClick={refreshToken}>
              Continue
            </button>
          </div>
        </div>
      )}
    />
  );
}

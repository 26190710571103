import { skipToken } from "@reduxjs/toolkit/query";
import clsx from "clsx";
import dayjs from "dayjs";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";

import Button from "@/components/button";
import Input from "@/components/input";
import { ModalComponentProps } from "@/components/modalV2/ModalController";
import TextArea from "@/components/textArea";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { STATUS_KEYS, STYLES } from "@/globals/constants";

import { cases } from "@/store/services/cases";
import type { CasesCreateRequest } from "@/store/services/generated/cases";
import type { PatientInfo } from "@/store/services/patient";
import { practice } from "@/store/services/practice";
import { staff } from "@/store/services/staff";
import { RootState } from "@/store/store";

import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";

import { SelectDefaultTheme } from "@/styles/themes/selectDefaultTheme";

import styles from "./styles.module.scss";

import DateTimeSplitInput from "./DateTimeSplitInput";

type CreateCaseFormFields = CasesCreateRequest;

const formKeys = {
  staff_id: "staff_id",
  title: "title",
  date_time: "date_time",
  note: "note",
  patient_id: "patient_id"
} as const;

const formLabels: Record<keyof CreateCaseFormFields, string> = {
  [formKeys.staff_id]: "Provider",
  [formKeys.title]: "Title",
  [formKeys.date_time]: "",
  [formKeys.note]: "Note",
  [formKeys.patient_id]: "Patient"
};

export type CreateCaseModalProps = {
  patientId?: PatientInfo["user_id"];
  closeModal: () => void;
};

export const CreateCaseModal = ({
  patientId,
  closeModal
}: ModalComponentProps<CreateCaseModalProps>) => {
  const dispatch = useDispatch();

  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const form = useForm<CreateCaseFormFields>({
    defaultValues: {
      staff_id: sessionInfo?.user_id,
      date_time: dayjs().format("YYYY-MM-DD"),
      patient_id: patientId
    }
  });

  const title = form.watch(formKeys.title) ?? "";

  const { data: patientList } = practice.endpoints.patientGetList.useQuery(
    patientId ? skipToken : { pagesz: 100 }
  );

  const { data: staffList = [] } = staff.endpoints.staffUserList.useQuery({
    includeSuperadmins: false,
    includeInactive: false
  });

  const [createCase] = cases.endpoints.caseCreate.useMutation();

  const onSubmit: SubmitHandler<CreateCaseFormFields> = ({
    staff_id,
    title,
    note,
    date_time,
    patient_id
  }) => {
    createCase({
      casesCreateRequest: {
        patient_id,
        staff_id,
        title,
        note,
        date_time
      }
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Case created",
            type: STATUS_KEYS.SUCCESS
          })
        );
        closeModal();
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Error creating case",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <form
      className={clsx(styles.CreateCaseModal)}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div>
        <label className="standardLabel">{`${formLabels[formKeys.staff_id]} *`}</label>
        <Controller
          control={form.control}
          name={formKeys.staff_id}
          render={({ field }) => (
            <Select
              options={staffList}
              getOptionValue={option => String(option.user_id)}
              theme={SelectDefaultTheme}
              getOptionLabel={option => FORMAT.name(option)}
              value={staffList.find(option => option.user_id === field.value)}
              required
              onChange={option => {
                if (option) field.onChange(option.user_id);
              }}
            />
          )}
        />
      </div>
      {patientId ? null : (
        <div>
          <label className="standardLabel">{`${formLabels[formKeys.patient_id]} *`}</label>
          <Controller
            control={form.control}
            name={formKeys.patient_id}
            render={({ field }) => (
              <Select
                options={patientList?.patients}
                getOptionValue={option => String(option.user_id)}
                theme={SelectDefaultTheme}
                getOptionLabel={option => nameAndDOB(option)}
                value={patientList?.patients.find(
                  option => option.user_id === field.value
                )}
                required
                onChange={option => {
                  if (option) field.onChange(option.user_id);
                }}
              />
            )}
          />
        </div>
      )}
      <Input<CreateCaseFormFields>
        fullWidth
        placeholder="Descriptive Case Title"
        maxLength={64}
        id={formKeys.title}
        label={formLabels[formKeys.title]}
        register={form.register}
        name={formKeys.title}
        required
        type="text"
      />
      <div className={styles.characterCount}>
        {`${(64 - (title?.length || 0))
          .toString()
          .padStart(2, "0")} characters remaining`}
      </div>
      <DateTimeSplitInput<CreateCaseFormFields>
        name={formKeys.date_time}
        control={form.control}
      />
      <TextArea
        label={formLabels[formKeys.note]}
        name={formKeys.note}
        placeholder="Type here..."
        id={formKeys.note}
        rows={2}
        onChange={content => form.setValue(formKeys.note, content)}
      />
      <div className={styles.buttons}>
        <Button style={STYLES.SECONDARY} type="button" onClick={closeModal}>
          Cancel
        </Button>
        <Button style={STYLES.PRIMARY} type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};

/* Button Component */
// External
import clsx from "clsx";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

import { setSelectedView } from "@/components/mobile/mobileSlice";

// utils
import { unsetCookie } from "@/utils/cookies";

import { RootState } from "@/store/store";

// styles
import styles from "./styles.module.scss";

// components
import Pario from "../../../public/svgs/pario.svg";
// constants
import { PAGES, STYLES } from "../../globals/constants";
import AvatarPlaceholder from "../avatarPlaceholder";
import Button from "../button";
import Icon from "../icons";

// utils

export interface SidebarProps {
  links: {
    label: string;
    href: string;
  }[];
}

export default function MobileNav({ links }: SidebarProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  // get user session  info
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  let userInitials = "";
  if (sessionInfo)
    userInitials = sessionInfo.first
      ? sessionInfo?.first?.[0]
      : "" + sessionInfo?.last?.[0];

  const handleHomeClick = () => {
    dispatch(setSelectedView("home"));
    router.push(PAGES.HOME);
  };
  return (
    <nav className={clsx(styles.MobileNav)}>
      {/* logo (left content) */}
      <Button onClick={handleHomeClick} style={STYLES.ACTION}>
        <Pario />
      </Button>
      {/* right content */}
      <div className={styles.right}>
        {/* <Bell stroke={styles.primary200} height={18} />
        <Settings stroke={styles.primary200} height={18} /> */}
        <button
          onClick={() => {
            unsetCookie("X-Pario-Auth");
            router.push(PAGES.LOGIN);
          }}
        >
          <Icon svg="log-out" flipped />
        </button>
        <AvatarPlaceholder
          character={userInitials}
          isInverse
          isSmall
          userId={sessionInfo?.user_id}
        />
      </div>
    </nav>
  );
}

import { useDispatch, useSelector } from "react-redux";

import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { STATUS_KEYS } from "@/globals/constants";

import { PhraseListItem } from "@/store/services/generated/phrase";
import { usePhraseDeleteMutation } from "@/store/services/phrase";
import { RootState } from "@/store/store";

import Trash from "../../../public/svgs/delete.svg";
import Edit from "../../../public/svgs/edit_simple.svg";
import { ConfirmModal, ConfirmModalProps } from "../modalV2/ConfirmationModal";
import { ModalController, useModal } from "../modalV2/ModalController";
import styles from "./styles.module.scss";

interface PhraseOperationProps {
  phrase: PhraseListItem;
}

export default function PhraseOperations({ phrase }: PhraseOperationProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const [modalActions, modalState] = useModal<ConfirmModalProps>();

  const dispatch = useDispatch();
  const [deletePhrase] = usePhraseDeleteMutation();

  const onPhraseEdit = (phrase: PhraseListItem) => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.DOT_PHRASE_FORM,
        props: { phrase, title: "Edit dot phrase" }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  const onPhraseDelete = (phraseName: string) => {
    if (!sessionInfo?.practice_id) return;
    modalActions.open({
      message: "This action cannot be undone.",
      onConfirm: () => {
        deletePhrase({
          id: sessionInfo.practice_id,
          scope: "practice",
          phrase: phraseName
        })
          .unwrap()
          .then(() => {
            dispatch(
              addAlertToToastTrough({
                message: "Phrase Deleted",
                type: STATUS_KEYS.SUCCESS
              })
            );
            modalActions.close();
          })
          .catch(() => {
            dispatch(
              addAlertToToastTrough({
                message: "Failed to delete annotation",
                type: STATUS_KEYS.ERROR
              })
            );
          });
      }
    });
  };

  return (
    <>
      <a
        className={styles.phraseButton}
        onClick={() => onPhraseDelete(phrase.name)}
      >
        <Trash width={20} height={20} />
      </a>
      <a className={styles.phraseButton} onClick={() => onPhraseEdit(phrase)}>
        <Edit width={22} height={22} />
      </a>
      <ModalController
        modalState={modalState}
        modalComponent={ConfirmModal}
        close={modalActions.close}
        title="Are you sure you want to delete this phrase?"
      />
    </>
  );
}

/* Copy Fax to Paitent Modal Template */

// External
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
import clsx from "clsx";
// components
import Button from "@/components/button";

// globals, constants, helpers
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { setModalIsOpen } from "../modalSlice";
import { PatientInfo, UserId } from "@/store/services/patient";
import { RootState } from "@/store/store";
import { usePracticeSearchUsersQuery } from "@/store/services/practice";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { useFileBatchCopyMutation } from "@/store/services/file";
import { setIds } from "@/components/datagrid/datagridSlice";
import { bulkRemoveSelectedFileIds } from "@/components/fileExplorer/fileExplorerSlice";

// styles
import styles from "../styles.module.scss";
import PatientPicker from "@/components/patientPicker";

export interface CopyFilesModalProps {
  selectedFileIds: Array<number>;
}

export default function CopyFiles({ selectedFileIds }: CopyFilesModalProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [copiedUser, setCopiedUser] = useState<UserId | null>();
  const [errorMessage, setErrorMessage] = useState<string>();

  const [copyFilesToPatient, { isLoading: isCopyLoading }] =
    useFileBatchCopyMutation();
  const { data: users, isSuccess: gotUsers } = usePracticeSearchUsersQuery(
    {
      practiceId: sessionInfo?.practice_id as number
    },
    {
      skip: sessionInfo?.practice_id === undefined
    }
  );

  // Event handlers

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const handleConfirm = () => {
    if (!copiedUser) {
      setErrorMessage("Patient is required");
      return;
    }

    if (!selectedFileIds) {
      console.warn("No file IDs provided");
      dispatch(setModalIsOpen(false));
      return;
    }

    copyFilesToPatient({
      fileBatchCopyRequest: {
        file_ids: selectedFileIds,
        patient_id: copiedUser
      }
    })
      .unwrap()
      .then(data => {
        dispatch(
          addAlertToToastTrough({
            message: "Files successfully copied",
            type: STATUS_KEYS.SUCCESS
          })
        );
        dispatch(setModalIsOpen(false));
        dispatch(setIds([]));
        dispatch(bulkRemoveSelectedFileIds(selectedFileIds));
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Something went wrong copying the file",
            type: STATUS_KEYS.ERROR
          })
        );
        setErrorMessage("Something went wrong copying the file");
      });
  };

  return (
    <div className={clsx(styles.CopyFaxes)}>
      {gotUsers && !isCopyLoading ? (
        <>
          <div className={styles.message}>
            <p>Please select a patient to copy the files to.</p>
            <PatientPicker
              onUpdatePatient={setCopiedUser}
              isCompact={false}
              initialPatientIds={copiedUser ? [copiedUser] : []}
            />
          </div>
          <div className={styles.buttons}>
            <Button style={STYLES.SECONDARY} onClick={handleCancel}>
              Cancel
            </Button>
            <Button style={STYLES.PRIMARY} onClick={handleConfirm}>
              Confirm
            </Button>
          </div>
        </>
      ) : (
        <Skeleton count={6} height={20} />
      )}
    </div>
  );
}

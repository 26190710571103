import { StylesConfig, Theme } from "react-select";

import styles from "./styles.module.scss";

import { OptionType } from "./patientUpsertSelect/helpers";

// Adjust path as needed

// Define your palette using the imported variables
export const blueThemePalette = {
  // Primary colors
  primary: styles.primary600,
  primaryLight: styles.primary400,
  primaryMedium: styles.primary500,
  primaryDark: styles.primary800,

  // Text colors
  textPrimary: styles.primary200,
  textSecondary: styles.white || "#fff", // Fallback if white isn't exported
  textHover: "#000000", // Black for hover states

  // Border colors
  borderDefault: styles.primary500,
  borderHover: styles.primary200,

  // Sizes and spacing
  borderRadius: {
    small: 4,
    medium: 8
  },
  spacing: {
    small: 2,
    medium: 4
  }
};

export function SelectBlueTheme(theme: Theme): Theme {
  return {
    ...theme,
    borderRadius: blueThemePalette.borderRadius.medium,
    spacing: {
      baseUnit: blueThemePalette.spacing.small,
      menuGutter: 0
    },
    colors: {
      ...theme.colors,
      primary: blueThemePalette.primary,
      primary75: blueThemePalette.primaryMedium,
      primary50: blueThemePalette.primaryLight,
      primary25: blueThemePalette.primaryLight,

      // Add neutral colors for better theme integration
      neutral0: blueThemePalette.primaryDark, // Menu background
      neutral20: blueThemePalette.borderDefault, // Border color
      neutral80: blueThemePalette.textPrimary // Regular text
    }
  } as Theme;
}

export const SelectBlueStyles: StylesConfig<OptionType> = {
  container: baseStyles => ({
    ...baseStyles,
    width: "100%"
  }),

  control: baseStyles => ({
    ...baseStyles,
    fontSize: "14px",
    lineHeight: "20px",
    borderRadius: blueThemePalette.borderRadius.medium,
    backgroundColor: blueThemePalette.primaryDark,
    padding: blueThemePalette.spacing.medium,
    borderColor: blueThemePalette.borderDefault,

    ":hover": {
      backgroundColor: `${blueThemePalette.primary}1A`, // 10% opacity
      borderColor: blueThemePalette.borderHover
    },

    ":focus": {
      backgroundColor: `${blueThemePalette.primary}1A`,
      borderColor: "transparent",
      boxShadow: "none"
    }
  }),

  option: (baseStyles, { isFocused }) => ({
    ...baseStyles,
    fontSize: "14px",
    color: isFocused
      ? blueThemePalette.textHover
      : blueThemePalette.textPrimary,
    backgroundColor: isFocused
      ? blueThemePalette.primaryLight
      : blueThemePalette.primaryDark
  }),

  menu: baseStyles => ({
    ...baseStyles,
    fontSize: "12px",
    borderRadius: blueThemePalette.borderRadius.small,
    backgroundColor: blueThemePalette.primaryDark
  }),

  dropdownIndicator: baseStyles => ({
    ...baseStyles,
    width: 20,
    height: 20,
    padding: 0,
    color: blueThemePalette.primaryLight // Default color
  }),

  clearIndicator: baseStyles => ({
    ...baseStyles,
    width: 20,
    height: 20,
    padding: 0,
    color: blueThemePalette.primaryLight // Default color
  }),

  indicatorSeparator: baseStyles => ({
    ...baseStyles,
    display: "none"
  }),

  singleValue: (baseStyles, { data }) => ({
    ...baseStyles,
    color: data.isNotable
      ? styles.warning300 // Use warning color for notable selections
      : blueThemePalette.textPrimary,
    fontWeight: data.isNotable ? "600" : "normal"
  }),

  placeholder: baseStyles => ({
    ...baseStyles,
    color: `${blueThemePalette.textPrimary}80` // 50% opacity
  }),
  loadingIndicator: baseStyles => ({
    ...baseStyles,
    color: blueThemePalette.primaryLight
  })
};

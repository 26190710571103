/* StatusGrid Name */
/* External Imports */
import clsx from "clsx";
import dayjs from "dayjs";
import libphonenumber from "google-libphonenumber";
import { useSelector } from "react-redux";

/* Local Imports */

import DeletePatient from "@/components/modal/triggers/deletePatient";

import { parseParioDate } from "@/utils/api/time";

import { METRICS } from "@/globals/constants";

import { PatientInfo } from "@/store/services/encounter";
import { RootState } from "@/store/store";

// store

import {
  calculateAge,
  calculateAgeInMonths,
  getGestationalAge,
  getPostPartumDuration
} from "@/globals/helpers";

// styles
import styles from "./styles.module.scss";

/* StatusGrid Typescript Interface */
interface StatusGridProps {
  patient: PatientInfo;
}

export default function StatusGrid({ patient }: StatusGridProps) {
  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const pregnancyHistory = patient.medical_history.pregnancies_gs_and_ps;

  /* Local State */

  const showGsPs =
    patient.practice_data.type !== METRICS.INFANT &&
    patient.sex !== METRICS.MALE &&
    pregnancyHistory;

  const patientAge =
    patient.practice_data.type === METRICS.INFANT
      ? calculateAgeInMonths(parseParioDate(patient.dob) || new Date())
      : calculateAge(parseParioDate(patient.dob) || new Date());

  let phoneStr = "--";
  if (patient.phone) {
    try {
      const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
      const phone = phoneUtil.parse(patient.phone, "US");
      phoneStr = phoneUtil.formatInOriginalFormat(phone, "US");
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * EDD String is the Estimated Due Date string formatted as MM/DD/YYYY
   */
  const eddString = patient.pregnancy?.edd?.estimated_due_date
    ? dayjs(patient.pregnancy.edd.estimated_due_date).format("MM/DD/YYYY")
    : "--";

  const showEDD = patient.pregnancy && eddString && eddString !== "--";

  const showDeliveryDate = patient.pregnancy && patient.pregnancy.delivery_date;

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.StatusGrid)}>
      {/* Gs&Ps */}
      {showGsPs && (
        <>
          <p data-cy="gravida-para">Gravida & Para</p>

          <p>
            <span>G{pregnancyHistory.gravida}</span>
            <span>
              {" "}
              P{pregnancyHistory.term_births}
              {pregnancyHistory.preterm_births}
              {pregnancyHistory.abortions_miscarriages}
              {pregnancyHistory.living_children}
            </span>
          </p>
        </>
      )}
      {/* Patient DOB */}
      <p>Date of Birth</p>
      <p>
        {dayjs(parseParioDate(patient.dob)).format("MM/DD/YYYY")}
        <p className="t5">{`(Age ${patientAge})`}</p>
      </p>
      {/* Patient Contact */}
      <p>Phone</p>
      <p>{phoneStr}</p>
      <p>Email</p>
      <p>{patient.email || "--"}</p>
      {/* Pregnancy section */}

      {showEDD && (
        <>
          <span
            className={styles.hasTooltip}
            title="EDD comes from the dating table. To access, click the dating button on the gestational timeline."
          >
            EDD <sup>?</sup>
          </span>
          <p>
            {eddString}
            <p className="xLight t5">
              (
              {getGestationalAge(
                parseInt(dayjs(eddString, "MM/DD/YYYY").format("YYYYMMDD"))
              )}
              )
            </p>
          </p>
        </>
      )}
      {showDeliveryDate && (
        <>
          <p>
            {patient?.pregnancy?.outcome?.replace(/\([^)]*\)/g, "") ||
              "Pregnancy completed"}
          </p>
          {/* We know that patient.pregnancy.delivery_date is defined from showDeliveryDate but TS can't infer this */}
          <p>
            {dayjs(
              parseParioDate(patient.pregnancy!.delivery_date as number)
            ).format("MM/DD/YYYY")}
            <p className="xLight t5">
              (
              {getPostPartumDuration(
                patient.pregnancy!.delivery_date as number
              )}
              )
            </p>
          </p>
        </>
      )}
      {/* insurance section */}
      <p>Insurance</p>
      <p>
        {patient.insurance?.self_pay
          ? "Self Pay"
          : patient.insurance?.primary?.payor || "--"}
      </p>
      {sessionInfo?.is_superadmin && <DeletePatient patient={patient} />}
    </div>
  );
}

import clsx from "clsx";
import FocusTrap from "focus-trap-react";
import { AnimatePresence, motion } from "framer-motion";
import { Inter } from "next/font/google";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import Icon from "@/components/icons";

import styles from "./styles.module.scss";

const modalVariant = {
  initial: {
    opacity: 0,
    y: 50,
    transition: { duration: 0.5, damping: 10, delay: 0.4 }
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: { type: "spring", duration: 0.5, damping: 10, delay: 0.2 }
  }
};

const font = Inter({
  subsets: ["latin"]
});

export type ModalComponentProps<T> = T & { onClose: () => void };

type ModalComponent<T> = (props: ModalComponentProps<T>) => JSX.Element;

type ModalState<T> = {
  modalProps?: T;
  isOpen: boolean;
};

export const useModal = <T,>(): [
  { open: (props: T) => void; close: () => void },
  ModalState<T>
] => {
  const [modalProps, setModalProps] = useState<T | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const open = (props: T) => {
    setModalProps(props);
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
  };

  return [{ open, close }, modalProps ? { modalProps, isOpen } : { isOpen }];
};

type ModalControllerProps<T> = {
  modalComponent: ModalComponent<T>;
  modalState: ModalState<T>;
  fullWidth?: boolean;
  close: () => void;
  title: string;
  width?: number;
  isClosable?: boolean;
};

export const ModalController = <T,>({
  modalComponent,
  modalState,
  fullWidth,
  width = 560,
  title,
  close,
  isClosable = true
}: ModalControllerProps<T>) => {
  const { modalProps, isOpen } = modalState;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const handleKeyDown = (event: KeyboardEvent) => {
      if (isClosable && event.key === "Escape") close();
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [isOpen, close]);

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true);
    }
  }, [isOpen]);

  if (!isVisible || !modalComponent || !modalProps) return null;

  const ModalComponent = modalComponent;

  return createPortal(
    <AnimatePresence mode="wait" onExitComplete={() => setIsVisible(false)}>
      {isOpen && (
        <motion.div
          layout
          className={clsx(font.className, styles.Modal, {
            [styles.isOpen]: isOpen,
            [styles.fullWidth]: fullWidth
          })}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <FocusTrap
            active={isOpen}
            focusTrapOptions={{ allowOutsideClick: true }}
          >
            <motion.div
              className={styles.card}
              style={{ width }}
              role="dialog"
              aria-modal="true"
              onClick={e => e.stopPropagation()} // stops click event on modal card from closing modal
              variants={modalVariant}
              initial="initial"
              animate="animate"
              exit="initial"
              data-cy="modal"
              data-testid="modal"
              onAnimationComplete={() => {
                if (!isOpen) setIsVisible(false);
              }}
            >
              <div className={styles.header}>
                <h4>{title}</h4>
                {isClosable && (
                  <button
                    type="button"
                    onClick={close}
                    className={styles.buttonClose}
                    data-cy="modal-close"
                  >
                    <Icon svg="close_grey" width={24} />
                  </button>
                )}
              </div>
              <ModalComponent {...modalProps} onClose={close} />
            </motion.div>
          </FocusTrap>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

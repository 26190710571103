// External
import clsx from "clsx";
import { createPortal } from "react-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import { Editor, Range } from "@tiptap/core";

// Components
import BoldIcon from "../../../public/svgs/rte_bold.svg";
import ItalicsIcon from "../../../public/svgs/rte_italics.svg";
import HeaderOneIcon from "../../../public/svgs/rte_header_1.svg";
import OrderedListIcon from "../../../public/svgs/rte_ordered_list.svg";
import BulletListIcon from "../../../public/svgs/rte_bullet_list.svg";
import CheckIcon from "../../../public/svgs/rte_check.svg";
import LinkIcon from "../../../public/svgs/link-icon.svg";

import Tooltip, { TOOLTIP_POSITIONS } from "../tooltip";
import Input from "../input";
import Button from "../button";

// Styles
import styles from "./styles.module.scss";
import { STYLES } from "@/globals/constants";
import { useForm } from "react-hook-form";
import { Selection } from "@tiptap/pm/state";

export interface MenuBarProps {
  editor: Editor | null;
  // The scoped text area class name for reference
  textAreaClassName?: string;
}

export default function MenuBar({ editor, textAreaClassName }: MenuBarProps) {
  /*
    All of these buttons initiate editor "commands" on the TipTap editor.
    Commands are anything that affect the editor in anyway and in this case
    these commands are entirely for affect the text. The first command in
    the function calls is "chain" which is a TipTap specific function to
    queue up all the commands you want to run with the TipTap specific "run"
    function. The second is "focus" which simply focuses the editor
    (ie textarea). The next command is the actual text affect we want to
    happen and they're fairly self-explanatory (toggleBold, toggleItalic, etc.).
    Then we run the "run" command which just executes all of our chained commands.

    The "can" function in the disabled prop is a "dry run" for the commands
    that come after it to see if those things are possible. In this instance,
    it's fairly useless but if, for example, we disabled text bolding while
    the text was in "code" (writing in monospace, code-like font) then the
    buttons would automatically disable themselves.
  */

  type LinkFormType = {
    displayText: string;
    linkText: string;
  };

  const linkFormRef = useRef<HTMLDivElement>(null);

  const [linkPopUpOpen, setLinkPopUpOpen] = useState<boolean>(false);

  const { register, setValue, handleSubmit, getValues } =
    useForm<LinkFormType>();

  const getPortalRoot = () => {
    if (!textAreaClassName) return document.body;
    const els = document.getElementsByClassName(textAreaClassName);
    if (els.length > 0) {
      return els[0];
    } else {
      return document.body;
    }
  };

  const portalRoot: Element | DocumentFragment = getPortalRoot();

  const handleDocumentClick = (event: MouseEvent) => {
    if (
      linkPopUpOpen &&
      linkFormRef.current &&
      !linkFormRef.current.contains(event.target as Node) &&
      editor
    ) {
      setLinkPopUpOpen(false);
      editor?.commands.unsetHighlight();
    }
  };

  const createLink = () => {
    if (!editor) return;

    const data: LinkFormType = getValues();

    let linkText = data.linkText;
    if (linkText == "") {
      editor.commands.unsetLink();
      setValue("linkText", "");
      setLinkPopUpOpen(false);
      return;
    }
    if (!linkText.startsWith("http")) {
      linkText = `https://${linkText}`;
    }
    const linkObject = { href: linkText };
    editor.commands.unsetHighlight();
    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .command(({ tr, dispatch }) => {
        if (!dispatch) return true;

        const { from, to } = tr.selection;

        const displayText = data.displayText || data.linkText || "";

        tr.insertText(displayText, from, to);
        tr.addMark(
          from,
          from + displayText.length,
          editor.schema.marks.link.create(linkObject)
        );

        return true;
      })
      .run();

    setValue("linkText", "");

    setLinkPopUpOpen(false);
  };

  // When the link pop up opens/closes add/remove the event listener
  useEffect(() => {
    if (linkPopUpOpen) {
      document.addEventListener("mousedown", handleDocumentClick);
    } else {
      document.removeEventListener("mousedown", handleDocumentClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleDocumentClick);
    };
  }, [linkPopUpOpen]);

  if (!editor) {
    return null;
  }

  return (
    <>
      {portalRoot && linkPopUpOpen && createPortal(
        <div
          className={clsx(styles.LinkPopUp, { [styles.open]: linkPopUpOpen })}
          ref={linkFormRef}
        >
          <form onSubmit={handleSubmit(createLink)}>
            <Input
              type="text"
              name="displayText"
              id="displayText"
              label="Display Text"
              fullWidth
              register={register}
            />
            <Input
              type="text"
              name="linkText"
              id="linkText"
              label="Link"
              fullWidth
              register={register}
            />
            <div className={styles.button}>
              <Button
                type="button"
                style={STYLES.PRIMARY}
                onClick={() => createLink()}
              >
                Insert Link
              </Button>
            </div>
          </form>
        </div>,
        portalRoot
      )}
      <div className={clsx(styles.MenuBar)}>
        <Tooltip
          tooltipText="Shortcut: ctrl/cmd + B"
          tooltipPosition={TOOLTIP_POSITIONS.RIGHT_ALIGNED_TOP}
        >
          <button
            type="button"
            onClick={() => {
              editor.chain().focus().toggleBold().run();
            }}
            disabled={!editor.can().chain().focus().toggleBold().run()}
            className={clsx({ [styles.active]: editor.isActive("bold") }, [
              styles.textControl
            ])}
          >
            <BoldIcon stroke={styles.gray400} />
          </button>
        </Tooltip>
        <Tooltip tooltipText="Shortcut: ctrl/cmd + I">
          <button
            type="button"
            onClick={() => {
              editor.chain().focus().toggleItalic().run();
            }}
            disabled={!editor.can().chain().focus().toggleItalic().run()}
            className={clsx({ [styles.active]: editor.isActive("italic") }, [
              styles.textControl
            ])}
          >
            <ItalicsIcon stroke={styles.gray400} />
          </button>
        </Tooltip>
        <Tooltip tooltipText="Shortcut: ctrl/cmd + alt + 1">
          <button
            type="button"
            onClick={() => {
              editor.chain().focus().toggleHeading({ level: 1 }).run();
            }}
            className={clsx(
              { [styles.active]: editor.isActive("heading", { level: 1 }) },
              [styles.textControl]
            )}
          >
            <HeaderOneIcon stroke={styles.gray400} />
          </button>
        </Tooltip>
        <Tooltip tooltipText="Shortcut: ctrl/cmd + shift + 8">
          <button
            type="button"
            onClick={() => {
              editor.chain().focus().toggleBulletList().run();
            }}
            className={clsx(
              { [styles.active]: editor.isActive("bulletList") },
              [styles.textControl]
            )}
          >
            <BulletListIcon stroke={styles.gray400} />
          </button>
        </Tooltip>
        <Tooltip tooltipText="Shortcut: ctrl/cmd + shift + 7">
          <button
            type="button"
            onClick={() => {
              editor.chain().focus().toggleOrderedList().run();
            }}
            className={clsx(
              { [styles.active]: editor.isActive("orderedList") },
              [styles.textControl]
            )}
          >
            <OrderedListIcon stroke={styles.gray400} />
          </button>
        </Tooltip>
        <Tooltip tooltipText="Shortcut: ctrl/cmd + shift + 9">
          <button
            type="button"
            onClick={() => {
              editor.chain().focus().toggleTaskList().run();
            }}
            className={clsx({ [styles.active]: editor.isActive("taskList") }, [
              styles.textControl
            ])}
          >
            <CheckIcon stroke={styles.gray400} />
          </button>
        </Tooltip>
        <Tooltip tooltipText="Create link from selected text">
          <button
            type="button"
            onClick={() => {
              const { from, to } = editor.state.selection;
              editor.commands.toggleHighlight({ color: "#017c9a80" });
              const text = editor.state.doc.textBetween(from, to);
              setValue("displayText", text);
              setValue("linkText", editor.getAttributes("link").href);
              setLinkPopUpOpen(true);
            }}
            className={clsx({ [styles.active]: editor.isActive("taskList") }, [
              styles.textControl
            ])}
          >
            <LinkIcon stroke={styles.gray400} />
          </button>
        </Tooltip>
      </div>
    </>
  );
}

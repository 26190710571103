/* BloodLoss form component */
/* External Imports */
import clsx from "clsx";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
/* Local Imports */

// components
import Input from "@/components/input";
import Button from "@/components/button";
import Clock from "../../../../public/svgs/clock.svg";

// constants
import { STYLES } from "@/globals/constants";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// store

// styles
import styles from "./styles.module.scss";
import ControlledTextArea from "@/components/textArea/controlledTextArea";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";

/* BloodLossform Typescript Interface */
interface BloodLossformProps {
  onSubmit: (data: any) => void;
  defaultValues: any;
  startTime: number;
}

export default function BloodLossform({
  onSubmit,
  defaultValues,
  startTime
}: BloodLossformProps) {
  const form = useForm({ defaultValues });
  const {
    handleSubmit,
    formState: { errors },
    control,
    register,
  } = form;
  /* Redux */

  /* Local State */
  const [textAreaFocused, setTextAreaFocused] = useState<boolean>(false);

  return (
    <form
      className={clsx(styles.LaborForm, styles.BloodLossform)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <Controller
        name="start_time"
        control={control}
        render={({ field: { onChange, name, value } }) => {
          return (
            <DateTimeStamp
              name={name}
              onChange={onChange}
              initialValue={defaultValues && defaultValues.start_time || startTime}
              value={value}
            />
          );
        }}
      />
      <Input
        type="text"
        label="EBL/QBL"
        name="EBL/QBL"
        id="EBL/QBL"
        fullWidth
        register={register}
      />
      <div className={styles.fullWidth}>
        <ControlledTextArea
          label="Comments"
          form={form}
          name="comments"
          id="comments"
          placeholder="Enter comments here."
          rows={textAreaFocused ? 4 : 1}
          onFocus={() => {
            setTextAreaFocused(true);
          }}
          onBlur={() => {
            setTextAreaFocused(false);
          }}
        />
      </div>
      <div className={styles.fullWidth}>
        <Button style={STYLES.FULL_WIDTH} type="submit">
          Save to flow sheet
        </Button>
      </div>
    </form>
  );
}

/* PatientMetadata Name */
/* External Imports */
import clsx from "clsx";
import { useRouter } from "next/router";

import Button from "@/components/button";
import Icon from "@/components/icons";

import { METRICS, PAGES, STYLES } from "@/globals/constants";

import { PatientInfo } from "@/store/services/patient";

import { FORMAT } from "@/globals/helpers/formatters";

/* Local Imports */

import styles from "./styles.module.scss";

import PatientID from "./patientID";
import StatusGrid from "./statusGrid";

/* PatientMetadata Typescript Interface */
interface PatientMetadataProps {
  patient: PatientInfo;
}

export default function PatientMetadata({ patient }: PatientMetadataProps) {
  const router = useRouter();
  /* Redux */

  /* Local State */
  const hasParent =
    patient.practice_data.type === METRICS.INFANT &&
    patient.parent_ids &&
    patient.parent_ids.length > 0;
  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.PatientMetadata)}>
      <PatientID patient={patient} />
      <StatusGrid patient={patient} />
      {/* link to parent chart */}
      {hasParent && (
        <Button
          style={STYLES.SECONDARY_FULL}
          onClick={() =>
            router.push(
              `${PAGES.PATIENTS}/${patient.parent_ids?.[0] as number}`
            )
          }
        >
          View Associated Chart{" "}
          <Icon svg="chevron_right" width={10} height={12} />
        </Button>
      )}

      {/* Link to infant chart from parent */}
      {patient.practice_data.type !== METRICS.INFANT &&
        patient.children?.map(child => (
          <Button
            key={`child-${child.user_id}`}
            style={STYLES.SECONDARY_FULL_TEXT_WRAP}
            onClick={() => router.push(`${PAGES.PATIENTS}/${child.user_id}`)}
          >
            <p>
              View {FORMAT.name(child)} Chart{" "}
              <Icon svg="chevron_right" width={10} height={12} />
            </p>
          </Button>
        ))}
    </div>
  );
}

import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { FIELD_TYPES, buildZodObject } from "@/globals/constants/formSchema";

import { EncounterInfo } from "@/store/services/encounter";

type NoteFormProps = {
  encounter: EncounterInfo;
};

export default function useNoteForm({ encounter }: NoteFormProps) {
  const schema = z.object(
    buildZodObject([{ fieldID: "SO_notes", fieldType: FIELD_TYPES.TEXT }])
  );

  const form = useForm<{ note: string }>({
    resolver: zodResolver(schema),
    defaultValues: {
      note: encounter.note
    }
  });

  // Update form when encounter changes
  useEffect(() => {
    form.reset({ note: encounter.note });
  }, [encounter.encounter_id, encounter.note, form]);

  const currentNote = form.watch("note");

  return { form, currentNote };
}

/* FacePage Name */
/* External Imports */
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMemo } from "react";

import Button from "@/components/button";
import { HealthHistory } from "@/components/healthHistory";
/* Local Imports */

// components
import Icon from "@/components/icons";

// utils

// constants
import { PAGES, STYLES } from "@/globals/constants";

import { PatientInfo } from "@/store/services/patient";

// helpers
import { useIncompleteEncountersList } from "@/globals/helpers/customHooks";

// styles
import styles from "./styles.module.scss";

import Arrow from "../../../public/svgs/arrow_link.svg";
// icons

import PatientMedicalState from "./patientMedicalState";
import PatientMetadata from "./patientMetadata";

/* FacePage Typescript Interface */
export interface FacePageProps {
  patient: PatientInfo;
}

export default function FacePage({ patient }: FacePageProps) {
  const router = useRouter();

  const incompleteEncounters = useIncompleteEncountersList("practice");
  const activeLabor = useMemo(() => {
    return incompleteEncounters?.find(
      encounter =>
        encounter.encounter_type === "LABOR" &&
        encounter.patient.user_id === patient.user_id
    );
  }, [incompleteEncounters, patient.user_id]);

  if (!patient) return null;

  return (
    <div
      key={patient.user_id}
      className={clsx(styles.FacePage)}
      data-cy="patient-sidebar"
    >
      <PatientMetadata patient={patient} />
      <PatientMedicalState patient={patient} />
      {activeLabor && (
        <div className={styles.activeLabor}>
          <div className={clsx(styles.banner, styles.warningBanner)}>
            <div className={styles.iconWrapper}>
              <Icon svg="warn_triangle" width={18} />
            </div>
            <div className={styles.info}>
              <p className="t4">
                <Link href={`${PAGES.ENCOUNTERS}/${activeLabor.encounter_id}`}>
                  {activeLabor.patient.first_name}'s labor is currently in
                  progress <Arrow stroke="rgb(181, 71, 8)" />
                </Link>
              </p>
            </div>
          </div>
        </div>
      )}
      {!router.pathname.includes(PAGES.PATIENTS) && (
        <div className={styles.buttonWrapper}>
          <Button
            style={STYLES.SECONDARY_FULL}
            onClick={() =>
              router.push(
                `${PAGES.PATIENTS}/${encodeURIComponent(patient.user_id)}`
              )
            }
          >
            <Icon svg="eye" />
            View Facepage
          </Button>
        </div>
      )}
      <HealthHistory patientId={patient.user_id} />
    </div>
  );
}

/* ToastTrough - Wraps toast alerts */
/* External Imports */
import clsx from "clsx";
import { useSelector } from "react-redux";

/* Local Imports */

// components
import Toast from "./toast";
// constants
import { STATUS_KEYS } from "@/globals/constants";

// store
import { RootState } from "@/store/store";
// styles
import styles from "./styles.module.scss";

/* ToastTrough Typescript Interface */
type ToastTroughProps = object;

export default function ToastTrough({}: ToastTroughProps) {
  /* Redux */

  const { alerts } = useSelector((state: RootState) => state.toast);
  /* Local State */

  /* Effects */

  /* Event Handlers */

  return (
    <div className={clsx(styles.ToastTrough)}>
      {alerts?.map((alert) => (
        <Toast key={alert.id} {...alert} type={(alert.type) || STATUS_KEYS.INFO} />
      ))}
    </div>
  );
}

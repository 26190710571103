import { Pregnancy, PregnancyUpsertRequest } from "@/store/services/pregnancy";

export type PregnancyFieldType = "pre_pregnancy_weight";

type FieldValueTypes = {
  pre_pregnancy_weight: string;
};

// Create update payload based on field and new value
export function createUpdatePayload<F extends keyof FieldValueTypes>(
  pregnancy: Pregnancy,
  field: F,
  newValue: FieldValueTypes[F]
): PregnancyUpsertRequest {
  const payload: PregnancyUpsertRequest = {
    pregnancy_id: pregnancy.pregnancy_id
  };

  if (field === "pre_pregnancy_weight") {
    payload.pre_pregnancy_weight = newValue;
  }

  return payload;
}

// Helper to check if value has changed
export function hasValueChanged<F extends keyof FieldValueTypes>(
  pregnancy: Pregnancy,
  field: F,
  newValue: FieldValueTypes[F]
): boolean {
  return newValue !== pregnancy[field];
}

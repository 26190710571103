// External

// Constants

// Components
import ControlledOptionsField from "@/components/forms/generated/options";
import { NewbornFormPartsProps } from "./newborn";
import Input from "@/components/input";

// Styles
import styles from "@/components/forms/_labor/styles.module.scss";
import { ExamType } from "./constants";

interface NewbornVitalsProps extends NewbornFormPartsProps {
  examType: ExamType
}

export default function NewbornVitals({
  disabled = false,
  form,
  examType
}: NewbornVitalsProps) {
  const {
    control,
  } = form;

  return (
    <>
      <div>
        <Input
          type="text"
          name="heart_rate"
          id="heart_rate"
          register={form.register}
          label="Heart Rate"
          isHorizontalLayout={true}
          disabled={disabled}
        />
      </div>
      <div>
        <Input
          type="text"
          name="respiratory_rate"
          id="respiratory_rate"
          register={form.register}
          label="Respiratory Rate"
          isHorizontalLayout={true}
          disabled={disabled}
        />
      </div>
      {examType == "LABOR" && (
        <>
          <div className={styles.row}>
            <label>Vitamin K Administered</label>
            <ControlledOptionsField
              label="Vitamin K Administered"
              name="vit_k"
              control={control}
              options={[
                { id: "yes", label: "Yes" },
                { id: "no", label: "No/Declined" }
              ]}
              hiddenLabel
              disabled={disabled}
            />
          </div>
          <div className={styles.row}>
            <label>Eye Prophylaxis</label>
            <ControlledOptionsField
              label="Eye Prophylaxis"
              name="eye_proph"
              control={control}
              options={[
                { id: "yes", label: "Yes" },
                { id: "no", label: "No/Declined" }
              ]}
              hiddenLabel
              disabled={disabled}
            />
          </div>
        </>
      )}
    </>
  );
}

// External
import clsx from "clsx";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

// Components
import Clock from "../../../../public/svgs/clock.svg";
// Styles
import styles from "./styles.module.scss";

interface DateTimeStampProps {
  // TODO(Landon); better typing
  onChange: (content: any) => void;
  initialValue?: number;
  name: string;
  value: any;
  label?: string;
  hiddenLabel?: boolean;
  disabled?: boolean;
}

const DATE_FORMAT = "YYYYMMDDHHmmss";
const INPUT_DATE_FORMAT = "YYYY-MM-DDTHH:mm:ss";

export default function DateTimeStamp({
  onChange,
  initialValue,
  name,
  value,
  label = "Time",
  hiddenLabel = false,
  disabled = false
}: DateTimeStampProps) {
  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    if (!initialValue) {
      return;
    }
    // Initial value will be a UTC int
    const utcString = `${initialValue}`;
    const utcDateTime = dayjs.utc(utcString, DATE_FORMAT);
    setDateTime(utcDateTime.local().format(INPUT_DATE_FORMAT));
  }, [initialValue]);

  useEffect(() => {
    // Here we check if the value is false or if it's "" because we set
    // the internal state to "" which will change the value causing rerenders
    // since "" doesn't return as falsey
    if (!value || value == "") {
      setDateTime("");
      return;
    }
    // Initial value will be a UTC int
    const utcString = `${value}`;
    const utcDateTime = dayjs.utc(utcString, DATE_FORMAT);
    setDateTime(utcDateTime.local().format(INPUT_DATE_FORMAT));
  }, [value]);

  useEffect(() => {
    const utcDateTime = dayjs(dateTime, DATE_FORMAT).utc().format(DATE_FORMAT);
    const utcInt = parseInt(utcDateTime);
    onChange(utcInt);
  }, [dateTime]);

  return (
    <div className={styles.time}>
      <label
        htmlFor="time"
        className={clsx({ [styles.hiddenLabel]: hiddenLabel })}
      >
        {label}
      </label>
      <div className={styles.timeInputs}>
        <input
          disabled={disabled}
          type="datetime-local"
          value={dateTime}
          onChange={e => setDateTime(e.target.value)}
        />

        <button
          onClick={() => setDateTime(dayjs().format(INPUT_DATE_FORMAT))}
          type={"button"}
          disabled={disabled}
        >
          <Clock stroke={styles.primary700} width={16} height={16} />
          Now
        </button>
      </div>
    </div>
  );
}

/* Newborn form component */
/* External Imports */
import { useEffect, useMemo, useRef } from "react";
import { Controller, UseFormReturn, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import Skeleton from "react-loading-skeleton";
import Select from "react-select";

/* Local Imports */
// Components
import Button from "@/components/button";
import DateTimeStamp from "@/components/flows/_sections/dateTimeStamp";
import Alert from "@/components/alert";
import Input from "@/components/input";
import BasicAccordion from "@/components/accordions/basic";
import NewbornDemographics from "./newbornDemograpics";
import NewbornPhysical from "./newbornPhysicalExam/newbornPhysical";
import NewbornReflexes from "./newbornReflexes";
import NewbornGrowth from "./newbornGrowth";
import NewbornVitals from "./newbornVitals";

// Constants
import { METRICS } from "@/globals/constants";
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { FORMAT } from "@/globals/helpers/formatters";
import {
  ExamType,
  GetNewbornExamDefaultValues,
  NewbornExamEncounterValues,
  NewbornExamLaborValues,
} from "./constants";

// Store
import { RootState } from "@/store/store";
import { usePracticeSearchUsersQuery } from "@/store/services/practice";
import { usePatientGetInfoQuery } from "@/store/services/patient";
import {
  useEncounterInfoQuery,
  useEncounterLaborInfoQuery,
  useLazyEncounterInfoQuery,
  useLazyEncounterLaborInfoQuery,
} from "@/store/services/encounter";
import { usePregnancyInfoQuery } from "@/store/services/pregnancy";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// Styles
import styles from "@/components/forms/_labor/styles.module.scss";
import {
  SelectDefaultStyles,
  SelectDefaultTheme,
} from "@/styles/themes/selectDefaultTheme";
import { skipToken } from "@reduxjs/toolkit/query";

/* Newbornform Typescript Interface */
interface NewbornformProps {
  onSubmit: (data: any) => void;
  disabled?: boolean;
  patientId: number;
  examType: ExamType;
  encounterId: number;
}

/* Interface for the components that make up the newborn exam */
export interface NewbornFormPartsProps {
  form: UseFormReturn<any, any, undefined>;
  disabled?: boolean;
  previousExamData?: NewbornExamEncounterValues | NewbornExamLaborValues;
}

export default function NewbornForm({
  onSubmit,
  disabled = false,
  patientId,
  examType,
  encounterId
}: NewbornformProps) {
  const dispatch = useDispatch();

  const [getLaborData, getLaborResponse] = useLazyEncounterLaborInfoQuery();
  const [getEncounterData, getEncounterResponse] = useLazyEncounterInfoQuery();

  const { data: encounterData } = useEncounterInfoQuery(
    { encounterId },
    { skip: !encounterId }
  );

  const { data: laborData } = useEncounterLaborInfoQuery(
    { encounterId },
    { skip: !encounterId }
  );

  const { data: pregnancyInfo } = usePregnancyInfoQuery(
    {
      pregnancyId: laborData?.pregnancy_id as number
    },
    { skip: !laborData?.pregnancy_id }
  );

  // Create the form with the default values of either labor or encounter
  const form = useForm({
    defaultValues: async () => {
      if (examType == "LABOR") {
        const labor = await getLaborData({
          encounterId
        }).unwrap();

        const previousExamData: NewbornExamLaborValues | null =
          (labor?.stages?.immediate_postpartum?.events?.newborn_exam
            ?.forms as NewbornExamLaborValues) || undefined;
        return GetNewbornExamDefaultValues("LABOR", previousExamData);
      } else {
        const encounter = await getEncounterData({ encounterId }).unwrap();
        const previousExamData: NewbornExamEncounterValues | null =
          ((encounter.type_specific as Record<string, any>)
            ?.newborn_exam as NewbornExamEncounterValues) || undefined;
        return GetNewbornExamDefaultValues(METRICS.ENCOUNTER, previousExamData);
      }
    },
    // Do not validate on blur for labor forms as it causes issues with the autosave
    mode: examType == "LABOR" ? "onSubmit": "onBlur"
  });

  const { handleSubmit, formState: { errors }, control, getValues, setValue, reset } = form;

  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  // Local state
  // TODO(Landon): Figure out how to type these better
  const formRefs = useRef<Array<any>>([]);
  const physicalRef = useRef<any>();

  const { data: users } = usePracticeSearchUsersQuery(
    sessionInfo?.practice_id ? { practiceId: sessionInfo.practice_id } : skipToken,
    { skip: !sessionInfo?.practice_id }
  );

  const { data: motherInfo } = usePatientGetInfoQuery(
    { patientId },
    { skip: !patientId }
  );

  let previousExamData;

  /* Effects */
  const providerOptions = useMemo(() => {
    const allUsers = users?.providers.concat(users.medical_assistants);
    return allUsers?.map(user => ({
      label: FORMAT.name(user),
      value: user
    }));
  }, [users]);
  
  useEffect(() => {
    // If we don't have any provider data, default it to the logged in user
    if (!getValues("select_provider")) {
      const loggedInUser = users?.providers.find(
        user => user.user_id === sessionInfo?.user_id
      );
      if (loggedInUser) {
        setValue("select_provider", loggedInUser);
      }
    }
  }, [getValues, sessionInfo?.user_id, setValue, users]);

  const handleCreateInfant = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.CREATE_INFANT,
        props: {
          patientId: motherInfo?.user_id,
          title: "Create infant",
          pregnancyId: motherInfo?.pregnancy?.pregnancy_id,
          defaultValues: { infant_dob: dayjs().format("YYYY-MM-DD") }
        }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  // Submit the form with toast when the save button is clicked
  const buttonSubmit = () => {
    handleSubmit(onSubmitWrapper)()
      .then(() => {
      dispatch(
        addAlertToToastTrough({
        message: "Newborn exam saved",
        type: STATUS_KEYS.SUCCESS as keyof typeof STATUS_KEYS
        })
      );
      })
      .catch(() => {
      dispatch(
        addAlertToToastTrough({
        message: "Oops! Newborn exam could not be saved",
        type: STATUS_KEYS.ERROR as keyof typeof STATUS_KEYS
        })
      );
      });
  };

  // Submit the form if there are fields that have changed
  const onSubmitWrapper = (data: Record<string, any>) => {
    if (!form.formState.dirtyFields || Object.keys(form.formState.dirtyFields).length === 0) {
      // If there are no dirty fields, don't submit
      return;
    }

    // Run onSubmit for each form part
    formRefs.current.forEach(formRef => {
      formRef.examPartSubmit(data);
    });

    onSubmit({ ...data });

    // Reset the form keeping the values and clearing dirty fields tags
    form.reset(form.getValues(), { keepValues: true });
  };

  // Mark all physical exam fields as normal
  const markAllPhysicalAsNormal = (e: any) => {
    if (physicalRef.current) {
      physicalRef.current.markAllAsNormal(e);
    }
  };

  if (getLaborResponse.isLoading || getEncounterResponse.isLoading) {
    return (
      <div>
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
        <Skeleton height="30" />
      </div>
    );
  }

  if (examType === "LABOR" && !pregnancyInfo?.infant_id) {
    // This is a labor appointment and we haven't created the infant chart yet
    return (
      <div className={styles.newInfantChartAlert}>
        <Alert
          type={STATUS_KEYS.WARNING}
          message="No infant chart has been created yet."
        />
        <Button style={STYLES.FULL_WIDTH} onClick={handleCreateInfant}>
          Create infant chart
        </Button>
      </div>
    );
  } else {
    return (
      <BasicAccordion
        title="Newborn Physical Exam"
        style={STYLES.SECONDARY}
        open={examType === "LABOR"}
      >
        <form
          className={styles.NewbornForm}
          // For triggering autosaves on blur unless a labor form
          onBlur={examType != "LABOR" ? handleSubmit(onSubmitWrapper) : undefined}
          onSubmit={handleSubmit(onSubmitWrapper)}
        >
          <div style={{ display: "none" }}>
            <Input
              type="text"
              value="2"
              name="version"
              id="version"
              label="Version"
              register={form.register}
            />
          </div>
          {examType == "LABOR" && motherInfo && (
            <>
              <Controller
                name="start_time"
                control={control}
                render={({ field: { onChange, name, value } }) => {
                  return (
                    <DateTimeStamp
                      name={name}
                      label={"Exam time"}
                      onChange={onChange}
                      initialValue={value}
                      value={value}
                      disabled={disabled}
                    />
                  );
                }}
              />
              <div className={styles.fieldWrapper}>
                <div className={styles.physical}>
                  <NewbornDemographics
                    form={form}
                    newbornId={pregnancyInfo?.infant_id || patientId}
                    parentId={motherInfo.user_id}
                    ref={ref => (formRefs.current[0] = ref)}
                  />
                </div>
              </div>
              <div className={styles.physical}>
                <NewbornGrowth
                  form={form}
                  newbornId={pregnancyInfo?.infant_id || patientId}
                  encounterId={encounterId}
                  ref={ref => (formRefs.current[1] = ref)}
                />
              </div>
            </>
          )}
          <div className={styles.physical}>
            <NewbornVitals
              form={form}
              disabled={disabled}
              previousExamData={previousExamData}
              examType={examType}
            />
          </div>

          <div className={styles.header}>
            <p>Physical</p>{" "}
            <Button
              type="button"
              onClick={markAllPhysicalAsNormal}
              nativeButtonProps={{ disabled }}
            >
              Mark all as normal
            </Button>
          </div>
          <div className={styles.physical}>
            <NewbornPhysical
              disabled={disabled}
              previousExamData={previousExamData}
              form={form}
              newbornId={pregnancyInfo?.infant_id || patientId}
              ref={ref => {
                physicalRef.current = ref;
              }}
            />
          </div>
          <div className={styles.header}>Reflexes</div>
          <div className={styles.reflexes}>
            <NewbornReflexes form={form} disabled={disabled} />
          </div>

          {examType == "LABOR" && (
            <>
              <div className={styles.header}>Sign-off</div>
              <Controller
                name="select_provider"
                control={control}
                disabled={disabled}
                render={({ field: { value, onChange } }) => (
                  <Select
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={providerOptions}
                    value={providerOptions?.find(
                      item => item.value.user_id === value?.user_id
                    )}
                    // @ts-expect-error requires type definition for newValue
                    onChange={newValue => onChange(newValue?.value)}
                  />
                )}
              />
              {errors && errors.select_provider && (
                <p className="error-text">
                  {(errors.select_provider.message) ||
                    "Please select a provider from the sign-off dropdown"}
                </p>
              )}
            </>
          )}
          <Button
            style={STYLES.FULL_WIDTH}
            nativeButtonProps={{ disabled }}
            onClick={buttonSubmit}
          >
            Save
          </Button>
        </form>
      </BasicAccordion>
    );
  }
}

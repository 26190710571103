/* Modal Component */

// External
import clsx from "clsx";
import { Inter } from "next/font/google";
import { range } from "d3";

// components
import Button from "../button";

// constants
import { STYLES } from "@/globals/constants";

// styles
import styles from "./styles.module.scss";
import Icon from "../icons";

export interface ModalProps {
  pages?: number;
  onClick: (selectedPage: number) => void;
  selectedPage?: number;
  nextEnabled?: boolean;
  oneIndexed?: boolean;
  isMinimal?: boolean;
}

export default function Pagination({
  pages = 0,
  onClick,
  nextEnabled = true,
  selectedPage = 0,
  oneIndexed = false,
  isMinimal = false
}: ModalProps) {
  // state

  // TODO: clarify UX
  // handlers
  const handleDecrement = () => {
    // handle 'previous' logic
    onClick(selectedPage - 1);
  };

  const handleIncrement = () => {
    // handle 'next' logic
    onClick(selectedPage + 1);
  };

  /* Below is some crazy calculations to basically ensure only
  5 page numbers are shown with the selected page either in the
  middle or at the end ranges (page 1 or the last page) it will
  just travel to or from the middle. For example, [1] 2 3 4 5 -> 1 [2] 3 4 5
  OR 7 8 9 [10] 11 -> 7 8 9 10 [11] (assuming 11 is the last page).
  Something to keep in mind is that the "range" function isn't inclusive
  of the second value, so you always have to add 1 to pages to get
  the max page value.
  */
  let topRange = Math.min(selectedPage + 3, pages + 1);
  let bottomRange = Math.max(1, selectedPage - 2);

  if (selectedPage <= 2) {
    topRange = Math.min(6, pages + 1);
    bottomRange = 1;
  }

  if (pages - selectedPage <= 2) {
    topRange = pages + 1;
    bottomRange = Math.max(1, pages - 4);
  }

  const pageRange = range(bottomRange, topRange);

  return (
    <div className={clsx(styles.Pagination, { [styles.minimal]: isMinimal })}>
      <Button
        style={isMinimal ? STYLES.NO_OUTLINE : STYLES.SECONDARY}
        isCompact={isMinimal}
        nativeButtonProps={{ disabled: selectedPage === (oneIndexed ? 1 : 0) }}
        onClick={handleDecrement}
      >
        {isMinimal ? (
          <Icon svg="chevron_left" width={12} height={12} />
        ) : (
          "<-  Previous"
        )}
      </Button>
      <div className={styles.pageMenu}>
        {pageRange.map(page => (
          <button
            key={`page-${page}`}
            className={clsx(styles.pageButton, {
              [styles.selected]: selectedPage === page
            })}
            onClick={() => onClick(page)}
          >
            {page}
          </button>
        ))}
      </div>
      <Button
        style={isMinimal ? STYLES.NO_OUTLINE : STYLES.SECONDARY}
        isCompact={isMinimal}
        nativeButtonProps={{ disabled: !nextEnabled }}
        onClick={handleIncrement}
      >
        {isMinimal ? (
          <Icon svg="chevron_right" width={12} height={12} />
        ) : (
          "Next ->"
        )}
      </Button>
    </div>
  );
}

import dayjs from "dayjs";

import Icon from "@/components/icons";
import { Weight } from "@/components/input/weightInput";

import styles from "./styles.module.scss";

import { FieldMetadataTypes } from "./types";

interface FlowSheetDataDisplayProps {
  data: string | number | Date | null | undefined;
  type: FieldMetadataTypes;
  id: number;
  handleToggleRowOpen: () => void;
  openRowIds: number[];
}

export default function FlowSheetDataDisplay({
  data,
  type,
  id,
  handleToggleRowOpen,
  openRowIds
}: FlowSheetDataDisplayProps) {
  if (data === undefined || data === null) {
    return <p>"-"</p>;
  }

  switch (type) {
    case "pTime":
      const dataAsDate = dayjs(String(data), "YYYYMMDDHHmmss").format(
        "MM/DD/YYYY"
      );
      return <p>{dataAsDate}</p>;
    case "date":
      return <p>{dayjs(data as Date).format("MM/DD/YYYY")}</p>;
    case "weight":
      const dataAsWeight = data as Weight;
      return (
        <p>
          {dataAsWeight.pounds} lbs {dataAsWeight.ounces} oz
        </p>
      );
    case "note":
      return (
        <div className={styles.note}>
          {/* cut off at the first five letters */}
          <button onClick={() => handleToggleRowOpen()} type="button">
            <Icon
              svg="chevron_down_blue"
              width={10}
              flipped={openRowIds.includes(id)}
            />
          </button>
        </div>
      );
    case "percentage":
      let percentage = data as number;
      let string = "-";
      if (percentage) {
        percentage = Math.round(percentage * 10) / 10;
        string = `${percentage}%`;
      }
      return <p>{string}</p>;
    default:
      return <p>{String(data)}</p>;
  }
}

// External
import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";
import { EditHealthHistoryEntryProps } from "@/components/modal/templates/editHealthHistoryEntry";

// Store
import {
  HealthHistoryEntry,
  HealthHistorySection,
  Medication,
  PatientHealthHistory,
  useCreatetHealthHistoryEntryMutation,
  useGetHealthHistoryQuery,
  usePatientGetInfoQuery
} from "@/store/services/patient";

// Types & Utitlies
import { HEALTH_HISTORY_LABEL } from ".";
// Icons
import Plus from "../../../public/svgs/plus.svg";
import Icon from "../icons";
import { HealthHistoryEntryItem } from "./healthHistoryEntry";
// Styles
import styles from "./styles.module.scss";

export interface HistoryCardProps {
  cardIndex: number;
  isOpen: boolean;
  onOpenToggle: (cardIndex: number) => void;
  patientId: number;
  section: HealthHistorySection;
}

export function HistoryCard({
  cardIndex,
  isOpen,
  onOpenToggle,
  patientId,
  section
}: HistoryCardProps) {
  const dispatch = useDispatch();

  const [isAddingEntry, setIsAddingEntry] = useState<boolean>(false);
  const [entryText, setEntryText] = useState<string>("");

  const [currentlyEditingEntry, setCurrentlyEditingEntry] =
    useState<string>("");

  const { data } = useGetHealthHistoryQuery({
    patientId: patientId
  });
  const { data: patient } = usePatientGetInfoQuery(
    {
      patientId
    },
    { skip: !patientId }
  );

  const [submitEntry] = useCreatetHealthHistoryEntryMutation();

  const inputRef = useRef<HTMLInputElement>(null);

  const entries: HealthHistoryEntry[] = useMemo(() => {
    if (!data) {
      return [];
    }
    const sectionKey = section.toLowerCase() as keyof PatientHealthHistory;
    return data[sectionKey];
  }, [data]);

  useEffect(() => {
    if (entries?.length > 0 && !isOpen) {
      onOpenToggle(cardIndex);
    }
  }, [entries?.length]);

  const list = {
    hidden: {
      height: 0,
      transition: {
        when: "afterChildren",
        staggerChildren: 0.2
      },
      transitionEnd: {
        display: "none"
      }
    },
    shown: {
      height: "auto",
      transitionEnd: {
        display: "block"
      },
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.2
      }
    }
  };

  const item = {
    hidden: { x: -10, opacity: 0 },
    shown: { x: 0, opacity: 1 }
  };

  const chevron = {
    hidden: { transform: "rotate(0)" },
    shown: { transform: "rotate(180deg)" }
  };

  const input = {
    hidden: { width: 0, border: "none", borderColor: "#d0d5dd" },
    shown: { width: "auto", borderBottom: "solid 1px #d0d5dd" }
  };

  const onAddEntry = () => {
    if (!isAddingEntry) {
      switch (section) {
        case "ALLERGY":
          dispatch(
            setModalContent({
              type: MODAL_TYPES.ALLERGIES,
              props: {
                // I think this is caused by an old to-do to update the spec since this is
                // how it is in the allergies modal itself
                // @ts-ignore - allergies doesn't exist on metadata
                allergies: patient?.metadata?.allergies,
                patientId: patientId,
                title: "Allergies"
              }
            })
          );
          dispatch(setModalIsOpen(true));
          setIsAddingEntry(false);
          setCurrentlyEditingEntry("");
          break;
        case "PROBLEM":
          dispatch(
            setModalContent({
              type: MODAL_TYPES.PROBLEMS,
              props: {
                problems: patient?.problems,
                patientId: patient?.user_id,
                title: "Problems"
              }
            })
          );
          dispatch(setModalIsOpen(true));
          setIsAddingEntry(false);
          setCurrentlyEditingEntry("");
          break;
        case "MEDICATION":
          dispatch(
            setModalContent({
              type: MODAL_TYPES.MEDICATION_LIST,
              props: {
                patient,
                title: "Edit Medication"
              }
            })
          );
          dispatch(setModalIsOpen(true));
          setIsAddingEntry(false);
          setCurrentlyEditingEntry("");
          break;
        default:
          setIsAddingEntry(true);
          break;
      }
    } else {
      if (entryText == "") {
        setIsAddingEntry(false);
        return;
      }
      submitEntry({
        patientId: patientId,
        patientHealthHistoryCreate: {
          title: entryText,
          section: section
        }
      })
        .unwrap()
        .then(() => {
          setIsAddingEntry(false);
          setEntryText("");
          inputRef.current?.blur();
        });
    }
  };

  const handleEntryOptionsClicked = (entry: HealthHistoryEntry) => {
    switch (section) {
      case "ALLERGY":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.ALLERGIES,
            props: {
              // I think this is caused by an old to-do to update the spec since this is
              // how it is in the allergies modal itself
              // @ts-ignore - allergies doesn't exist on metadata
              allergies: patient?.metadata?.allergies,
              patientId: patientId,
              title: "Allergies"
            }
          })
        );
        dispatch(setModalIsOpen(true));
        setIsAddingEntry(false);
        setCurrentlyEditingEntry("");
        break;
      case "PROBLEM":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.PROBLEMS,
            props: {
              problems: patient?.problems,
              patientId: patient?.user_id,
              title: "Problems"
            }
          })
        );
        dispatch(setModalIsOpen(true));
        setIsAddingEntry(false);
        setCurrentlyEditingEntry("");
        break;
      case "MEDICATION":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.MEDICATION_LIST,
            props: {
              patient,
              title: "Medications"
            }
          })
        );
        dispatch(setModalIsOpen(true));
        setIsAddingEntry(false);
        setCurrentlyEditingEntry("");
        break;
      default:
        const props: EditHealthHistoryEntryProps = {
          section,
          entry,
          patientId
        };
        dispatch(
          setModalContent({
            type: MODAL_TYPES.EDIT_HEALTH_HISTORY_ENTRY,
            props: {
              ...props,
              title: `Edit ${HEALTH_HISTORY_LABEL[section]} entry`
            }
          })
        );
        dispatch(setModalIsOpen(true));
        setIsAddingEntry(false);
        setCurrentlyEditingEntry("");
        break;
    }
  };

  const handleEntryClick = (entry: HealthHistoryEntry) => {
    switch (section) {
      case "ALLERGY":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.ALLERGIES,
            props: {
              // I think this is caused by an old to-do to update the spec since this is
              // how it is in the allergies modal itself
              // @ts-ignore - allergies doesn't exist on metadata
              allergies: patient?.metadata?.allergies,
              patientId: patientId,
              title: "Allergies"
            }
          })
        );
        dispatch(setModalIsOpen(true));
        setIsAddingEntry(false);
        setCurrentlyEditingEntry("");
        break;
      case "PROBLEM":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.PROBLEMS,
            props: {
              problems: patient?.problems,
              patientId: patient?.user_id,
              title: "Problems"
            }
          })
        );
        dispatch(setModalIsOpen(true));
        setIsAddingEntry(false);
        setCurrentlyEditingEntry("");
        break;
      case "MEDICATION":
        dispatch(
          setModalContent({
            type: MODAL_TYPES.MEDICATION_LIST,
            props: {
              patient,
              title: "Edit Medication"
            }
          })
        );
        dispatch(setModalIsOpen(true));
        setIsAddingEntry(false);
        setCurrentlyEditingEntry("");
        break;
      default:
        setCurrentlyEditingEntry(entry.id);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      setIsAddingEntry(false);
      setCurrentlyEditingEntry("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAddingEntry]);

  return (
    <div className={styles.historyCard}>
      <div
        className={styles.cardHeader}
        onClick={() => onOpenToggle(cardIndex)}
      >
        <p>{HEALTH_HISTORY_LABEL[section]} </p>
        {section === "ALLERGY" &&
          !patient?.medical_history?.no_known_drug_allergies && (
            <div className={styles.alert} data-cy="history-card-allergy-count">
              <Icon svg={"warning"} width={15} height={15}></Icon>
              {patient?.medical_history.allergies?.length
                ? patient?.medical_history.allergies?.length
                : "Not recorded"}
            </div>
          )}
        {section === "ALLERGY" &&
          patient?.medical_history?.no_known_drug_allergies && (
            <div
              className={clsx(styles.alert, styles.normal)}
              data-cy="history-card-allergy-NKDA"
            >
              NKDA
            </div>
          )}
        <motion.div
          animate={isOpen ? "shown" : "hidden"}
          variants={chevron}
          className={styles.showHideIcon}
        >
          <Icon svg={"chevron_up_blue"} width={15} height={15}></Icon>
        </motion.div>
      </div>
      <motion.div
        animate={isOpen ? "shown" : "hidden"}
        variants={list}
        className={styles.cardBody}
      >
        <ul className={styles.historyEntries}>
          {entries &&
            entries.map(entry => (
              <HealthHistoryEntryItem
                entry={entry}
                onEntryClick={handleEntryClick}
                isEditing={currentlyEditingEntry == entry.id}
                patientId={patientId}
                onEditSubmit={() => {
                  setCurrentlyEditingEntry("");
                }}
                onOptionsClick={entry => {
                  handleEntryOptionsClicked(entry);
                }}
                key={entry.id}
              />
            ))}
        </ul>
        <motion.div variants={item} animate={isOpen ? "shown" : "hidden"}>
          <div className={styles.newEntryContainer}>
            <motion.div
              className={styles.inputContainer}
              variants={input}
              animate={isAddingEntry ? "shown" : "hidden"}
            >
              {isAddingEntry && (
                <input
                  type="text"
                  placeholder="New entry"
                  value={entryText}
                  onChange={e => setEntryText(e.target.value)}
                  ref={inputRef}
                  onKeyDown={e => {
                    if (e.key === "Enter") {
                      onAddEntry();
                    }
                  }}
                />
              )}
            </motion.div>
            <button className={styles.newEntry} onClick={onAddEntry}>
              <Plus />
            </button>
          </div>
        </motion.div>
      </motion.div>
    </div>
  );
}

// External
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";

import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";
import ContentRenderer from "@/components/textArea/contentRenderer";

// Store
import { EncounterInfo } from "@/store/services/encounter";
import { LaborModel, PatientInfo } from "@/store/services/patient";

import { useCustomPracticeLocations } from "@/globals/helpers/customHooks";

// Components
import KeyValueGrid from "../keyValueGrid";
import KeyValueItem from "../keyValueItem";
import ReadOnlySection from "../readOnlySection";
import SectionCol from "../sectionCol";
import SectionRow from "../sectionRow";

interface SummaryBirthInfoProp {
  patient: PatientInfo;
  labor: LaborModel;
  encounter: EncounterInfo;
}

export default function SummaryBirthInfo({
  patient,
  labor,
  encounter
}: SummaryBirthInfoProp) {
  const [birthForm, setBirthForm] = useState<Record<string, any>>({});
  const [bloodLossForm, setBloodLossForm] = useState<Record<string, any>>({});
  const [bloodLossString, setBloodLossString] = useState<string>("");

  const locations = useCustomPracticeLocations();

  useEffect(() => {
    // we used to store the placenta and laceration forms under the birth stage
    // now we store them under the labor stage
    // this code is to handle the backwards compatibility of the data
    if (labor?.stages?.birth?.events?.birth?.forms) {
      setBirthForm(
        labor.stages.birth.events.birth.forms as Record<string, any>
      );
    }
    if (labor?.stages?.labor?.events?.birth?.forms) {
      setBirthForm(
        labor.stages.labor.events.birth.forms as Record<string, any>
      );
    }

    // Same reason as above for doubling up, backwards compatibility
    if (labor?.stages?.birth?.events?.blood_loss?.forms) {
      const form: Record<string, any> =
        (labor?.stages?.birth?.events?.blood_loss?.forms as Record<
          string,
          any
        >) || {};
      setBloodLossString(form["EBL/QBL"] || "-");
    }
    let laborString = "";
    let ppString = "";
    if (labor?.stages?.labor?.events?.blood_loss?.forms) {
      const form: Record<string, any> =
        (labor?.stages?.labor?.events?.blood_loss?.forms as Record<
          string,
          any
        >) || {};
        laborString = form["EBL/QBL"] + "(Labor) " || "";
    }
    if (labor?.stages?.immediate_postpartum?.events?.blood_loss?.forms) {
      const form: Record<string, any> =
        (labor?.stages?.immediate_postpartum?.events?.blood_loss?.forms as Record<
          string,
          any
        >) || {};
        ppString = form["EBL/QBL"] + "(Postpartum) " || "";
    }

    setBloodLossString(laborString + ppString);
  }, [labor]);

  const forms = labor?.stages?.conclusion?.events?.labor_summary
    ?.forms as Record<string, any>;

  const birthNarrativeNoteContent = useMemo(() => {
    if (forms?.birth_summary) {
      return forms.birth_summary;
    } else {
      return "-";
    }
  }, [forms?.birth_summary]);

  return (
    <ReadOnlySection header="Birth Information">
      <SectionRow>
        {birthForm && (
          <SectionCol>
            <KeyValueGrid>
              <KeyValueItem keyLabel="Presentation:">
                {birthForm.presentation ? (
                  <p>{birthForm.presentation}</p>
                ) : (
                  <p>
                    <em>Presentation not set in the birth milestone</em>
                  </p>
                )}
              </KeyValueItem>
              <KeyValueItem keyLabel="Maternal Position:">
                {birthForm.maternal_position ? (
                  <p>{birthForm.maternal_position}</p>
                ) : (
                  <p>
                    <em>Maternal position not set in the birth milestone</em>
                  </p>
                )}
              </KeyValueItem>
              <KeyValueItem keyLabel="Fetal Position:">
                {birthForm.fetal_position ? (
                  <p>{birthForm.fetal_position}</p>
                ) : (
                  <p>
                    <em>Fetal position not set in the birth milestone</em>
                  </p>
                )}
              </KeyValueItem>
              <KeyValueItem keyLabel="Nuchal Cord:">
                {birthForm.nuchal_cord ? (
                  <p>{birthForm.nuchal_cord}</p>
                ) : (
                  <p>
                    <em>Nuchal cord not set in the birth milestone</em>
                  </p>
                )}
              </KeyValueItem>
            </KeyValueGrid>
          </SectionCol>
        )}
        <SectionCol>
          <KeyValueGrid>
            <KeyValueItem keyLabel="1st Breath:">
              <p>
                {patient?.pregnancy?.labor?.stages?.birth?.events?.first_breath
                  ?.start ||
                patient?.pregnancy?.labor?.stages?.labor?.events?.first_breath
                  ?.start ? (
                  <p>
                    {convertUtcIntToLocalDatetime(
                      patient.pregnancy?.labor?.stages?.birth?.events
                        ?.first_breath.start ||
                        patient?.pregnancy?.labor?.stages?.labor?.events
                          ?.first_breath?.start ||
                        0
                    ).format("MM/DD/YYYY hh:mm:ss a")}
                  </p>
                ) : (
                  <p>
                    <em>First breath milestone not set</em>
                  </p>
                )}
              </p>
            </KeyValueItem>
            {birthForm && (
              <KeyValueItem keyLabel="Delivery Type:">
                {birthForm.delivery_type ? (
                  <p>{birthForm.delivery_type}</p>
                ) : (
                  <p>
                    <em>Delivery type not set in the birth milestone</em>
                  </p>
                )}
              </KeyValueItem>
            )}
            {bloodLossString !== "" && (
              <KeyValueItem keyLabel="Blood Loss:">
                <p>{bloodLossString}</p>
              </KeyValueItem>
            )}
            {/* Location name */}
            <KeyValueItem keyLabel="Place of Service:">
              {encounter.location ? (
                <p>
                  {typeof encounter.location === "string"
                    ? encounter.location?.toLocaleLowerCase()
                    : locations.find(
                        // @ts-ignore
                        loc => loc.location_id == encounter.location
                        // @ts-ignore
                      )?.name || ""}
                </p>
              ) : (
                <p>
                  <em>Encounter location not set</em>
                </p>
              )}
            </KeyValueItem>
            <KeyValueItem keyLabel="Labor Summary:">
              <ContentRenderer content={birthNarrativeNoteContent} />
            </KeyValueItem>
          </KeyValueGrid>
        </SectionCol>
      </SectionRow>
    </ReadOnlySection>
  );
}

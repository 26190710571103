import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

import { convertLocalDatetimeToUtcInt } from "@/components/scheduling/calendars/utils";

import {
  EncounterBriefListApiArg,
  IdType,
  useEncounterBriefListQuery,
  useEncounterListQuery
} from "@/store/services/encounter";
import {
  LocationListItem,
  useLocationGetListQuery
} from "@/store/services/location";
import { PatientInfo, PatientType } from "@/store/services/patient";
import {
  AppointmentTypeDetails,
  AppointmentTypeList,
  usePracticeInfoQuery,
  usePracticeListAppointmentTypesQuery
} from "@/store/services/practice";
import { useGetFeatureFlagsQuery } from "@/store/services/system";
import { RootState } from "@/store/store";

import {
  APP_KEYS,
  DASH_CONFIG,
  FLOW_KEYS,
  GRID_METRICS,
  METRICS,
  METRIC_LABELS,
  SCHEDULE_VIEWS
} from "../constants";
import { FORM_SCHEMA } from "../constants/formSchema";
import { DashboardType } from "../constants/types";
import { getUserPersona } from "../helpers";
import { FORMAT } from "./formatters";

const useCustomPatientTypes = () => {
  const { data: features } = useGetFeatureFlagsQuery();
  const [patientTypes, setPatientTypes] = useState<PatientType[]>([]);

  useEffect(() => {
    if (features) {
      let patientTypes: string[] = [METRICS.GYN, METRICS.PROSPECTIVE];
      if (features.prenatal_charting_enabled) {
        // Include prenatal type if prenatal_charting_enabled is true
        patientTypes = [METRICS.OB, ...patientTypes];
      }
      if (features.postpartum_charting_enabled) {
        // Include postpartum type if postpartum_charting_enabled is true
        patientTypes = [...patientTypes, METRICS.POSTPARTUM];
      }
      if (features.infant_charting_enabled) {
        // Include infant type if infant_charting_enabled is true
        patientTypes = [...patientTypes, METRICS.INFANT];
      }
      if (features.primary_care_charting_enabled) {
        // Include primary care type if primary_care_charting_enabled is true
        patientTypes = [...patientTypes, METRICS.PRIMARY_CARE];
      }
      setPatientTypes([...patientTypes, METRICS.INACTIVE] as PatientType[]);
    }
  }, [features]);

  return patientTypes;
};

const useCustomPatientDemographics = () => {
  const { data: features } = useGetFeatureFlagsQuery();
  const [patientDemographics, setPatientDemographics] = useState<
    {
      fieldType: string;
      fieldID: string;
      options?: { value: string; label: string }[];
      label: string;
    }[]
  >([]);

  const customPatientSexOptions = useCustomPatientSexOptions();
  useEffect(() => {
    // Define your default patient demographics
    const defaultPatientDemographics = FORM_SCHEMA.PatientDemographicsFields;
    if (features) {
      let customPatientDemos = defaultPatientDemographics;
      if (!features.pronouns_enabled) {
        customPatientDemos = customPatientDemos.filter(
          demo => demo.fieldID !== "pronouns"
        );
      } else {
        const sexOptionIndex = customPatientDemos.findIndex(
          opt => opt.fieldID == "sex"
        );
        if (sexOptionIndex > -1) {
          customPatientDemos[sexOptionIndex].label = "Sex at birth";
        }
      }
      if (!features.male_patients_enabled) {
        const sexOptions = customPatientSexOptions.map(opt => ({
          value: opt,
          label: FORMAT.capitalize(opt)
        }));
        customPatientDemos = customPatientDemos.map(demo =>
          demo.fieldID === "sex" ? { ...demo, options: sexOptions } : demo
        );
      }
      setPatientDemographics(customPatientDemos);
    }
  }, [features, customPatientSexOptions]);
  return patientDemographics;
};

const useCustomPatientSexOptions = () => {
  const { data: features } = useGetFeatureFlagsQuery();
  const [sexOptions, setSexOptions] = useState<string[]>([]);

  useEffect(() => {
    // Define
    const defaultSexOptions = [METRICS.FEMALE, METRICS.UNKNOWN];
    if (features) {
      if (features.male_patients_enabled) {
        setSexOptions([METRICS.FEMALE, METRICS.MALE, METRICS.UNKNOWN]);
      } else {
        setSexOptions(defaultSexOptions);
      }
    }
  }, [features]);
  return sexOptions;
};

const useCustomUserLinks = () => {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const persona = getUserPersona(sessionInfo);
  const { data: features } = useGetFeatureFlagsQuery(undefined, {
    skip: !sessionInfo
  });
  const [userLinks, setUserLinks] = useState<{ label: string; href: string }[]>(
    []
  );
  // in the case user ends up with a sidebar without links
  const defaultLinks = [
    { label: "home", href: "/home" },
    { label: "scheduling", href: "/scheduling" },
    { label: "patients", href: "/patients" },
    { label: "phrases", href: "/phrases" },
    { label: "files", href: "/practiceFiles" }
  ];

  const overrideLinks = {
    [METRICS.PATIENT]: [
      { label: "home", href: "/home" },
      { label: "messaging", href: "/inbox" }
    ]
  };

  useEffect(() => {
    const links = defaultLinks;
    if (features) {
      // if messaging is enabled, add the messaging link between patients and phrases
      if (features.messaging_enabled) {
        links.splice(3, 0, { label: "messaging", href: "/inbox" });
      }
      if (features.clm_enabled) {
        links.push({ label: "claims", href: "/claims" });
      }

      if (sessionInfo?.is_superadmin) {
        links.push({ label: "settings", href: "/settings" });
      }
    }
    setUserLinks(overrideLinks[persona] || links);
  }, [persona, features, sessionInfo]);

  return userLinks;
};

const useCustomOBDatagridColumns = () => {
  const { data: practiceInfo } = usePracticeInfoQuery();

  const [columns, setColumns] = useState<any[]>([]);
  const defaultColumns = GRID_METRICS[METRICS.OB];

  useEffect(() => {
    if (
      practiceInfo &&
      practiceInfo.desired_birthplaces &&
      practiceInfo.desired_birthplaces.length > 0
    ) {
      const columnsWithDesiredBirthLoc = [
        ...defaultColumns.slice(0, -1),
        METRICS.DESIRED_BIRTH_LOC,
        ...defaultColumns.slice(-1)
      ];
      setColumns(columnsWithDesiredBirthLoc);
    } else {
      setColumns(defaultColumns);
    }
  }, [practiceInfo]);

  return columns;
};

// get locations
const useCustomPracticeLocations = () => {
  // locations
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const { data: practiceInfo } = usePracticeInfoQuery();

  const { data: locations } = useLocationGetListQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo?.practice_id }
  );

  const [allLocations, setAllLocations] = useState<
    (string | LocationListItem)[]
  >([]);
  useEffect(() => {
    const allLocations = [
      ...(locations || []),
      ...(practiceInfo?.alt_locations || [])
    ];
    setAllLocations(allLocations);
  }, [practiceInfo, locations]);

  return allLocations;
};

// return custom schedule views based on feature flags
// if feature flag multi_provider_schedule_enabled is true, include MULTI_VIEW, else include DAY_VIEW
const useCustomScheduleViews = () => {
  const { data: features } = useGetFeatureFlagsQuery();
  const [scheduleViews, setScheduleViews] = useState<
    { id: string; label: string }[]
  >([]);
  const defaultScheduleViews = SCHEDULE_VIEWS;

  useEffect(() => {
    const scheduleViews = features?.multi_provider_schedule_enabled
      ? [
          { id: APP_KEYS.MULTI, label: APP_KEYS.DAY },
          ...defaultScheduleViews.slice(1)
        ]
      : defaultScheduleViews;
    setScheduleViews(scheduleViews);
  }, [features]);
  return scheduleViews;
};

// use custom default calendar view
const useDefaultCalendarView = () => {
  const { data: features } = useGetFeatureFlagsQuery();
  const [defaultView, setDefaultView] = useState<string>(APP_KEYS.MULTI);

  useEffect(() => {
    const view = features?.multi_provider_schedule_enabled
      ? APP_KEYS.MULTI
      : APP_KEYS.DAY;

    setDefaultView(view);
  }, [features]);
  return defaultView;
};

// return custom calendar color rule depending on settings in practice info
const useColorByProvider = () => {
  const { data: features } = useGetFeatureFlagsQuery();
  const { data: practiceInfo } = usePracticeInfoQuery();

  const [colorRule, setColorRule] = useState<boolean | null>(true);
  useEffect(() => {
    if (practiceInfo) {
      setColorRule(practiceInfo.calendar_color_coding === "provider_id");
    }
  }, [practiceInfo, features]);
  return colorRule;
};

// return custom calendar slot interval depending on settings in practice info
const useCustomCalendarSlotInterval = () => {
  const { data: practiceInfo } = usePracticeInfoQuery();

  const [slotInterval, setSlotInterval] = useState<number>(30);
  useEffect(() => {
    if (practiceInfo) {
      setSlotInterval(practiceInfo.calendar_slot_interval);
    }
  }, [practiceInfo]);
  return slotInterval;
};

// return whether calendar should have accessible styles
const useAccessibleCalStyles = () => {
  const { data: features } = useGetFeatureFlagsQuery();
  const [accessibleStyles, setAccessibleStyles] = useState<boolean>(false);
  useEffect(() => {
    if (features?.accessible_calendar_enabled) {
      setAccessibleStyles(features?.accessible_calendar_enabled);
    }
  }, [features?.accessible_calendar_enabled]);
  return accessibleStyles;
};

const useIncompleteEncountersList = (
  persona: "provider" | "practice",
  page?: number
) => {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: encounters } = useEncounterListQuery({
    id: (persona === "provider"
      ? sessionInfo?.user_id
      : sessionInfo?.practice_id) as number,
    // TODO: look into experience for medical assistants
    scope: persona,
    encounterStatus: "IN_PROGRESS",
    page,
    pagesz: 10,
    end: convertLocalDatetimeToUtcInt(dayjs().startOf("day"))
  });

  return encounters;
};

const useIncompleteEncountersTotal = (persona: "provider" | "practice") => {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  let scope: IdType = "practice";
  let id: number = sessionInfo?.practice_id || 0;
  if (persona === "provider") {
    scope = "provider";
    id = sessionInfo?.user_id || 0;
  }
  const endOfTodayUtcInt = convertLocalDatetimeToUtcInt(dayjs().endOf("day"));
  const incompleteEncounterBriefListParams: EncounterBriefListApiArg = {
    id,
    scope,
    encounterStatus: "IN_PROGRESS",
    end: endOfTodayUtcInt
  };

  const { data: incompleteEncounters } = useEncounterBriefListQuery(
    incompleteEncounterBriefListParams
  );

  return incompleteEncounters?.count;
};

/**
 * Custom hook for getting filtered appointment types available to the patient
 * @param patient PatientInfo for the patient in question
 * @returns [appointmentTypeOptions, appointmentTypes] - The first element of the
 * return array is the options which is what's usually needed, the second element
 * is the listing on appointment types, if needed for something other than a select
 */
const useFilteredAppointmentTypes = (
  patient: PatientInfo | undefined
): [
  {
    value: AppointmentTypeDetails;
    label: string;
  }[],
  boolean
] => {
  const { data: appointmentTypes, isLoading: isLoadingPracticeList } =
    usePracticeListAppointmentTypesQuery();
  const { data: features, isLoading: isLoadingFeatureFlags } =
    useGetFeatureFlagsQuery();
  const filteredTypes = useMemo(() => {
    let filteredTypes: AppointmentTypeList = [];
    if (appointmentTypes && patient && features) {
      filteredTypes = appointmentTypes;
      // Remove LABOR encounter type for non-OB patient types
      // FLOW_KEYS.LABOR && METRICS.OB represent the labor encounter type and OB patient type respectively
      if (patient.practice_data.type !== METRICS.OB) {
        // Filter out labor appointments for non-ob patients
        filteredTypes = filteredTypes.filter(
          apptType => apptType.encounter_type !== FLOW_KEYS.LABOR
        );

        // Filter out prenatal appointments for non-ob patients
        filteredTypes = filteredTypes.filter(
          apptType => apptType.encounter_type !== FLOW_KEYS.PRENATAL
        );
      }
      // Remove INFANT encounter type for non-INFANT patient types
      // FLOW_KEYS.INFANT && METRICS.INFANT represent the infant encounter type and INFANT patient type respectively
      if (patient.practice_data.type !== METRICS.INFANT) {
        filteredTypes = filteredTypes.filter(
          apptType => apptType.encounter_type !== FLOW_KEYS.INFANT
        );
      }
      // Remove all encounter types except INFANT for infant patients
      if (patient.practice_data.type === METRICS.INFANT) {
        filteredTypes = filteredTypes.filter(
          apptType => apptType.encounter_type === FLOW_KEYS.INFANT
        );
      }

      // Remove all POSTPARTUM type encounters if postpartum charting is not enabled
      if (!features.postpartum_charting_enabled) {
        filteredTypes = filteredTypes.filter(
          apptType => apptType.encounter_type !== FLOW_KEYS.POSTPARTUM
        );
      }

      // Remove all INFANT type encounters if infant charting is not enabled
      if (!features.infant_charting_enabled) {
        filteredTypes = filteredTypes.filter(
          apptType => apptType.encounter_type !== FLOW_KEYS.INFANT
        );
      }

      // Remove all PRENATAL type encounters if prenatal charting is not enabled
      if (!features.prenatal_charting_enabled) {
        filteredTypes = filteredTypes.filter(
          apptType => apptType.encounter_type !== FLOW_KEYS.PRENATAL
        );
      }

      // Remove all LABOR type encounters if labor charting is not enabled
      if (!features.labor_charting_enabled) {
        filteredTypes = filteredTypes.filter(
          apptType => apptType.encounter_type !== FLOW_KEYS.LABOR
        );
      }
    }
    return filteredTypes;
  }, [patient, appointmentTypes, features]);

  const apptTypeOptions = filteredTypes.map(apptType => ({
    value: apptType,
    label: METRIC_LABELS[apptType.appointment_type] || apptType.appointment_type
  }));

  const isLoading = isLoadingFeatureFlags || isLoadingPracticeList;
  return [apptTypeOptions, isLoading];
};

const useCustomDashConfig = (dashType: DashboardType) => {
  const { data: features } = useGetFeatureFlagsQuery();
  let defaultDashConfig = DASH_CONFIG[dashType];

  const dashConfig = useMemo(() => {
    // If features query hasn't completed, return default config
    if (!features) return defaultDashConfig;

    if (features.dev_provider_tab_dashboard && dashType == METRICS.PROVIDER) {
      defaultDashConfig = DASH_CONFIG["dev" + METRICS.PROVIDER];
    }

    // Handle feature flag
    return features.dev_staff_tasking_enabled
      ? defaultDashConfig
      : defaultDashConfig.filter(dash => dash.id !== APP_KEYS.STAFF_TASKS);
  }, [features, dashType, defaultDashConfig]);

  return dashConfig;
};

export {
  useAccessibleCalStyles,
  useColorByProvider,
  useCustomCalendarSlotInterval,
  useCustomDashConfig,
  useCustomOBDatagridColumns,
  useCustomPatientDemographics,
  useCustomPatientSexOptions,
  useCustomPatientTypes,
  useCustomPracticeLocations,
  useCustomScheduleViews,
  useCustomUserLinks,
  useDefaultCalendarView,
  useFilteredAppointmentTypes,
  useIncompleteEncountersList,
  useIncompleteEncountersTotal
};

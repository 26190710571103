/* Send File For Review Modal */
import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { skipToken } from "@reduxjs/toolkit/query";
import clsx from "clsx";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import Select, { MultiValue, StylesConfig } from "react-select";

import Button from "@/components/button";
import { addRemoveSelectedFileId } from "@/components/fileExplorer/fileExplorerSlice";
import Icon from "@/components/icons";
import Input from "@/components/input";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { STATUS_KEYS, STYLES } from "@/globals/constants";

import { FileListItem, useFileUpdateMutation } from "@/store/services/file";
import { PatientInfo, ProviderInfo } from "@/store/services/patient";
import { usePracticeSearchUsersQuery } from "@/store/services/practice";
import { useTagsListQuery } from "@/store/services/tag";
import { useTaskCreateMutation } from "@/store/services/task";
import { RootState } from "@/store/store";

import { FORMAT, nameAndDOB } from "@/globals/helpers/formatters";

import { setModalIsOpen } from "../../modalSlice";
import styles from "../../styles.module.scss";

export interface SendFilesForReviewProps {
  file: FileListItem;
  inbound_fax_id?: number;
}

type TagOption = { label: string; value: number };

type PatientOption = { value: PatientInfo["user_id"]; label: string };

type SendFilesForReviewForm = {
  select_patient: PatientInfo["user_id"];
  select_provider: ProviderInfo | null;
  file_name: string;
  file_tags: TagOption[];
};

export default function SendFilesForReview({
  file,
  inbound_fax_id
}: SendFilesForReviewProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const [updateFile, updateFileReq] = useFileUpdateMutation();

  const [createTask, createTaskReq] = useTaskCreateMutation();

  const { data: users, isSuccess: gotUsers } = usePracticeSearchUsersQuery(
    sessionInfo?.practice_id
      ? { practiceId: sessionInfo.practice_id }
      : skipToken
  );

  const { data: availableTags = [] } = useTagsListQuery(
    sessionInfo?.practice_id
      ? { practiceId: sessionInfo.practice_id }
      : skipToken
  );

  const tagOptions: TagOption[] | undefined = useMemo(() => {
    return availableTags.map(tag => ({
      label: tag.name,
      value: tag.tag_id
    }));
  }, [availableTags]);

  const fileTags = tagOptions?.filter(tag =>
    Object.keys(file.tags)?.includes(tag.value.toString())
  );

  const form = useForm<SendFilesForReviewForm>({
    defaultValues: {
      select_patient: file.patient_id,
      file_name: file.name,
      file_tags: fileTags
    }
  });

  const { handleSubmit, control } = form;

  // Memoized values
  const providerOptions = useMemo(() => {
    // Add null checks
    if (!users?.providers) return [];

    const allUsers = users.providers.concat(users.medical_assistants || []);
    return allUsers
      .map(user => ({
        label: user ? FORMAT.name(user) : "", // Add null check
        value: user
      }))
      .filter(option => option.label); // Filter out empty labels
  }, [users]);

  const patientOptions: PatientOption[] = useMemo(() => {
    if (!users?.patients) return [];

    return users.patients
      .filter(p => p) // Filter out any null/undefined patients
      .map(p => ({
        value: p.user_id,
        label: nameAndDOB(p)
      }));
  }, [users]);

  const handleCancel = () => {
    // close modal
    dispatch(setModalIsOpen(false));
  };

  const onSubmit = (data: SendFilesForReviewForm) => {
    // first creater the DOCUMENT_REVIEW assigned to the selected provider
    createTask({
      taskCreatePayload: {
        task_type: "DOCUMENT_REVIEW",
        staff_assignee: data.select_provider?.user_id,
        associated_patient: data.select_patient,
        document_review: {
          file_id: file.file_id,
          fax_id: inbound_fax_id
        }
      }
    })
      .then(res => {
        // then update the file with new name, tags, and patient_id
        // this will also move the file to patient files
        updateFile({
          fileId: file.file_id,
          fileUpdateRequest: {
            name: data.file_name,
            tags: data.file_tags.map(tag => tag.value),
            patient_id: data.select_patient
          }
        })
          .then(res => {
            dispatch(
              addAlertToToastTrough({
                message: "File sent for review",
                status: STATUS_KEYS.SUCCESS
              })
            );
            dispatch(addRemoveSelectedFileId(file.file_id));
            dispatch(setModalIsOpen(false));
          })
          .catch(err => {
            dispatch(
              addAlertToToastTrough({
                message: "Failed to send file for review",
                status: STATUS_KEYS.ERROR
              })
            );
          });
      })
      .catch(err => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to send file for review",
            status: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <form
      className={clsx(styles.SendFilesForReview)}
      onSubmit={handleSubmit(onSubmit)}
      data-cy="send-files-for-review"
    >
      {gotUsers ? (
        <>
          <div className={styles.message}>
            <div>
              <p>Please select a patient to copy the file to:</p>
              <Controller
                name="select_patient"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select<PatientOption>
                    styles={SelectDefaultStyles as StylesConfig<PatientOption>}
                    theme={SelectDefaultTheme}
                    options={patientOptions}
                    value={patientOptions.find(item => item.value === value)}
                    onChange={newValue => {
                      if (!newValue) return;
                      onChange(newValue.value);
                    }}
                    required
                    id="file_review_select_patient"
                  />
                )}
              />
            </div>
            <div>
              <p>Please select a provider to send file to for review:</p>

              <Controller
                name="select_provider"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={providerOptions}
                    // @ts-ignore
                    value={providerOptions?.find(
                      item => item.value.user_id === value?.user_id
                    )}
                    //@ts-ignore
                    onChange={newValue => onChange(newValue?.value)}
                    required
                    id="file_review_select_provider"
                  />
                )}
              />
            </div>
            <div>
              <p>
                Rename the file:<sup>*</sup>
              </p>

              <span className="t5">
                <sup>*</sup>File name must include a file extension (e.g. .pdf,
                .jpg)
              </span>
              <Controller
                name="file_name"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Input
                    type="text"
                    name="file_name"
                    id="file_review_file_name"
                    label="Change file name"
                    hiddenLabel
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    required
                  />
                )}
              />
            </div>
            <div>
              <p>Tags:</p>
              <Controller
                name="file_tags"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Select
                    isMulti
                    styles={SelectDefaultStyles}
                    theme={SelectDefaultTheme}
                    options={tagOptions}
                    value={value}
                    onChange={onChange}
                    id="file_review_tags"
                  />
                )}
              />
            </div>
          </div>

          <div className={styles.buttons}>
            <Button style={STYLES.SECONDARY} onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              style={STYLES.PRIMARY}
              type="submit"
              loading={createTaskReq.isLoading || updateFileReq.isLoading}
            >
              <Icon svg="check-done-wh" />
              Confirm
            </Button>
          </div>
        </>
      ) : (
        <Skeleton count={6} height={20} />
      )}
    </form>
  );
}

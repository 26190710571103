import dayjs from "dayjs";
import React from "react";
import { useEffect, useState } from "react";
import { Control, FieldValues, Path, useController } from "react-hook-form";

import Input from "@/components/input";

import styles from "./styles.module.scss";

interface CustomInputProps<T extends FieldValues>
  extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  name: Path<T>;
  control: Control<T>;
}

export default function DateTimeSplitInput<T extends FieldValues>({
  name,
  control
}: CustomInputProps<T>) {
  const { field } = useController({ name, control });

  const [date, setDate] = useState<string | undefined>(
    field.value ? dayjs(field.value).format("YYYY-MM-DD") : undefined
  );
  const [time, setTime] = useState<string | undefined>(
    field.value ? dayjs(field.value).format("HH:mm:ss") : undefined
  );

  useEffect(() => {
    if (!date || !dayjs(date).isValid()) return;
    const date_time = dayjs(time ? `${date}T${time}` : date)
      .utc()
      .format();
    field.onChange(date_time);
  }, [date, time]);

  return (
    <div className={styles.DateTimeSplitInput}>
      <Input
        fullWidth
        id="date"
        label="Date"
        name="date"
        value={date}
        onChange={e => setDate(e.target.value)}
        required
        type="date"
      />
      <Input
        fullWidth
        id="time"
        label="Time (optional)"
        name="time"
        value={time}
        onChange={e => setTime(e.target.value)}
        placeholder="--:--"
        type="time"
      />
    </div>
  );
}

/* Assign Generic Staff Task  */
/* External Imports */
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Button from "@/components/button";
import { MODAL_TYPES } from "@/components/modal/dispatcher";
import { setModalContent, setModalIsOpen } from "@/components/modal/modalSlice";

// styles
import { STYLES } from "@/globals/constants";

// constants

// store
import { PatientInfo } from "@/store/services/patient";

/* DeletePatient Typescript Interface */
interface DeletePatientProps {
  patient: PatientInfo;
}

export default function DeletePatient({ patient }: DeletePatientProps) {
  /* Redux */
  const dispatch = useDispatch();

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const handleDeletePatient = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.CONFIRM_DELETE_PATIENT,
        props: {
          title: "Confirm Delete Patient",
          patient
        }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  return (
    <Button style={STYLES.DELETE} onClick={handleDeletePatient}>
      Delete Patient
    </Button>
  );
}

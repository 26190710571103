// External
import { useDispatch } from "react-redux";
import { ChangeEvent, useState } from "react";

// TipTap
import { EditorContent, useEditor } from "@tiptap/react";
import { getDefaultExtensions } from "@/components/textArea/textAreaHelpers";

// Api
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { STATUS_KEYS, STYLES } from "@/globals/constants";
import { setModalIsOpen } from "../../modalSlice";
import { PhraseUpsertRequest } from "@/store/services/generated/phrase";

// Components
import Button from "@/components/button";
import Input from "@/components/input";
import MenuBar from "@/components/textArea/menuBar";

// Styles
import styles from "../../styles.module.scss";
import { useForm } from "react-hook-form";

interface DotPhraseCreationProps {
  onSubmit: (apiArg: PhraseUpsertRequest) => void;
}

export default function DotPhraseCreation({
  onSubmit
}: DotPhraseCreationProps) {
  const dispatch = useDispatch();

  type PhraseFormType = {
    phrase_name: string;
    phrase_description: string;
  };
  const { register, getValues } = useForm<PhraseFormType>({
    defaultValues: {
      phrase_name: "",
      phrase_description: ""
    }
  });

  const defaultExtensions = getDefaultExtensions({
    placeholder: "Enter dot phrase template here."
  });

  const editor = useEditor({
    extensions: defaultExtensions,
    immediatelyRender: false
  });

  const onVariableAdd = () => {
    editor?.chain().focus().insertContent("*** ").run();
  };

  const onCancel = () => {
    dispatch(setModalIsOpen(false));
  };

  const handleSubmit = () => {
    const { phrase_name, phrase_description } = getValues();

    if (phrase_name === "") {
      dispatch(
        addAlertToToastTrough({
          message: "Dot Phrase name can't be blank",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    const editorContent = editor?.getHTML();
    if (editorContent === "" || editorContent === "<p></p>") {
      dispatch(
        addAlertToToastTrough({
          message: "Dot Phrase content can't be blank",
          type: STATUS_KEYS.ERROR
        })
      );
      return;
    }

    const serializedData = {
      phrase: phrase_name,
      description: phrase_description,
      text: editorContent as string
    } as PhraseUpsertRequest;

    onSubmit(serializedData);
  };

  return (
    <div className={styles.DotPhraseCreation}>
      <div className={styles.phraseFormContainer}>
        <div className={styles.phraseInfo}>
          <Input
            label="Name"
            id="phrase_name"
            name="phrase_name"
            type="text"
            register={register}
          />
          <Input
            label="Description"
            id="phrase_description"
            name="phrase_description"
            type="text"
            register={register}
          />
        </div>
        <div className={styles.phraseContent}>
          <p className={styles.editorInstructions}>
            <strong>
              Enter dot phrase below. Add *** for blank spaces where you will
              add custom text to the template.
            </strong>
          </p>
          <div className={styles.textAreaContainer}>
            <EditorContent
              editor={editor}
              className={styles.textAreaContent}
              style={{ height: "20rem" }}
            />
          </div>
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <div className={styles.menuContainer}>
          <MenuBar editor={editor} textAreaClassName={styles.textAreaContent} />
          <Button onClick={onVariableAdd} style={STYLES.TERTIARY}>
            Add ***
          </Button>
        </div>
        <Button onClick={onCancel} style={STYLES.SECONDARY}>
          Cancel
        </Button>
        <Button onClick={handleSubmit} style={STYLES.PRIMARY}>
          Save
        </Button>
      </div>
    </div>
  );
}

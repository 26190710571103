import dayjs from "dayjs";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { STATUS_KEYS } from "@/globals/constants";

import {
  EncounterInfo,
  EncounterType,
  useEncounterUpdateMutation
} from "@/store/services/encounter";
import { useReportFrontendErrorMutation } from "@/store/services/system";

const AUTOSAVE_DEBOUNCE_TIME = 1500;

type NoteAutoSaveProps = {
  isEditable: boolean;
  noteText: string;
  encounter: EncounterInfo;
};

export default function useNoteAutosave({
  isEditable,
  noteText,
  encounter
}: NoteAutoSaveProps) {
  const dispatch = useDispatch();

  const [autosaveNotification, setAutosaveNotification] = useState("");
  const [isAutosaveError, setIsAutosaveError] = useState(false);

  const [reportError] = useReportFrontendErrorMutation();
  const [updateEncounter] = useEncounterUpdateMutation();

  const handleSaveSuccess = useCallback(() => {
    const timestamp = dayjs().format("h:mm a");
    setAutosaveNotification(`Saved at ${timestamp}`);

    setIsAutosaveError(false);
  }, []);

  const handleSaveError = useCallback(() => {
    setAutosaveNotification("Autosaving unavailable right now");

    if (!isAutosaveError) {
      reportError({
        reportSystemError: {
          message: `Autosave error: Failed to update encounter note. Encounter ID: ${encounter.encounter_id}`,
          stack_trace: (() => new Error().stack)()
        }
      });

      dispatch(
        addAlertToToastTrough({
          message: "Failed to update encounter note",
          type: STATUS_KEYS.ERROR
        })
      );

      setIsAutosaveError(true);
    }
  }, [isAutosaveError, encounter.encounter_id, reportError, dispatch]);

  const saveNote = useCallback(
    (text: string) => {
      if (!isEditable) return;

      if (!text || text === encounter.note) {
        return;
      }

      updateEncounter({
        encounterId: encounter.encounter_id,
        encounterCreateUpdatePayload: {
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          encounter_type: encounter.encounter_type as EncounterType,
          note: text
        }
      })
        .unwrap()
        .then(handleSaveSuccess)
        .catch(handleSaveError);
    },
    [isEditable, encounter, updateEncounter, handleSaveSuccess, handleSaveError]
  );

  // useMemo to memoize the debounced autosave function
  // because useCallback does not let us pass our text argument
  // to the debounced function
  const autosave = useMemo(
    () => debounce((text: string) => saveNote(text), AUTOSAVE_DEBOUNCE_TIME),
    [saveNote]
  );

  // Cleanup function for when component unmounts
  useEffect(() => {
    return () => autosave.cancel();
  }, [autosave]);

  // Trigger autosave when note text changes
  useEffect(() => {
    if (isEditable) {
      autosave(noteText);
    }
  }, [noteText, autosave, isEditable]);

  return { autosaveNotification, isAutosaveError };
}

// External
import React from "react";

import dayjs from "dayjs";
import { NextRouter } from "next/router";

// components
import AvatarPlaceholder from "@/components/avatarPlaceholder";
import { DataTag, metrics } from "@/components/datagrid";
import Icon from "@/components/icons";
import { Weight } from "@/components/input/weightInput";
import {
  openOrderRequisition,
  openOrderResults
} from "@/components/labOrdering/utils";
import PhraseOperations from "@/components/phraseLibrary/phraseOperations";
import {
  convertUtcIntToLocalDate,
  convertUtcIntToLocalDatetime
} from "@/components/scheduling/calendars/utils";
import Folder from "@/components/svgIcons/Folder";
import Tag from "@/components/tag";
import ContentRenderer from "@/components/textArea/contentRenderer";

import { parseParioDate } from "@/utils/api/time";

// constants
import {
  APPOINTMENT_STATUS_TYPES,
  CLAIM_PROVIDER_LABEL_OVERRIDES,
  CLAIM_PROVIDER_STATUS_TYPES_OVERRIDES,
  CLAIM_STATUS_TYPES,
  METRICS,
  METRIC_LABELS,
  STATUS_KEYS,
  TASK_STATUS_TYPES
} from "@/globals/constants";

// store
import { LabOrderInfo } from "@/store/services/encounter";
import { PhraseListItem } from "@/store/services/generated/phrase";
import { PerformingSite } from "@/store/services/patient";

import {
  calculateAge,
  getGestationalAge,
  getPostPartumDuration,
  normalizePhoneNumber
} from "@/globals/helpers";

// styles
import styles from "./styles.module.scss";

export function GetColSpan(metric: string): number {
  switch (metric) {
    case (METRICS.ORDERS_PERFORMING_SITE, METRICS.PREGNANCY_OUTCOME):
      return 2;
    case METRICS.LAB_NOTE:
      return 3;
    default:
      return 1;
  }
}

/**
 * Handle formatting metrics data for display in datagrid.
 *
 * @param {Object.<string, string|number|Date|null|{}>} metrics - The metrics data.
 * @param {string} metric - The metric being formatted.
 * @param {string} id - The ID of the metric.
 * @param {function} onToggle - The function to toggle expanded note metric visibility.
 * @param {string[]} openRowIds - An array containg the IDs of rows which are expanded.
 * @param {NextRouter} router - The Next.js router object.
 * @param {DataTag[]} tags - (OPTIONAL) the data tags to render with this metric
 * @returns {JSX.Element|string} The formatted metric data.
 */

export function HandleFormat(
  metrics: metrics,
  metric: string,
  id: number | string,
  onToggle: (id: number) => void,
  openRowIds: number[],
  router: NextRouter,
  tags: DataTag[] = [],
  useOverride?: boolean
): React.JSX.Element | string | number {
  const datum = metrics[metric];

  // Metric handlers to format data for display
  const metricHandlers: Record<any, () => any> = {
    ...Object.fromEntries(
      // Date metrics to convert to date format (MM/DD/YYYY)
      [
        METRICS.DATE,
        METRICS.DELIVERY_DATE,
        METRICS.APPOINTMENT_DATE,
        METRICS.PED,
        METRICS.DATE_OF_ENTRY,
        METRICS.DATE_OF_BIRTH,
        METRICS.DUE_DATE
      ].map(metric => [
        metric,
        () =>
          datum
            ? convertUtcIntToLocalDate(datum as number).format("MM/DD/YYYY")
            : "-"
      ])
    ),
    ...Object.fromEntries(
      // Date metrics to convert to date and time format (MM/DD/YYYY h:mm A)
      [
        METRICS.CREATED_AT,
        METRICS.SENT_FOR_REVIEW_AT,
        METRICS.RECEIVED_AT,
        METRICS.REVIEWED_AT,
        METRICS.DATE_SENT,
        METRICS.DATE_FULFILLED,
        METRICS.DATE_UPDATED,
        METRICS.DATETIME,
        METRICS.UPLOAD_TIME,
        METRICS.PHRASE_LAST_EDIT_TIME
      ].map(metric => [
        metric,
        () =>
          datum ? (
            <div>
              <p>
                {convertUtcIntToLocalDatetime(datum as number).format(
                  "MM/DD/YYYY"
                )}
              </p>
              <p className="t5">
                {convertUtcIntToLocalDatetime(datum as number).format("h:mm A")}
              </p>
            </div>
          ) : (
            "-"
          )
      ])
    ),
    [METRICS.DATE_OF_SERVICE]: () =>
      datum ? dayjs(datum as string).format("MM/DD/YYYY") : "-",
    [METRICS.TIME]: () =>
      datum
        ? convertUtcIntToLocalDatetime(datum as number).format("hh:mm a")
        : "-",
    [METRICS.EDD]: () => {
      if (!datum) return "-";
      const edd = dayjs.utc(datum as string);
      return (
        <div>
          <p>{edd.format("MM/DD/YYYY")}</p>
          <p className="t5">
            {getGestationalAge(parseInt(edd.format("YYYYMMDD")))}
          </p>
        </div>
      );
    },
    ...Object.fromEntries(
      // Phone number metrics to normalize phone number
      [METRICS.RECIPIENT, METRICS.SENDER].map(metric => [
        metric,
        () => {
          const phone = normalizePhoneNumber(datum as string);
          return phone.error && phone.error !== ""
            ? ""
            : (phone.normalizedPhoneNumber as string);
        }
      ])
    ),
    [METRICS.PREGNANCY_DELIVERY_DATE]: () => {
      const dt = parseParioDate(datum as number);
      return dt ? (
        <div>
          <p>{dayjs(dt).format("MM/DD/YYYY")}</p>
          <p className="t5">{getPostPartumDuration(datum as number)}</p>
        </div>
      ) : (
        "-"
      );
    },
    [METRICS.DOB]: () => {
      const dt = parseParioDate(datum as number);
      return dt
        ? dayjs(dt).format("MM/DD/YYYY") + " (Age " + calculateAge(dt) + ")"
        : "-";
    },
    [METRICS.APPOINTMENT_TYPE]: () =>
      useOverride ? (
        <p>{datum as string}</p>
      ) : (
        <Tag label={datum as string} type={STATUS_KEYS.INFO} />
      ),
    [METRICS.NAME]: () => {
      if (!datum || datum.toString().split(", ").length < 2) {
        return datum as string;
      }
      const [lastName, firstName] = datum.toString().split(", ");
      return (
        <div className={styles.name}>
          <AvatarPlaceholder character={firstName[0] + lastName[0]} />
          <div>
            {lastName}, {firstName}
          </div>
          {tags.map((tag, index) => (
            <Tag label={tag.label} type={tag.type} key={index} />
          ))}
        </div>
      );
    },
    [METRICS.ENCOUNTER_TYPE]: () =>
      METRIC_LABELS[datum as string] || (datum as string),
    [METRICS.APPOINTMENT_STATUS]: () => (
      <Tag
        label={METRIC_LABELS[datum as string] as string}
        type={
          APPOINTMENT_STATUS_TYPES[
            datum as keyof typeof APPOINTMENT_STATUS_TYPES
          ]
        }
      />
    ),
    [METRICS.STATUS]: () => {
      const label = useOverride
        ? CLAIM_PROVIDER_LABEL_OVERRIDES[datum as string] ||
          (METRIC_LABELS[datum as string] as string) ||
          (datum as string)
        : (METRIC_LABELS[datum as string] as string) || (datum as string);

      const type = useOverride
        ? CLAIM_PROVIDER_STATUS_TYPES_OVERRIDES[datum as string] ||
          CLAIM_STATUS_TYPES[datum as string] ||
          TASK_STATUS_TYPES[datum as keyof typeof TASK_STATUS_TYPES] ||
          STATUS_KEYS.INFO
        : CLAIM_STATUS_TYPES[datum as string] ||
          TASK_STATUS_TYPES[datum as keyof typeof TASK_STATUS_TYPES] ||
          STATUS_KEYS.INFO;

      return <Tag label={label} type={type} />;
    },
    [METRICS.NOTE]: () => (
      <div className={styles.note}>
        <button onClick={() => onToggle(id as number)} type="button">
          <Icon
            svg="chevron_down_blue"
            width={10}
            flipped={openRowIds.includes(id as number)}
          />
        </button>
      </div>
    ),
    [METRICS.LINK]: () => (
      <div className={styles.actions}>
        <button onClick={() => router.push(datum as string)}>
          <Icon svg="open_page" />
        </button>
      </div>
    ),
    [METRICS.STARTS]: () =>
      datum && convertUtcIntToLocalDatetime(datum as number).format("h:mm a"),
    [METRICS.FILE_VISIBLE_TO_PATIENT]: () => (
      <Tag
        label={datum as string}
        type={datum === "Yes" ? STATUS_KEYS.WARNING : STATUS_KEYS.INFO}
      />
    ),
    [METRICS.TASK_ACTIONS]: () => (
      <>
        <Icon svg="bell" />
        <span>
          <Icon svg="check_circle_blue" />
        </span>
      </>
    ),
    [METRICS.NEW_TAB_LINK]: () =>
      datum ? (
        <div className={styles.actions}>
          <button onClick={() => window.open(datum as string, "_blank")}>
            <Icon svg="open_page" />
          </button>
        </div>
      ) : (
        "-"
      ),
    [METRICS.ORDER_REQUISITION_LINK]: () =>
      datum ? (
        <div className={styles.actions}>
          <button onClick={() => openOrderRequisition(datum as string)}>
            <Icon svg="open_page" />
          </button>
        </div>
      ) : (
        "-"
      ),
    [METRICS.ORDER_RESULT_NOTE]: () => {
      const lines = (datum as string)
        .split(/(\s)/)
        .filter(x => x != " " && x != "")
        .join(" ")
        .split("\n")
        .map(x => x.trim())
        .filter(x => x);
      return (
        <div data-cy="order-note-from-lab">
          {lines.reduce<React.JSX.Element[]>((acc, line, i, arr) => {
            acc.push(<p>{line}</p>);
            if (i < arr.length - 1) acc.push(<br />);
            return acc;
          }, [])}
        </div>
      );
    },
    [METRICS.ORDER_RESULTS_LINK]: () =>
      datum ? (
        <div className={styles.actions}>
          <button onClick={() => openOrderResults(datum as string)}>
            <Icon svg="open_page" />
          </button>
        </div>
      ) : (
        "-"
      ),
    [METRICS.ENCOUNTER_LINK]: () => (
      <div className={styles.actions}>
        <button onClick={() => router.push(datum as string)}>
          <Icon svg="encounter" />
        </button>
      </div>
    ),
    [METRICS.ORDER_REVIEWED]: () => {
      const order = datum as LabOrderInfo;
      return (
        <Tag
          label={order.reviewed ? "Reviewed" : "Not Reviewed"}
          type={order.reviewed ? STATUS_KEYS.SUCCESS : STATUS_KEYS.WARNING}
          customIconSvgPath={
            order.reviewed ? "check_success_outline" : "x_failure_outline"
          }
        />
      );
    },
    [METRICS.ORDERS_PERFORMING_SITE]: () => {
      const site = datum as PerformingSite | undefined;
      return !site ? (
        "-"
      ) : (
        <div>
          <p className="med">{site.contact}</p>
          <p className="t5">{site.address?.line1}</p>
          <p className="t5">
            {site.address?.city}, {site.address?.state} {site.address?.zip}
          </p>
          <p className="t5">{site.name}</p>
        </div>
      );
    },
    [METRICS.ORDER_STATUS]: () => {
      const status = (datum as string).replaceAll("_", "").trim();
      const label =
        status?.charAt(0)?.toUpperCase() + status?.slice(1)?.toLowerCase();
      return (
        <div className={styles.actions}>
          <Tag
            label={label}
            type={
              {
                new: STATUS_KEYS.INFO_GREY,
                sent: STATUS_KEYS.INFO,
                in_progress: STATUS_KEYS.INFO,
                preliminary: STATUS_KEYS.INFO,
                fulfilled: STATUS_KEYS.WARNING,
                reviewed: STATUS_KEYS.SUCCESS,
                error: STATUS_KEYS.ERROR,
                normal: STATUS_KEYS.SUCCESS,
                final: STATUS_KEYS.SUCCESS
              }[status.toLowerCase().trim()] || STATUS_KEYS.ERROR
            }
          />
        </div>
      );
    },
    [METRICS.FILENAME]: () => {
      const isDir = (datum as string)?.endsWith("/");
      return (
        <div className="flex" style={{ whiteSpace: "nowrap" }}>
          {isDir ? (
            <Folder />
          ) : (
            <Icon svg="file_highlighted" width={30} height={30} />
          )}
          <span
            style={{
              marginLeft: 2,
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}
          >
            {datum as string}
          </span>
        </div>
      );
    },
    [METRICS.FILE_TAGS]: () => {
      if (!datum) return "-";
      const tags = (datum as string).split(",").map(tag => tag.trim());
      return (
        <div
          style={{
            maxWidth: "100%",
            display: "flex",
            flexWrap: "wrap",
            gap: "10px"
          }}
        >
          {tags.map(tag => (
            <Tag key={tag} label={tag} type={STATUS_KEYS.INFO} />
          ))}
        </div>
      );
    },
    [METRICS.PHRASE_OPERATIONS]: () => (
      <PhraseOperations phrase={datum as PhraseListItem} />
    ),
    [METRICS.OUTBOUND_FAX_STATUS]: () => {
      const statusKey =
        datum === "success" ? STATUS_KEYS.SUCCESS : STATUS_KEYS.INFO_GREY;
      const tagLabel =
        (datum as string).charAt(0).toUpperCase() +
        (datum as string).slice(1).toLowerCase();
      return (
        <Tag
          key={`${Date.valueOf()}-${tagLabel}`}
          label={tagLabel}
          type={statusKey}
        />
      );
    },
    [METRICS.DATA_TAGS]: () => (
      <Tag label={datum as string} type={STATUS_KEYS.WARNING} />
    ),
    [METRICS.CLAIM_ID]: () => (
      <button
        type="button"
        onClick={() => {
          navigator.clipboard.writeText(datum as string);
          alert("Copied full ID " + datum + " to clipboard");
        }}
        title={"Click to copy full ID to clipboard"}
      >
        <Tag label={(datum as string).slice(-5)} type={STATUS_KEYS.INFO_GREY} />
      </button>
    ),
    [METRICS.ORDER_ID]: () => (
      <button
        type="button"
        onClick={() => {
          navigator.clipboard.writeText(datum as string);
          alert("Copied full ID " + datum + " to clipboard");
        }}
        title={"Click to copy full ID to clipboard"}
      >
        <Tag label={(datum as string).slice(-5)} type={STATUS_KEYS.INFO_GREY} />
      </button>
    ),
    [METRICS.LAB_NOTE]: () => (
      <div className={styles.labNote}>
        {datum &&
          (datum as string).split("\n").map((line, idx) => (
            <p
              key={`line-${idx}`}
              dangerouslySetInnerHTML={{
                __html: line.replaceAll(" ", "&nbsp;")
              }}
            ></p>
          ))}
      </div>
    ),
    [METRICS.REASON]: () => <ContentRenderer content={datum as string} />,
    [METRICS.SIGNUP_COMPLETED]: () =>
      datum ? (
        <div title="Signup completed">
          <Icon svg="badge_check" width={14} height={14} />
        </div>
      ) : (
        "-"
      ),
    [METRICS.BIRTH_WEIGHT]: () => {
      const weight = datum as Weight;
      return (
        <div>
          <p>{`${weight.pounds || 0} lbs ${weight.ounces || 0} oz`}</p>
          <p className="t5">{(weight.grams || 0).toFixed(2)} grams</p>
        </div>
      );
    },
    [METRICS.INCOMPLETE_TASK_COUNT]: () =>
      datum ? (
        <Tag label={datum as string} type={STATUS_KEYS.WARNING} />
      ) : (
        <Tag label="0" type={STATUS_KEYS.SUCCESS} />
      ),
    [METRICS.IS_ACTIVE]: () =>
      datum ? (
        <Tag label="Active" type={STATUS_KEYS.SUCCESS} />
      ) : (
        <Tag label="Inactive" type={STATUS_KEYS.WARNING} />
      )
  };

  if (metricHandlers[metric]) {
    return metricHandlers[metric]();
  }

  if (typeof datum === "boolean") {
    return datum ? (
      <Tag label="Yes" type={STATUS_KEYS.SUCCESS} />
    ) : (
      <Tag label="No" type={STATUS_KEYS.WARNING} />
    );
  }

  if (datum && typeof datum === "string") {
    return METRIC_LABELS[datum] || datum;
  }

  if (datum) {
    return <>{datum}</>;
  }

  return "-";
}

import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    authRefresh: build.query<AuthRefreshApiResponse, AuthRefreshApiArg>({
      query: () => ({ url: `/auth/refresh` })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as auth };
export type AuthRefreshApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type AuthRefreshApiArg = void;
export type ErrorId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export const { useAuthRefreshQuery, useLazyAuthRefreshQuery } = injectedRtkApi;

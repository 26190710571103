import {
  EncounterInfo,
  LaborEvent,
  useEncounterLaborInfoQuery
} from "@/store/services/patient";
import Button from "../button";
import { STYLES } from "@/globals/constants";
import OptionsMenu from "../optionsMenu";
import {
  EVENTS,
  FIRST_STAGE_EVENTS,
  SECOND_STAGE_EVENTS,
  SINCE_BIRTH_EVENTS,
  SINCE_RUPTURE_EVENTS,
  STAGE_LABELS,
  STAGE_TABS_V2,
  STAGES,
  THIRD_STAGE_EVENTS
} from "../flows/labor/constants";
import LaborProgressUpdate from "./progressUpdate/laborProgressUpdate";
import styles from "./styles.module.scss";
import TimerIndex from "../timer";

import { max } from "d3";
import { useState } from "react";
import PostpartumProgressUpdate from "./progressUpdate/postpartumProgressUpdate";
interface LaborFlowProps {
  encounter: EncounterInfo;
}

export default function LaborFlow({ encounter }: LaborFlowProps) {
  const [selectedStage, setSelectedStage] = useState<string>(STAGES.LABOR);

  const handleSelectEvent = (e: string) => setSelectedStage(e);

  const { data: labor } = useEncounterLaborInfoQuery(
    {
      encounterId: encounter.encounter_id as number
    },
    { skip: !encounter || !encounter.encounter_id }
  );

  const getLastFHT = () => {
    if (labor?.stages) {
      const timersStopped =
        (labor?.stages[STAGES.CONCLUSION]?.events?.[EVENTS.STOP_TIMERS]
          ?.start || 0) +
        (labor?.stages[STAGES.CONCLUSION]?.events?.[EVENTS.LABOR_CONCLUDED]
          ?.start || 0);

      if (timersStopped > 0) {
        return;
      }

      const progressUpdates = Object.values(labor?.stages).flatMap(
        stage => stage.progress_updates
      );
      const timestamps = progressUpdates.flatMap(p => {
        // The typing here is lame because eventually this will be
        // refactored once the LaborEvent is more strongly typed in
        // another ticket.
        const forms = p.forms as Record<string, any>;
        return p && forms.FHT && p.start;
      });
      const nums = timestamps.filter(t => typeof t === "number");
      if (nums) {
        return max(nums);
      }
    }
  };

  const getLastVitals = () => {
    if (labor?.stages) {
      // Add up all of the start values of the global timer stop events
      // and if it's greater than 0 (any of the events have happened)
      // return nothing.
      const timersStopped =
        (labor?.stages[STAGES.CONCLUSION]?.events?.[EVENTS.STOP_TIMERS]
          ?.start || 0) +
        (labor?.stages[STAGES.CONCLUSION]?.events?.[EVENTS.LABOR_CONCLUDED]
          ?.start || 0);

      if (timersStopped > 0) {
        return;
      }

      // Filter out triage events from starting the timers
      const filteredStages = Object.fromEntries(
        Object.entries(labor.stages).filter(([k]) => k != STAGES.TRIAGE)
      );

      let progressUpdates = Object.values(filteredStages).flatMap(
        stage => stage.progress_updates
      );

      progressUpdates = progressUpdates.filter(event => {
        if (!event) {
          return false;
        }
        const form = event.forms as Record<string, any>;
        if (!form) {
          return false;
        }

        return (
          form.BP ||
          form.heart_rate ||
          form.temp ||
          form.inputs ||
          form.outputs ||
          form.respirations
        );
      });

      let timestamps = progressUpdates.flatMap(p => p && p.start);
      const nums = timestamps.filter(t => typeof t === "number");
      if (nums) {
        return max(nums);
      }
    }
  };

  return (
    <div className={styles.LaborFlow}>
      <TimerIndex
        events={[
          {
            label: "since rupture",
            event: labor?.stages[SINCE_RUPTURE_EVENTS.START_STAGE]?.events?.[
              SINCE_RUPTURE_EVENTS.START_EVENT
            ] as LaborEvent,
            endEvents: [
              labor?.stages[SINCE_RUPTURE_EVENTS.END_STAGE]?.events?.[
                SINCE_RUPTURE_EVENTS.END_EVENT
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.STOP_TIMERS
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.LABOR_CONCLUDED
              ] as LaborEvent
            ]
          },
          {
            label: "first stage",
            event: labor?.stages[FIRST_STAGE_EVENTS.START_STAGE]?.events?.[
              FIRST_STAGE_EVENTS.START_EVENT
            ] as LaborEvent,
            endEvents: [
              labor?.stages[FIRST_STAGE_EVENTS.END_STAGE]?.events?.[
                FIRST_STAGE_EVENTS.END_EVENT
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.STOP_TIMERS
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.LABOR_CONCLUDED
              ] as LaborEvent
            ]
          },
          {
            label: "second stage",
            event: labor?.stages[SECOND_STAGE_EVENTS.START_STAGE]?.events?.[
              SECOND_STAGE_EVENTS.START_EVENT
            ] as LaborEvent,
            endEvents: [
              labor?.stages[SECOND_STAGE_EVENTS.END_STAGE]?.events?.[
                SECOND_STAGE_EVENTS.END_EVENT
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.STOP_TIMERS
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.LABOR_CONCLUDED
              ] as LaborEvent
            ]
          },
          {
            label: "third stage",
            event: labor?.stages[THIRD_STAGE_EVENTS.START_STAGE]?.events?.[
              THIRD_STAGE_EVENTS.START_EVENT
            ] as LaborEvent,
            endEvents: [
              labor?.stages[THIRD_STAGE_EVENTS.END_STAGE]?.events?.[
                THIRD_STAGE_EVENTS.END_EVENT
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.STOP_TIMERS
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.LABOR_CONCLUDED
              ] as LaborEvent
            ]
          },
          {
            label: "since birth",
            event: labor?.stages[SINCE_BIRTH_EVENTS.START_STAGE]?.events?.[
              SINCE_BIRTH_EVENTS.START_EVENT
            ] as LaborEvent,
            endEvents: [
              labor?.stages[SINCE_BIRTH_EVENTS.END_STAGE]?.events?.[
                SINCE_BIRTH_EVENTS.END_EVENT
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.STOP_TIMERS
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.LABOR_CONCLUDED
              ] as LaborEvent
            ]
          },
          {
            label: "since last FHT",
            event: { start: getLastFHT() } as LaborEvent,
            endEvents: [
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.STOP_TIMERS
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.LABOR_CONCLUDED
              ] as LaborEvent
            ]
          },
          {
            label: "since last vitals",
            event: { start: getLastVitals() } as LaborEvent,
            endEvents: [
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.STOP_TIMERS
              ] as LaborEvent,
              labor?.stages[STAGES.CONCLUSION]?.events?.[
                EVENTS.LABOR_CONCLUDED
              ] as LaborEvent
            ]
          }
        ]}
      />
      <div className="flex apart" style={{ marginTop: "16px" }}>
        <OptionsMenu
          selectedOption={selectedStage}
          onClick={handleSelectEvent}
          options={[
            { id: STAGES.TRIAGE, label: STAGE_LABELS[STAGES.TRIAGE] },
            { id: STAGES.LABOR, label: STAGE_LABELS[STAGES.LABOR] },
            {
              id: STAGES.POSTPARTUM,
              label: STAGE_LABELS[STAGES.POSTPARTUM]
            },
            {
              id: STAGES.CONCLUSION,
              label: STAGE_LABELS[STAGES.CONCLUSION]
            }
          ]}
        />
        <OptionsMenu
          selectedOption={STAGES.LABOR}
          onClick={handleSelectEvent}
          options={[
            {
              id: STAGES.HEMORRHAGE,
              label: STAGE_LABELS[STAGES.HEMORRHAGE],
              style: STYLES.DANGER
            },
            {
              id: STAGES.RESUSCITATION,
              label: STAGE_LABELS[STAGES.RESUSCITATION],
              style: STYLES.DANGER
            },
            { id: STAGES.TRANSFER, label: STAGE_LABELS[STAGES.TRANSFER] }
          ]}
        />
      </div>
      <div>
        {selectedStage == "labor" ? (
          <LaborProgressUpdate encounterId={encounter.encounter_id} />
        ) : (
          <PostpartumProgressUpdate encounterId={encounter.encounter_id} />
        )}
      </div>
    </div>
  );
}

import * as generated from "./generated/lab";

const lab = generated.lab.enhanceEndpoints({
  addTagTypes: ["Encounter"],
  endpoints: {
    labSendOrder: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labToggleOrderReviewed: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labOrderRead: {
      providesTags: (_resp, _err, _req) => {
        return ["LabOrder"];
      }
    },
    labOrderUpdate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labOrderCreate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labDeleteOrder: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Encounter", "LabOrder"];
      }
    },
    labOrderAnnotationCreate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["OrderAnnotations"];
      }
    },
    labOrderAnnotationDelete: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["OrderAnnotations"];
      }
    },
    labOrderAnnotationUpdate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["OrderAnnotations"];
      }
    },
    labOrderAnnotationList: {
      providesTags: (_resp, _err, _req) => {
        return ["OrderAnnotations"];
      }
    },
    labFlowsheetRead: {
      providesTags: (_result, _error, args) => {
        return [{ type: "LabFlowsheet", id: args.labFlowsheetId }];
      }
    },
    labFlowsheetCreate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["LabFlowsheet", "Patients"];
      }
    },
    labFlowsheetDelete: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["LabFlowsheet"];
      }
    },
    labFlowsheetInsertEntries: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["LabFlowsheet"];
      }
    },
    labFlowsheetAttachFile: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Patient-Files", "Practice-Files", "LabFlowsheet"];
      }
    },
    labFlowsheetRemoveFile: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["Patient-Files", "Practice-Files", "LabFlowsheet"];
      }
    },
    labFlowsheetDeleteEntries: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["LabFlowsheet"];
      }
    },
    labFlowsheetDeleteDay: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["LabFlowsheet"];
      }
    },
    labFlowsheetUpdateEntries: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["LabFlowsheet"];
      }
    },
    labFlowsheetUpdateDate: {
      invalidatesTags: (_resp, _err, _req) => {
        return ["LabFlowsheet"];
      }
    },
    labFlowsheetCreateTemplate: {
      invalidatesTags: (resp, _err, _req) => {
        return [
          "LabFlowsheetTemplates",
          { type: "LabFlowsheet", id: resp?.template_id }
        ];
      }
    },
    labFlowsheetReadTemplate: {
      providesTags: (_result, _error, args) => {
        return [
          { type: "LabFlowsheetTemplates", id: args.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetUpdateTemplate: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          "LabFlowsheetTemplates",
          { type: "LabFlowsheet", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetDeleteTemplate: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          "LabFlowsheetTemplates",
          { type: "LabFlowsheet", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetTemplateInsertTests: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          { type: "LabFlowsheetTemplates", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetTemplateUpdateTest: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          { type: "LabFlowsheetTemplates", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetTemplateDeleteTest: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          { type: "LabFlowsheetTemplates", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetTemplateInsertAnalytes: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          { type: "LabFlowsheetTemplates", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetTemplateUpdateAnalyte: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          { type: "LabFlowsheetTemplates", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetTemplateDeleteAnalyte: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          { type: "LabFlowsheetTemplates", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetTemplateSwapTests: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          { type: "LabFlowsheetTemplates", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetTemplateSwapAnalytes: {
      invalidatesTags: (_resp, _err, req) => {
        return [
          { type: "LabFlowsheetTemplates", id: req.labFlowsheetTemplateId }
        ];
      }
    },
    labFlowsheetListTemplates: {
      providesTags: (_result, _error, _args) => {
        return ["LabFlowsheetTemplates"];
      }
    }
  }
});

export * from "./generated/lab";
export default lab;
export const {
  useSetClaimNonBillableMutation,
  useLabListQuery,
  useLazyLabListQuery,
  useLabGetInfoQuery,
  useLazyLabGetInfoQuery,
  useLabOrderCreateMutation,
  useLabOrderReadQuery,
  useLazyLabOrderReadQuery,
  useLabOrderUpdateMutation,
  useLabDeleteOrderMutation,
  useLabOrderTransmissionErrorQuery,
  useLazyLabOrderTransmissionErrorQuery,
  useLabListTestsQuery,
  useLazyLabListTestsQuery,
  useLabGetAoeQuestionsQuery,
  useLazyLabGetAoeQuestionsQuery,
  useLabSearchLocationsMutation,
  useLabBookmarkLocationMutation,
  useLabListBookmarkedLocationsQuery,
  useLazyLabListBookmarkedLocationsQuery,
  useLabGetLocationInfoQuery,
  useLazyLabGetLocationInfoQuery,
  useLabDeleteBookmarkedLocationMutation,
  useLabListTestBundlesQuery,
  useLazyLabListTestBundlesQuery,
  useLabCreateTestBundleMutation,
  useLabUpdateTestBundleMutation,
  useLabDeleteTestBundleMutation,
  useLabSendOrderMutation,
  useLabGetOrderResultsPdfQuery,
  useLazyLabGetOrderResultsPdfQuery,
  useLabGetOrderRequisitionPdfQuery,
  useLazyLabGetOrderRequisitionPdfQuery,
  useLabGetOrderObservationsQuery,
  useLazyLabGetOrderObservationsQuery,
  useLabToggleOrderReviewedMutation,
  useLabGetOrderAbnQuery,
  useLazyLabGetOrderAbnQuery,
  useLabGetOrderLetterLabelsQuery,
  useLazyLabGetOrderLetterLabelsQuery,
  useLabGetOrderDymoLabelsQuery,
  useLazyLabGetOrderDymoLabelsQuery,
  useLabFlowsheetCreateMutation,
  useLabFlowsheetReadQuery,
  useLazyLabFlowsheetReadQuery,
  useLabFlowsheetDeleteMutation,
  useLabFlowsheetDeleteDayMutation,
  useLabFlowsheetListQuery,
  useLazyLabFlowsheetListQuery,
  useLabFlowsheetDeleteEntriesMutation,
  useLabFlowsheetInsertEntriesMutation,
  useLabFlowsheetUpdateEntriesMutation,
  useLabFlowsheetRemoveFileMutation,
  useLabFlowsheetAttachFileMutation,
  useLabFlowsheetUpdateDateMutation,
  useLabFlowsheetCreateTemplateMutation,
  useLabFlowsheetReadTemplateQuery,
  useLazyLabFlowsheetReadTemplateQuery,
  useLabFlowsheetUpdateTemplateMutation,
  useLabFlowsheetDeleteTemplateMutation,
  useLabFlowsheetTemplateSwapTestsMutation,
  useLabFlowsheetTemplateSwapAnalytesMutation,
  useLabFlowsheetListTemplatesQuery,
  useLazyLabFlowsheetListTemplatesQuery,
  useLabFlowsheetTemplateInsertTestsMutation,
  useLabFlowsheetTemplateUpdateTestMutation,
  useLabFlowsheetTemplateDeleteTestMutation,
  useLabFlowsheetTemplateInsertAnalytesMutation,
  useLabFlowsheetTemplateUpdateAnalyteMutation,
  useLabFlowsheetTemplateDeleteAnalyteMutation,
  useLabOrderAnnotationCreateMutation,
  useLabOrderAnnotationUpdateMutation,
  useLabOrderAnnotationDeleteMutation,
  useLabOrderAnnotationListQuery,
  useLazyLabOrderAnnotationListQuery
} = lab;

import { useCallback } from "react";
import { useSelector } from "react-redux";

import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

import { LaborModel } from "@/store/services/patient";
import { usePracticeGetProvidersQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";

import { FORMAT } from "@/globals/helpers/formatters";

import ReadOnlySection from "../readOnlySection";
import SectionRow from "../sectionRow";
import TableItem from "../tableItem";

interface SummaryMedicationsInfoProps {
  labor: LaborModel;
}

export default function SummaryMedicationsInfo({
  labor
}: SummaryMedicationsInfoProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const { data: providers } = usePracticeGetProvidersQuery(
    { practiceId: sessionInfo?.practice_id as number },
    { skip: !sessionInfo || !sessionInfo?.practice_id }
  );

  const getProviderName = useCallback(
    (userId: number) => {
      if (!providers) {
        return "-";
      }

      const provider = providers.find(prov => prov.user_id == userId);
      if (provider) {
        return FORMAT.name(provider);
      }

      return "-";
    },
    [providers]
  );

  const serializeMedications = (
    laborObject: LaborModel,
    who: "MOTHER" | "INFANT"
  ) => {
    let allMeds: Array<any> = [];
    for (const stage in laborObject.stages) {
      if (who == "MOTHER") {
        (laborObject.stages[stage].medications || []).forEach(med => {
          const form = med.forms as Record<string, any>;
          let newArr: Array<any> = [
            form.name,
            convertUtcIntToLocalDatetime(form.start_time).format(
              "MM/DD/YYYY HH:mm"
            ),
            form.dose,
            form.med_route,
            form.site,
            form.lot_num,
            form.expiration,
            // TODO: Turn this into name
            // TODO: Make sure this is the right date for the admin by
            getProviderName(med.authors?.[0] || -1)
          ];
          allMeds.push(newArr);
        });
      } else {
        (laborObject.stages[stage].infant_medications || []).forEach(med => {
          const form = med.forms as Record<string, any>;
          let newArr: Array<any> = [
            form.name,
            convertUtcIntToLocalDatetime(form.start_time).format(
              "MM/DD/YYYY HH:mm"
            ),
            form.dose,
            form.med_route,
            form.site,
            form.lot_num,
            form.expiration,
            getProviderName(med.authors?.[0] || -1)
          ];
          allMeds.push(newArr);
        });
      }
    }

    return allMeds;
  };

  if (
    !labor ||
    (serializeMedications(labor, "MOTHER").length == 0 &&
      serializeMedications(labor, "INFANT").length == 0)
  ) {
    return <></>;
  }

  return (
    <ReadOnlySection header="Medication Log">
      <>
        {serializeMedications(labor, "MOTHER").length > 0 && (
          <>
            <SectionRow>
              <h4>Mother Medications</h4>
            </SectionRow>
            <SectionRow>
              <TableItem
                headers={[
                  "Med Name",
                  "Time",
                  "Dosage",
                  "Route",
                  "Site",
                  "Lot#",
                  "Exp. Date",
                  "Admin By"
                ]}
                values={serializeMedications(labor, "MOTHER")}
              />
            </SectionRow>
          </>
        )}
        {serializeMedications(labor, "INFANT").length > 0 && (
          <>
            <SectionRow>
              <h4>Newborn Medications</h4>
            </SectionRow>
            <SectionRow>
              <TableItem
                headers={[
                  "Med Name",
                  "Time",
                  "Dosage",
                  "Route",
                  "Site",
                  "Lot#",
                  "Exp. Date",
                  "Admin By"
                ]}
                values={serializeMedications(labor, "INFANT")}
              />
            </SectionRow>
          </>
        )}
      </>
    </ReadOnlySection>
  );
}

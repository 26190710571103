import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    chargemasterCreateEntry: build.mutation<
      ChargemasterCreateEntryApiResponse,
      ChargemasterCreateEntryApiArg
    >({
      query: queryArg => ({
        url: `/chargemaster`,
        method: "POST",
        body: queryArg.chargemasterEntryCreatePayload
      })
    }),
    chargemasterGetEntries: build.mutation<
      ChargemasterGetEntriesApiResponse,
      ChargemasterGetEntriesApiArg
    >({
      query: queryArg => ({
        url: `/chargemaster/lookup`,
        method: "POST",
        body: queryArg.chargemasterLookupPayload
      })
    }),
    chargemasterDeleteEntry: build.mutation<
      ChargemasterDeleteEntryApiResponse,
      ChargemasterDeleteEntryApiArg
    >({
      query: queryArg => ({
        url: `/chargemaster/${queryArg.payorName}/${queryArg.cptCode}`,
        method: "DELETE"
      })
    }),
    chargemasterUpdateEntry: build.mutation<
      ChargemasterUpdateEntryApiResponse,
      ChargemasterUpdateEntryApiArg
    >({
      query: queryArg => ({
        url: `/chargemaster/${queryArg.payorName}/${queryArg.cptCode}`,
        method: "PATCH",
        body: queryArg.chargemasterUpdatePayload
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as chargemaster };
export type ChargemasterCreateEntryApiResponse =
  /** status 200 Sent in response to successfully create a new chargemaster entry.
   */ ChargemasterEntry;
export type ChargemasterCreateEntryApiArg = {
  /** Request to create a new chargemaster entry.
   */
  chargemasterEntryCreatePayload: ChargemasterEntryCreatePayload;
};
export type ChargemasterGetEntriesApiResponse =
  /** status 200 Sent in response to successfully looking up one ore more chargemaster entries.
   */ ChargemasterEntries;
export type ChargemasterGetEntriesApiArg = {
  /** Request to lookup one or more codes in the chargemaster for a given practice/payor combination.
   */
  chargemasterLookupPayload: ChargemasterLookupPayload;
};
export type ChargemasterDeleteEntryApiResponse =
  /** status 200 Sent in response to successfully deleting a chargemaster entry.
   */ ChargemasterEntry;
export type ChargemasterDeleteEntryApiArg = {
  payorName: string;
  cptCode: CptCode;
};
export type ChargemasterUpdateEntryApiResponse =
  /** status 200 Sent in response to successfully updating a chargemaster entry.
   */ ChargemasterEntry;
export type ChargemasterUpdateEntryApiArg = {
  payorName: string;
  cptCode: CptCode;
  /** Request to update an existing chargemaster entry.
   */
  chargemasterUpdatePayload: ChargemasterUpdatePayload;
};
export type ChargemasterEntry = {
  /** The system-assigned ID of a practice, the highest grouping level in the Pario system. This is frequently
    used as a linkage between tables and as a parameter to API requests.
     */
  practice_id: number;
  payor: string;
  /** The actual CPT code.
   */
  cpt_code: string;
  alias?: string;
  description?: string;
  amount?: number;
  medicare_multiplier?: number;
};
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type ChargemasterEntryCreatePayload = {
  payor: string;
  /** The actual CPT code.
   */
  cpt_code: string;
  alias?: string;
  description?: string;
  amount?: number;
  medicare_multiplier?: number;
};
export type ChargemasterEntries = ChargemasterEntry[];
export type CptCode = string;
export type ChargemasterLookupPayload = {
  payor: string;
  cpt_codes: CptCode[];
};
export type ChargemasterUpdatePayload = {
  payor?: string;
  /** The actual CPT code.
   */
  cpt_code?: string;
  alias?: string;
  description?: string;
  amount?: number;
  medicare_multiplier?: number;
};
export const {
  useChargemasterCreateEntryMutation,
  useChargemasterGetEntriesMutation,
  useChargemasterDeleteEntryMutation,
  useChargemasterUpdateEntryMutation
} = injectedRtkApi;

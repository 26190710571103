// PregnancySelect.tsx - Using helpers to simplify the component
import React from "react";
import { useDispatch } from "react-redux";
import Select, { SingleValue, StylesConfig } from "react-select";

import {
  SelectBlueStyles,
  SelectBlueTheme
} from "@/components/facePage/patientMedicalState/helpers";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

import { STATUS_KEYS } from "@/globals/constants";

import { Sex, UserId } from "@/store/services/patient";
import { usePracticeInfoQuery } from "@/store/services/practice";
import {
  GbsStatus,
  Pregnancy,
  usePregnancyUpsertMutation
} from "@/store/services/pregnancy";

import {
  OptionType,
  PregnancyFieldType,
  createUpdatePayload,
  getCurrentValue,
  getOptions,
  hasValueChanged
} from "./helpers";

interface PregnancySelectProps {
  patientId: UserId;
  pregnancy: Pregnancy;
  field: PregnancyFieldType;
  label: string;
}

export default function PregnancyUpsertSelect({
  patientId,
  pregnancy,
  field,
  label
}: PregnancySelectProps) {
  const dispatch = useDispatch();
  const [upsertPregnancy] = usePregnancyUpsertMutation();
  const { data: practiceInfo } = usePracticeInfoQuery();

  // For birthplace, check if there are options available
  if (
    field === "desired_birthplace" &&
    (!practiceInfo?.desired_birthplaces ||
      practiceInfo.desired_birthplaces.length === 0)
  ) {
    return null;
  }

  // Get options using the helper
  const options = getOptions(field, practiceInfo?.desired_birthplaces);

  // Get current value using the helper
  const currentValue = getCurrentValue(pregnancy, field, options);

  // Handle select changes
  const handleChange = async (selectedOption: SingleValue<OptionType>) => {
    // Extract the value with proper typing
    const getValueWithType = () => {
      const value = selectedOption?.value || "";

      switch (field) {
        case "gbs_status":
          return value as GbsStatus;
        case "fetal_sex":
          return value as Sex;
        case "desired_birthplace":
          return value as string;
        default:
          return value as never;
      }
    };

    const newValue = getValueWithType();

    // Check if the value has changed
    if (!hasValueChanged(pregnancy, field, newValue)) {
      console.warn(`${label} has not changed. No update needed.`);
      return;
    }

    // Create the update payload
    const updatePayload = createUpdatePayload(pregnancy, field, newValue);

    // Send the update
    await upsertPregnancy({
      patientId: patientId,
      pregnancyUpsertRequest: updatePayload
    })
      .unwrap()
      .then(() => {
        dispatch(
          addAlertToToastTrough({
            message: `Updated ${label.toLowerCase()}`,
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: `Failed to update ${label.toLowerCase()}`,
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  return (
    <>
      <p>{label}</p>
      <Select<OptionType>
        theme={SelectBlueTheme}
        styles={SelectBlueStyles as StylesConfig<OptionType>}
        options={options}
        value={currentValue}
        onChange={handleChange}
        aria-label={label}
        isClearable
        hideSelectedOptions
      />
    </>
  );
}

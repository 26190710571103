// external
import { skipToken } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";
import { useRouter } from "next/router";
import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";

import { addAlertToToastTrough } from "@/components//toastTrough/toastSlice";
// styles
import styles from "@/components/datagrid/styles.module.scss";
// components
import EncounterDataFlowSheet from "@/components/flowSheet/EncounterDataFlowSheet";
import {
  BaseFlowSheetEntry,
  FlowSheetConfig
} from "@/components/flowSheet/types";
import {
  Weight,
  getWeightObjectFromGrams
} from "@/components/input/weightInput";
import { convertUtcIntToLocalDatetime } from "@/components/scheduling/calendars/utils";

// constants
import { METRICS, PAGES, STATUS_KEYS } from "@/globals/constants";

// store
import {
  EncounterInfo,
  EncounterType,
  useEncounterUpdateMutation
} from "@/store/services/encounter";
import { usePatientGetInfantGrowthSheetQuery } from "@/store/services/patient";

interface InfantGrowthSheetEntry extends BaseFlowSheetEntry {
  date_of_entry?: string;
  weight_grams?: number;
  birth_weight?: Weight;
  percent_change_weight_since_last_entry?: number;
  standing_height_cm?: number;
  recumbent_length_cm?: number;
  head_circumference_cm?: number;
  respiratory_rate?: number;
  temperature?: number;
  pulse?: number;
}

const infantGrowthSheetConfig: FlowSheetConfig<InfantGrowthSheetEntry> = {
  fields: {
    [METRICS.DATE_OF_ENTRY]: {
      type: "date",
      label: "Date of Entry"
    },
    [METRICS.BIRTH_WEIGHT]: {
      type: "weight",
      label: "Weight"
    },
    percent_change_weight_since_last_entry: {
      type: "percentage",
      label: "Weight Delta (Since Last Entry)"
    },
    [METRICS.RECUMBENT_LENGTH_CM]: {
      type: "number",
      label: "Recumbent Length (cm)"
    },
    [METRICS.HEAD_CIRCUMFERENCE_CM]: {
      type: "number",
      label: "Head Circumference (cm)"
    },
    [METRICS.RESPIRATORY_RATE]: {
      type: "number",
      label: "RR"
    },
    [METRICS.TEMP]: {
      type: "number",
      label: "Temp (\u00B0F)"
    },
    [METRICS.PULSE]: {
      type: "number",
      label: "Pulse"
    },
    note: {
      type: "note",
      label: "Note"
    }
  }
};

interface InfantGrowthFlowSheetProps {
  encounter?: EncounterInfo;
  patientId?: number;
  isReadOnly?: boolean;
}

export default function InfantGrowthFlowSheet({
  encounter,
  patientId,
  isReadOnly
}: InfantGrowthFlowSheetProps) {
  const router = useRouter();
  const dispatch = useDispatch();
  const [updateEncounter] = useEncounterUpdateMutation();
  const { data: infantGrowthSheet = [], isLoading } =
    usePatientGetInfantGrowthSheetQuery(
      patientId || (encounter?.patient.user_id as number)
        ? {
            patientId: patientId || (encounter?.patient.user_id as number)
          }
        : skipToken
    );

  const data: InfantGrowthSheetEntry[] = useMemo(() => {
    // If the infant has no entries, null is the correct response from the endpoint
    if (!infantGrowthSheet) {
      if (encounter) {
        return [
          {
            date_of_entry: convertUtcIntToLocalDatetime(
              encounter.start ||
                encounter.appointment?.starts ||
                encounter.created
            ).format("MM/DD/YYYY"),
            encounterId: encounter.encounter_id
          }
        ];
      } else {
        return [];
      }
    }
    return infantGrowthSheet.map(entry => {
      const weight: Weight = getWeightObjectFromGrams(entry.weight_grams || 0);
      const newEntry: InfantGrowthSheetEntry = {
        [METRICS.DATE_OF_ENTRY]: entry.date || "",
        [METRICS.WEIGHT_GRAMS]: weight.grams || 0,
        [METRICS.BIRTH_WEIGHT]: weight,
        percent_change_weight_since_last_entry:
          entry.percent_change_weight_since_last_entry || undefined,
        [METRICS.STANDING_HEIGHT_CM]: entry.standing_height_cm,
        [METRICS.RECUMBENT_LENGTH_CM]: entry.recumbent_length_cm,
        [METRICS.HEAD_CIRCUMFERENCE_CM]: entry.head_circumference_cm,
        [METRICS.RESPIRATORY_RATE]: entry.respiratory_rate,
        [METRICS.TEMP]: entry.temperature,
        [METRICS.PULSE]: entry.pulse,
        encounterId: entry.encounter_id || (encounter?.encounter_id as number),
        note: entry.encounter_note
      };
      return newEntry;
    });
  }, [encounter, infantGrowthSheet]);

  // Event handlers
  const submit = useCallback(
    (formData: InfantGrowthSheetEntry) => {
      if (isReadOnly || !encounter) {
        dispatch(
          addAlertToToastTrough({
            message: "There was an issue saving the flowsheet",
            type: STATUS_KEYS.ERROR
          })
        );
        return;
      }

      const currentEntry = data.find(
        entry => entry.encounterId == encounter.encounter_id
      );
      const dateString =
        formData.date_of_entry ||
        currentEntry?.date_of_entry ||
        convertUtcIntToLocalDatetime(
          encounter.start || encounter.appointment?.starts || encounter.created
        ).format("MM/DD/YYYY");

      if (!dateString || dateString == "") {
        dispatch(
          addAlertToToastTrough({
            message: "There was an issue saving the flowsheet",
            type: STATUS_KEYS.ERROR
          })
        );
        return;
      }

      const newEntry = {
        [METRICS.DATE]: dayjs(dateString).format("YYYY-MM-DD"), // backend expects "YYYY-MM-DD" date format
        [METRICS.WEIGHT_GRAMS]: (formData.birth_weight as Weight)?.grams,

        [METRICS.HEAD_CIRCUMFERENCE_CM]:
          parseInt(String(formData.head_circumference_cm)) || undefined,
        [METRICS.RECUMBENT_LENGTH_CM]:
          parseInt(String(formData.recumbent_length_cm)) || undefined,
        [METRICS.RESPIRATORY_RATE]:
          parseInt(String(formData.respiratory_rate)) || undefined,
        [METRICS.TEMP]: parseInt(String(formData.temperature)) || undefined,
        [METRICS.PULSE]: parseInt(String(formData.pulse)) || undefined
      };

      // update encounter
      updateEncounter({
        encounterId: encounter.encounter_id,
        encounterCreateUpdatePayload: {
          patient_id: encounter.patient.user_id,
          provider_id: encounter.provider.user_id,
          encounter_type: encounter.encounter_type as EncounterType,
          infant_growth_measurements: newEntry
        }
      })
        .unwrap()
        .catch(err => {
          console.error(err);
          dispatch(
            addAlertToToastTrough({
              message: "There was an issue saving the flowsheet",
              type: STATUS_KEYS.ERROR
            })
          );
        });
    },
    [data, dispatch, encounter, isReadOnly, updateEncounter]
  );

  const defaultValues = useMemo(() => {
    if (!encounter) {
      return {};
    }

    const weight: Weight = getWeightObjectFromGrams(
      encounter.infant_growth?.weight_grams || 0
    );
    return {
      ...encounter.infant_growth,
      birth_weight: weight
    };
  }, [encounter]);

  return (
    <div className={styles.FetalGrowthFlowSheet}>
      <EncounterDataFlowSheet<InfantGrowthSheetEntry>
        title="Infant Growth Sheet"
        isReadOnly={isReadOnly}
        isLoading={isLoading}
        submitOnBlur
        entries={data}
        config={infantGrowthSheetConfig}
        defaultValues={defaultValues}
        encounterId={encounter?.encounter_id}
        onRowSubmit={submit}
        onRowNavigate={encounterId => {
          router.push(`${PAGES.ENCOUNTERS}/${encounterId}`);
        }}
        navigateButtonTitle="View associated encounter"
      />
    </div>
  );
}

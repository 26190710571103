/* LeftPane Wrapper Component */
import clsx from "clsx";
import FocusTrap from "focus-trap-react";
import { Inter } from "next/font/google";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// store
import { setLeftPaneOpen } from "@/components/drawer/drawerSlice";
import FacePage from "@/components/facePage";
// components
import Icon from "@/components/icons";

// constants

import { RootState } from "@/store/store";

import { DRAWER_TYPES } from "./dispatcher";
// styles
import styles from "./styles.module.scss";

const font = Inter({
  subsets: ["latin"]
});

export type LeftPaneProps = object

export default function LeftPane({}: LeftPaneProps) {
  /* Redux */
  const isVisible = useSelector(
    (state: RootState) => state.drawer.leftPaneOpen
  );

  const { type, props } = useSelector(
    (state: RootState) => state.drawer.leftPaneContent
  );

  const { sessionInfo: cookie } = useSelector((state: RootState) => state.auth);

  const DRAWER_DISPATCHER = {
    [DRAWER_TYPES.FACEPAGE]: FacePage
  };
  const Content = DRAWER_DISPATCHER[type || ""];
  const dispatch = useDispatch();

  // Local state //
  const [active, setActive] = useState<boolean>(false);

  useEffect(() => {
    /**
     * If the drawer is open (`isVisible` is true), a 1-second timeout is used to delay
     * changing the `active` state and passing it to the <FocusTrap /> component.
     * This prevents errors during the drawer's transition. <FocusTrap/> requires
     * a visible "focusable" element at all times. Waiting for the transition ensures
     * the element is visible before trapping the focus.
     */
    if (isVisible) {
      setTimeout(() => {
        setActive(true);
      }, 1000);
    } else {
      setActive(false);
    }
  }, [isVisible]);

  // if the user is a patient, the left pane should not be visible
  if (cookie?.is_patient) {
    return null;
  }

  return (
    // trap focus inside left pane when drawer is visible (for accessibility)
    <FocusTrap
      active={active}
      focusTrapOptions={{
        allowOutsideClick: true,
        clickOutsideDeactivates: true
      }}
    >
      <div
        className={clsx(styles.LeftPane, font.className, {
          [styles.isVisible]: isVisible
        })}
      >
        <div>
          <nav>
            <button onClick={() => dispatch(setLeftPaneOpen(!isVisible))}>
              <Icon svg="back" height={8} />
            </button>
          </nav>
          {type && Content && (
            // @ts-expect-error Not defining props for Content
            <div className={styles.content}>{<Content {...props} />}</div>
          )}
        </div>
      </div>
    </FocusTrap>
  );
}

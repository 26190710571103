/* WriteRx Modal Trigger */
/* External Imports */
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
/* Local Imports */

// components
import Icon from "@/components/icons";
import Button from "@/components/button";

// constants
import { STYLES } from "@/globals/constants";
import { MODAL_TYPES } from "../../dispatcher";

// store
import { UserId } from "@/store/services/patient";
import { setModalContent, setModalIsOpen } from "../../modalSlice";
import { RootState } from "@/store/store";
import { useGetFeatureFlagsQuery } from "@/store/services/system";

// styles
import styles from "./styles.module.scss";

/* WriteRx Typescript Interface */
interface WriteRxProps {
  patientId?: UserId;
  disabled?: boolean;
}

export default function WriteRx({ patientId, disabled = false }: WriteRxProps) {
  /* Redux */
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const dispatch = useDispatch();
  const { data: featureFlags } = useGetFeatureFlagsQuery();
  const unauthorizedUser = !(
    sessionInfo?.is_superadmin ||
    sessionInfo?.is_provider ||
    sessionInfo?.is_ma
  );

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const handleOpenPrescriptionModal = () => {
    dispatch(
      setModalContent({
        type: MODAL_TYPES.RX,
        props: { patientId, title: "Write Rx" }
      })
    );
    dispatch(setModalIsOpen(true));
  };

  return (
    <div
      className={clsx(styles.WriteRx, {
        [styles.hidden]: !featureFlags?.rx_enabled || unauthorizedUser
      })}
    >
      <Button
        style={STYLES.SECONDARY}
        onClick={handleOpenPrescriptionModal}
        nativeButtonProps={{ disabled }}
      >
        <Icon svg="write_rx" />
        <p>Write Rx</p>
      </Button>
    </div>
  );
}

/* AddPatientToConversation  */
/* External Imports */
import clsx from "clsx";
import { useState } from "react";
import { useDispatch } from "react-redux";
/* Local Imports */

// components
import PatientPicker from "@/components/patientPicker";
import Button from "@/components/button";
import { setModalIsOpen } from "../../modalSlice";

// constants
import { STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import {
  ConversationId,
  InboxType,
  useUpdateConversationMutation
} from "@/store/services/conversations";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";
import { UserId } from "@/store/services/patient";

// styles
import styles from "./styles.module.scss";

/* AddPatientToConversation Typescript Interface */
interface AddPatientToConversationProps {
  conversationId: ConversationId;
  fullWidth?: boolean;
  inboxType: InboxType;
}

export default function AddPatientToConversation({
  conversationId,
  fullWidth,
  inboxType,
}: AddPatientToConversationProps) {
  /* Redux */
  const dispatch = useDispatch();
  const [updateConversation, { isLoading: isUpdating }] =
    useUpdateConversationMutation();

  /* Local State */
  const [selectedPatientId, setSelectedPatientId] = useState<UserId>();

  /* Effects */

  /* Event Handlers */
  const handleSelectPatient = (patientId: number) => {
    setSelectedPatientId(patientId);
  };

  const handleConfirm = () => {
    updateConversation({
      inboxType: inboxType,
      conversationId: conversationId,
      conversationUpdateParams: {
        patient_participant: {
          // selectedPatientId will always be defined when we get here
          // because the submit button is disabled until a patient is selected
          user_id: selectedPatientId as UserId,
          included: true
        }
      }
    })
      .unwrap()
      .then(() => {
        dispatch(setModalIsOpen(false));
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed add patient to conversation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCancel = () => {
    dispatch(setModalIsOpen(false));
  };

  return (
    <div
      className={clsx(styles.AddPatientToConversation, {
        [styles.fullWidth]: fullWidth
      })}
    >
      <p className={styles.message}>
        Select the patient to add as a recipient of this conversation. Patient
        will see entire message history and all future responses. You may remove
        this patient at any time.
      </p>

      <div className={styles.group}>
        <div className={styles.label}>Patient</div>
        <PatientPicker
          aria-label="Select Patient Recipient"
          // We don't allow clearning the patient selection
          // So we don't need to handle the case where the patientId is null
          onUpdatePatient={patientId =>
            handleSelectPatient(patientId as UserId)
          }
          initialPatientIds={[selectedPatientId as UserId]}
        />
      </div>
      <div className={styles.actions}>
        <Button
          type="button"
          style={fullWidth ? STYLES.SECONDARY_FULL : STYLES.SECONDARY}
          onClick={handleCancel}
        >
          Cancel
        </Button>
        <Button
          type="button"
          onClick={handleConfirm}
          nativeButtonProps={{ disabled: !selectedPatientId || isUpdating }}
          loading={isUpdating}
          style={fullWidth ? STYLES.FULL_WIDTH : STYLES.PRIMARY}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
}

import { backendSlice as api } from "../../../components/api/apiSlice";

const injectedRtkApi = api.injectEndpoints({
  endpoints: build => ({
    superbillUpdate: build.mutation<
      SuperbillUpdateApiResponse,
      SuperbillUpdateApiArg
    >({
      query: queryArg => ({
        url: `/encounter/${queryArg.encounterId}/superbill/update`,
        method: "POST",
        body: queryArg.superbillUpdatePayload
      })
    })
  }),
  overrideExisting: true
});
export { injectedRtkApi as superbill };
export type SuperbillUpdateApiResponse =
  /** status 200 API call successful. No extra information available.
   */ ErrorId;
export type SuperbillUpdateApiArg = {
  encounterId: EncounterId;
  /** Payload for updating an encounter's superbill.
   */
  superbillUpdatePayload: SuperbillUpdatePayload;
};
export type ErrorId = number;
export type ExtraErrorItem = {
  /** The name of the field that has an error.
   */
  field: string;
  /** Description of the error in the associated field.
   */
  message: string;
};
export type ErrorInfo = {
  /** Internal error ID. There is a defined list of error IDs, which may also appear in the database but should
    probably be implemented as a switch/case. This `error_id` is generated by the backed in response to various
    error conditions. 0 always means no error.
     */
  error: number;
  message: string;
  extra_data?: ExtraErrorItem[];
  /** If true, the error message is safe to display to the user. If false, this error may contain internal / technical information.
   */
  user_facing: boolean;
} | null;
export type EncounterId = number;
export type SuperbillStatus =
  | "IN_PROGRESS"
  | "SUBMITTED"
  | "DENIED"
  | "APPROVED"
  | "REQUIRES_CHANGES";
export type SuperbillUpdatePayload = {
  status: SuperbillStatus;
};
export const { useSuperbillUpdateMutation } = injectedRtkApi;

/* UnlinkPatientFromConversation  */
/* External Imports */
import clsx from "clsx";
import { useDispatch } from "react-redux";

/* Local Imports */

import Button from "@/components/button";

// constants
import { PAGES, STATUS_KEYS, STYLES } from "@/globals/constants";

// store
import { setModalIsOpen } from "../../modalSlice";
import {
  useUpdateConversationMutation,
  ConversationRecipient,
  ConversationId,
  InboxType
} from "@/store/services/conversations";
import { addAlertToToastTrough } from "@/components/toastTrough/toastSlice";

// styles
import styles from "./styles.module.scss";

// utils
import { nameAndDOB } from "@/globals/helpers/formatters";

/* UnlinkPatientFromConversation Typescript Interface */
interface UnlinkPatientFromConversationProps {
  conversationId: ConversationId;
  patient: ConversationRecipient;
  inboxType: InboxType;
}

export default function UnlinkPatientFromConversation({
  conversationId,
  patient,
  inboxType
}: UnlinkPatientFromConversationProps) {
  /* Redux */
  const dispatch = useDispatch();
  const [updateConversation] = useUpdateConversationMutation();

  /* Local State */

  /* Effects */

  /* Event Handlers */
  const handleConfirm = () => {
    // TODO: Implement linking conversation to patient
    updateConversation({
      inboxType,
      conversationId,
      conversationUpdateParams: {
        patient_participant: {
          // unset
          user_id: undefined,
          included: false
        }
      }
    })
      .unwrap()
      .then(() => {
        dispatch(setModalIsOpen(false));
        dispatch(
          addAlertToToastTrough({
            message: "Conversation unlinked",
            type: STATUS_KEYS.SUCCESS
          })
        );
      })
      .catch(() => {
        dispatch(
          addAlertToToastTrough({
            message: "Failed to unlink conversation",
            type: STATUS_KEYS.ERROR
          })
        );
      });
  };

  const handleCancel = () => {
    dispatch(setModalIsOpen(false));
  };

  const handleOpenChart = () => {
    // open patient chart in new window
    window.open(`${PAGES.PATIENTS}/${patient.user_id}`, "_blank");
  };

  return (
    <div className={clsx(styles.UnlinkPatientFromConversation)}>
      <p className={styles.message}>
        Are you sure you want to prevent all future messages in this
        conversation from being displayed on the chart of the following patient?
      </p>

      <button
        className={clsx(styles.action, styles.secondary)}
        type="button"
        onClick={handleOpenChart}
      >
        {nameAndDOB(patient)}
      </button>
      <p className={styles.message}>
        You may relink a conversation again at anytime in future.
      </p>
      <div className={styles.actions}>
        <Button type="button" style={STYLES.SECONDARY} onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          type="button"
          onClick={handleConfirm}
          // TODO: show loading state while linking
        >
          Yes, Unlink
        </Button>
      </div>
    </div>
  );
}

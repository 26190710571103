import dayjs from "dayjs";
import {
  Controller,
  FieldValues,
  Path,
  SubmitHandler,
  UseFormReturn
} from "react-hook-form";

import Input from "@/components/input";
import WeightInput, { Weight } from "@/components/input/weightInput";

import { FieldMetadata } from "./types";

interface FlowSheetInputProps<T> {
  fieldKey: Path<T>;
  metadata: FieldMetadata;
  form: UseFormReturn;
  submitOnBlur: boolean;
  onSubmit: SubmitHandler<FieldValues>;
}

export default function FlowSheetInput<T>({
  fieldKey,
  metadata,
  form,
  submitOnBlur,
  onSubmit
}: FlowSheetInputProps<T>) {
  const { control, handleSubmit, register, getValues } = form;

  const type = metadata.type;
  switch (type) {
    case "weight":
      return (
        <Controller
          name={fieldKey}
          control={control}
          rules={{
            onBlur: () => {
              if (submitOnBlur) {
                handleSubmit(onSubmit)();
              }
            }
          }}
          render={({ field: { onChange, onBlur, value } }) => (
            <WeightInput
              value={value as Weight}
              onChange={onChange}
              hiddenLabel
              onBlur={onBlur}
            />
          )}
        />
      );
    case "date":
      // The date isn't editted in the flow sheet
      return <p>{dayjs(getValues(fieldKey)).format("MM/DD/YYYY")}</p>;
    case "pTime":
      return (
        <p>
          {dayjs(`${getValues(fieldKey)}`, "YYYYMMDDHHmmss").format(
            "MM/DD/YYYY"
          )}
        </p>
      );
    case "number":
      return (
        <Input
          type="number"
          name={fieldKey}
          id={fieldKey}
          label={metadata.label}
          hiddenLabel
          register={register}
          placeholder=" "
          rules={{
            onBlur: () => {
              if (submitOnBlur) {
                handleSubmit(onSubmit)();
              }
            }
          }}
        />
      );
    case "displayString":
      return <p>{String(getValues(fieldKey) || "-")}</p>;
    case "percentage":
      let percentage = getValues(fieldKey) as number;
      let string = "-";
      if (percentage) {
        percentage = Math.round(percentage * 10) / 10;
        string = `${percentage}%`;
      }
      return <p>{string}</p>;
    case "note":
      // Notes in the input section don't need anything
      return <p>&nbsp;</p>;
    default:
      return (
        <Input
          type="text"
          name={fieldKey}
          id={fieldKey}
          label={metadata.label}
          hiddenLabel
          register={register}
          placeholder=" "
          rules={{
            onBlur: () => {
              if (submitOnBlur) {
                handleSubmit(onSubmit)();
              }
            }
          }}
        />
      );
  }
}

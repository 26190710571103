import * as generated from "./generated/patient";

const patient = generated.patient.enhanceEndpoints({
  addTagTypes: ["Patient-Files", "Recalls"],
  endpoints: {
    patientGetLabOrders: {
      providesTags: (_res, _err, _req) => ["LabOrder"]
    },
    patientUpsert: {
      invalidatesTags: (_res, _err, req) => [
        {
          type: "Patients",
          id:
            req.patientUpsertRequest.parent_ids &&
            req.patientUpsertRequest.parent_ids[0]
        },
        "Patients"
      ]
    },
    patientGetCareHistory: {
      providesTags: ["CareHistory"]
    },
    patientGetInfo: {
      providesTags: (_resp, _err, req) => [
        { type: "Patients", id: req.patientId },
        { type: "Problems", id: req.patientId }
      ]
    },
    patientSubmitForm: {
      invalidatesTags: (_res, _err, req) => [
        { type: "Patients", id: req.patientId },
        { type: "Forms" }
      ]
    },
    patientGetForm: {
      providesTags: ["Forms"]
    },
    patientGetFiles: {
      providesTags: (_res, _err, req) => [
        { type: "Patient-Files", id: req.patientId }
      ]
    },
    patientUploadFiles: {
      invalidatesTags: (_res, _err, req) => [
        { type: "Patient-Files", id: req.patientId }
      ]
    },
    patientDeleteFiles: {
      invalidatesTags: (_res, _err, req) => [
        { type: "Patient-Files", id: req.patientId }
      ]
    },
    patientArchive: {
      invalidatesTags: ["Patients"]
    },
    patientUnarchive: {
      invalidatesTags: ["Patients"]
    },
    patientArchiveDiagnosis: {
      invalidatesTags: (_resp, _err, req) => [
        { type: "Patients", id: req.patientId },
        { type: "Problems", id: req.patientId }
      ]
    },
    patientGetTransactions: {
      providesTags: ["Transactions"]
    },
    patientCreateTransaction: {
      invalidatesTags: ["Transactions"]
    },
    patientUpdateTransaction: {
      invalidatesTags: ["Transactions"]
    },
    patientGetBalance: {
      providesTags: ["Transactions"]
    },
    patientGetInfantGrowthSheet: {
      providesTags: ["InfantFlowSheet"]
    },
    getPatientRecall: {
      providesTags: ["Recalls"]
    },
    listPatientRecalls: {
      providesTags: ["Recalls"]
    },
    createPatientRecall: {
      invalidatesTags: ["Recalls"]
    },
    deletePatientRecall: {
      invalidatesTags: ["Recalls"]
    },
    updatePatientRecall: {
      invalidatesTags: ["Recalls"]
    },
    patientCloseDoseSpot: {
      invalidatesTags: ["Patients"]
    },
    createtHealthHistoryEntry: {
      invalidatesTags: ["Patients"]
    },
    getHealthHistory: {
      providesTags: ["Patients"]
    },
    updateHealthHistoryEntry: {
      invalidatesTags: ["Patients"]
    },
    deleteHealthHistoryEntry: {
      invalidatesTags: ["Patients"]
    },
    taskGeneratePdf: {
      invalidatesTags: ["Tasks"]
    },
    patientDelete: {
      invalidatesTags: ["Patients"]
    }
  }
});

export * from "./generated/patient";
export default patient;
export const {
  useEncounterCreateMutation,
  useEncounterListQuery,
  useLazyEncounterListQuery,
  useEncounterBriefListQuery,
  useLazyEncounterBriefListQuery,
  useEncounterUpdateMutation,
  useEncounterListVersionsQuery,
  useLazyEncounterListVersionsQuery,
  useEncounterInfoQuery,
  useLazyEncounterInfoQuery,
  useEncounterSubmitMutation,
  useEncounterDeleteMutation,
  useEncounterLaborInfoQuery,
  useLazyEncounterLaborInfoQuery,
  useEncounterLaborSheetQuery,
  useLazyEncounterLaborSheetQuery,
  useEncounterLaborUpsertMutation,
  useEncounterLaborEventUpsertMutation,
  useDevLaborEventUpsertMutation,
  useEncounterLaborEventDeleteMutation,
  useEncounterLaborRecurringEventUpdateMutation,
  useEncounterLaborRecurringEventCreateMutation,
  useEncounterLaborRecurringEventDeleteMutation,
  useLocationGetUserListQuery,
  useLazyLocationGetUserListQuery,
  useMedicationCreateForPatientMutation,
  useMedicationUpdateForPatientMutation,
  useMedicationDeleteFromPatientMutation,
  usePatientUpsertMutation,
  usePatientDeleteMutation,
  usePatientGetInfoQuery,
  useLazyPatientGetInfoQuery,
  usePatientInfoExportQuery,
  useLazyPatientInfoExportQuery,
  usePatientGetFormQuery,
  useLazyPatientGetFormQuery,
  usePatientSubmitFormMutation,
  usePatientSendInviteLinkMutation,
  usePatientPediatricTransferRecordsPdfQuery,
  useLazyPatientPediatricTransferRecordsPdfQuery,
  useGetHealthHistoryQuery,
  useLazyGetHealthHistoryQuery,
  useCreatetHealthHistoryEntryMutation,
  useDeleteHealthHistoryEntryMutation,
  useUpdateHealthHistoryEntryMutation,
  usePatientGeneratePdfMutation,
  usePatientGetFilesQuery,
  useLazyPatientGetFilesQuery,
  usePatientUploadFilesMutation,
  usePatientDeleteFilesMutation,
  usePatientGetInfantGrowthSheetQuery,
  useLazyPatientGetInfantGrowthSheetQuery,
  usePatientArchiveMutation,
  usePatientGetDoseSpotSsoUrlQuery,
  useLazyPatientGetDoseSpotSsoUrlQuery,
  usePatientCloseDoseSpotMutation,
  usePatientListRxQuery,
  useLazyPatientListRxQuery,
  usePatientUnarchiveMutation,
  useProblemUpsertMutation,
  useProblemInfoQuery,
  useLazyProblemInfoQuery,
  usePatientDiagnosisHistoryQuery,
  useLazyPatientDiagnosisHistoryQuery,
  usePatientArchiveDiagnosisMutation,
  usePatientGetLabOrdersQuery,
  useLazyPatientGetLabOrdersQuery,
  usePatientDeletePharmacyMutation,
  usePatientGetBalanceQuery,
  useLazyPatientGetBalanceQuery,
  usePatientGetTransactionsQuery,
  useLazyPatientGetTransactionsQuery,
  usePatientCreateTransactionMutation,
  usePatientUpdateTransactionMutation,
  usePregnancyUpsertMutation,
  useListPastPregnanciesQuery,
  useLazyListPastPregnanciesQuery,
  usePregnancyInfoQuery,
  useLazyPregnancyInfoQuery,
  usePregnancyLaborInfoQuery,
  useLazyPregnancyLaborInfoQuery,
  usePregnancyLaborSheetQuery,
  useLazyPregnancyLaborSheetQuery,
  usePregnancyLaborUpsertMutation,
  usePregnancyLaborEventUpsertMutation,
  usePregnancyLaborEventDeleteMutation,
  usePregnancyLaborRecurringEventUpdateMutation,
  usePregnancyLaborRecurringEventCreateMutation,
  usePregnancyLaborRecurringEventDeleteMutation,
  useListPatientRecallsQuery,
  useLazyListPatientRecallsQuery,
  useCreatePatientRecallMutation,
  useDeletePatientRecallMutation,
  useGetPatientRecallQuery,
  useLazyGetPatientRecallQuery,
  useUpdatePatientRecallMutation,
  useTaskCreateMutation,
  useTaskUpdateMutation,
  useTaskInfoQuery,
  useLazyTaskInfoQuery,
  useTaskDeleteMutation,
  useTaskCompleteMutation,
  useTaskMarkIncompleteMutation,
  useTaskListQuery,
  useLazyTaskListQuery,
  useTaskGeneratePdfMutation,
  useTaskListAssignablesQuery,
  useLazyTaskListAssignablesQuery
} = patient;

/* ToastAlert Name */
/* External Imports */
import clsx from "clsx";
import { motion, AnimatePresence } from "framer-motion";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

/* Local Imports */

// components
import Icon from "@/components/icons";

// constants
import { STATUS_KEYS } from "@/globals/constants";

// store
import { removeAlertFromToastTrough } from "./toastSlice";

// styles
import styles from "./styles.module.scss";

/* ToastAlert Typescript Interface */
interface ToastAlertProps {
  message?: string;
  type: keyof typeof STATUS_KEYS;
  id: number;
}

export default function ToastAlert({ message, type, id }: ToastAlertProps) {
  /* Redux */
  const dispatch = useDispatch();
  /* Local State */

const AUTO_DISMISS_DURATION = 3000;

useEffect(() => {
  const timer = setTimeout(() => {
    dispatch(removeAlertFromToastTrough(id));
  }, AUTO_DISMISS_DURATION);

  return () => clearTimeout(timer); // Cleanup the timer on unmount
}, [id, dispatch]);

  /* Effects */

  /* Event Handlers */

  const iconMap = {
    [STATUS_KEYS.SUCCESS]: "check_success_outline",
    [STATUS_KEYS.ERROR]: "x_failure_outline",
    [STATUS_KEYS.LOADING]: <div className={styles.spinner} />,
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0, y: 200 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.25 }}
        key={id}
        className={clsx(styles.ToastAlert, styles[type])}
        role="alert" // notify screen readers
        aria-live="assertive"
        onClick={() => dispatch(removeAlertFromToastTrough(id))} // dismiss on click
      >
        {iconMap[type] && (
          typeof iconMap[type] === "string" 
            ? <Icon svg={iconMap[type]} /> 
            : iconMap[type]
        )}
        {message}
      </motion.div>
    </AnimatePresence>
  );
}

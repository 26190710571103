// TipTap
import { AnyExtension, Extension } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Placeholder from "@tiptap/extension-placeholder";
import Link from "@tiptap/extension-link";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import Highlight from "@tiptap/extension-highlight";

interface GetDefaultExtensionsArg {
  placeholder?: string;
  // Should return true
  submitOnEnterFunction?: () => void;
}

/**
 * A helper function to get the default extensions that should be used in pretty much
 * everything text area component.
 * @param placeholder Optional placeholder text for the text area
 * @param submitOnEnterFunction Optional function to run on ctrl-enter
 * @returns The default extensions (not including dot phrases) for the text area
 */
const getDefaultExtensions = ({
  placeholder,
  submitOnEnterFunction
}: GetDefaultExtensionsArg) => {
  const defaultExtensions: AnyExtension[] = [
    StarterKit.configure({
      blockquote: false,
      codeBlock: false,
      heading: {
        levels: [1]
      },
      horizontalRule: false,
      code: false,
      strike: false
    }),
    TaskList,
    Highlight.configure({
      multicolor: true
    }),
    Link.extend({ inclusive: false }).configure({
      autolink: true,
      linkOnPaste: true,
      defaultProtocol: "https",
      protocols: ["http", "https"],
      HTMLAttributes: {
        rel: "noopener noreferrer",
        target: "_blank"
      },
      isAllowedUri: (url, ctx) => {
        try {
          // construct URL
          const parsedUrl = url.includes(":")
            ? new URL(url)
            : new URL(`${ctx.defaultProtocol}://${url}`);

          // use default validation
          if (!ctx.defaultValidate(parsedUrl.href)) {
            return false;
          }

          // disallowed protocols
          const disallowedProtocols = ["ftp", "file", "mailto"];
          const protocol = parsedUrl.protocol.replace(":", "");

          if (disallowedProtocols.includes(protocol)) {
            return false;
          }

          // only allow protocols specified in ctx.protocols
          const allowedProtocols = ctx.protocols.map(p =>
            typeof p === "string" ? p : p.scheme
          );

          if (!allowedProtocols.includes(protocol)) {
            return false;
          }

          // EXAMPLE: disallowed domains (Do we have any URLs we want to disallow?)
          // const disallowedDomains = ['example-phishing.com', 'malicious-site.net']
          // const domain = parsedUrl.hostname

          // if (disallowedDomains.includes(domain)) {
          //   return false
          // }

          // all checks have passed
          return true;
        } catch {
          return false;
        }
      }
    }),
    TaskItem.configure({
      nested: true
    }),
    Placeholder.configure({
      placeholder: placeholder
    }),
    Extension.create({
      name: "EnterExtension",
      addKeyboardShortcuts() {
        return {
          "Ctrl-Enter": () => {
            submitOnEnterFunction && submitOnEnterFunction();
            return true;
          }
        };
      }
    })
  ];

  return defaultExtensions;
};

export { getDefaultExtensions };

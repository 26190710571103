import { useCallback, useEffect, useRef, useState } from "react";

import { useAuth } from "@/hooks";
import { usePostHog } from "posthog-js/react";

import {
  ModalController,
  useModal
} from "@/components/modalV2/ModalController";

import { useReportFrontendErrorMutation } from "@/store/services/system";

import styles from "./styles.module.scss";

interface InactivityMonitorProps {
  inactivityThreshold: number;
  inactivityResponseTime: number;
  activityEvents: string[];
  checkInterval: number;
}

export default function InactivityMonitor({
  inactivityThreshold,
  inactivityResponseTime,
  activityEvents,
  checkInterval
}: InactivityMonitorProps) {
  const { logout } = useAuth();
  const posthog = usePostHog();
  const [modalActions, modalState] = useModal();
  const [_inactiveTime, setInactiveTime] = useState(0);
  const [lastActivity, setLastActivity] = useState(Date.now());

  const [reportError] = useReportFrontendErrorMutation();
  // Simple flag to track if we've warned about inactivity in this session
  const hasWarnedInactivity = useRef(false);
  // Cooldown time for modal
  const canShowModalAfter = useRef(0);
  // Timeout for auto-logout after warning
  const logoutTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  // Track when the warning was shown
  const warningShownAtRef = useRef(0);

  useEffect(() => {
    posthog.capture("inactivity_monitor_initialized", {
      timestamp: new Date().toISOString()
    });
    if (modalState.isOpen) {
      posthog.capture("inactivity_monitor_modal_opened", {
        timestamp: new Date().toISOString()
      });
    }

    return () => {
      posthog.capture("inactivity_monitor_removed");
    };
  }, [modalState.isOpen, posthog]);

  const resetInactivityTimer = useCallback(() => {
    setLastActivity(Date.now());

    // Reset the warning flag
    hasWarnedInactivity.current = false;

    // Clear auto-logout timeout if it exists
    if (logoutTimeoutRef.current) {
      clearTimeout(logoutTimeoutRef.current);
      logoutTimeoutRef.current = null;
    }

    // Close modal if it's open
    if (modalState.isOpen) {
      modalActions.close();
      posthog.capture("inactivity_monitor_modal_closed", {
        timestamp: new Date().toISOString()
      });
      // Set cooldown after closing
      canShowModalAfter.current = Date.now() + 1000;
    }
  }, [posthog, modalActions, modalState.isOpen]);

  useEffect(() => {
    // Add event listeners for user activity
    activityEvents.forEach(event => {
      window.addEventListener(event, resetInactivityTimer);
    });

    // Check inactivity periodically
    const interval = setInterval(() => {
      const currentInactiveTime = Date.now() - lastActivity;
      setInactiveTime(currentInactiveTime);

      if (
        currentInactiveTime >= inactivityThreshold &&
        !hasWarnedInactivity.current &&
        !modalState.isOpen &&
        Date.now() > canShowModalAfter.current
      ) {
        hasWarnedInactivity.current = true;
        warningShownAtRef.current = Date.now();
        modalActions.open({});

        // Set timeout for auto-logout if user doesn't respond
        logoutTimeoutRef.current = setTimeout(() => {
          console.error("User has been inactive for too long");
          reportError({
            reportSystemError: {
              message:
                "User has been inactive for too long and has been logged out",
              stack_trace: (() => new Error().stack)()
            }
          });
          logout();
        }, inactivityResponseTime);
      }
    }, checkInterval);

    return () => {
      // Clean up event listeners and interval
      activityEvents.forEach(event => {
        window.removeEventListener(event, resetInactivityTimer);
      });
      clearInterval(interval);

      if (logoutTimeoutRef.current) {
        clearTimeout(logoutTimeoutRef.current);
        logoutTimeoutRef.current = null;
      }
    };
  }, [
    activityEvents,
    inactivityThreshold,
    inactivityResponseTime,
    checkInterval,
    lastActivity,
    modalActions,
    modalState.isOpen,
    logout,
    reportError,
    resetInactivityTimer
  ]);

  // Calculate time left to respond before auto-logout
  const getTimeLeftToRespond = () => {
    if (!warningShownAtRef.current) return inactivityResponseTime;

    const elapsedSinceWarning = Date.now() - warningShownAtRef.current;
    const timeLeft = Math.max(0, inactivityResponseTime - elapsedSinceWarning);

    const seconds = Math.floor(timeLeft / 1000);
    return `${Math.floor(seconds / 60)}m ${seconds % 60}s`;
  };

  return (
    <ModalController
      title="Are you still there?"
      width={600}
      close={() => {
        modalActions.close();
        // Set cooldown after closing
        canShowModalAfter.current = Date.now() + 1000;
      }}
      isClosable={true}
      modalState={modalState}
      modalComponent={() => (
        <div className={styles.InactivityModal}>
          <p>You've been inactive for awhile.</p>
          <p>
            You will be logged out in {getTimeLeftToRespond()} if you don't take
            any action.
          </p>
        </div>
      )}
    />
  );
}

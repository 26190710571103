import {
  SelectDefaultStyles,
  SelectDefaultTheme
} from "@/styles/themes/selectDefaultTheme";
import { skipToken } from "@reduxjs/toolkit/query";
import clsx from "clsx";
import dayjs from "dayjs";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";

import Button from "@/components/button";
import ControlledOptionsField from "@/components/forms/generated/options";
import Input from "@/components/input";
import TextArea from "@/components/textArea";

import { STYLES } from "@/globals/constants";

import { EncounterId } from "@/store/services/encounter";
import {
  DevLaborEventUpsertApiArg,
  LaborMedicationEntry,
  LaborProgressUpdateEvent,
  LaborProgressUpdateForm,
  UserId,
  useDevLaborEventUpsertMutation
} from "@/store/services/patient";
import { usePracticeGetProvidersQuery } from "@/store/services/practice";
import { RootState } from "@/store/store";

import { FORMAT } from "@/globals/helpers/formatters";

import Clock from "../../../../public/svgs/clock.svg";
import styles from "./styles.module.scss";

type FormWithTimestamp = LaborProgressUpdateForm & {
  timestamp: string;
  author: UserId;
};

type FormPart = {
  label: string;
  id: keyof LaborProgressUpdateForm;
};

const temp_vitals: FormPart[] = [
  {
    label: "Heart rate",
    id: "heart_rate"
  },
  {
    label: "Blood pressure",
    id: "blood_pressure"
  },
  {
    label: "Temperature",
    id: "temperature"
  },
  {
    label: "Temperature Location",
    id: "temperature_location"
  },
  {
    label: "Maternal Position",
    id: "maternal_position"
  },
  {
    label: "Intake",
    id: "intake"
  },
  {
    label: "Output",
    id: "output"
  },
  {
    label: "Hydrotherapy",
    id: "hydrotherapy"
  },
  {
    label: "Water Temp",
    id: "water_temperature"
  }
];

const temp_intake_output = [
  {
    label: "Food",
    id: "food"
  },
  {
    label: "Drink",
    id: "drink"
  },
  {
    label: "Void",
    id: "void"
  },
  {
    label: "BM",
    id: "bm"
  },
  {
    label: "Recipient",
    id: "recipient"
  }
];

type MedicationFormPart = {
  label: string;
  id: keyof LaborMedicationEntry;
};

const maternal_medication: MedicationFormPart[] = [
  {
    label: "Name",
    id: "name"
  },
  {
    label: "Dose",
    id: "dose"
  },
  {
    label: "Units",
    id: "units"
  },
  {
    label: "Route",
    id: "route"
  },
  {
    label: "Site Given",
    id: "site_given"
  },
  {
    label: "Lot Number",
    id: "lot_number"
  },
  {
    label: "Expiration",
    id: "expiration"
  },
  {
    label: "Indication",
    id: "indication"
  }
];

const contractionFields: FormPart[] = [
  { id: "frequency", label: "Frequency" },
  { id: "duration", label: "Duration" },
  { id: "strength", label: "Strength" }
];

const progressFields: FormPart[] = [
  { id: "dilation", label: "Dilation" },
  { id: "effacement", label: "Effacement" },
  { id: "station", label: "Station" }
];

type ProviderOptionType = {
  label: string;
  value: number;
};

interface LaborProgressUpdateProps {
  encounterId: EncounterId;
}

export default function LaborProgressUpdate({
  encounterId
}: LaborProgressUpdateProps) {
  const { sessionInfo } = useSelector((state: RootState) => state.auth);

  const [laborUpsert] = useDevLaborEventUpsertMutation();

  const { data: providers = [] } = usePracticeGetProvidersQuery(
    sessionInfo ? { practiceId: sessionInfo.practice_id } : skipToken
  );

  const form = useForm<FormWithTimestamp>();
  const { control, register, setValue } = form;

  const providerOptions: ProviderOptionType[] = useMemo(() => {
    return providers.map(provider => ({
      label: FORMAT.name(provider),
      value: provider.user_id
    }));
  }, [providers]);

  const onSubmit = (data: FormWithTimestamp) => {
    console.log(data);
    const { timestamp, author, ...forms } = data;

    const typedForms: LaborProgressUpdateForm = forms;

    const event: LaborProgressUpdateEvent = {
      event_id: Math.random() + "rand",
      timestamp: 3,
      author: author,
      forms: typedForms
    };

    const arg: DevLaborEventUpsertApiArg = {
      encounterId,
      stage: "LABOR",
      laborEvent: "Progress Update",
      laborEventUpsert: {
        event
      }
    };

    laborUpsert(arg).unwrap().then(val => console.log(val));
  };

  return (
    <form
      className={styles.ProgressUpdateForm}
      onSubmit={form.handleSubmit(onSubmit)}
    >
      <div className={clsx(styles.spanTwo, styles.columnContainer)}>
        <fieldset className={clsx(styles.bgLight)}>
          <legend>Entry Information</legend>
          <div className={styles.inputs}>
            <div className={styles.dateTimeContainer}>
              {/* NOTE: I'm using the native datetime picker because we're migrating
              away from pTime anyway and this is a much better option than what we had before */}
              <Input
                type="datetime-local"
                label="Entry time"
                id="timestamp"
                name="timestamp"
                register={register}
              />
            </div>
            <div className={styles.nowButtonContainer}>
              <button
                className={styles.nowButton}
                onClick={() => {
                  setValue(
                    "timestamp",
                    dayjs().format("YYYY-MM-DD[T]hh:mm:ss")
                  );
                }}
                type={"button"}
              >
                <Clock stroke={styles.primary700} width={16} height={16} />
                Now
              </button>
            </div>
            <Controller
              name="author"
              control={control}
              render={({ field: { onChange, value } }) => (
                <label className="standardLabel">
                  <span>Author:</span>
                  <Select
                    options={providerOptions}
                    value={
                      providerOptions.find(item => item.value === value) || null
                    }
                    onChange={(item: any) => onChange(item?.value)}
                    theme={SelectDefaultTheme}
                    styles={SelectDefaultStyles}
                  />
                </label>
              )}
            />
          </div>
        </fieldset>
        <fieldset>
          <legend>Maternal Vitals & Assessment</legend>
          <div className={styles.inputs}>
            {temp_vitals.map(item => {
              if (item.id === "maternal_position") {
                return (
                  <div className={styles.fullWidth} key={item.id}>
                    <Input
                      label={item.label as string}
                      type="text"
                      id={item.id}
                      key={item.id}
                      name={item.id}
                      placeholder={item.label}
                      register={register}
                      noMaxWidth
                    />
                  </div>
                );
              } else if (item.id === "hydrotherapy") {
                return (
                  <ControlledOptionsField
                    label="Hydrotherapy"
                    name={item.id}
                    id={item.id}
                    key={item.id}
                    control={control}
                    isHorizontal={false}
                    options={[
                      { label: "Yes", id: "yes" },
                      { label: "No", id: "no" }
                    ]}
                  />
                );
              } else {
                return (
                  <Input
                    label={item.label as string}
                    type="text"
                    id={item.id}
                    key={item.id}
                    name={item.id}
                    placeholder={item.label}
                    noMaxWidth
                    register={register}
                  />
                );
              }
            })}
          </div>
        </fieldset>
      </div>
      <div className={clsx(styles.spanFive, styles.columnContainer)}>
        <fieldset>
          <legend>Fetal Heart Tones</legend>
          <div className={clsx(styles.inputs, styles.fitRow)}>
            <div className={styles.halfWidth}>
              <Input
                label="FHT"
                type="text"
                id="fht"
                name="fht"
                placeholder="FHT"
                register={register}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                label="Baseline Shift?"
                name="baseline_shift"
                id="baseline_shift"
                control={control}
                isHorizontal={false}
                options={[
                  { label: "Yes", id: "yes" },
                  { label: "No", id: "no" }
                ]}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                label="Relative to contractions"
                name="relative_to_contractions"
                control={control}
                isHorizontal={false}
                options={[
                  { label: "before", id: "before" },
                  { label: "during", id: "during" },
                  { label: "after", id: "after" },
                  { label: "All", id: "all" }
                ]}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                label="Accelerations"
                name="accelerations"
                control={control}
                isHorizontal={false}
                options={[
                  { label: "Yes", id: "yes" },
                  { label: "No", id: "no" }
                ]}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                label="Decelerations"
                name="decelerations"
                control={control}
                isHorizontal={false}
                options={[
                  { label: "Yes", id: "yes" },
                  { label: "No", id: "no" }
                ]}
              />
            </div>
            <div className={styles.flexUnset}>
              <ControlledOptionsField
                options={[
                  { label: "Doppler", id: "doppler" },
                  { label: "Fetoscope", id: "fetoscope" },
                  { label: "EFM", id: "efm" }
                ]}
                label="Listening device"
                name={"listening_device"}
                control={control}
                isHorizontal={false}
              />
            </div>
          </div>
        </fieldset>
        <div className={clsx(styles.columnContainer)}>
          <fieldset className={clsx(styles.spanThree)}>
            <legend className={clsx("t4 dark", styles.sectionTitle)}>
              Contractions
            </legend>
            <div className={styles.inputs}>
              {contractionFields.map(field => (
                <Input
                  key={field.id}
                  type="text"
                  label={field.label}
                  name={field.id}
                  id={field.id}
                  fullWidth
                  fitWidth
                  noMaxWidth
                  register={register}
                />
              ))}
            </div>
          </fieldset>

          {/** Cervical Exam section */}
          <fieldset className={clsx(styles.spanThree)}>
            <legend>Cervical Exam</legend>
            <div className={styles.inputs}>
              {progressFields.map(field => (
                <Input
                  key={field.id}
                  type="text"
                  label={field.label}
                  name={field.id}
                  id={field.id}
                  fullWidth
                  fitWidth
                  register={register}
                />
              ))}
            </div>
          </fieldset>
          <fieldset>
            <legend>Medication</legend>
            <div className={styles.inputs}>
              {maternal_medication.map(item => (
                <Input
                  label={item.label as string}
                  type="text"
                  id={item.id}
                  key={item.id}
                  name={`medication.${item.id}`}
                  placeholder={item.label}
                  register={register}
                />
              ))}
            </div>
          </fieldset>
        </div>
      </div>

      <fieldset className={styles.spanSix}>
        <legend>Notes / Comments</legend>
        <TextArea label="Notes" hiddenLabel name="notes" id="notes" rows={4} />
      </fieldset>
      <Button style={STYLES.FULL_WIDTH} type="submit">
        Add to flowsheet
      </Button>
    </form>
  );
}
